
export class DateRange {
    dateFrom: Date;
    dateTo: Date;

    contains(date: Date): boolean {
    if (date.getTime() < this.dateFrom.getTime() || date.getTime() > this.dateTo.getTime()) {return false; }
        return true;
    }

    isAfter(date: Date) {
        return date.getTime() < this.dateFrom.getTime();
    }

    isBefore(date: Date) {
        return date.getTime() > this.dateTo.getTime();
    }
 }

export class TimeUnits {
    private static SECOND = 1000;
    private static MINUTE = 60 * TimeUnits.SECOND;
    private static HOUR = 60 * TimeUnits.MINUTE;
    private static DAY = 24 * TimeUnits.HOUR;

    private unit: number;
    private no: number;

    public static Seconds(no: number) {
        return new TimeUnits(TimeUnits.SECOND, no);
    }
    public static Minues(no: number) {
        return new TimeUnits(TimeUnits.MINUTE, no);
    }
    public static Hours(no: number) {
        return new TimeUnits(TimeUnits.HOUR, no);
    }
    public static Days(no: number) {
        return new TimeUnits(TimeUnits.DAY, no);
    }

    public toMilis() {
        return this.no * this.unit;
    }
    private constructor(timeUnit: number, no: number) {
        this.unit = timeUnit;
        this.no = no;
    }
}

export class DateRangeSet {
    orderedRanges: DateRange[] = [];

    add(range: DateRange) {
        let leftContainingIndex: number = null;
        let rightContainingIndex: number = null;
        let leftInsertIndex = 0;
        let rightInsertIndex = 0;

        for (let i = 0; i < this.orderedRanges.length; i++)  {
            const examItem = this.orderedRanges[i];
            if (!leftContainingIndex && examItem.contains(range.dateFrom)) {
                leftContainingIndex = i;
            }
            if (!rightContainingIndex && examItem.contains(range.dateTo)) {
                rightContainingIndex = i;
            }
            if (examItem.isBefore(range.dateFrom)) {
                leftInsertIndex = i + 1;
            }
            if (rightContainingIndex || examItem.isAfter(range.dateTo)) {
                break;
            }
            rightInsertIndex = i + 1;
        }

        const leftIdx = leftContainingIndex != null ? leftContainingIndex : leftInsertIndex;
        const rightIdx = rightContainingIndex != null ? rightContainingIndex + 1 : rightInsertIndex;

        const toJoin = this.orderedRanges.slice(leftIdx, rightIdx);
        toJoin.push(range);
        const joined = this.join(toJoin);
        this.orderedRanges.splice(leftIdx, rightIdx - leftIdx, joined);
        return this;
    }

    sub(range: DateRange) {
        let leftContainingIndex: number = null;
        let rightContainingIndex: number = null;
        let leftInsertIndex = 0;
        let rightInsertIndex = 0;

        for (let i = 0; i < this.orderedRanges.length; i++)  {
            const examItem = this.orderedRanges[i];
            if (!leftContainingIndex && examItem.contains(range.dateFrom)) {
                leftContainingIndex = i;
            }
            if (!rightContainingIndex && examItem.contains(range.dateTo)) {
                rightContainingIndex = i;
            }

            if (examItem.isBefore(range.dateFrom)) {
                leftInsertIndex = i + 1;
            }
            if (rightContainingIndex || examItem.isAfter(range.dateTo)) {
                break;
            }
            rightInsertIndex = i + 1;
        }

        if (leftContainingIndex != null) {
            this.orderedRanges.splice(leftContainingIndex, 1, ...this.split(this.orderedRanges[leftContainingIndex], range.dateFrom));
            leftInsertIndex = leftContainingIndex + 1;
            // because previous block was divided into 2, right indexes should be moved
            if (rightContainingIndex != null) {
                rightContainingIndex++;
            }
            rightInsertIndex ++;
        }
        if (rightContainingIndex != null) {
            this.orderedRanges.splice(rightContainingIndex, 1, ...this.split(this.orderedRanges[rightContainingIndex], range.dateTo));
            rightInsertIndex = rightContainingIndex + 1;
        }

        this.orderedRanges.splice(leftInsertIndex, rightInsertIndex - leftInsertIndex);
        this.orderedRanges = this.orderedRanges.filter( r => r.dateFrom.getTime() !== r.dateTo.getTime());
        return this;
    }
    split(range: DateRange, onDate: Date): DateRange[] {
        const res = [new DateRange(), new DateRange()];
        res[0].dateFrom = range.dateFrom;
        res[0].dateTo = onDate;
        res[1].dateFrom = onDate;
        res[1].dateTo = range.dateTo;
        return res;
    }

    private join(toJoin: DateRange[]): DateRange {
        const res = new DateRange();
        const minDate = new Date(Math.min(...toJoin.map( r => r.dateFrom).map( d => d.getTime())));
        const maxDate = new Date(Math.max(...toJoin.map( r => r.dateTo).map( d => d.getTime())));
        res.dateFrom = minDate;
        res.dateTo = maxDate;
        return res;
    }
}

export class Dates {
  static hoursAhead(time: number): number {
        let timeAhead = (time - new Date().getTime()) / TimeUnits.Hours(1).toMilis();
        timeAhead = Math.round(timeAhead);

        return timeAhead;
    }

    public static diff(from: Date, to: Date) {
        const toMs = to.getTime() ;
        const fromMs = from.getTime();
        const res = toMs - fromMs;
        return res;
    }

    public static monthStartDate(monthRelative: number): Date {
        const now = new Date();
        const targetMonthStartDate = new Date(now.getFullYear(), now.getMonth() + monthRelative, 1, 0, 0, 0, 0);
        return targetMonthStartDate;
    }

    public static monthFinishDate(monthRelative: number): Date {
        const now = new Date();
        const targetMonthEndDate = new Date(now.getFullYear(), now.getMonth() + monthRelative + 1, 1, 0, 0, 0, 0);
        return new Date(targetMonthEndDate.getTime() - 1);
    }

    public static toTimeStr(time: number, round = true, showDaysNumber = false): string {
        if (!time) {
            return '';
        }

        const timePartsArray = [];

        const days = Math.floor(time / 1000 / 60 / 60 / 24);
        let result = '';
        if (round) {
            if (showDaysNumber) {
                if (days > 2) {
                    return days + ' days';
                } else if (days === 1) {
                    return  '1 day';
                } else {}
            } else {
                if (days > 30) {
                return 'more than one month';
                } else if (days > 14) {
                return 'more than two weeks';
                } else if (days > 1) {
                return '' + days + ' days ';
                } else if (days === 1) {
                result = 'one day and ';
                } else {
                }
            }
        } else {
            if (days > 0) {
                result = days.toString().padStart(2, '0') + 'd ';
            }
        }

        time = time - days * 1000 * 60 * 60 * 24;
        const hours = Math.floor(time / 1000 / 60 / 60);
        time = time - hours * 1000 * 60 * 60;
        const minutes = Math.floor(time / 1000 / 60);
        time = time - minutes * 1000 * 60;
        const seconds = Math.floor(time / 1000);

        timePartsArray.push(hours.toString().padStart(2, '0'));
        timePartsArray.push(minutes.toString().padStart(2, '0'));
        timePartsArray.push(seconds.toString().padStart(2, '0'));

        return result + timePartsArray.join(':');
    }
    public static simpleDateTimeParse(dateStr: string): Date {
        const regExp = /(\d{1,2})[\/-](\d{1,2})[\/-](\d{4})\s+((\d{1,2}):(\d{1,2}))?$/gm;
        const parsed = regExp.exec(dateStr);
        if (!parsed) {
            return null;
        }
        const day = Number(parsed[1]);
        const month = Number(parsed[2]);
        const year = Number(parsed[3]);
        let hour = 0;
        let minute = 0;

        if (parsed[4]) {
            hour = Number(parsed[5]);
            minute = Number(parsed[6]);
        }

        return new Date(year, month - 1, day, hour, minute, 0, 0);
    }

    public static simpleDateFormat(date: Date): string {
        if (!date) {
            return '';
        }

        return date.getDate().toString().padStart(2, '0')
        + '/'
        +  (date.getMonth() + 1).toString().padStart(2, '0')
        + '/'
        + date.getFullYear()
        + ' '
        + date.getHours().toString().padStart(2, '0')
        + ':'
        + date.getMinutes().toString().padStart(2, '0');
    }

    public static parse(date: string): Date {
        if (date == null ) { return null; }
        if (date === undefined) { return undefined; }
        if (date === 'null') { return null; }
        const regExp = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})(\+|-)?(\d{2}):?(\d{2})/gm;
        const parsed = regExp.exec(date);

        const year = Number(parsed[1]);
        const month = Number(parsed[2]) - 1;
        const day = Number(parsed[3]);
        const hour = Number(parsed[4]);
        const minute = Number(parsed[5]);
        const second = Number(parsed[6]);
        const mili = Number(parsed[7]);
        const timezoneSign = parsed[8];
        const timezoneHour = Number(parsed[9]);
        const timezoneMinute = Number(parsed[10]);

        let offset = TimeUnits.Hours(timezoneHour).toMilis() + TimeUnits.Minues(timezoneMinute).toMilis();
        if (timezoneSign === '-') {
            offset = -offset;
        }

        offset = -offset;

        const utcDate = Date.UTC(year, month, day, hour, minute, second, mili) + offset;
        const res = new Date(utcDate);
        return res;
    }

    public static diffAbs(from: Date, to: Date) {
        return Math.abs(Dates.diff(from, to));
    }

    public static roundDateIntoMiddleOfClick(date: Date): Date {
        const movedDate = new Date( date.getTime() - 1000 * 60 * 15);
        return  this.roundDateToHalfHour(movedDate);
    }

    public static roundDateToNextHalfHour(date: Date): Date {
        const movedDate = new Date( date.getTime() + 1000 * 60 * 15);
        return  this.roundDateToHalfHour(movedDate);
    }

    public static roundDateToHalfHour(date: Date): Date {
        // express hour as decimal number. It will allow to use rounding to find nearest hour
        let decimalHour = (date.getHours() + date.getMinutes() / 60);
        // multiply twice to increase rounding resolution - now rouding will work for each half an hour
        decimalHour *= 2;
        // round to the nearest half an hour
        decimalHour = Math.round(decimalHour);
        // divide by 2 to back to the hour scale
        decimalHour /= 2;

        const minutes = decimalHour % 1 * 60;
        const hour = Math.floor(decimalHour);

        const roundedDate = new Date(date);
        roundedDate.setHours(hour);
        roundedDate.setMinutes(minutes);
        roundedDate.setSeconds(0); roundedDate.setMilliseconds(0);
        return roundedDate;
    }
    public static dateToWeekNumber(date: Date): number {
        return Math.floor((date.getTime() + 345600000) / (1000 * 60 * 60 * 24 * 7));
    }

    public static getWeekStartDate(focusDate: Date) {
      let weekStart = new Date((focusDate).getTime() - new Date().getDay() * 24 * 60 * 60 * 1000)
      weekStart.setHours(0)
      weekStart.setMinutes(0)
      weekStart.setSeconds(0)
      weekStart.setMilliseconds(0)
      return weekStart
    }

    static getWeekEndDate(focusDate: Date) {
      const weekEnd = new Date(focusDate?.getTime() + (6 - focusDate?.getDay())*24*60*60*1000);
      weekEnd.setHours(23)
      weekEnd.setMinutes(59)
      weekEnd.setSeconds(59)
      weekEnd.setMilliseconds(999)
      return weekEnd
    }
}

