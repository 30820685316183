import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudentRestService } from 'src/app/services/rest/student-rest.service';
import { ApiLessonInstance, ApiLessonStatus, ApiLessonProgress, ApiLearningUnitTeacher, ApiLessonFlag, ApiPersonalProfile } from 'src/app/model/rest/rest-model';
import { ProfilePhotoUrlExtractor, PersonNameExtractor } from 'src/app/utils/profile-photo-url-extractor';
import { Dates, TimeUnits } from 'src/app/utils/calendar-utils';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { map } from 'rxjs/operators';
import { Utils } from 'src/app/utils/utils';
import { TeacherLessonDetailsComponent } from '../../teacher/teacher-lesson-details/teacher-lesson-details.component';



@Component({
  selector: 'app-student-lesson-details',
  templateUrl: './student-lesson-details.component.html',
  styleUrls: ['./student-lesson-details.component.css']
})
export class StudentLessonDetailsComponent implements OnInit {

  _lessonId: number;
  lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  _studentId: number;
  _isNext: boolean;
  nextProgress: ApiLessonProgress;
  revision: number = null;
  progress: ApiLessonProgress;
  REVISION_LESSONS = 8;

  constructor(private route: ActivatedRoute,
    private studentRest: StudentRestService,
    private studentProxy: StudentCacheProxyService) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.lessonId = Number(params.get('lessonId'));
    });
    this.route.parent.paramMap.subscribe( params => {
      this.studentId = Number(params.get('studentId'));
    });
  }

  set lessonId(lessonId: number) {
    this._lessonId = lessonId;
    this.loadLessonDetails();
  }

  set studentId(studentId: number) {
    this._studentId = studentId;
    this.loadLessonDetails();
  }

  private getTeacherPerson() {
    if (!this.lesson || !this.lesson.teacher || !this.lesson.teacher.person) {
      return null;
    }
    return this.lesson.teacher.person;
  }

  getTeacherName() {
    return PersonNameExtractor.getPersonName(this.getTeacherPerson());
  }

  isStudentNoShow() {
    if (!this.lesson) {
      return false;
    }
    return this.lesson.flags && this.lesson.flags.some( f => f.flagType === ApiLessonFlag.FtStudentNotShow );
  }

  isTutorNoShow() {
    if (!this.lesson) {
      return false;
    }

    return this.lesson.flags && this.lesson.flags.some( f => f.flagType === ApiLessonFlag.FtTeacherNotShow);
  }

  isInvalidated() {
    return this.isLessonStatus('InvalidatedByStudent', 'InvalidatedByTeacher');
  }

  isCancelled() {
    return this.isLessonStatus('Cancel');
  }

  isFinished() {
    return this.isInvalidated() || this.isCancelled() || this.isComplete();
  }

  isInProgress() {
    return this.isLessonStatus('InProgress');
  }

  isByStudent() {
    if (!this.lesson || !this.lesson.cancellationDetails) {
      return false;
    }
    return !this.lesson.cancellationDetails.cancelledByTeacher;
  }

  isLessonStatus(...expectedStatuses: string[]): boolean {
    if (!this.lesson || !expectedStatuses) {
      return false;
    }
    return expectedStatuses.some( es => es === this.lesson.lessonStatus);
  }

  getMessage() {
    if (!this.lesson) {
      return '';
    }
    return this.lesson.message.message;
  }

  getCancellationReason() {
    if (!this.lesson || !this.lesson.cancellationDetails) {
      return '';
    }
    return this.lesson.cancellationDetails.cancellationReason;
  }

  hasCancellationDetails() {
    if (!this.lesson.cancellationDetails) {
      return false;
    }
    return true;
  }


  isClose() {
    if (!this.lesson) {
      return false;
    }
    const timeToTheLesson = Dates.diff(new Date(), this.lesson.lessonMetric.plannedStartDate);
    if (timeToTheLesson === 0 || timeToTheLesson > TimeUnits.Hours(12).toMilis()) {
      return false;
    }
    return true;
  }

  isFuture() {
    if (!this.lesson) {
      return false;
    }

    const timeToTheLesson = Dates.diff(new Date(), this.lesson.lessonMetric.plannedStartDate);
    if (timeToTheLesson < 0 ) {
      return false;
    }
    return true;
  }

  isComplete() {
    return this.isLessonStatus('Complete');
  }

  isNext() {
    return this._isNext;
  }

  getStatusDescription() {
    if (!this.lesson) {
      return '';
    }
    const status = ApiLessonStatus[this.lesson.lessonStatus];
    let result = this.lesson.lessonStatus;
    if (status === ApiLessonStatus.Complete && this.isStudentNoShow()) {
      result = 'StudentNoShow';
    }
    return result;
    /*
    before translation
    switch (status) {
      case ApiLessonStatus.Booked: return 'lesson is reserved.';
      case ApiLessonStatus.Due: return 'waiting for lesson start.';
      case ApiLessonStatus.Cancel: return 'lesson is cancelled.';
      case ApiLessonStatus.InProgress: return 'lesson is in progress.';
      case ApiLessonStatus.Initializing: return 'invalid lesson status. Please report the problem.';
      case ApiLessonStatus.InvalidatedByStudent: return 'you have invalidated the lesson.';
      case ApiLessonStatus.InvalidatedByTeacher: return 'tutor has invalidated the lesson';
      case ApiLessonStatus.Complete:
        if (this.isStudentNoShow()) {
          return 'tutor has reported that you were absent.';
        }
        return 'lesson complete.';
    }
    */
  }

  getTeacherPhoto() {
    const teacher = this.getTeacherPerson();
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(teacher);
  }
  getLessonDate() {
    if (!this.lesson) {
      return null;
    }
    return this.lesson.lessonMetric.plannedStartDate;
  }

  public getRecomendedReading() {
    if (!this.progress || !this.progress.nwp) {
      return 1;
    }
    return Math.max(this.progress.nwp - TeacherLessonDetailsComponent.READING_RECOMENDATION, 1);
  }

  getCourseName() {
    if (!this.lesson) {
      return '';
    }
    return this.lesson.course.name;
  }

  loadLessonDetails(): any {
    if (this._lessonId == null || this._lessonId === undefined || this._studentId == null || this._studentId === undefined) {
      return;
    }
    this.studentRest.loadLesson(
      this._studentId,
      this._lessonId
    ).subscribe(lesson => {
      this.lesson = lesson;
      this.checkIfIsTheLatest();
    });
  }
  checkIfIsTheLatest() {
    this.studentRest.findUpcomingNextLesson(this._studentId).subscribe(
      upcoming => {
        this._isNext = upcoming && upcoming.id === this.lesson.id;
        this.prepareProgress();
      }
    );
  }

  prepareProgress() {
    this.studentProxy.loadStudentLessonProgress(this._studentId)
    .pipe(
      map ( progressList => {
        let progress = progressList.find( p => p.productCode === this.lesson.course.product.code );
        if (!progress) {
          progress = new ApiLessonProgress();
          progress.nwp = 1;
          progress.reading = 1;
          progress.start = 1;
        }
        return progress;
      })
    )
    .subscribe( progress => {
      progress = Utils.jsonClone(progress);
      progress.start -= this.REVISION_LESSONS;
      if (progress.start < 1 ) {
        progress.start = 1;
      }
      this.progress = progress;
    });
  }

  isProva() {
    return this.lesson && this.lesson.lessonType === 'Prova';
  }

  getCourseLangName() {
    return this.lesson && (
        (this.isEnglishCore() && "English")
        || (this.isSpanish() && "Spanish")
        || (this.isBusiness() && "Business English")
        || (this.lesson.course.code.startsWith("en.ch.") && "English For Children")
      )
      || ""
  }

  isEnglishCore() {
    return this.lesson?.course?.code?.startsWith("en.");
  }

  isSpanish() {
    return this.lesson?.course?.code?.startsWith("sp.");
  }

  isBusiness() {
    return this.lesson?.course?.code?.startsWith("en.bs.");
  }
}
