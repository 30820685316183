import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-empty-nav-bar',
  templateUrl: './empty-nav-bar.component.html',
  styleUrls: ['./empty-nav-bar.component.css']
})
export class EmptyNavBarComponent implements OnInit {

  constructor(public themeService: ThemeService) { }

  ngOnInit() {
  }

}
