import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CalendarEntry, ClickEvent} from '../../calendar/week-calendar/week-calendar.component';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {StudentRestService} from 'src/app/services/rest/student-rest.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {DateRange, DateRangeSet, Dates} from 'src/app/utils/calendar-utils';
import {
  ApiCompetence,
  ApiCourse,
  ApiCourseProduct,
  ApiLessonType,
  ApiPersonalProfile,
  ApiProductContext,
  ApiTeacherProfile,
  IntroductionState
} from 'src/app/model/rest/rest-model';
import {ModalComponent} from '../../modal/modal.component';
import {PersonNameExtractor, ProfilePhotoUrlExtractor} from 'src/app/utils/profile-photo-url-extractor';
import {AppEventsService} from 'src/app/services/ctx/app-events.service';
import {StudentCacheProxyService} from 'src/app/services/student-cache-proxy.service';
import {Router} from '@angular/router';
import {ErrorBase} from 'src/app/model/errors/ErrorBase';
import {LocaleManagerService} from 'src/app/services/locale-manager.service';
import {AnalyticsService, Event} from 'src/app/services/analytics.service';
import {
  LocalDateTime,
  SimpleLessonScheduleRequest,
  SimpleScheduleEvents,
  SimpleTeacherProductAvailability,
  TeacherReference
} from "../../../model/rest/booking-rest-v2";

class InductionAnalyticsTrace {

  private analytics: AnalyticsService;
  private isFirstReservation: () => Observable<boolean>;
  private locale: string;
  private constructor() {}
  public static build( analytics: AnalyticsService, isFirstReservation: () => Observable<boolean>, locale: string) {
    const res = new InductionAnalyticsTrace();
    res.locale = locale;
    res.analytics = analytics;
    res.isFirstReservation = isFirstReservation;
    return res;
  }
  private emitEventIfFirstReservation(action: string, event: Event) {
    this.isFirstReservation().subscribe(
      isFirst => {
        if (isFirst) {
          this.analytics.event(action, event);
        }
      }
    );
  }
  updateFocusDate(date: Date) {
    this.emitEventIfFirstReservation(Event.ACT_IND_CALENDAR_DATE, new Event(Event.CAT_IND, undefined, Dates.hoursAhead(date.getTime())));

    this.emitEventIfFirstReservation(
      Event.ACT_IND_CALENDAR_DATE, new Event(Event.catInductionWithLang(this.locale), undefined, Dates.hoursAhead(date.getTime())));
  }
  cancelReservation(): void {
   this.emitEventIfFirstReservation(Event.ACT_IND_CLOSE_RESERVE_DIALOG, new Event(Event.CAT_IND));

   this.emitEventIfFirstReservation(Event.ACT_IND_CLOSE_RESERVE_DIALOG, new Event(Event.catInductionWithLang(this.locale)));
  }
  reserveProva(scheduleDate: Date, lessonType: string, teacherId: number) {
    this.emitEventIfFirstReservation(Event.ACT_LESSON_RESERVE, new Event(Event.CAT_IND, lessonType));
    this.emitEventIfFirstReservation(Event.ACT_LESSON_TIME, new Event(Event.CAT_IND, undefined, Dates.hoursAhead(scheduleDate.getTime())));
    this.emitEventIfFirstReservation(Event.ACT_LESSON_TEACHER, new Event(Event.CAT_IND, teacherId.toString()));

    this.emitEventIfFirstReservation(Event.ACT_LESSON_RESERVE, new Event(Event.catInductionWithLang(this.locale), lessonType));
    this.emitEventIfFirstReservation(
      Event.ACT_LESSON_TIME, new Event(Event.catInductionWithLang(this.locale), undefined, Dates.hoursAhead(scheduleDate.getTime())));
    this.emitEventIfFirstReservation(Event.ACT_LESSON_TEACHER, new Event(Event.catInductionWithLang(this.locale), teacherId.toString()));
  }
  openLessonSchedule(date: Date) {
    this.emitEventIfFirstReservation(
      Event.ACT_IND_OPEN_RESERVE_DIALOG, new Event(Event.CAT_IND, undefined, Dates.hoursAhead(date.getTime())));

    this.emitEventIfFirstReservation(
      Event.ACT_IND_OPEN_RESERVE_DIALOG, new Event(Event.catInductionWithLang(this.locale), undefined, Dates.hoursAhead(date.getTime())));
  }

  filterTeachers(teachersIds: number[]) {
    if (!teachersIds || teachersIds.length === 0) {
      return;
    }
    this.emitEventIfFirstReservation(Event.ACT_IND_FILTER_TEACHERS, new Event(Event.CAT_IND));

    this.emitEventIfFirstReservation(Event.ACT_IND_FILTER_TEACHERS, new Event(Event.catInductionWithLang(this.locale)));
  }
}

@Component({
  selector: 'app-student-main-calendar',
  templateUrl: './student-main-calendar.component.html',
  styleUrls: ['./student-main-calendar.component.css']
})
export class StudentMainCalendarComponent implements OnInit, OnDestroy {


  @ViewChild('scheduleLessonModal', {static: true}) scheduleLessonModal: ModalComponent;
  @ViewChild('noCreditsModal', {static: true}) noCreditsModal: ModalComponent;
  @ViewChild('reserveErrorModal', {static: true}) reserveErrorModal: ModalComponent;
  // inputs
  _studentId: number;
  _langCode: string;
  _focusDate: Date;
  /** events to display in calendar. Dependet on the _focusDate which tells about the week which is displayed */
  events: CalendarEntry[];
  loading = true;


  /** list of competences to conftinue the course according to the calculated estimation from the server */
  requiredCompetences: string[];
  /** result of available worktimes search on th server */
  availableWorktimes: SimpleTeacherProductAvailability[] = [];
  /** scoring table for teachers */

  // lesson schedule variables
  _slScheduleDate: Date;
  _slTeacherIds: number[];
  _slTeacherProfiles: {[id: number]: ApiTeacherProfile};
  _slShowTeachers = false;
  _slPrefferedTeacher: number = null;
  _slScheduleFinishDate: Date;
  _slSelectedWorktimes: SimpleTeacherProductAvailability[];
  _slShowAll = true;
  studentProductContext: ApiProductContext;
  creditsLeft = 0;

  _errorMessage: string;

  @Output()
  availableTeachersIdsUpdate = new EventEmitter<number[]>();
  teachersIdFilter: number[];
  schedules: SimpleScheduleEvents[];
  creditsSubscription: Subscription;
  scheduleModalHideSubscription: Subscription;

  constructor(private studentRest: StudentRestService,
    private router: Router,
    private studentCacheProxy: StudentCacheProxyService,
    private appEvents: AppEventsService,
    private analytics: AnalyticsService,
    private localeService: LocaleManagerService) { }

  @Input()
  set filterTeachersIds(teachersIds: number[]) {
    this.teachersIdFilter = teachersIds;
    this.remapCalendarEntries();
    InductionAnalyticsTrace.build(this.analytics, () => this.isFirstReservation(), this.localeService.localeId).filterTeachers(teachersIds);
  }

  @Input()
  set studentId (studentId: number) {
    this._studentId = studentId;
    this.loadCalendarEvents();
  }

  @Input()
  set langCode(langCode: string) {
    this._langCode = langCode;
    this.loadCalendarEvents();
    this.subscribeForCredits();
  }

  @Input() _introductionState: string

  subscribeForCredits(): any {
    if (!this._langCode) {return; }
    if (this.creditsSubscription) {return; }

    this.creditsSubscription = this.appEvents.credits(this._langCode).subscribe( credits => this.creditsLeft = credits);
  }

  get langCode() { return this._langCode; }
  get studentId() { return this._studentId; }


  set focusDate(date: Date) {
    this._focusDate = date;
    this.loadCalendarEvents();
    InductionAnalyticsTrace.build(this.analytics, () => this.isFirstReservation(), this.localeService.localeId).updateFocusDate(date);
  }

  filterWorktimeByEventDate(date: Date) {
    const asTime = Dates.roundDateIntoMiddleOfClick(date).getTime();
    const now = new Date().getTime();
    const lessonDuration = 30 * 60 * 1000
    return this.availableWorktimes
      .filter(wt => {
        let events = wt.events.filter(ev => asTime >= ev.eventDate.getTime() && (asTime + lessonDuration) <= (ev.eventDate.getTime() + ev.duration))
        return events.length > 0
      })
      .filter(wt => wt.details.overtake == null || asTime - now > wt.details.overtake );
  }

  openLessonScheduleDialog(date: Date) {
    if (this.creditsLeft === 0 ) {
      this.noCreditsModal.show();
      return;
    }
    InductionAnalyticsTrace.build(this.analytics, () => this.isFirstReservation(), this.localeService.localeId).openLessonSchedule(date);
    this._slPrefferedTeacher = null;
    this._slScheduleDate = Dates.roundDateIntoMiddleOfClick(date);
    this._slScheduleFinishDate = new Date();
    this._slScheduleFinishDate.setTime(this._slScheduleDate.getTime() + 1000 * 60 * 30);
    // added to avoid auto selecting the teacher
    this._slShowTeachers = true;
    // this._slShowTeachers = false;
    this._slShowAll = true;
    if (this.teachersIdFilter && this.teachersIdFilter.length > 0) {
      this._slShowTeachers = true;
      this._slShowAll = false;
      this._slPrefferedTeacher = this._slTeacherIds[0];
    }
    // choose the teacher when is only one and not yet selected
    if (!this._slPrefferedTeacher && this._slTeacherIds.length === 1) {
      this._slPrefferedTeacher = this._slTeacherIds[0];
    }
    this.scheduleLessonModal.show();
  }

  scheduleLesson() {
    this.loading = true;
    const lessonSchedule = new SimpleLessonScheduleRequest();
    const teacherAvailableCompetences = this._slSelectedWorktimes
      .map( wt => wt.details.competences)
      .reduce((acc, x) => acc.concat(x))
      .map( competence => competence.code );
    const teachersUniqueCompetences = Array.from(new Set(teacherAvailableCompetences));
    const scheduleCompetence = teachersUniqueCompetences.find( competenceCode => this.requiredCompetences.indexOf(competenceCode) >= 0);
    lessonSchedule.name = "";
    lessonSchedule.competence = new ApiCompetence();
    lessonSchedule.competence.code = scheduleCompetence;
    lessonSchedule.course = this.studentProductContext.currentCourse;
    lessonSchedule.course.product = new ApiCourseProduct();
    lessonSchedule.course.product.code = this._langCode;
    lessonSchedule.duration = 1000 * 60 * 30;
    lessonSchedule.time = new LocalDateTime();
    lessonSchedule.time.starting = this._slScheduleDate;
    lessonSchedule.time.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    lessonSchedule.teacher = new TeacherReference();
    lessonSchedule.teacher.id = this._slPrefferedTeacher;
    this.studentCacheProxy.reserveSchedule(this._studentId, lessonSchedule)
      .pipe(
        switchMap(schedule => this.studentRest.getLessonScheduleEventReference(this._studentId, schedule.schedule.id))
      )
    .subscribe(evRef => {
      this.analytcisReservationEvent();
      this.loadCalendarEvents();
      this.handleTutorial(evRef.eventData.lesson.lessonId);
      }, e => {
        this.loading = false;
        this.handleReservationError(e);
      });
    this.scheduleLessonModal.hide();
  }

  private analytcisReservationEvent() {
    let label = this.studentProductContext.currentCourse.code;
      if (ApiLessonType[this.studentProductContext.nextLessonType] === ApiLessonType.Prova) {
        label = this._langCode + '.prova';
      }
    const teacherId = this._slPrefferedTeacher;

    this.analytics.event(Event.ACT_LESSON_RESERVE, new Event(Event.CAT_RESERV, label ));
    if (teacherId) {
      this.analytics.event(Event.ACT_LESSON_TEACHER, new Event(Event.CAT_RESERV, teacherId.toString()));
    }

    const timeAhead = Dates.hoursAhead(this._slScheduleDate.getTime());

    this.analytics.event(Event.ACT_LESSON_TIME, new Event(Event.CAT_RESERV, undefined, timeAhead));
    InductionAnalyticsTrace.build(
      this.analytics, () => this.isFirstReservation(), this.localeService.localeId ).reserveProva(this._slScheduleDate, label, teacherId);

    /*
    uncomment after receive tracking label
    this.isFirstReservation().subscribe(
      isFirst => {
        if (!isFirst) {return; }
        this.analytics.event(
          Event.ACT_CONVERSION,
          new Event(undefined, undefined), Group.ADS, environment.adsProvaRegistration);
      }
    ); */

  }

  handleReservationError(error: ErrorBase) {
    // check if error is expected structure
    if (!error || !error.errorCode || error.errorCode !== 'INSUFFICIENT_RESOURCE') {
      return;
    }
    this.loadCalendarEvents();
    this._errorMessage = error.userMessage;
    this.reserveErrorModal.show();

  }

  isFirstReservation(): Observable<boolean> {
    if (!this.studentProductContext || !this.studentProductContext.nextLessonType) {
      return of(false);
    }
    if (ApiLessonType[this.studentProductContext.nextLessonType] !== ApiLessonType.Prova) {
      return of(false);
    }

    return this.studentCacheProxy.loadStudentTechnicalProfile(this._studentId).pipe(
      map( technicalProfile => {
        return !(!technicalProfile.introductionState
          || (technicalProfile.introductionState !== IntroductionState.ReserveProva
            && technicalProfile.introductionState !== IntroductionState.ReserveSkipped));
      })
    );
  }

  handleTutorial(lessonId: number): any {
   this.isFirstReservation().subscribe(
     firstReservation => {
       if (firstReservation) {
        this.studentCacheProxy.updateIntroductionState(this._studentId, IntroductionState.FutureLesson).subscribe(
          _ => this.router.navigate(['student', this._studentId, 'lessons',lessonId, 'confirmation'])
        );
      }
    }
   );
  }

  getProfilePhoto(teacherId: number) {
    const teacherProfile = this._slTeacherProfiles[teacherId];
    if (teacherProfile && teacherProfile.teacher && teacherProfile.teacher.person.personalProfile.profilePhoto) {
      return ProfilePhotoUrlExtractor.getPersonProfilePhoto(teacherProfile.teacher.person);
    }
    return null;
  }

  getTeacherName(teacherId: number) {
    const teacherProfile = this._slTeacherProfiles[teacherId];
    if (!teacherProfile || !teacherProfile.teacher.person) {
      return PersonNameExtractor.getPersonName(null);
    }
    return PersonNameExtractor.getPersonName(teacherProfile.teacher.person);
  }

  onCalendarClick(event: ClickEvent) {
    if (event.entry != null && event.entry.relatedObject instanceof DateRange) {
      if (event.clickDate.getTime() < new Date().getTime() + 60000 * 30 ) {
        return;
      }
      const worktimesClicked = this.filterWorktimeByEventDate(event.clickDate);
      this._slSelectedWorktimes = worktimesClicked;
      let teacherToSearch = Array.from(new Set(worktimesClicked.map(wt => wt.teacher.id)))
      if (this.teachersIdFilter && this.teachersIdFilter.length > 0) {
        teacherToSearch = teacherToSearch.filter( teacherId => this.teachersIdFilter.indexOf(teacherId) >= 0);
        this._slShowTeachers = true;
        this._slShowAll = false;
        this._slPrefferedTeacher = this.teachersIdFilter[0];
      }

      this._slTeacherIds = teacherToSearch;
      this._slTeacherProfiles = {};
      this.studentCacheProxy.findTeachers(this._studentId, teacherToSearch)
      .pipe (
        tap (
          teachersProfiles => teachersProfiles.
            filter( teacherProfile => teacherProfile
              && teacherProfile.teacher
              && teacherProfile.teacher.person
              && !teacherProfile.teacher.person.personalProfile)
            .forEach ( teacherProfile => {
              teacherProfile.teacher.person.personalProfile = new ApiPersonalProfile();
              teacherProfile.teacher.person.personalProfile.name = teacherProfile.teacher.person.name;
              teacherProfile.teacher.person.personalProfile.surname = teacherProfile.teacher.person.surname;
            })
        ),
        tap ( teacherProfiles =>
          teacherProfiles.forEach( teacherProfile => this._slTeacherProfiles[teacherProfile.teacher.id] = teacherProfile ))
      )
      .subscribe();
      this.openLessonScheduleDialog(event.clickDate);
    }
  }

  private mapContextLessonTypeOnRequiredCompetences(): ApiCompetence[] {
    // for standard lesson types return
    if (!this.studentProductContext
      || !this.studentProductContext.nextLessonType
      || ApiLessonType[this.studentProductContext.nextLessonType] === ApiLessonType.Standard) {
        return null;
      }
    // TODO: map lesson type on competences
    const lessonTypeEnum = ApiLessonType[this.studentProductContext.nextLessonType];
    if (lessonTypeEnum === ApiLessonType.Prova) {
      return [{code: 'PROVA', name: null}];
    }
    return null;
  }

  loadCalendarEvents() {
    if (!this._studentId || !this._langCode || !this._focusDate) {
      return;
    }

    let loadProductContext = of(null);
    if (!this.requiredCompetences) {
      loadProductContext = this.studentRest.findProductContext(this._studentId, this._langCode)
      .pipe(
        map ( context => context ? context : new ApiProductContext()),
        tap ( (context: ApiProductContext) => this.studentProductContext = context ),
        // map to course code
        switchMap ( context => {
          if ( context.currentCourse ) { return of(context.currentCourse.code); }
          return this.studentCacheProxy.loadProductProgressEstimation(this._studentId, this._langCode).pipe(
            tap ( p => {
              this.studentProductContext.currentCourse = new ApiCourse();
              this.studentProductContext.currentCourse.code = p[0].courseCode; }
              ),
            map ( p => p.length > 0 && p[0] ? p[0].courseCode : null )
          );
        }),
        // get competences if non standard lesson type or map to the course onto competences
        switchMap( courseCode => {
          const competencesFromLessonType = this.mapContextLessonTypeOnRequiredCompetences();
          if (competencesFromLessonType) {
            return of(competencesFromLessonType);
          }
          return this.studentRest.listCourseAllowedCompetences(courseCode);
        }),
        tap(competences => this.requiredCompetences = competences.map(ac => ac.code))
      );
    }

    const loadCalendarObservable = combineLatest(
      this.studentRest.listStudentSchoolTeachersAvailability(this._studentId, this._focusDate, this._langCode)
      .pipe(
        map (worktimes => worktimes.filter( wt => {
          const wtCompetences = wt.details.competences.map(c => c.code );
          return wt.details.product.code === this._langCode && wtCompetences.some( c => this.requiredCompetences.includes(c));
        })
        ),
        tap( worktimes => this.collectAvailableTeacherIds(worktimes)),
        tap( worktimes => this.availableWorktimes = worktimes)
      ),
      this.studentRest.listStudentLessonSchedules(this._studentId, this._focusDate, this._langCode)
      .pipe(
        tap( schedules => this.schedules = schedules)
      )
    );

    this.loading = true;

    loadProductContext.pipe(
      switchMap(() => loadCalendarObservable),
      tap( () => this.remapCalendarEntries())
    ).subscribe(() => this.loading = false, () => this.loading = false);
  }

  remapCalendarEntries() {

    let schedulesEvents: CalendarEntry[] = [];
    if (this.schedules) {
      schedulesEvents = this.schedules.map(schedule => this.mapScheduleToCalendarEntry(schedule));
    }

    let worktimes = [];
    if (this.availableWorktimes) {
      worktimes = this.availableWorktimes;
    }
    if (this.teachersIdFilter && this.teachersIdFilter.length > 0) {
      worktimes = worktimes.filter( wt => this.teachersIdFilter.indexOf(wt.teacher.id) >= 0);
    }
    const availabilities = this.mapAvailabilitiesToCalendarEntries(worktimes);

    this.events = availabilities.concat(schedulesEvents);
  }

  collectAvailableTeacherIds(worktimes: SimpleTeacherProductAvailability[]): void {
    const availableTeacherIds =  Array.from(new Set(worktimes.map( wt => wt.teacher.id )));
    this.availableTeachersIdsUpdate.emit(availableTeacherIds);
  }

  mapScheduleToCalendarEntry(schedule: SimpleScheduleEvents): CalendarEntry {
    const dateTo = new Date();
    const dateFrom = schedule.events[0].eventDate
    const duration = schedule.events[0].duration
    dateTo.setTime(dateFrom.getTime() + duration);
    const title = schedule.schedule.name;
    return new CalendarEntry(schedule.schedule.id, 1, dateFrom, dateTo, () => title, null, schedule, 'schedule');
  }

  mapAvailabilitiesToCalendarEntries(worktimes: SimpleTeacherProductAvailability[]): CalendarEntry[] {

    const rangeSet = new DateRangeSet();

    for (const wt of worktimes) {
      for(const ev of wt.events) {
        // create range set which holds just the worktime range
        const wtRangeSet = new DateRangeSet();
        const wtDateRange = new DateRange();
        wtDateRange.dateFrom = new Date(ev.eventDate);
        wtDateRange.dateTo = new Date(wtDateRange.dateFrom.getTime() + ev.duration);
        wtRangeSet.add(wtDateRange);

        // create overtake range
        const overtakeRange = new DateRange();
        overtakeRange.dateFrom = new Date(0);
        if (wt.details.overtake == null) { wt.details.overtake = 0; }
        overtakeRange.dateTo = Dates.roundDateToNextHalfHour(new Date(new Date().getTime() + wt.details.overtake));

        // substract overtake and add results to the final range set
        wtRangeSet.sub(overtakeRange);
        wtRangeSet.orderedRanges.forEach( r => rangeSet.add(r));
      }
    }

    return rangeSet.orderedRanges.map(range => {
      return new CalendarEntry(null, 1, range.dateFrom, range.dateTo, null, null, range, 'worktime');
    });
  }

  ngOnInit() {
    this.scheduleModalHideSubscription = this.scheduleLessonModal.cancelSubject.subscribe( () =>
      InductionAnalyticsTrace.build(this.analytics, () => this.isFirstReservation(), this.localeService.localeId).cancelReservation()
    );
  }

  ngOnDestroy(): void {
    if (this.creditsSubscription) {
      this.creditsSubscription.unsubscribe();
      this.creditsSubscription = null;
    }
    this.scheduleModalHideSubscription.unsubscribe();
  }

  getInitials(teacherId: number){
    const teacherProfile = this._slTeacherProfiles[teacherId];
    return (teacherProfile.teacher.person.name[0] + teacherProfile.teacher.person.surname[0]).toUpperCase()
  }
}
