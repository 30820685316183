import { Component, OnInit, Input } from '@angular/core';
import { ApiLessonBundle } from 'src/app/model/rest/rest-model';
import { LangProductMapper } from 'src/app/utils/lang-mappers';
import { ManagerRestService } from 'src/app/services/rest/manager-rest.service';

@Component({
  selector: 'app-create-students-credits-bundle',
  templateUrl: './create-students-credits-bundle.component.html',
  styleUrls: ['./create-students-credits-bundle.component.css']
})
export class CreateStudentsCreditsBundleComponent implements OnInit {
  @Input()
  schoolId: number;
  @Input()
  studentId: number;

  newBundle = new ApiLessonBundle();
  availableProducts  = LangProductMapper.getCourseProducts();

  constructor(private managerRest: ManagerRestService) { }

  registerBundle() {
    this.newBundle.registeredLessons = this.newBundle.available;
    this.newBundle.date = new Date();

    this.managerRest.saveLessonBundle(this.schoolId, this.studentId, this.newBundle)
    .subscribe( resBundle => {
      this.newBundle = new ApiLessonBundle();
    });
  }
  ngOnInit() {
  }

}
