import { Component, OnInit, Input } from '@angular/core';
import { TeacherRestService } from 'src/app/services/rest/teacher-rest.service';
import { AllowanceType } from 'src/app/model/rest/rest-model';
import { Observable } from 'rxjs';
import { ManagerRestService } from 'src/app/services/rest/manager-rest.service';

@Component({
  selector: 'app-teacher-allowances',
  templateUrl: './teacher-allowances.component.html',
  styleUrls: ['./teacher-allowances.component.css']
})
export class TeacherAllowancesComponent implements OnInit {
  _componentRole: string;
  _teacherId: number;
  gdpr = false;
  _schoolId: number;

  constructor(private teacherRest: TeacherRestService, private managerRest: ManagerRestService) { }

  @Input()
  set componentRole(mode: string) {
    this._componentRole = mode;
  }

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.refreshAllowances();
  }

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.refreshAllowances();
  }

  gdprEnabled() {
    return this._componentRole === 'teacher';
  }

  refreshAllowances() {
    if (!this._componentRole) { return; }
    if (this._componentRole === 'teacher' && (!this._teacherId)) { return; }
    if (this._componentRole === 'manager' && (!this._teacherId || !this._schoolId)) {return; }

    this.listAllowances().subscribe(
      allowances => this.setupAllowances(allowances)
    );
  }

  listAllowances() {
    if (this._componentRole === 'teacher') {
      return this.teacherRest.listAllowances(this._teacherId);
    } else if (this._componentRole === 'manager') {
      return this.managerRest.listTeacherAllowances(this._schoolId, this._teacherId);
    }
  }

  setupAllowances(allowances: String[]): void {
    this.gdpr = allowances.findIndex( a => a === AllowanceType.TEACHER_GDPR) >= 0;
  }

  gdprChange(event: any) {
    this.teacherRest.saveAllowances(this._teacherId, [AllowanceType.TEACHER_GDPR], this.gdpr)
    .subscribe();
  }


  ngOnInit() {
  }

}
