<h3 i18n="@@starter.get-your-offer">Get your limited offer.</h3>
<div class="row" *ngIf="hasOffer('en')">
  <div class="col-12 col-md-6 col-lg-8" >
    <p class="lead">
      <span i18n="@@starter.en.name">Starter Plan, English: 3 lessons + eBook.</span>
      <br>
      <span i18n="@@common.expires-in">Expires in:</span> <span class="text-thin"> {{getTime('en')}}</span>
    </p>
    
  </div>
  <div class="col-12 col-md-6 col-lg-4">
    <a class="btn btn-light d-block w-100 text-uppercase text-violet" routerLink="/student/{{_studentId}}/buy-courses/product/en-starter"><span i18n="@@starter.claim-offer">Claim offer</span>&nbsp;<i class="fas fa-chevron-right"></i></a>

  </div>
</div>
<div class="row" *ngIf="hasOffer('sp')">
    <div class="col-12 col-md-6 col-lg-8" >
      <p class="lead">
        <span i18n="@@starter.es.name">Starter Plan, Spanish: 3 lessons + eBook.</span>
        <br>
        <span i18n="@@common.expires-in">Expires in:</span> <span class="text-thin"> {{getTime('sp')}}</span>
      </p>
      
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <a class="btn btn-light d-block w-100 text-uppercase text-violet" routerLink="/student/{{_studentId}}/buy-courses/product/sp-starter"><span i18n="@@starter.claim-offer">Claim offer</span>&nbsp;<i class="fas fa-chevron-right"></i></a>
    </div>
  </div>
