<ul class="navbar-nav" #main [ngClass]="isHidden(main)? 'd-none' : 'd-block animation-main-menu'">
    <a class="navbar-brand text-center d-none d-md-block mb-5" href="#" routerLink="/student/{{studentId}}/dashboard" >
        <img [src]="themeService.getLogoPath()" alt="Callan Online Logo"  class="d-inline-block align-top">
    </a>
    <div class="d-md-none header mb-4">
        <div class="d-flex justify-content-between mb-4">
            <a class="navbar-brand text-center" href="#" routerLink="/student/{{studentId}}/dashboard" >
                <img [src]="themeService.getLogoPath()" alt="Callan Online Logo"  class="d-inline-block align-top">
            </a>
            <button type="button" class="btn btn-primary btn-circle text-reset lh-1"
                data-bs-dismiss="offcanvas" aria-label="Close"
                (click)="onClose()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <path fill="#FFFFFF" fill-rule="evenodd" d="M11.4141,10 L15.7071,5.707 C16.0981,5.316 16.0981,4.684 15.7071,4.293 C15.3161,3.902 14.6841,3.902 14.2931,4.293 L10.0001,8.586 L5.7071,4.293 C5.3161,3.902 4.6841,3.902 4.2931,4.293 C3.9021,4.684 3.9021,5.316 4.2931,5.707 L8.5861,10 L4.2931,14.293 C3.9021,14.684 3.9021,15.316 4.2931,15.707 C4.4881,15.902 4.7441,16 5.0001,16 C5.2561,16 5.5121,15.902 5.7071,15.707 L10.0001,11.414 L14.2931,15.707 C14.4881,15.902 14.7441,16 15.0001,16 C15.2561,16 15.5121,15.902 15.7071,15.707 C16.0981,15.316 16.0981,14.684 15.7071,14.293 L11.4141,10 Z"/>
                </svg>
            </button>
        </div>
        <div class="d-flex align-items-center">
            <button
                type="button"
                class="btn btn-primary btn-circle lh-1 me-3"
                (click)="onOpen(profileMenu)"
                *ngIf="!hasBack">
                <i class="far fa-smile"></i>
            </button>
            <div class="d-flex flex-column pe-4">
                <span class="f-14 my-2">{{ getStudentName() }}</span>
            </div>
        </div>
    </div>
    <li class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        (click)="goToDashboardTo('top')"
        [ngClass]="{active: isActivePath('dashboard')}">
        <img src="../../../../assets/img/dashIcons/dash_icon_-_dashboard.svg" class="nav-icon me-2">
        <a tabindex="" class="nav-link" i18n="@@student-menu.dashboard">Dashboard</a>
    </li>
    <li class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        routerLink="/student/{{studentId}}/lessons/reserve"
        [ngClass]="{active: isActivePath('lessons')}">
        <img src="../../../../assets/img/dashIcons/dash_icon_-_reserve_lesson.svg" class="nav-icon me-2">
        <a tabindex="" class="nav-link" i18n="@@student-dashboard.reserve-lesson.btn">Reserve a lesson</a>
    </li>
    <li class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        (click)="goToDashboardTo('futureLess')">
        <img src="../../../../assets/img/dashIcons/dash_icon_-_future_lessons.svg" class="nav-icon me-2">
        <a tabindex="" class="nav-link" i18n="@@student-dashboard.future-lessons-box.title">Future lessons</a>
    </li>
    <li class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        (click)="goToDashboardTo('pastLess')">
        <img src="../../../../assets/img/dashIcons/dash_icon_-_past_lessons.svg" class="nav-icon me-2">
        <a tabindex="" class="nav-link" i18n="@@student-dashboard.past-lessons-box.title">Past lessons</a>
    </li>
    <li class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        (click)="goToDashboardTo('exercises')">
        <img src="../../../../assets/img/dashIcons/dash_icon_-_exercises.svg" class="nav-icon me-2">
        <a tabindex="" class="nav-link" i18n="@@student-dashboard.exercises-box.title">Exercises</a>
    </li>
    <li class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        routerLink="/student/{{studentId}}/wallet/{{lang}}">
        <img src="../../../../assets/img/dashIcons/dash_icon_-_credits.svg" class="nav-icon me-2">
        <a tabindex="" class="nav-link" i18n="@@student-dashboard.my-credits.title">Credits</a>
    </li>
    <li class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        routerLink="/student/{{studentId}}/teachers"
        [ngClass]="{active: isActivePath('teachers')}">
        <img src="../../../../assets/img/dashIcons/dash_icon_-_teachers.svg" class="nav-icon me-2">
        <a tabindex="" class="nav-link" i18n="Show all available tutors profiles@@student-menu.tutors">Teachers</a>
    </li>
    <li class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        routerLink="/student/{{studentId}}/buy-courses"
        [ngClass]="{active: isActivePath('buy-courses')}">
        <img src="../../../../assets/img/dashIcons/dash_icon_-_add_course.svg" class="nav-icon me-2">
        <a tabindex="" class="nav-link" i18n="@@student-menu.add-new-course">Add course</a>
    </li>
</ul>
<ul class="navbar-nav" #profileMenu [ngClass]="isHidden(profileMenu)? 'd-none' : 'd-block animation-profile-menu'">
    <div class="d-md-none header d-flex align-items-center justify-content-between mb-4">
        <button
            type="button"
            class="btn btn-outline-secondary btn-circle btn-back btn-sm"
            (click)="onBack()"
            *ngIf="hasBack">
            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                <path fill="#FFFFFF" d="m12 15-5-5 5-5 1.062 1.062L9.125 10l3.937 3.938Z"/>
            </svg>
        </button>
        <h4>Profile</h4>
        <button type="button" class="btn btn-primary btn-circle lh-1 text-reset"
            data-bs-dismiss="offcanvas" aria-label="Close"
            (click)="onClose()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path fill="#FFFFFF" fill-rule="evenodd" d="M11.4141,10 L15.7071,5.707 C16.0981,5.316 16.0981,4.684 15.7071,4.293 C15.3161,3.902 14.6841,3.902 14.2931,4.293 L10.0001,8.586 L5.7071,4.293 C5.3161,3.902 4.6841,3.902 4.2931,4.293 C3.9021,4.684 3.9021,5.316 4.2931,5.707 L8.5861,10 L4.2931,14.293 C3.9021,14.684 3.9021,15.316 4.2931,15.707 C4.4881,15.902 4.7441,16 5.0001,16 C5.2561,16 5.5121,15.902 5.7071,15.707 L10.0001,11.414 L14.2931,15.707 C14.4881,15.902 14.7441,16 15.0001,16 C15.2561,16 15.5121,15.902 15.7071,15.707 C16.0981,15.316 16.0981,14.684 15.7071,14.293 L11.4141,10 Z"/>
            </svg>
        </button>
    </div>
    <li class="nav-item" data-bs-dismiss="offcanvas"
        [ngClass]="{active: isActivePath('profile')}">
        <a class="nav-link"
            aria-current="page"
            routerLink="/student/{{studentId}}/profile"
        >
            <i class="far fa-user text-primary me-3"></i>
            <span i18n="Show student profile edit page@@student-menu.profile">Edit Profile</span>
        </a>
    </li>
    <li class="nav-item" data-bs-dismiss="offcanvas">
        <a class="nav-link" aria-current="page">
            <i class="far fa-envelope text-primary me-3"></i>
            <span class="">Contact</span>
        </a>
    </li>
    <li class="nav-item" data-bs-dismiss="offcanvas" (click)="themeService.onThemeChange(theme)">
      <i class="text-primary me-2" [class]="theme !== 'dark' ? 'far fa-moon' : 'far fa-sun'"></i>
      <a class="nav-link" aria-current="page">
          {{ themeService.getThemeName(theme) }}
      </a>
    </li>
    <li class="nav-item" data-bs-dismiss="offcanvas" routerLink="./" (click)="logout()">
        <a tabindex="" class="nav-link" (click)="logout()">
            <i class="fas fa-sign-out-alt text-primary me-3"></i>
            <span i18n="@@student-menu.logout">Log Out</span>
        </a>
    </li>
</ul>
