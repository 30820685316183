import {Component, OnInit} from '@angular/core';
import {ThemeService} from 'src/app/services/theme.service';
import {AuthServiceProvider} from "../../services/col2/auth.service";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(private authService: AuthServiceProvider,
    public themeService: ThemeService) { }

  ngOnInit() {
  }

  public logout(): void {
    this.authService.get().subscribe(api => api.logout());
  }
}
