<div class="card mb-5" [hidden]="!personalProfileReady">
  <div class="card-header">
      <span i18n="@@student-profile-page.profile-box.title">Your profile</span>
      <a (click)="editPersonalProfile()" *ngIf="!personalProfileUnderEdit" 
        class="float-end btn btn-primary rounded-circle p-2">
        <i class="fas fa-pencil-alt float-end"></i>
      </a>
  </div>
  <div class="card-body">
    <app-student-personal-profile-form #studentPersonalProfileFormComponent componentRole="student" [studentId]="studentId" (stateEvent)="personalProfileEvent($event)"></app-student-personal-profile-form>
  </div>
</div>
<div class="card" [hidden]="!technicalProfileReady">
  <div class="card-header">
      <span i18n="@@student-profile-page.technical-profile-box.title">Settings</span>
      <a (click)="editTechnicalProfile()" *ngIf="!technicalProfileUnderEdit" 
        class="float-end btn btn-primary rounded-circle p-2">
        <i class="fas fa-pencil-alt float-end"></i>
      </a>
  </div>
  <div class="card-body">
    <app-student-technical-profile-form #studentTechnicalProfileFormComponent componentRole="student" [studentId]="studentId" (stateEvent)="technicalProfileEvent($event)"></app-student-technical-profile-form>
  </div>
</div>
