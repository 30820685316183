import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {AuthServiceProvider, UserRole} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(private authService: AuthServiceProvider, private router: Router) {}

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const roles = route.data['roles'] as Array<UserRole>;
    return this.authService.get().pipe(
      switchMap((api) => api.getUserRole()),
      map((userRole) => roles.some((expected) => expected === userRole)),
      tap(approved => {
        !approved && this.router.navigate(['/forbidden']);
      })
    )
  }
}
