export class ExerciseSet {
  name: string;
  code: string;
}

export class Question {
    id: number;
    typeId: number;
    type: string;
    hasImage: string;
    affirm: string;
    answer: string;
    resourceBase: string;
    question: string;
    correction: string;
    askingSexMale: boolean;
    answers: string;
    wrongAnswers: string;
    defHasImage: string;
    correctAnswer: string;
    instructions: string;
    oldId: string;
}

export class Exercise {
   name: string;
   id: number;
   firstQuestionId: number;
   firstQuestionType: number;
   shortName: string;
   questions: Question[];
}

export class Section {
  name: string;
  id: number;
  exercises: Exercise[];
  type: string;
}

export class Chapter {
  name: string;
  id: number;
  code: string;
  sections: Section[];
  version: number;
  langCode: string;
  chapterOrder: number;
  shortName: string;
}

export class ApiExercisesStructure {
  chapters: Chapter[];
}

export class PersonQuestion {
  id: number;
  score: number;
  lastSubmitDate: Date;
}

export class PersonExercise {
  id: number;
  enabled: boolean;
  score: number;
  lastSubmitDate: Date;
  questions: PersonQuestion[];
}

export class PersonSection {
  id: number;
  enabled: boolean;
  score: number;
  sectionType: string;
  exercises: PersonExercise[];
}

export class PersonChapter {
  id: number;
  available: boolean;
  enabled: boolean;
  score: number;
  sections: PersonSection[];
}

export class ApiPersonScores {
  chapters: PersonChapter[];
}

export class ApiInnerQuestionDefinition {
  affirm: string;
  question: string;
  answer: string;
  correction: string;
  askingSexMale: string;
  answers: string[];
  wrongAnswers: string[];
  hasImage: boolean;
  correctAnswer: string;
}

export class ApiQuestionDefinition {
  typeId: number;
  defintion: ApiInnerQuestionDefinition;
  id: number;
  type: string;
  hasImage: boolean;
  resourceBase: string;
  instructions: string;
}

export class ApiSessionQuestion {
  def: ApiQuestionDefinition;
  score: number;
  questionName: string;
  submitDate: Date;
  updateDate: Date;
}

export class ApiExerciseSession {
  id: number;
  score: number;
  baseSessionQuestionNumber: number;
  questions: ApiSessionQuestion[];
  deviceUUID: string;
  prevUUID: string;
  lastUdpateDate: Date;
  startDate: Date;
}
