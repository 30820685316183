import { Component, Inject, LOCALE_ID } from '@angular/core';
import { ProductsUtils } from 'src/app/model/rest/products';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { StudentShoppingCartService } from 'src/app/services/student-shopping-cart.service';
import { CoreProductsBase } from '../english-core-products/english-core-products.component';

@Component({
  selector: 'app-spanish-core-products',
  templateUrl: './spanish-core-products.component.html',
  styleUrls: ['./spanish-core-products.component.css']
})
export class SpanishCoreProductsComponent extends CoreProductsBase {
  getEbookCategoryLabel(): string {
    return 'category:ebook.sp';
  }
  getPaygCategoryLabel(): string {
    return 'category:lessons.sp.pag';
  }
  getIntensiveCategoryLabel(): string {
    return 'category:lessons.sp.int';
  }
  getStdCategoryLabel(): string {
    return 'category:lessons.sp.std';
  }
  getLightCategoryLabel(): string {
    return 'category:lessons.sp.light';
  }
  getProductsLabel(): string {
    return ProductsUtils.SP_PRODUCT_LABEL;
  }
  constructor(
    studentCache: StudentCacheProxyService, 
    @Inject(LOCALE_ID) locale: string, 
    shoppingCart: StudentShoppingCartService
  ) {
    super(studentCache, locale, shoppingCart);
  }
}
