
<div class="card mb-2 mt-2" >
  <div class="card-header">
    <app-pagination [pageableComponent]="futureLessons" class="float-end"></app-pagination>
    All future Lessons <span class="badge bg-primary">{{futureLessons.getTotalResults()}}</span>
  </div>
  <div class="card-body">
    <app-student-lessons #futureLessons (lessonDetailsEvent)="openLessonDetails($event)" componentRole="manager" [lessonType]='lessonTypes.Upcomming' [studentId]="studentId" [schoolId]="schoolId"></app-student-lessons>
  </div>
</div>

<div class="card mb-2" >
    <div class="card-header">
      <app-pagination [pageableComponent]="pastLessons" class="float-end"></app-pagination>
      All past Lessons <span class="badge bg-primary">{{pastLessons.getTotalResults()}}</span>
    </div>
    <div class="card-body">
      <app-student-lessons #pastLessons (lessonDetailsEvent)="openLessonDetails($event)" componentRole="manager" [lessonType]='lessonTypes.Past' [studentId]="studentId" [schoolId]="schoolId"></app-student-lessons>
    </div>
  </div>

