import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ComponentEvent, StateAwareComponent, Languages } from 'src/app/model/rest/base';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { Dates } from 'src/app/utils/calendar-utils';
import { ApiProvaContext } from 'src/app/model/rest/rest-model';
import { map, tap, min } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';

export class StudentStartersOfferComponentEvent extends ComponentEvent {}

@Component({
  selector: 'app-student-starters-offer',
  templateUrl: './student-starters-offer.component.html',
  styleUrls: ['./student-starters-offer.component.css']
})
export class StudentStartersOfferComponent
  extends StateAwareComponent<StudentStartersOfferComponentEvent>
  implements OnInit, OnDestroy {
  _studentId: number;
  _langCode: string = null;

  supportedProductCodes = ['en', 'sp'];
  contexts: {[productCode: string]: ApiProvaContext} ;
  timeSubscription: Subscription;

  constructor(private studentCache: StudentCacheProxyService) {
    super();
  }

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    if (studentId) {
      this.load();
    }
  }

  @Input()
  set langCode(langCode: string) {
    this._langCode = langCode;
    if (langCode && this._studentId) {
      this.load();
    }
  }

  getLangName(productCode: string) {
    return Languages.values.find( l => l.code === productCode).name;
  }

  hasOffer(productCode: string) {
    if (!this.contexts) {return false; }
    return (this.contexts[productCode] !== undefined);
  }

  getTime(productCode: string) {
    if (!this.contexts) {return ''; }

    let time = null;
    const context = this.contexts[productCode];
    if (context) {
      time = context.starterExpiryDate.getTime() - new Date().getTime();
    }

    if (!time || time < 0) {
      this.eventNumberOfElements(0);
      return '';
    }

    return Dates.toTimeStr(time, false, true);
  }

  load() {
    this.loadContexts().pipe(
      map( contexts => this.filterSupportedContexts(contexts)),
      tap( contexts => this.storeContexts(contexts))
    ).subscribe( contexts => {
      const offersNumber = contexts.length;
      this.eventLoaded();
      this.eventNumberOfElements(offersNumber);
    });
  }

  storeContexts(contexts: ApiProvaContext[]): void {
    this.contexts = {};
    for (const context of contexts) {
      this.contexts[context.productContext.product.code] = context;
    }
  }

  filterSupportedContexts(contexts: ApiProvaContext[]) {
    let res = contexts.filter( c =>
      c.starterExpiryDate != null
      && c.starterExpiryDate.getTime() > new Date().getTime()
      && this.supportedProductCodes.includes(c.productContext.product.code)
      );
    if (this._langCode) {
      const productCode = this.mapLangCodeToProductCode(this._langCode);
      res = res.filter( c => c.productContext.product.code === productCode);
    }
    return res;
  }
  mapLangCodeToProductCode(langCode: string): string {
    if (langCode === 'en') {return 'en'; }
    if (langCode === 'sp') {return 'sp'; }
    throw new Error('Method not implemented.');
  }

  loadContexts() {
    return this.studentCache.listProvaContexts(this._studentId);
  }

  ngOnInit() {
    this.eventLoading();
    this.timeSubscription = interval(1000).subscribe( );
  }

  ngOnDestroy(): void {
    this.timeSubscription.unsubscribe();
  }


}
