import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { StaticPagesUrlProviderService } from 'src/app/services/shared/url-provider.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-friend-banner',
  templateUrl: './friend-banner.component.html',
  styleUrls: ['./friend-banner.component.css']
})
export class FriendBannerComponent implements OnInit, OnDestroy {

  firendsUrl: string;
  subsc: Subscription;

  constructor(staticPagesUrlProvider: StaticPagesUrlProviderService) {
    this.subsc = staticPagesUrlProvider.staticBase
    .pipe(
      filter( urlBase => urlBase != null)
    )
    .subscribe( urlBase => this.firendsUrl = urlBase + '/friend');
  }

  @Input()
  langCode: string;

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subsc.unsubscribe();
  }

}
