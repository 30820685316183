
<div class="card mb-5" [hidden]="!personalProfileReady">
  <div class="card-header">
    <span>Your personal informations</span>
      <a (click)="editPersonalProfile()" *ngIf="!personalProfileUnderEdit"
        class="float-end btn btn-primary rounded-circle p-2">
        <i class="fas fa-pencil-alt float-end"></i>
      </a>
  </div>
  <div class="card-body">
    <app-teacher-personal-profile-form #teacherPersonalProfileFormComponent componentRole="teacher" [teacherId]="teacherId" (stateEvent)="onPersonalProfileEvent($event)"></app-teacher-personal-profile-form>
  </div>
</div>

<div class="card mb-5" [hidden]="!teacherProfileReady">
  <div class="card-header">
    <span>Your personal informations</span>
    <a (click)="editTeacherProfile()" *ngIf="!teacherProfileUnderEdit"
      class="float-end btn btn-primary rounded-circle p-2">
      <i class="fas fa-pencil-alt float-end"></i>
    </a>
  </div>
  <div class="card-body">
    <app-teacher-profile-form #teacherProfileFormComponent componentRole="teacher" [teacherId]="teacherId" (stateEvent)="onTeacherProfileEvent($event)"></app-teacher-profile-form>
  </div>
  </div>

  <div class="card">
    <div class="card-body">
      <app-teacher-allowances componentRole="teacher" [teacherId]="teacherId"></app-teacher-allowances>
    </div>
  </div>
