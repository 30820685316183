<form>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" name="profile" type="radio"  checked="" value="student" [(ngModel)]="searchProfile">
        <label class="form-check-label" >Student</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" name="profile" type="radio"  checked="" value="teacher" [(ngModel)]="searchProfile">
        <label class="form-check-label" >Teacher</label>
      </div>
    </div>
    
    <div class="col">
        <div class="form-floating">
            <input type="text" name="queryValue" #queryValue class="form-control" placeholder="search query..." (input)="updateSearchQuery(queryValue.value)">
            <i class="fa fa-search text-primary search-icon"></i>
            <label for="queryValue">Search query</label>
          </div>
    </div>
  </div>
</form>
<div *ngIf="searchProfile === 'student'">
  <ul class="list-group">
    <li class="list-group-item person-row" (click)="itemClicked(student)" *ngFor="let student of studentList">
      <div class="person-photo">
          <img src="{{getPersonPhotoUrl(student.person)}}" class="rounded-circle img-thumbnail" alt="photoprofile"/>
      </div>
      <div class="person-name">
          {{getPersonName(student.person)}}
      </div>
      
    </li>
  </ul>
</div>
<div *ngIf="searchProfile === 'teacher'">
    <ul class="list-group">
        <li class="list-group-item person-row" (click)="itemClicked(teacherProfile)"  *ngFor="let teacherProfile of teacherList">
            <div class="person-photo">
                <img src="{{getPersonPhotoUrl(teacherProfile.teacher.person)}}" class="rounded-circle img-thumbnail" alt="photoprofile"/>
            </div>
            <div class="person-name">
                {{getPersonName(teacherProfile.teacher.person)}}
            </div>
            
          </li>
    </ul>
  </div>
<button *ngIf="hasNext" class="btn btn-primary" (click)="next()">next</button>