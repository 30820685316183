import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { StudentContextService } from 'src/app/services/ctx/student-context.service';
import { Subscription, Observable, of } from 'rxjs';
import {ApiLessonProgress, ApiCourse, ApiLessonInstance, ApiLessonStatus, ApiLearningUnitTeacher, ApiPersonalProfile} from 'src/app/model/rest/rest-model';
import { PropertyObserver } from 'src/app/utils/property-observer';
import { StudentRestService } from 'src/app/services/rest/student-rest.service';
import { tap, flatMap, switchMap } from 'rxjs/operators';
import { Pageable, ComponentEvent, StateAwareComponent, Page } from 'src/app/model/rest/base';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';

export class CourseProgress {
  productCode: string;
  courseCode: string;
  course: ApiCourse;
  firstLessonDate: Date;
  lastLessonDate: Date;
  isFinished: boolean;
  isStarted: boolean;
  numberOfLessons: number;
  lessons: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[];
  lessonsUntilPromotion: number;
  productProgress: ApiLessonProgress;
  progressEstimations: ApiLessonProgress[];
}

export class StudentProgressComponentEvent extends ComponentEvent {

}

@Component({
  selector: 'app-student-progress',
  templateUrl: './student-progress.component.html',
  styleUrls: ['./student-progress.component.css']
})
export class StudentProgressComponent extends StateAwareComponent<StudentProgressComponentEvent> implements OnInit, OnDestroy {
  private studentProgressSubscription: Subscription;
  progresses: ApiLessonProgress[];
  courseProgress: CourseProgress;
  courses: ApiCourse[];
  hasNext: boolean;
  hasPrev: boolean;
  _lang: string;
  @Input()
  studentId: number;

  clearResults(): any {
    this.courseProgress = null;
    this.hasNext = false;
    this.hasPrev = false;
  }

  ngOnDestroy(): void {
    this.studentProgressSubscription = PropertyObserver.unsuscribe(this.studentProgressSubscription);
  }

  constructor(
    private studentCache: StudentCacheProxyService,
    private studentRest: StudentRestService) {
      super();
    }

  ngOnInit() {
    this.eventLoading();
    this.studentProgressSubscription = this.studentCache.loadStudentLessonProgress(this.studentId)
    .pipe(
      switchMap( progresses => this.updateProgresses(progresses))
    )
    .subscribe(
      null,
      () => this.eventLoaded()
    );
  }

  updateProgresses(progresses: ApiLessonProgress[]): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    this.progresses = progresses;
    return this.fireOveralUpdate();
  }

  @Input()
  public set langCode(lang: string) {
    if (lang == null) {
      this._lang = null;
      this.clearResults();
    }
    if ( this.courseProgress != null && this.courseProgress.productCode === lang) {
      return;
    }
    this._lang = lang;
    this.courseProgress = new CourseProgress();
    this.courseProgress.productCode = lang;
    this.eventLoading();
    this.fireOveralUpdate().subscribe(
      null,
      () => this.eventLoaded()
    );

  }

  private switchCourse(productCode: string, courseCode: string): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    if (this.courseProgress.productProgress === undefined ) {return; }
    const productProgress = this.courseProgress.productProgress;
    this.courseProgress = new CourseProgress();
    this.courseProgress.productProgress = productProgress;
    this.courseProgress.productCode = productCode;
    this.courseProgress.courseCode = courseCode;
    this.courseProgress.course = this.courses.find (c => c.code === this.courseProgress.courseCode );

    let estimationsObservable: Observable<ApiLessonProgress[]> = of(null);
    if (this.courseProgress.productProgress.courseCode === courseCode ) {
      estimationsObservable = this.loadProductProgressEstimation(this.courseProgress.productCode);
    }

    return estimationsObservable.pipe(
      tap (estimations => this.courseProgress.progressEstimations = estimations),
      flatMap( estimations => this.loadCourseLessons( this.courseProgress.courseCode)),
      tap(courseLessonsPage => this.courseProgress.lessons = courseLessonsPage.content ),
      tap(courseLessonsPage => this.calculateProgressValues())
    );

  }

  private fireOveralUpdate(): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    this.eventLoading();
    if (this.progresses == null ||
      this.courseProgress == null ||
      this.courseProgress.productCode == null) {
        return of(null);
      }

      this.chooseCurrentLangProgress();
      if (this.courseProgress.courseCode == null) {
        this.eventLoaded();
        return of(null);
      }

      return this.loadLangStudentCourses(this.courseProgress.productCode)
      .pipe (
        tap( courses => this.courses = courses),
        switchMap( courses => this.switchCourse( this.courseProgress.productCode, this.courseProgress.courseCode)),
        tap( () => this.eventLoaded())
      );
  }

  calculateProgressValues(): void {
    this.hasNext = false;
    this.hasPrev = false;
    if (this.courseProgress.lessons != null ) {
      const finishedLessons = this.courseProgress.lessons.filter( l => ApiLessonStatus[l.lessonStatus] === ApiLessonStatus.Complete);
      if (this.courseProgress.lessons.length > 0) {
        this.courseProgress.isStarted = true;
        const datesSorted = finishedLessons.map( lesson => lesson.lessonMetric.started).sort();
        this.courseProgress.firstLessonDate = datesSorted[0];
        this.courseProgress.lastLessonDate = datesSorted[datesSorted.length - 1];
      }
      this.courseProgress.numberOfLessons = finishedLessons.length;
    }

    if (this.courseProgress.progressEstimations != null) {
      const courseCode = this.courseProgress.courseCode;
      const estimations = this.courseProgress.progressEstimations;

      if (estimations.length === 0 || estimations[0].courseCode !== courseCode) {
        this.courseProgress.isFinished = true;
        this.courseProgress.lessonsUntilPromotion = 0;
        return;
      }
      this.courseProgress.isFinished = false;
      this.courseProgress.lessonsUntilPromotion = 0;
      for (const estimation of estimations) {
        if (estimation.courseCode !== courseCode) {
          break;
        }
        this.courseProgress.lessonsUntilPromotion++;
      }
    }

    const numberOfCourses = this.courses.length;
    this.stateEvent.emit( ComponentEvent.numberOfElements(numberOfCourses));
    const currentNumber = this.courses.map( c => c.code ).indexOf( this.courseProgress.courseCode );

    this.hasPrev = currentNumber > 0;
    this.hasNext = currentNumber < numberOfCourses - 1;
  }

  public moveCourse(offset: number) {
    const courseCodes = this.courses.map( c => c.code );
    const currentNumber = courseCodes.indexOf( this.courseProgress.courseCode );
    const newNumber = currentNumber + offset;
    if (newNumber < 0 || newNumber > courseCodes.length - 1) { return; }

    this.switchCourse(this._lang, courseCodes[newNumber]).subscribe();
  }

  private chooseCurrentLangProgress() {
    if (this.courseProgress == null || this.courseProgress.productCode == null || this.progresses == null) {
      return;
    }

    this.courseProgress.productProgress = this.progresses.find( p => p.productCode === this.courseProgress.productCode);
    if (this.courseProgress.productProgress != null) {
      this.courseProgress.courseCode = this.courseProgress.productProgress.courseCode;
    }
  }

  private loadCourseLessons(courseCode: string) {
    return this.studentRest.findCourseLessons(
      this.studentId, courseCode, Pageable.of(0, 50, null)
    );
  }

  private loadProductProgressEstimation(productCode: string) {
    return this.studentCache.loadProductProgressEstimation(
      this.studentId,
      productCode
    );
  }

  private loadLangStudentCourses(lang: string): Observable<ApiCourse[]> {
    return this.studentCache.loadStudentAttendedCourses(this.studentId, lang);
  }

}
