import { Injectable } from '@angular/core';
import {ApiLessonBundle, ApiPersonTechnicalProfile, ApiLessonProgress,
  ApiLessonInstance, ApiCourse, LessonBundeFilter, ApiLessonCommit,
  StudentCommitsFilter, ApiPersonalProfile, ApiPerson,
  ApiLearningUnitTeacher, ApiCompetence, ApiProductContext, ApiTeacherProfile, ApiProvaContext} from '../../model/rest/rest-model';
import {Page, Pageable} from '../../model/rest/base';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { ApiProduct } from 'src/app/model/rest/products';
import { Dates } from 'src/app/utils/calendar-utils';
import {StudentBookRestBridgeService} from "./student-book-rest-bridge.service";
import {StudentBookRestService, StudentBookRestServiceImpl} from "./student-book-rest.service";
import {
  ApiLessonEventModel,
  LessonScheduleEventReference, SimpleLessonScheduleRequest,
  SimpleScheduleEvents, SimpleTeacherProductAvailability
} from "../../model/rest/booking-rest-v2";

@Injectable({
  providedIn: 'root'
})
export class StudentRestService implements StudentBookRestService {
  private bookingRest: StudentBookRestBridgeService | StudentBookRestServiceImpl
  constructor(private http: HttpClient,
              private bookingBridge: StudentBookRestBridgeService,
              private bookingRestV2: StudentBookRestServiceImpl) {
    this.bookingRest = environment.scheduleBridgeEnabled?
      bookingBridge : bookingRestV2
  }

  getLessonScheduleEventReference(studentId: number, scheduleId: number): Observable<LessonScheduleEventReference<ApiLessonEventModel>> {
      return this.bookingRest.getLessonScheduleEventReference(studentId, scheduleId)
  }
  listStudentSchoolTeachersAvailability(studentId: number, focusDate: Date, productCode: string): Observable<SimpleTeacherProductAvailability[]> {
      return this.bookingRest.listStudentSchoolTeachersAvailability(studentId, focusDate, productCode)
  }
  listStudentLessonSchedules(studentId: number, focusDate: Date, productCode: string): Observable<SimpleScheduleEvents[]> {
      return this.bookingRest.listStudentLessonSchedules(studentId, focusDate, productCode)
  }
  reserveColSchedule(studentId: number, request: SimpleLessonScheduleRequest): Observable<SimpleScheduleEvents> {
      return this.bookingRest.reserveColSchedule(studentId, request)
  }

  /*
  find studnet profile by id or null if profile wasn't initialzied yet
  */
  public loadStudentTechnicalProfile(studentId: number): Observable<ApiPersonTechnicalProfile> {
    const url = environment.apiEndpoint + '/student/' + studentId + '/technical-profile';
    return this.http.get<ApiPersonTechnicalProfile>(url);
  }

  public loadStudentProgress(studentId: number): Observable<ApiLessonProgress[]> {
    const url = environment.apiEndpoint + '/student/' + studentId + '/progress';

    return this.http.get<ApiLessonProgress[]>(url);
  }

  public loadStudentAttendedCourses(studentId: number, productCode: string) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/progress/' + productCode + '/courses';

    return this.http.get<ApiCourse[]>(url);
  }

  public findCourseLessons(studentId: number, courseCode: string, pageable: Pageable) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/lessons/by-course/' + encodeURIComponent(courseCode);
    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url,
      {
        params: Pageable.appedPageableParams(new HttpParams(), pageable)
      });
  }

  public loadProgressEstimation(studentId: number, productCode: string) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/progress/' + productCode + '/promote-estimate';

    return this.http.get<ApiLessonProgress[]>(url);
  }

  public findUpcomingNextLesson(studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    const url = environment.apiEndpoint + '/student/' + studentId + '/lessons/upcoming/next';

    return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url).pipe(
      tap( lesson => {
        if (!lesson) {
          return;
        }
        lesson.lessonMetric.plannedStartDate = Dates.parse(String(lesson.lessonMetric.plannedStartDate));
      })
    );
  }

  public findUpcomingNextLessonsMultiple(studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]> {
    const url = environment.apiEndpoint + '/student/' + studentId + '/lessons/upcoming/next-multiple';

    return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]>(url).pipe(
      tap( lesson => {
        if (!lesson) {
          return;
        }
        lesson.map(lesson =>lesson.lessonMetric.plannedStartDate = Dates.parse(String(lesson.lessonMetric.plannedStartDate)));
      })
    );
  }

  public findUpcomingLessons(studentId: number, productCode: string, pageable: Pageable) {
    let url: string;
    if (productCode == null || productCode === undefined) {
      url = environment.apiEndpoint + '/student/' + studentId + '/lessons/upcoming';
    } else {
      url = environment.apiEndpoint + '/student/' + studentId + '/lessons/by-product/' + encodeURIComponent(productCode) + '/upcoming';
    }

    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {
      params: Pageable.appedPageableParams(new HttpParams(), pageable)
    }).pipe(
      tap( lessonsPage =>
        lessonsPage.content.forEach(
          lesson => lesson.lessonMetric.plannedStartDate = Dates.parse(String(lesson.lessonMetric.plannedStartDate))
        )
      )
    );
  }

  public findPastLessons(studentId: number, productCode: string, pageable: Pageable) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/lessons/by-product/' + encodeURIComponent(productCode) + '/past';

    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {
      params: Pageable.appedPageableParams(new HttpParams(), pageable)
    }).pipe(
      tap( lessonsPage =>
        lessonsPage.content.forEach(
          lesson => {
            lesson.lessonMetric.plannedStartDate = Dates.parse(String(lesson.lessonMetric.plannedStartDate));
            lesson.lessonMetric.started = Dates.parse(String(lesson.lessonMetric.started));
          }
        )
      )
    );
  }

  public findStudentLessonBundles(studentId: number, filter: LessonBundeFilter, pageable: Pageable) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/bundles';

    return this.http.get<Page<ApiLessonBundle>>(url, {
      params: filter.apply(Pageable.appedPageableParams( new HttpParams(), pageable))
    }).pipe(
      tap( bundlesPage => bundlesPage.content.forEach( bundle => {
        bundle.date = Dates.parse(String(bundle.date));
        bundle.expiryDate = Dates.parse(String(bundle.expiryDate));
      }))
    );
  }

  public findCommits(studentId: number, filter: StudentCommitsFilter, pageable: Pageable) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/lessons/commits';
    return this.http.get<Page<ApiLessonCommit>>(url, {
      params: filter.apply(Pageable.appedPageableParams(new HttpParams(), pageable))
    });
  }

  public loadLesson(studentId: number, lessonId: number) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/lessons/' + lessonId;

    return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url).pipe(
      tap (  lesson => {
        lesson.lessonMetric.plannedStartDate = Dates.parse(String(lesson.lessonMetric.plannedStartDate));
        lesson.lessonMetric.started = Dates.parse(String(lesson.lessonMetric.started));
      })
    );
  }

  /*
  not used anymore - replaced by loadSelfPerson where profile is mapped into ApiPerson
  public loadPersonalProfile(studentId: number) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/person/profile';

    return this.http.get<ApiPersonalProfile>(url);
  }
  */

  public savePersonalProfile(studentId: number, profile: ApiPersonalProfile) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/person/profile';

    return this.http.put<ApiPersonalProfile>(url, profile);
  }

  public loadSelfPerson(studentId: number) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/person';

    return this.http.get<ApiPerson<ApiPersonalProfile>>(url);
  }

  public saveStudentTechnicalProfile(studentId: number, profile: ApiPersonTechnicalProfile): Observable<ApiPersonTechnicalProfile> {
    const url = environment.apiEndpoint + '/student/' + studentId + '/technical-profile';

    return this.http.put<ApiPersonTechnicalProfile>(url, profile);
  }

  public listCourseAllowedCompetences(courseCode: string) {
    const url = environment.apiEndpoint + '/student/courses/' + encodeURIComponent(courseCode) + '/competences';
    return this.http.get<ApiCompetence[]>(url);
  }

  public listTeachers(studentId: number, teacherIds?: number[]):
  Observable<ApiTeacherProfile[]> {
    const url = environment.apiEndpoint + '/student/' + studentId + '/teachers';

    let teacherIdsParam = new HttpParams();
    if (teacherIds) {
      teacherIds.forEach ( id => teacherIdsParam = teacherIdsParam.append('id', id.toString()) );
    }

    return this.http.get<ApiTeacherProfile[]>(url, { params: teacherIdsParam });
  }

  /*
  replaced by listTeachers which contains personal profile inside the person
  public listTeacherPersonalProfiles(studentId: number, teacherIds: number[]): Observable<ApiPersonalProfile[]> {
    const url = environment.apiEndpoint + '/student/' + studentId + '/teachers/person/profile';

    let params = new HttpParams();
    teacherIds.forEach( id => params = params.append('id', id.toString()));

    return this.http.get<ApiPersonalProfile[]>(url, {params: params});
  }
  */

  public cancelLesson(studentId: number, lessonId: number, reason: string) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/lessons/' + lessonId + '/cancel';

    return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, reason).pipe(
      tap( lesson => {
            lesson.lessonMetric.plannedStartDate = Dates.parse(String(lesson.lessonMetric.plannedStartDate));
            lesson.lessonMetric.started = Dates.parse(String(lesson.lessonMetric.started));
          }
        )
      );
  }

  public squanderLesson(studentId: number, lessonId: number, reason: string) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/lessons/' + lessonId + '/squander';

    return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, reason).pipe(
      tap( lesson => {
            lesson.lessonMetric.plannedStartDate = Dates.parse(String(lesson.lessonMetric.plannedStartDate));
            lesson.lessonMetric.started = Dates.parse(String(lesson.lessonMetric.started));
          }
        )
      );
  }

  public findProductContext(studentId: number, productCode: string) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/progress/' + productCode + '/context';

    return this.http.get<ApiProductContext>(url);
  }

  public addStudentProductContext(studentId: number, productCode: string):
  Observable<ApiProductContext> {
    const url = environment.apiEndpoint + '/student/' + studentId + '/progress/' + productCode + '/context';

    return this.http.post<ApiProductContext>(url, {});
  }

  public updateIntroductionState(studentId: number, state: string):
  Observable<ApiPersonTechnicalProfile> {
    const url = environment.apiEndpoint + '/student/' + studentId + '/technical-profile/introduction-state';

    return this.http.put<ApiPersonTechnicalProfile>(url, state);
  }

  public chargeFreeCredit(studentId: number, productCode: string):
  Observable<ApiLessonBundle> {
    const url = environment.apiEndpoint + '/student/' + studentId + '/bundle/' + productCode + '/free';

    return this.http.post<ApiLessonBundle>(url, {});
  }

  public listProducts(lang: string, currency: string):
  Observable<ApiProduct[]> {
    const url = environment.apiEndpoint + '/student/products';

    const params = new HttpParams().append('lang', lang).append('currency', currency);
    return this.http.get<ApiProduct[]>(url, { params: params });
  }

  public listProvaContexts(studentId: number, productCode?: string):
  Observable<ApiProvaContext[]> {
    const url = this.prepareStudentUrl(studentId) + '/prova-context';

    let params = new HttpParams();
    if (productCode) {
      params = params.append('productCode', productCode);
    }

    return this.http.get<ApiProvaContext[]>(url, { params: params}).pipe(
      tap( contexts => contexts.forEach( c => {
        c.starterExpiryDate = Dates.parse(String(c.starterExpiryDate));
        c.starterBuyDate = Dates.parse(String(c.starterBuyDate));
      }))
    );
  }

  prepareStudentUrl(studentId: number) {
    return environment.apiEndpoint + '/student/' + studentId;
  }

}
