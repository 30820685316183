import { Component, OnInit, Input, OnDestroy, Inject, LOCALE_ID, Directive } from '@angular/core';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { ApiProduct, ProductsUtils } from 'src/app/model/rest/products';
import { map, tap } from 'rxjs/operators';
import { ApiProvaContext } from 'src/app/model/rest/rest-model';
import { AppEventsService } from 'src/app/services/ctx/app-events.service';
import { Subscription, interval } from 'rxjs';
import { Dates } from 'src/app/utils/calendar-utils';
import { StudentShoppingCartService } from 'src/app/services/student-shopping-cart.service';

@Directive()
export abstract class StarterComponentBase implements OnInit, OnDestroy  {
  starter: ApiProduct[];
  context: ApiProvaContext;
  creditsUpdateSubs: Subscription;
  timeSubscription: Subscription;

  constructor(
    protected studentCache: StudentCacheProxyService,
    protected appEvents: AppEventsService,
    protected locale: string,
    protected shoppingCart: StudentShoppingCartService) { }
  _currency: string;
  _studentId: number;

  @Input()
  set studentId(studentId: number) {
     this._studentId = studentId;
     this.loadContexts();
  }

  ngOnDestroy(): void {
    this.creditsUpdateSubs.unsubscribe();
    this.timeSubscription.unsubscribe();
  }

  getTime(): string {
    if (!this.context) { return ''; }
    return Dates.toTimeStr(this.context.starterExpiryDate.getTime() - new Date().getTime(), true, false);
  }

  @Input()
  set currency(currency: string) {
    this._currency = currency;
    this.refreshProducts();
  }

  loadContexts() {
    this.studentCache.listProvaContexts(this._studentId).pipe(
      map( contexts => this.filterContextByProduct(contexts)),
      tap( context => this.storeContext(context))
    ).subscribe( );
  }

  isAvailable() {
    if (this.context
      && this.context.starterExpiryDate
      && this.context.starterExpiryDate.getTime() > new Date().getTime()
      && this.context.starterBuyDate == null) {
      return true;
    } else {
      return false;
    }
  }

  isBought() {
    if (this.context && this.context.starterBuyDate != null) {return true; }
    return false;
  }

  getNotAvailable() {
    if (this.isAvailable()) {
      return null;
    } else {return 'unavailable'; }
  }

  storeContext(context: ApiProvaContext) {
    this.context = context;
  }
  filterContextByProduct(contexts: ApiProvaContext[]) {
    return contexts.find( c => c.productContext.product.code === this.getProductCode());
  }

  refreshProducts(): any {
    if (!this._currency) {return; }
    if (!this.locale) {
      this.locale = 'en';
    }
    this.locale = this.locale.toUpperCase();
    this.studentCache.listProducts(this.locale, this._currency).subscribe(products => this.filterProducts(products));
  }

  filterProducts(products: ApiProduct[]): void {
    this.starter = ProductsUtils.filterByVariantLabel(products, this.getStarterCategoryId());
  }

  abstract getStarterCategoryId(): string;
  abstract getProductCode(): string;

  ngOnInit() {
    this.shoppingCart.orderComplete.subscribe(_ => {
      this.handleOrderComplete()
    });
    this.creditsUpdateSubs = this.appEvents.creditsUpdate.subscribe( () => this.loadContexts());
    this.timeSubscription = interval(1000).subscribe(() => {});
  }
  
  handleOrderComplete() {
    this.context.starterBuyDate = new Date();
    this.shoppingCart.changeOrderStatus()
  }
}

@Component({
  selector: 'app-english-starter-page',
  templateUrl: './english-starter-page.component.html',
  styleUrls: ['./english-starter-page.component.css']
})
export class EnglishStarterPageComponent extends StarterComponentBase {
  getProductCode(): string {
    return 'en';
  }

  constructor(
    studentCache: StudentCacheProxyService,
    appEvents: AppEventsService, 
    @Inject(LOCALE_ID) locale: string,
    shoppingCart: StudentShoppingCartService) {
      super(studentCache, appEvents, locale, shoppingCart);
    }

  getStarterCategoryId(): string {
    return 'category:lessons.en.starter';
  }
}
