import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ThemeService} from 'src/app/services/theme.service';
import {AuthServiceProvider} from "../../../services/col2/auth.service";

@Component({
  selector: 'app-manager-menu',
  templateUrl: './manager-menu.component.html',
  styleUrls: ['./manager-menu.component.css']
})
export class ManagerMenuComponent implements OnInit {
  schoolId: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private authService: AuthServiceProvider,
    public themeService: ThemeService) { }

  ngOnInit() {
    this.activeRoute.children[0].paramMap.subscribe( params => this.schoolId = params.get('schoolId') );
  }


  logout() {
    this.authService.get().subscribe(api => api.logout());
  }

}
