<a routerLink="/student/{{studentId}}/buy-courses/product/{{langCode}}">
<div class="card xmas">
  <div class="xmas-content" >
    <span i18n="@@xmas-banner.title">20% off all plans</span>
    <div class="xmas-body">
      <span i18n="@@xmas-banner.body">promo code: COLXMAS20</span>
      <span class="xmas-valid" i18n="@@xmas-banner.valid">*valid until 31.01.2020</span>
    </div>
  </div>
</div>
</a>