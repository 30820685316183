import { Component, OnInit, Input } from '@angular/core';
import { PersonScoresStruct } from '../cspa-summary.component';
import { Chapter } from 'src/app/model/rest/cspa-rest-model';

@Component({
  selector: 'app-stage-details',
  templateUrl: './stage-details.component.html',
  styleUrls: ['./stage-details.component.css']
})
export class StageDetailsComponent implements OnInit {
  _personScoresStruct: PersonScoresStruct;
  _chapter: Chapter;

  @Input()
  set personScoresStruct(struct: PersonScoresStruct) {
    this._personScoresStruct = struct;
  }
  
  @Input()
  set chapter(chapter: Chapter) {
    this._chapter = chapter;
  }

  get personScoresStruct(): PersonScoresStruct {
    return this._personScoresStruct;
  }

  get chapter(): Chapter {
    return this._chapter;
  }



  constructor() { }

  ngOnInit() {
  }

}
