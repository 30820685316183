import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { StudentContextService } from 'src/app/services/ctx/student-context.service';

@Component({
  selector: 'app-buy-courses',
  templateUrl: './buy-courses.component.html',
  styleUrls: ['./buy-courses.component.css']
})
export class BuyCoursesComponent implements OnInit, OnDestroy {
  studentId: number;
  supportedLangsSubscription: Subscription;
  langSubscription: Subscription;
  langs: string[];
  activeLangs: string[]
  isLoading = true

  constructor(private route: ActivatedRoute,
    private studentContext: StudentContextService) { }
    
  ngOnInit() {
    this.supportedLangsSubscription = this.studentContext.supportedLangsSubject
    .pipe(
      tap(langs => this.langs = langs),
      switchMap(_ => this.route.parent.paramMap),
      tap(params => this.studentId = Number(params.get('studentId'))),
      tap(_ => { if(this.langs.length) this.isLoading = false })
    ).subscribe()
  }


  isCourseUnactive(course: string) {
    return !(this.langs.length && this.langs.includes(course)) ? true : false
  }

  hasAnyAvailableCourse() {
    return this.langs.length === 3 ? false : true
  }


  ngOnDestroy(): void {
    if (this.supportedLangsSubscription) { this.supportedLangsSubscription.unsubscribe(); this.supportedLangsSubscription = null; }
    if (this.langSubscription) { this.langSubscription.unsubscribe(); this.langSubscription = null; }
  }
}
