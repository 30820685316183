import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-discrete-progress',
  templateUrl: './discrete-progress.component.html',
  styleUrls: ['./discrete-progress.component.css']
})
export class DiscreteProgressComponent implements OnInit {

  constructor() { }

  @Input()
  step: number;
  @Input()
  steps: number;

  ngOnInit() {
  }

  getBarStyle() {
    if (this.step === undefined || this.steps === undefined) {
      return {};
    }
    return {
      width: (this.step / this.steps * 100 ) + '%'
    };
  }

}
