import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
    'providedIn': 'root'
})
export class StudentDashboardService {

    chosenSection = new BehaviorSubject<string>('')

    constructor() {}
    
    getChosenSection(): Observable<string>{
        return this.chosenSection
    }

    setChosenSection(section: string) {
        this.chosenSection.next(section)
    }
}