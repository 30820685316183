import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manager-student-credits-page',
  templateUrl: './manager-student-credits-page.component.html',
  styleUrls: ['./manager-student-credits-page.component.css']
})
export class ManagerStudentCreditsPageComponent implements OnInit {
  schoolId: number;
  studentId: number;

  constructor(private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activeRoute.parent.parent.paramMap.subscribe( params => this.schoolId = Number(params.get('schoolId')));
    this.activeRoute.parent.paramMap.subscribe( params => this.studentId = Number(params.get('studentId')));
  }

}
