import {ApiCompetence, ApiCourse, ApiCourseProduct} from "./rest-model";

export class ScheduleReference {
  id: number;
  name: string;
}
export class TeacherReference {
  id: number;
}

export class EventTime {
  eventDate: Date;
  duration: number;
}

export class EventReference extends EventTime {
  eventId: number;
}

export class LocalDateTime {
  starting: Date;
  timezone: String;
}

export class ScheduleEvents<S extends ScheduleReference, E extends EventReference> {
  schedule: S;
  events: E[];
}

export class LessonReference {
  lessonId: number
}

abstract class ApiScheduleEventModel {}

export class ApiLessonEventModel extends ApiScheduleEventModel {
  lesson: LessonReference
}
export class ScheduleEventReference<EM extends ApiScheduleEventModel> extends EventReference {
  eventData: EM
}

export class LessonScheduleEventReference<EM extends ApiLessonEventModel> extends ScheduleEventReference<EM> {}

export class ColLessonScheduleEventReference extends LessonScheduleEventReference<ApiLessonEventModel> {}

export class ProductAvailabilityDetails {
  product: ApiCourseProduct;
  competences: ApiCompetence[];
  overtake: number;
}

export class ProductAvailability<
  S extends ScheduleReference,
  E extends EventReference
> extends ScheduleEvents<S,E> {
  details: ProductAvailabilityDetails;
}

export class SimpleScheduleEvents extends ScheduleEvents<ScheduleReference, EventReference> {}

export class NonRecurringScheduleDefinition {
  duration: number;
  time: LocalDateTime;
}

export class ScheduleDefinition extends NonRecurringScheduleDefinition {
  ending: Date;
  recurring: string;
}

export class ProductAvailabilityRequest {
  scheduleDetails: ScheduleDefinition;
  availabilityDetails: ProductAvailabilityDetails;
}

export class TeacherProductAvailability<T extends TeacherReference, E extends EventTime> {
  teacher: T;
  details: ProductAvailabilityDetails;
  events: E[];
}

export class SimpleProductAvailability extends ProductAvailability<ScheduleReference, EventReference> {}

export class SimpleTeacherProductAvailability extends TeacherProductAvailability<TeacherReference, EventTime> {}

export class SimpleLessonScheduleRequest {
  name: String;
  time: LocalDateTime;
  duration: number;
  course: ApiCourse;
  competence: ApiCompetence;
  teacher: TeacherReference;
}
