<ng-template [ngLoading]="!loaded" type="spinner">
  <div class="table-responsive">
    <table class="table table-borderless pe-none">
      <thead>
        <tr>
          <th i18n="@@student-transactions.table.event-date">Date</th>
          <th i18n="@@student-transactions.table.event-name">Name</th>
          <th i18n="@@student-transactions.table.event-related-plan">Plan</th>
          <th i18n="@@student-transactions.table.event-credits-amount">Amount</th>
          <th i18n="@@student-transactions.table.event-final-balance">Balance</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let viewRow of viewRows">
          <td>{{viewRow.date | date:'dd MMM yyyy HH:mm'}}</td>
          <td >
            <span i18n="available transaction actions@@student-transactions.table.action.select">{viewRow.action, select, purchased {Plan Purchased} reserved {Lesson Reserved} cancelled {Lesson Cancelled} finished {Lesson Finished} other {Unknown action}}</span></td>
          <td>{{viewRow.bundleName}}</td>
          <td>{{viewRow.amount}}</td>
          <td>{{viewRow.balance}}</td>
        </tr>
        <tr *ngIf="hasNext" class="pe-auto">
          <td colspan="5" class="text-center">
            <a (click)="moveNext()" tabindex=""><i class="fa fa-angle-double-down justify-content-center"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
 