import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Pageable, Page, ComponentEvent, StateAwareComponent } from 'src/app/model/rest/base';
import { Observable } from 'rxjs';
import { ApiLessonInstance, ApiPerson, LessonStatusUtils, ApiLessonStatus, ApiLessonFlag, ApiLearningUnitTeacher, ApiPersonalProfile } from 'src/app/model/rest/rest-model';
import { TeacherRestService } from 'src/app/services/rest/teacher-rest.service';
import {switchMap, tap} from 'rxjs/operators';
import { ModalComponent } from '../../modal/modal.component';
import { ProfilePhotoUrlExtractor, PersonNameExtractor } from 'src/app/utils/profile-photo-url-extractor';
import { ManagerRestService } from 'src/app/services/rest/manager-rest.service';
import { LessonTypeColors } from 'src/app/utils/utils';
import {LocalStateService} from "../../../services/local-state.service";
import {PageableComponent} from "../../pagination/pagination.component";
import {LessonDetailsEvent} from "../../student/student-lessons/student-lessons.component";

export enum LessonType {
  Upcoming, Incomplete, Past
}

export class TeacherLessonsComponentEvent extends ComponentEvent {

}

@Component({
  selector: 'app-teacher-lessons',
  templateUrl: './teacher-lessons.component.html',
  styleUrls: ['./teacher-lessons.component.css']
})
export class TeacherLessonsComponent extends StateAwareComponent<TeacherLessonsComponentEvent> implements OnInit, PageableComponent {
  _teacherId: number;
  _lessonType: LessonType;
  pageable: Pageable;
  queryResults: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> = null;
  lessonTypes = LessonType;

  // cancel lesson
  @ViewChild('cancelLessonModal', {static: true}) cancelLessonModal: ModalComponent;
  _clLessonToCancel: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  _clReason: string;
  _componentRole: string = null;
  _schoolId: number;
  constructor(
    private teacherRest: TeacherRestService,
    private managerRest: ManagerRestService,
    private localState: LocalStateService) {
    super();
  }

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.initialLoad();
  }

  get teacherId(): number {
    return this._teacherId;
  }

  @Input()
  set lessonType(lessonType: LessonType) {
    this._lessonType = lessonType;
    this.initialLoad();
  }

  @Input()
  set componentRole(role: string) {
    this._componentRole = role;
    this.initialLoad();
  }

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.initialLoad();
  }

  @Output()
  lessonDetailsEvent = new EventEmitter<LessonDetailsEvent>();

  isCancelable(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.lessonStatus === 'Booked'
    && new Date(lesson.lessonMetric.plannedStartDate).getTime() - 1000 * 60 * 15 > new Date().getTime();
  }

  public hasResults() {
    return !!this.queryResults;

  }

  public mayViewLesson(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return true;
  }

  public openDetails(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>, event: MouseEvent) {
    this.lessonDetailsEvent.emit(new LessonDetailsEvent(lesson, event));
  }

  public cancelLesson() {
    if (!this.isCancelable(this._clLessonToCancel)) {
      this.teacherRest.squanderLesson(this._teacherId, this._clLessonToCancel.id, this._clReason)
      .subscribe(() => {
        this._clLessonToCancel = null;
        this.reloadCurrent();
      });
    } else {
      this.teacherRest.cancelLesson(this._teacherId, this._clLessonToCancel.id, this._clReason)
      .subscribe(() => {
        this._clLessonToCancel = null;
        this.reloadCurrent();
      });
    }
    this.cancelLessonModal.hide();

  }

  public lessonBadgeClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (this._lessonType === undefined) {
      return '';
    }
    return LessonTypeColors.getTeacherLessonColorBorderClass(lesson);
  }

  getLessonStatus(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return LessonTypeColors.getStudentLessonStatus(lesson);
  }

  public showLessonCancelation(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    this._clLessonToCancel = lesson;
    this._clReason = null;
    this.cancelLessonModal.show();

  }

  get lessonType(): LessonType {
    return this._lessonType;
  }

  private initialLoad() {
    this.eventLoading();
    this.clearLessonRequests();

    if (this._componentRole == null) {return; }
    if (this._componentRole === 'teacher') {
      if (this._lessonType == null
        || this._teacherId == null) {
        return;
      }
    }
    if (this._componentRole === 'manager') {
      if (this._lessonType == null || this._teacherId == null
        || this._schoolId == null) {
          return;
        }
    }

    const order = this._lessonType === LessonType.Past
      ? ['lesson.teacher.metricDetails.plannedStartDate,DESC']
      : ['lesson.teacher.metricDetails.plannedStartDate,ASC'];

    this.localState.get<Pageable>(this.prepareLocalStateUrl(), () => Pageable.of(0, 10, order))
      .pipe(
        switchMap( state => this.loadAndProcessPage(state))
      )
      .subscribe(_ => this.eventLoaded())
  }

  private loadLessons(pageable: Pageable): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    if (this._componentRole === 'teacher') {
      if (this._lessonType === LessonType.Upcoming ) {
        return this.teacherRest.listUpcomingLessons(this._teacherId, pageable);
      } else if (this._lessonType === LessonType.Past) {
        return this.teacherRest.listPastLessons(this._teacherId, pageable);
      } else if (this._lessonType === LessonType.Incomplete) {
        return this.teacherRest.listIncompleteLessons(this._teacherId, pageable);
      }
    } else if (this._componentRole === 'manager') {
      if (this._lessonType === LessonType.Upcoming ) {
        return this.managerRest.listTeacherUpcomingLessons(this._schoolId, this._teacherId, pageable);
      } else if (this._lessonType === LessonType.Past) {
        return this.managerRest.listTeacherPastLessons(this._schoolId, this._teacherId, pageable);
      } else if (this._lessonType === LessonType.Incomplete) {
        return this.managerRest.listTeacherIncompleteLessons(this._schoolId, this._teacherId, pageable);
      }
    }
  }

  getPersonProfilePhoto(person: ApiPerson<ApiPersonalProfile>) {
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(person);
  }

  getLessonDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): Date {
    if (this._lessonType === LessonType.Upcoming ) {
      return lesson.lessonMetric.plannedStartDate;
    } else  {
      return lesson.lessonMetric.plannedStartDate;
    }
  }

  public getStudentNames(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): string {
    return lesson.students
      .map(s => s.person )
      .map(p => PersonNameExtractor.getPersonName(p))
      .join(', ');
  }

  public nextPage() {
    this.loadAndProcessPage(this.pageable.next()).subscribe()
  }

  public prevPage() {
    this.loadAndProcessPage(this.pageable.prev()).subscribe()
  }

  public firstPage() {
    this.loadAndProcessPage(this.pageable.first()).subscribe();
  }

  public lastPage() {
    this.loadAndProcessPage(this.pageable.last(this.queryResults)).subscribe();
  }

  public nthPage(page: string) {
    this.loadAndProcessPage(this.pageable.nth(+page)).subscribe();
  }

  mayExtend() {
    return (this.queryResults && this.queryResults.pageable.pageSize < 50 && this.hasNext());
  }

  mayCollapse() {
    return (this.queryResults && this.queryResults.pageable.pageSize > 10);
  }

  getTotalResults() {
    if (!this.queryResults) return 0;
    return this.queryResults.totalElements;
  }

  getPageNumber() {
    if (!this.queryResults) return 0;
    return this.queryResults.number + 1;
  }

  getPagesNumber() {
    if (!this.queryResults) return 0;
    return this.queryResults.totalPages;
  }

  getLessonsToDisplay() {
    return this.queryResults.content;
  }

  hasNext() {
    if (!this.queryResults) return false;
    return !this.queryResults.last;
  }

  hasPrev() {
    if (!this.queryResults) return false;
    return !this.queryResults.first;
  }

  hasPagination() {
    return this.hasPrev() || this.hasNext();
  }

  changePageSize(modifier: number) {
    this.loadAndProcessPage(this.pageable.modifyPageSize(modifier)).subscribe();
  }

  clearLessonRequests(): any {
    this.pageable = null;
    this.queryResults = null;
  }

  ngOnInit() {
    this.eventLoading();
  }

  private prepareLocalStateUrl() {
    return `${this._componentRole}/teachers/${this._teacherId}/lessons/${this._lessonType}`;
  }

  private reloadCurrent() {
    this.loadAndProcessPage(this.pageable).subscribe();
  }

  private loadAndProcessPage(state: Pageable) {
    this.pageable = state;
    return this.localState.set<Pageable>(this.prepareLocalStateUrl(), state)
      .pipe(
        switchMap( pageable => this.loadLessons(pageable)),
        tap<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>( data => {
          this.storeData(data);
          this.eventNumberOfElements(data.totalElements)
        })
      )
  }

  private storeData(data: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>) {
    this.queryResults = data;
  }

  buildVersionNumber(courseCode: string, productVersion: string) {
    if (!courseCode.startsWith("en.") && !productVersion) return null;
    if (!productVersion) return '2nd Ed.';
    const intVer = parseInt(productVersion);
    if (isNaN(intVer)) return `v: ${productVersion}`;
    const lastDigit = intVer % 10;
    let postfix: string;
    if (intVer > 3 && intVer < 20) postfix = 'th';
    else if (lastDigit == 1) postfix = 'st';
    else if (lastDigit == 2) postfix = 'nd';
    else if (lastDigit == 3) postfix = 'rd';
    else postfix = 'th';
    return `${intVer}${postfix} Ed.`;
  }

  getProductVersion(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return this.buildVersionNumber(lesson.course.code, lesson.productVersion);
  }

  isRedLabel(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.course.code.startsWith('en.') && lesson.productVersion == null
  }
}
