import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-container-layout',
  templateUrl: './container-layout.component.html',
  styleUrls: ['./container-layout.component.css']
})
export class ContainerLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
