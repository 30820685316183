<form>
  <div class="row">
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select name="productCode" class="form-select" [(ngModel)]="productCode">
          <option *ngFor="let langCode of langCodes" [value]="langCode">{{getLangName(langCode)}}</option>
        </select>
        <label for="productCode">Product</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select (change)="preselectedChange()" name="preselectedPeriod" class="form-select" [(ngModel)]="preselectedPeriod">
          <option value="0" selected>current month</option>
          <option value="-1">previous month</option>
          <option value="-2">2 months ago</option>
          <option value="-3">3 months ago</option>
          <option value="-4">4 months ago</option>
          <option value="-5">5 months ago</option>
          <option value="-6">6 months ago</option>
          <option value="-7">7 months ago</option>
        </select>
        <label for="preselectedPeriod">Preselect period of set dates</label>
      </div>
    </div> 
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input name="dateFrom" placeholder="dd/MM/yyyy HH:mm" class="form-control" type="text" [(ngModel)]="dateFrom">
        <label for="dateFrom">Date from</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input name="dateTo" placeholder="dd/MM/yyyy HH:mm" class="form-control" type="text" [(ngModel)]="dateTo">
        <label for="dateTo">Date to</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="withLessonType" class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select name="lessonType" class="form-select" [(ngModel)]="lessonType">
          <option value="Standard">Standard</option>
          <option value="Revision">Revision</option>
          <option value="QuickStageRevision">Quick Stage Revision</option>
          <option value="Introduction">Introduction</option>
          <option value="Prova">Prova</option>
          <option value="Exam">Exam</option>
        </select>
        <label for="lessonType">Lesson type</label>
      </div>
    </div>
    <div *ngIf="withLessonStatus" class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select name="lessonStatus" class="form-select" [(ngModel)]="lessonStatus">
          <option value="Initializing">Initializing</option>
          <option value="Booked">Booked</option>
          <option value="Due">Due</option>
          <option value="InProgress">In Progress</option>
          <option value="Complete">Complete</option>
          <option value="Cancel">Cancel</option>
          <option value="InvalidatedByTeacher">Invalidated By Teacher</option>
          <option value="InvalidatedByStudent">Invalidated By Student</option>
        </select>
        <label for="lessonStatus">Lesson Status</label>
      </div>
    </div>
    <div *ngIf="withLessonFlag" class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select name="lessonFlag" class="form-select" [(ngModel)]="lessonFlag">
          <option value="PoorConnection">PoorConnection</option>
          <option value="ClassroomIssue">ClassroomIssue</option>
          <option value="StudentNeedsHelp">StudentNeedsHelp</option>
          <option value="StudentNotShow">StudentNotShow</option>
          <option value="TeacherNotShow">TeacherNotShow</option>
          <option value="Other">Other</option>
        </select>
        <label for="lessonFlag">Lesson Flag</label>
      </div>
    </div>
  </div>
  <div *ngIf="withLessonFlag && withLessonStatus && withLessonType">
      predefined:
      <ul class="list-inline">
        <li class="list-inline-item"><a tabindex="" (click)="predefProva()">Prova</a></li>
        <li class="list-inline-item"><a tabindex="" (click)="predefProvaNoShow()">Prova no show</a></li>
        <li class="list-inline-item"><a tabindex="" (click)="predefNoShow()">No show</a></li>
      </ul>
   </div>
  <div class="row">
    <div class="col text-end">
        <button (click)="generate()" class="btn btn-primary px-4 py-2">Generate</button>
    </div>
  </div>
  <div class="clearfix"></div>
  
</form>
