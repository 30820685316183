import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudentContextService } from 'src/app/services/ctx/student-context.service';
import { Subscription } from 'rxjs';
import { AppEventsService } from 'src/app/services/ctx/app-events.service';
import { ApiPersonTechnicalProfile, IntroductionState } from 'src/app/model/rest/rest-model';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { switchMap } from 'rxjs/operators';
import { AnalyticsService, Event } from 'src/app/services/analytics.service';
import { LocaleManagerService } from 'src/app/services/locale-manager.service';

@Component({
  selector: 'app-student-reserve-lesson-page',
  templateUrl: './student-reserve-lesson-page.component.html',
  styleUrls: ['./student-reserve-lesson-page.component.css']
})
export class StudentReserveLessonPageComponent implements OnInit, OnDestroy {

  @ViewChild('freeCreditModal', {static: true})
  freeCreditModal: ModalComponent;

  studentId: string;
  langCode: string;
  langCodeSubscription: Subscription;
  availableTeachersIds: number[];
  filterTeachersIds: number[];
  technicalProfileUpdateSubscription: Subscription;
  _introductionState: string;
  _showTutorialDim = false;

  constructor(private route: ActivatedRoute,
    private studentContext: StudentContextService,
    private appEvents: AppEventsService,
    private studentCache: StudentCacheProxyService,
    private analytics: AnalyticsService,
    private localeService: LocaleManagerService
    ) {
      this.technicalProfileUpdateSubscription = appEvents.technicalProfileUpdated.subscribe(
        newProfile => this.resolveIntroductionState(newProfile)
      );
     }

  ngOnInit() {
    this.route.parent.paramMap.subscribe( params => this.studentId = params.get('studentId'));
    this.langCodeSubscription = this.studentContext.langCodeSubject.subscribe(
      langCode => this.langCode = langCode
    );
    this.route.parent.paramMap.subscribe( params => {
      this.studentId = params.get('studentId');
      if (this.studentId ) {
        this.loadTechnicalProfile();
      }
    });
  }

  updateTeachersFilter(teachersIds: number[]) {
    this.filterTeachersIds = Array.from(teachersIds);
  }

  updateAvailableTeachers(teachersIds: number[]) {
    this.availableTeachersIds = teachersIds;
  }

  loadTechnicalProfile(): any {
    this.studentCache.loadStudentTechnicalProfile(Number(this.studentId))
    .subscribe(
      studentProfile => this.resolveIntroductionState(studentProfile)
    );
  }

  resolveIntroductionState(studentProfile: ApiPersonTechnicalProfile): void {
    this._introductionState = studentProfile && studentProfile.introductionState ? studentProfile.introductionState : '';

    if (this._introductionState === IntroductionState.FreeCredit) {
      setTimeout(() => {
        this.freeCreditModal.show(false)
        const freeCredit = document.getElementById('free-credit-modal')
        freeCredit.classList.add('modal-appear')
      }, 500);
    }
  }

  closeCalendarPopover() {
    this.studentCache.chargeFreeCredit(Number(this.studentId), this.langCode).pipe(
      switchMap( () => this.studentCache.updateIntroductionState(Number(this.studentId), IntroductionState.ReserveProva ))
    ).subscribe(
      () => {
        this.analytics.event(Event.ACT_IND_GET_CREDIT, new Event(Event.CAT_IND, this.langCode));
        this.analytics.event(Event.ACT_IND_GET_CREDIT, new Event(Event.catInductionWithLang(this.localeService.localeId), this.langCode));
      }
    );
    this.freeCreditModal.hide()
  }

  ngOnDestroy(): void {
    this.langCodeSubscription.unsubscribe();
    this.technicalProfileUpdateSubscription.unsubscribe();
  }


}
