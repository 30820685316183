<div class="d-flex flex-column justify-content-between bg-radial min-vh-100">
  <div class="container pt-5">
    <nav class="navbar">
        <a class="navbar-brand mx-auto" href="#" routerLink="/" >
          <img [src]="themeService.getLogoPath()" alt="Callan Online Logo"  class="d-inline-block align-top">
        </a>
    </nav>
  </div>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
  <app-page-footer></app-page-footer>
</div>
