<!-- <section id="courses" class="mb-5">

  <div class="row mt-3 mb-4 text-center">
    <h2 i18n="courses page title@@courses_page_title">Courses</h2>
  </div>
  <div class="row text-center gy-5 g-md-5">
    <div class="col-12 col-md-6 col-xl-3 icon-box">
      <img src="https://assets.callan.app/callanonline/icon course 1 speak fluently@2x.png" class="img-fluid icon">
      <p class="text-muted mb-0" i18n="courses page introduction point 1|courses description list, point about speaking fluently@@courses_description_1">Our courses are all about speaking fluently, confidently, and without translating. In Callan Online lessons you communicate and interact with your tutors all the time. As a result, your skills in listening, speaking and reading improve dramatically.</p>
    </div>

    <div class="col-12 col-md-6 col-xl-3 icon-box">
      <img src="https://assets.callan.app/callanonline/icon course 4 25 minutes@2x.png" class="img-fluid icon">
      <p class="text-muted mb-0" i18n="courses page introduction point 2|courses description list, point about lesson lenght@@courses_description_2">Every 25 minute lesson is carefully structured so that you feel comfortable and can clearly see the progress you are making. All the material is repeated and repeated at a rapid pace, which means that you quickly become fluent and confident in using the language.</p>
    </div>

    <div class="col-12 col-md-6 col-xl-3 icon-box">
      <img src="https://assets.callan.app/callanonline/icon course 12 vocab@2x.png" class="img-fluid icon">
      <p class="text-muted mb-0" i18n="courses page introduction point 3|courses description list, point about exercises@@courses_description_3">After every lesson the online interactive exercises will help you improve your writing and grammar and let you practise the language you have learnt with your tutors in the lessons.</p>
    </div>

    <div class="col-12 col-md-6 col-xl-3 icon-box">
      <img _ngcontent-nme-c64="" src="https://assets.callan.app/callanonline/icon course 3 progress@2x.png" class="img-fluid icon">
      <p class="text-muted mb-0"  i18n="courses page introduction point 4|courses description list, point about ebooks@@courses_description_4">All the content of the lessons is provided in the course eBooks as reference. The eBooks also include audio recordings by native speakers so that you can listen to correct pronunciation of the language at any time. </p>
    </div>

  </div>
</section> -->

<ng-template [ngLoading]="isLoading" type="spinner">
  <p *ngIf="!hasAnyAvailableCourse()" class="text-muted text-center my-3"> No results found.</p>
  <div class="course-en mb-4" *ngIf="isCourseUnactive('en')">
    <div class="course">
        <span class="h4" i18n="courses page english course title |title of the english course@@courses_title_english">General English</span>
        <a
        class="btn btn-primary rounded-pill float-end"
        i18n="courses page see plans button|see plans button @@button_see_plans"
        routerLink="/student/{{studentId}}/buy-courses/product/en"
        >
        See Plans
      </a>
    </div>
  </div>

  <div class="course-sp mb-4" *ngIf="isCourseUnactive('sp')">
    <div class="course">
      <span class="h4" i18n="courses page spanish course title |title of the spanish course@@courses_title_spanish">General Spanish</span>
      <a
        class="btn btn-primary rounded-pill float-end"
        i18n="courses page see plans button|see plans button @@button_see_plans"
        routerLink="/student/{{studentId}}/buy-courses/product/sp"
      >
        See Plans
      </a>
    </div>
  </div>
  <div class="course-be mb-4" *ngIf="isCourseUnactive('en.bs')">
    <div class="course">
      <span class="h4" i18n="courses page business course title |title of the business course@@courses_title_business">Business English</span>
      <a
        class="btn btn-primary rounded-pill float-end"
        i18n="courses page see plans button|see plans button @@button_see_plans"
        routerLink="/student/{{studentId}}/buy-courses/product/en.bs"
      >
        See Plans
      </a>
    </div>
  </div>
</ng-template>
