import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { StudentContextService } from 'src/app/services/ctx/student-context.service';
import { StudentRestService } from 'src/app/services/rest/student-rest.service';
import { Observable, of, Subscription } from 'rxjs';
import { Page, Pageable, ComponentEvent, StateAwareComponent } from 'src/app/model/rest/base';
import { ApiLessonInstance, ApiPersonalProfile, ApiLessonStatus, ApiLessonFlag, ApiLearningUnitTeacher } from 'src/app/model/rest/rest-model';
import { ModalComponent } from '../../modal/modal.component';
import {switchMap, tap} from 'rxjs/operators';
import { ProfilePhotoUrlExtractor, PersonNameExtractor } from 'src/app/utils/profile-photo-url-extractor';
import { AppEventsService } from 'src/app/services/ctx/app-events.service';
import { Dates, TimeUnits } from 'src/app/utils/calendar-utils';
import { ManagerRestService } from 'src/app/services/rest/manager-rest.service';
import { LessonTypeColors } from 'src/app/utils/utils';
import {LocalStateService} from "../../../services/local-state.service";
import {PageableComponent} from "../../pagination/pagination.component";

export enum LessonType {
  Upcomming, Past
}

export class LessonDetailsEvent {
  constructor(public lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>, public event: MouseEvent) {}
}

export class StudentLessonsComponentEvent extends ComponentEvent {
}

@Component({
  selector: 'app-student-lessons',
  templateUrl: './student-lessons.component.html',
  styleUrls: ['./student-lessons.component.css']
})
export class StudentLessonsComponent extends StateAwareComponent<StudentLessonsComponentEvent> implements OnInit, OnDestroy, PageableComponent {

  private _lang: string;
  private _lessonType: LessonType;
  private pageable: Pageable;
  private queryResults: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  @Input()
  set studentId(value: number) {
    this._studentId = value;
    this.initialLoad();
  }
  get studentId() {return this._studentId;}
  _studentId: number = null;
  private _schoolId: number = null;

  // cancel lesson
  @ViewChild('cancelLessonModal', {static: true}) cancelLessonModal: ModalComponent;
  _clLessonToCancel: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  _clReason: string;
  scheduleReservationSubscription: Subscription;
  _componentRole: string = null;

  constructor(
    private studentRest: StudentRestService,
    private managerRest: ManagerRestService,
    private appEvents: AppEventsService,
    private localState: LocalStateService) {
      super();
    }
  @Output()
  lessonDetailsEvent = new EventEmitter<LessonDetailsEvent>();

  @Input()
  public set langCode(lang: string) {
    this._lang = lang;
    this.initialLoad();
  }

  @Input()
  public set lessonType(lessonType: LessonType) {
    this._lessonType = lessonType;
    this.initialLoad();
  }

  @Input()
  public set componentRole(role: string) {
    this._componentRole = role;
    this.initialLoad();
  }

  @Input()
  public set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.initialLoad();
  }

  public hasResults(): boolean {
    return !!(this.queryResults);

  }

  openDetails(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>, event: MouseEvent) {
    this.lessonDetailsEvent.emit(new LessonDetailsEvent(lesson, event));
  }

  private initialLoad() {
    this.eventLoading();
    this.clearData();

    if (!this._componentRole) {
      return;
    }

    if (this._componentRole === 'student') {
      if (this._lang == null || this._lessonType == null || this.studentId == null) {
        return;
      }
    }

    if (this._componentRole === 'manager') {
      if (this.studentId == null || this._schoolId == null || this._lessonType == null) {
        return;
      }
    }

    const localStateUrl = this.prepareLocalStateUrl();
    let stateSource: Observable<Pageable>;

    if (this._lessonType === LessonType.Upcomming) {
      stateSource = this.localState.get<Pageable>(localStateUrl, () => Pageable.of(0, 5, ['lessonStudent.metricDetails.plannedStartDate,ASC']));
    } else {
      stateSource = this.localState.get<Pageable>(localStateUrl, () => Pageable.of(0, 5, ['lessonStudent.metricDetails.plannedStartDate,DESC']));
    }

    stateSource.pipe(
      switchMap( state => this.loadAndProcessPage(state))
    ).subscribe(
      _ => this.eventLoaded()
    )
  }

  private prepareLocalStateUrl() {
    return `${this._componentRole}/students/${this.studentId}/lessons/${this._lessonType}`;
  }

  private loadAndProcessPage(state: Pageable): Observable<any> {
    this.pageable = state;
    return this.localState.set<Pageable>(this.prepareLocalStateUrl(), state)
      .pipe(
        switchMap( pageable => this.loadLessons(pageable)),
        tap( data => {
          this.storeData(data);
          this.eventNumberOfElements(data.totalElements);
        }),
      )
  }

  private storeData(lessons: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>) {
    this.queryResults = lessons;
  }

  clearData(): any {
    this.pageable = null;
    this.queryResults = null;
  }

  public nextPage() {
    this.loadAndProcessPage(this.pageable.next()).subscribe();
  }

  public prevPage() {
    this.loadAndProcessPage(this.pageable.prev()).subscribe();
  }

  public firstPage() {
    this.loadAndProcessPage(this.pageable.first()).subscribe();
  }

  public lastPage() {
    this.loadAndProcessPage(this.pageable.last(this.queryResults)).subscribe();
  }

  public nthPage(page: string) {
    this.loadAndProcessPage(this.pageable.nth(+page)).subscribe();
  }

  mayExtend() {
    return (this.queryResults && this.queryResults.pageable.pageSize < 50 && this.hasNext());
  }

  mayCollapse() {
    return (this.queryResults && this.queryResults.pageable.pageSize > 5);
  }

  changePageSize(modifier: number) {
    this.loadAndProcessPage(this.pageable.modifyPageSize(modifier)).subscribe();
  }


  public getLessonTeacherPhotoUrl(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): string {
    let teacherPerson = null;
    if (lesson && lesson.teacher && lesson.teacher.person) {
      teacherPerson = lesson.teacher.person;
    }
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(teacherPerson);
  }

  public getLessonTeacher(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): string {
    if (!lesson.teacher || !lesson.teacher.person ) {
      return PersonNameExtractor.getPersonName(null);
    }
    return PersonNameExtractor.getPersonName(lesson.teacher.person);
  }

  public getLessonPlannedDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): Date {
    return lesson.lessonMetric.plannedStartDate;
  }
  public getLessonDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): Date {
    if (this._lessonType === LessonType.Upcomming ) {
      return lesson.lessonMetric.plannedStartDate;
    }
    return lesson.lessonMetric.plannedStartDate;
  }

  getLessonStatus(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return LessonTypeColors.getStudentLessonStatus(lesson);
  }

  private loadLessons(pageable: Pageable): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    let result: Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> = of (Page.empty());

    if (this._componentRole === 'student') {
      if (this._lessonType === LessonType.Upcomming) {
        result = this.studentRest.findUpcomingLessons(this.studentId, this._lang, pageable);
      } else {
        result =  this.studentRest.findPastLessons(this.studentId, this._lang, pageable);
      }
    } else if (this._componentRole === 'manager') {
      if (this._lessonType === LessonType.Upcomming) {
        result = this.managerRest.findStudentUpcomingLessons(this._schoolId, this.studentId, pageable);
      } else {
        result = this.managerRest.findStudentPastLessons(this._schoolId, this.studentId, pageable);
      }
    }

    return result.pipe( tap( resultPage =>
       resultPage.content
        .filter ( lesson => lesson.teacher && lesson.teacher.person && ! lesson.teacher.person.personalProfile )
        .forEach( lesson => {
          const p = lesson.teacher.person;
          const profile = new ApiPersonalProfile();
          profile.name = p.name;
          profile.surname = p.surname;
          profile.email = p.emailAddress;
        })
      ));
  }

  public mayBeViewed(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): boolean {
    return true;
  }

  public mayBeCancelled(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (this._componentRole === 'manager') {
      return false;
    }

    if (this._lessonType === undefined || this._lessonType !== LessonType.Upcomming) {
      return false;
    }
    const lessonStatus = ApiLessonStatus[lesson.lessonStatus];
    const timeDiff = Dates.diff( new Date(), lesson.lessonMetric.plannedStartDate);
    const moreThanMinimumCancelationPeriod = (timeDiff > TimeUnits.Hours(12).toMilis());
    return (lessonStatus === ApiLessonStatus.Booked || lessonStatus === ApiLessonStatus.Due)
      && moreThanMinimumCancelationPeriod;

  }

  public lessonBadgeClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (this._lessonType === undefined) {
      return '';
    }
    return LessonTypeColors.getStudentLessonBorderColorClass(lesson);
  }


  public cancelLesson() {
    if (this._clLessonToCancel.lessonStatus === 'Due') {
      this.studentRest.squanderLesson(this.studentId, this._clLessonToCancel.id, this._clReason)
      .subscribe(lesson => {
        this.appEvents.lessonsUpdated.next(this._clLessonToCancel);
        this.appEvents.creditsUpdate.next();
        this._clLessonToCancel = null;
        this.reloadCurrent()
      });
    } else {
      this.studentRest.cancelLesson(this.studentId, this._clLessonToCancel.id, this._clReason)
      .subscribe(lesson => {
        this.appEvents.lessonsUpdated.next(this._clLessonToCancel);
        this.appEvents.creditsUpdate.next();
        this._clLessonToCancel = null;
        this.reloadCurrent();
      });
    }
    this.cancelLessonModal.hide();

  }

  public showLessonCancelation(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    this._clLessonToCancel = lesson;
    this._clReason = null;
    this.cancelLessonModal.show();

  }

  ngOnInit() {
    // reload the lessons if new schedule appears
    if (this.appEvents ) {
      this.scheduleReservationSubscription = this.appEvents.scheduleReservedSubject.subscribe( schedule => {
        if (this._lessonType === LessonType.Upcomming) {
          this.reloadCurrent();
        }
      });
    }
  }
  ngOnDestroy(): void {
    if (this.scheduleReservationSubscription) {
      this.scheduleReservationSubscription.unsubscribe();
    }
  }

  getTotalResults() {
    if (!this.queryResults) return 0;
    return this.queryResults.totalElements;
  }

  getPageNumber() {
    if (!this.queryResults) return 0;
    return this.queryResults.number + 1;
  }

  getPagesNumber() {
    if (!this.queryResults) return 0;
    return this.queryResults.totalPages;
  }

  getLessonsToDisplay() {
    return this.queryResults.content;
  }

  hasNext() {
    if (!this.queryResults) return false;
    return !this.queryResults.last;
  }

  hasPrev() {
    if (!this.queryResults) return false;
    return !this.queryResults.first;
  }

  hasPagination() {
    return this.hasPrev() || this.hasNext();
  }

  private reloadCurrent() {
    this.loadAndProcessPage(this.pageable).subscribe();
  }
}
