<!-- <h2 class="text-center" i18n="@@student-teachers-list-page.title">Tutors</h2>
<div class="row text-center text-muted">
    <div class="col-12 col-md-4">
        <h3 i18n="@@student-teachers-list-page.friendly">✓ Friendly</h3>
    </div>
    <div class="col-12 col-md-4">
        <h3 i18n="@@student-teachers-list-page.professional">✓ Professional</h3>
    </div>
    <div class="col-12 col-md-4">
        <h3 i18n="@@student-teachers-list-page.experienced">✓ Experienced</h3>
    </div>
</div> -->
<!-- <p i18n="@@student-teachers-list-page.care-by-teachers">Our tutors will give you their undivided attention at all times. They will ask you questions, help you give answers and form complete sentences. They'll patiently correct your mistakes and constantly motivate you to achieve your goal of speaking fluently.
</p> -->
<!-- <div class="alert alert-primary" i18n="Try different teachers|Encourage student to try different teachers@@student-teachers-list-page.try-different-teachers">
    Remember to have lessons with many different tutors to become familiar with different accents. Every tutor follows the same book so that your course continues seamlessly from one lesson to another.
</div> -->
<app-student-teachers-grid [langCode]="langCode" [studentId]="studentId"></app-student-teachers-grid>