import { Component, OnInit, ViewChild } from '@angular/core';
import { StudentPersonalProfileFormComponent, StudentPersonalProfileFormComponentEvent
} from 'src/app/components/student/student-personal-profile-form/student-personal-profile-form.component';
import { StudentTechnicalProfileFormComponent, StudentTechnicalProfileFormComponentEvent
} from 'src/app/components/student/student-technical-profile-form/student-technical-profile-form.component';
import { ActivatedRoute } from '@angular/router';
import { ComponentEvent } from 'src/app/model/rest/base';

@Component({
  selector: 'app-manager-student-profile-page',
  templateUrl: './manager-student-profile-page.component.html',
  styleUrls: ['./manager-student-profile-page.component.css']
})
export class ManagerStudentProfilePageComponent implements OnInit {


  @ViewChild('studentPersonalProfileFormComponent', {static: true})
  private studentProfileForm: StudentPersonalProfileFormComponent;
  @ViewChild('studentTechnicalProfileFormComponent', {static: true})
  private studentTechnicalProfileForm: StudentTechnicalProfileFormComponent;
  personalProfileUnderEdit: boolean;
  technicalProfileUnderEdit: boolean;
  personalProfileReady = false;
  technicalProfileReady = false;
  schoolId: string;
  teacherId: string;
  studentId: string;

  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.parent.paramMap.subscribe( params => {
      this.schoolId = params.get('schoolId');
      this.studentId = params.get('studentId');
    });
  }
  personalProfileEvent( event: StudentPersonalProfileFormComponentEvent) {
    if (event.eventType === StudentPersonalProfileFormComponentEvent.ET_FORM_EDITABLE) {
      this.personalProfileUnderEdit = true;
    } else if (event.eventType === StudentPersonalProfileFormComponentEvent.ET_FORM_READ_ONLY) {
      this.personalProfileUnderEdit = false;
    } else if (event.eventType === ComponentEvent.ET_INITIALIZED) {
      this.personalProfileReady = true;
    }
  }

  technicalProfileEvent( event: StudentTechnicalProfileFormComponentEvent) {
    if (event.eventType === StudentTechnicalProfileFormComponentEvent.ET_FORM_EDITABLE) {
      this.technicalProfileUnderEdit = true;
    } else if (event.eventType === StudentTechnicalProfileFormComponentEvent.ET_FORM_READONLY) {
      this.technicalProfileUnderEdit = false;
    }  else if (event.eventType === ComponentEvent.ET_INITIALIZED) {
      this.technicalProfileReady = true;
    }
  }

  editPersonalProfile() {
    this.studentProfileForm.editable = true;
  }

  editTechnicalProfile() {
    this.studentTechnicalProfileForm.editable = true;
  }

  ngOnInit() {
  }


}
