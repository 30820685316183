<div class="container tutorial">
    <div class="w-100 text-center mt-5 mb-4">
        <div class="progress-size-wrapper">
            <app-discrete-progress [step]="getStepNumber()" [steps]="getStepsNumber()"></app-discrete-progress>
        </div>
    </div>
    <h1 class="tutorial-intro" *ngIf="getStepNumber() == 1" i18n="@@tutorial.prova-0">
        Great, you've booked your first lesson!
        You'll find it at the <strong>top of your Dashboard:</strong>
    </h1>
    <h1 class="tutorial-intro" *ngIf="getStepNumber() == 2" i18n="@@tutorial.prova-1">
        At the time of the lesson, log in and
        <strong>follow the instructions</strong> in
        the 'Next lesson' box.
    </h1>
    <h1 class="tutorial-intro" *ngIf="getStepNumber() == 3" i18n="@@tutorial.prova-2">
        The video chat room will open in a new tab in your browser.
        <strong>Allow your browser to access</strong> your camera and
        microphone!
    </h1>
    <h1 class="tutorial-intro" *ngIf="getStepNumber() == 4" i18n="@@tutorial.prova-3">
            If the lesson is given via Skype, you will see
            the teacher's Skype name, and a
            <strong>button that will open Skype on your device.</strong>
        </h1>
<div class="row align-items-center my-4">
    <div class="popover-arrow text-start">
        <a *ngIf="getStepNumber() > 1" class="" (click)="onPrev()"><i class="fas fa-chevron-left"></i></a>
    </div>
    <div class="arrows-content">
            <div *ngIf="isShowBrowserWindow()" class="browser">
                <div class="address"><i class="fas fa-lock"></i> https://video.callanonline.com/rooms/...</div>
                <div class="dialog">
                    <i class="close float-end fas fa-times"></i>
                    <div class="dialog-content">
                        <p i18n="@@tutorial.prova-2.prompt-wants">video.callanonline.com wants to</p>
                        <ul class="list-unstyled">
                            <li><i class="fas fa-microphone priv-icon"></i> &nbsp; <span i18n="@@tutorial.prova-2.prompt-micro">Use your microphone</span></li>
                            <li><i class="fas fa-camera priv-icon"></i> &nbsp; <span i18n="@@tutorial.prova-2.prompt-camara">Use your camera</span></li>
                        </ul>
                        <div class="buttons text-end">
                            <button class="btn btn-outline-dark" i18n="@@tutorial.prova-2.button-block">Block</button>
                            <button id="tutorial-allow-audio-video" class="btn btn-outline-dark tutorial-highlight-animation" i18n="@@tutorial.prova-2.button-allow">Allow</button>

                        </div>
                    </div>
                </div>

            </div>
            <div *ngIf="isShowCommingLesson()"  class="comming-lesson-screen" >
                    <div class="bg-primary">
                        <nav class="navbar navbar-dark">
                            <a class="navbar-brand" href="javascript:void(0);">
                                <img src="/assets/img/Callan_Logo_sm.png"  class="d-inline-block align-top me-3" alt="">
                                <span i18n="@@common.langName">{langName, select, English {English} Spanish {Spanish} Business English {Business English} English For Children {English For Children}}</span>
                            </a>
                            <button class="navbar-toggler" type="button" >
                                <span class="navbar-toggler-icon"></span>
                            </button>
                        </nav>
                    </div>
                    <div class="backdrop"></div>

                    <div class="mt-2">
                        <ol class="breadcrumb mb-2">
                            <li  class="breadcrumb-item">
                                <a >Dashboard</a>
                            </li>
                        </ol>
                        <app-student-comming-lesson
                            (stateEvent)="onCommingLessonEvent($event)"
                            [demoMode]="getDemoMode()"
                            [studentId]="studentId"></app-student-comming-lesson>
                    </div>
                </div>
    </div>
    <div class="popover-arrow text-end">
        <a *ngIf="getStepNumber() <= getStepsNumber()" class="" (click)="onNext()"><i class="fas fa-chevron-right"></i></a>
    </div>
</div>
<ul class="instructions mt-2" *ngIf="getStepNumber() === 1">
    <li data-tutorial-highlight="#comming-lesson-info" i18n="@@tutorial.prova-0.hover-info">Lesson info</li>
    <li data-tutorial-highlight="#comming-lesson-dialog" i18n="@@tutorial.prova-0.hover-countdown">Countdown to lesson</li>
    <li data-tutorial-highlight="#comming-lesson-actions" i18n="@@tutorial.prova-0.hover-help">Click for help</li>
</ul>
<ul class="instructions mt-2" *ngIf="getStepNumber() === 2">
    <li data-tutorial-highlight="#comming-lesson-dialog" i18n="@@tutorial.prova-1.hover-teacher">Teacher is ready and waiting</li>
    <li data-tutorial-highlight="#comming-lesson-actions-classroom" i18n="@@tutorial.prova-1.hover-enter">Click 'Enter' to open video chat</li>
</ul>
<ul class="instructions mt-2" *ngIf="getStepNumber() === 3">
    <li data-tutorial-highlight="#tutorial-allow-audio-video" i18n="@@tutorial.prova-2.hover-allowVideo">It's necessary for the classroom to work!</li>
</ul>
<ul class="instructions mt-2" *ngIf="getStepNumber() === 4">
        <li data-tutorial-highlight="#comming-lesson-dialog" i18n="@@tutorial.prova-3.hover-skypeName">Check your teacher's Skype name</li>
        <li data-tutorial-highlight="#comming-lesson-actions-skype" i18n="@@tutorial.prova-3.hover-skypeCall">Or click here to open Skype and call the teacher automatically</li>
    </ul>
    <div class="clearfix"></div>


</div>

