<div class="px-1 mb-2">
  <div class="winter-banner pt-4 pt-md-2 pt-lg-0">
    <div class="winter-banner-text text-center">
      <img src="/assets/img/winter-text.png">
    </div>
    <div class="winter-banner-code text-end pe-5">
      <img src="/assets/img/winter-code.png">
    </div>
  </div>
</div>
