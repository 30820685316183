import {Component, OnInit} from '@angular/core';
import {AuthServiceProvider} from "../../services/col2/auth.service";

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit {

  constructor(private authService: AuthServiceProvider) { }

  ngOnInit() {
  }

  public logout(): void {
    this.authService.get().subscribe(api => api.logout());
  }


}
