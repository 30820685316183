<div class="card">
    <div class="card-body row align-items-center justify-content-between">
        <div class="col-12 col-md-6 col-lg-5 mb-3 mb-lg-0 border-md-right border-secondary">
            <p class="text-muted" i18n="@@common.currency">Choose currency:</p>
            <div class="d-inline-block text-md-center">
                <button class="btn btn-lg rounded-circle text-white ms-2 mb-md-2"
                    [class]="cur === _currency? 'btn-primary pe-none' : 'btn-outline-primary'"
                    *ngFor="let cur of currencies" (click)="currency = cur">
                    {{ getCurrencyIcon(cur) }}
                </button>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0 d-flex justify-content-between">
            <div class="d-inline-flex flex-column">
                <span i18n="@@e-commerce-basket.items-number"
                    class="text-muted">Number of items:
                    <span class="text-white">{{getItems()}}</span>
                </span>
                <span i18n="@@e-commerce-basket.total-price"
                    class="text-muted">Total price:
                    <span class="text-white">{{getTotal()}}</span>
                </span>
            </div>
        </div>
        <div class="col-12 col-lg-4 mb-3 mb-lg-0">
            <button
                class="btn btn-primary d-block w-100 rounded-pill"
                i18n="@@e-commerce-basket.checkout.link"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasCart">
                Click here to checkout
            </button>
        </div>
    </div>
</div>
