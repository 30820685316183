<div class="container pb-5">
  <div class="row text-center mb-5">
    <div class="col-10 col-md-7 mx-auto">
      <p class="text-muted text-uppercase" i18n="error code|Error code and name@@forbidden.error-code">403 - PAGE FORBIDDEN</p>
      <h2 i18n="error details|Error description@@forbidden.error-details">
        We're sorry, you don't have access to the page you requested.
        Please go back to the homepage.
      </h2>
      <div class="d-flex flex-column align-items-center text-center mt-5">
        <a routerLink="/" class="btn btn-primary rounded-pill btn-bubble px-5 py-2">
          <span class="text-uppercase" i18n="@@not-found.take-home">Home</span> 
        </a>
        <a class="btn btn-link f-12 text-decoration-none" (click)="logout()" i18n="logout@@forbidden.logout">Logout</a>
      </div>
    </div>
  </div>
</div>
