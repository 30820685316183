import { ApiPerson, Gender, ApiPersonalProfile, ApiPersonalProfileBaseWithPhoto } from '../model/rest/rest-model';
import { environment } from 'src/environments/environment';

export class PersonNameExtractor {
    public static getPersonName(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
        if (!person) {return ''; }
        const nameComponents: string[] = [];
        if (person.personalProfile) {
            if (person.personalProfile.name) {
                nameComponents.push(person.personalProfile.name);
            }
            if (person.personalProfile.surname) {
                nameComponents.push(person.personalProfile.surname);
            }
        } else {
            if (person.name) {
                nameComponents.push(person.name);
            }
            if (person.surname) {
                nameComponents.push(person.surname);
            }
        }
        return nameComponents.join(' ');
    }
}

export class ProfilePhotoUrlExtractor {

    public static getProfilePhoto(personalProfile: ApiPersonalProfileBaseWithPhoto) {
        if (!personalProfile) {
            return '/assets/img/user-ml.png';
        }
        if (!personalProfile.profilePhoto) {
            const gender: Gender = <Gender>Gender[personalProfile.gender];
            if (gender == null || gender === undefined) {
                return '/assets/img/user-ml.png';
            }
            if (gender === Gender.Male) {
                return '/assets/img/user-ml.png';
            } else {
                return '/assets/img/user-fm.png';
            }
        }

        return environment.fileEndpoint + '/img/'
        + personalProfile.profilePhoto.id
        + '?fileAccessToken='
        + personalProfile.profilePhoto.accessToken ;
    }

    public static getPersonProfilePhoto(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
        if (!person || !person.personalProfile) {
            return this.getProfilePhoto(null);
        }
        return this.getProfilePhoto(person.personalProfile);
    }
}
