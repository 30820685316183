import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ManagerRestService } from 'src/app/services/rest/manager-rest.service';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, map, switchMap, filter } from 'rxjs/operators';
import { ApiLearningUnitStudent, ApiTeacherProfile, SchoolPeopleQueryFilter, ApiPerson, ApiPersonalProfile } from 'src/app/model/rest/rest-model';
import { Pageable, Page } from 'src/app/model/rest/base';
import { ProfilePhotoUrlExtractor, PersonNameExtractor } from 'src/app/utils/profile-photo-url-extractor';

@Component({
  selector: 'app-school-people-list',
  templateUrl: './school-people-list.component.html',
  styleUrls: ['./school-people-list.component.css']
})
export class SchoolPeopleListComponent implements OnInit {
  searchQuery: string;
  hasNext: boolean;

  constructor(private managerRest: ManagerRestService) { }

  _schoolId: number;
  _searchProfile = 'student';
  querySubject = new Subject<string>();

  studentList: ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>[];
  teacherList: ApiTeacherProfile[];
  pageable: Pageable;
  lastStudentPage: Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>;
  lastTeacherPage: Page<ApiTeacherProfile>;

  @Output()
  itemClick = new EventEmitter<any>();

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
  }

  set searchProfile(profile: string) {
    this._searchProfile = profile;
    this.clearData();
  }

  get searchProfile(): string {
    return this._searchProfile;
  }

  itemClicked(item: any) {
    this.itemClick.emit(item);
  }

  getPersonName(person: ApiPerson<ApiPersonalProfile>): string {
    return PersonNameExtractor.getPersonName(person);
  }

  getPersonPhotoUrl(person: ApiPerson<ApiPersonalProfile>): string {
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(person);
  }

  clearData() {
    this.studentList = [];
    this.teacherList = [];
    this.hasNext = false;
    this.pageable = null;
    this.lastStudentPage = this.lastTeacherPage = null;
  }

  preparePageable() {
    this.pageable = Pageable.of(0, 10, null);
  }

  updateSearchQuery(queryValue: string) {
    this.clearData();
    this.preparePageable();
    this.querySubject.next(queryValue);
  }

  doQuery(queryValue: string): Observable<any> {
    const peopleFilter = new SchoolPeopleQueryFilter();
    peopleFilter.query = queryValue;
    if (this._searchProfile === 'student') {
      return this.doQueryForStudents(peopleFilter);
    } else {
      return this.doQueryForTeachers(peopleFilter);
    }
  }

  doQueryForTeachers(peopleFilter: SchoolPeopleQueryFilter): Observable<ApiTeacherProfile[]> {
    return this.managerRest.listTeachers(this._schoolId, peopleFilter, this.pageable).pipe(
      tap( resultPage => this.lastTeacherPage = resultPage ),
      tap( resultPage => this.hasNext = !resultPage.last),
      map( resultPage => resultPage.content ),
      tap( resultTeachers => this.teacherList = this.teacherList.concat(resultTeachers))
    );
  }

  next() {
    if (!this.pageable) {return; }
    this.pageable = this.pageable.next();
    this.doQuery(this.searchQuery);
  }

  doQueryForStudents(peopleFilter: SchoolPeopleQueryFilter): Observable<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>[]> {
    return this.managerRest.listStudents(this._schoolId, peopleFilter, this.pageable).pipe(
      tap( resultPage => this.lastStudentPage = resultPage ),
      tap( resultPage => this.hasNext = !resultPage.last),
      map( resultPage => resultPage.content ),
      tap( resultStudents => this.studentList = this.studentList.concat(resultStudents))
    );
  }

  ngOnInit() {
    this.querySubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter( queryValue => !queryValue || queryValue.length > 2 ),
      tap( queryValue => this.searchQuery = queryValue ),
      switchMap( queryValue => this.doQuery(queryValue))
    ).subscribe();
  }

}
