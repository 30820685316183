<footer class="text-center bg-transparent pt-4 pb-4 text-light">
  <!-- <div class="footer-above pb-4">
      <div class="container">
      <div class="row">
          <div class="footer-col col-lg-4" style="text-align: center;  padding-top: 15px;">
          <a href="{{base}}/en/contact-us" class="text-light" i18n="@@footer.contacts.link">Contacts</a><br>
          <a href="{{base}}/en/contact-us#faqs" class="text-light" i18n="@@footer.faq.link">FAQS</a><br>
          <a href="{{base}}/en/teachatcallan" class="text-light" i18n="@@footer.teach-at-callan.link">Teach at Callan</a><br>
          </div>
          <div class="footer-col col-lg-4" style="padding-top: 10px; text-align: center;">
          <a href="{{base}}/en/termsandconditions" class="text-light" i18n="@@footer.terms.link">Terms &amp; Conditions</a><br>
          <a href="{{base}}/en/gdpr-student" class="text-light" i18n="@@footer.student-privacy.link">Student Privacy Policy</a><br>
          <a href="{{base}}/en/gdpr-tutor" class="text-light" i18n="@@footer.tutor-privacy.link">Tutor Privacy Policy</a><br>
          <a href="http://www.odreurope.com" class="text-light" i18n="@@footer.dispute-res.link">Dispute Resolution</a><br>
          </div>
          <div class="footer-col col-lg-4">
          <p class="footertext" style="padding-top:15px;     text-align: justify;"></p>
          <a href="http://www.callan.co.uk/" class="text-light" i18n="@@footer.callan-org.link">Callan Method Organisation</a><br>
          <a href="http://www.callanmethodgroup.com/" class="text-light" i18n="@@footer.callan-group.link">Callan Method Group</a><br>
          </div>
      </div>
      </div>
  </div> -->
  <div class="footer-below">
      <div class="container">
      <div class="row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 text-primary" i18n="@@footer.copyrights">© Callan Online Ltd 2014-2023 The Callan name and logo are protected trademarks and must not be used without permission.</div>
      </div>
      </div>
  </div>
  </footer>
