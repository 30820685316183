<div class="container text-center">
  <div class="row">
    <div class="col-12">
      <h2 class="text-uppercase mb-4" i18n="@@order-fail.title">Payment cancelled</h2>
      <p class="text-muted mb-5">
        <span i18n="@@order-fail.details">If you experienced any problems or have any questions, please </span>
        <a i18n="@@contact-us" href="mailto:zibi.wisniewski@callan.co.uk">contact us</a>.
      </p>
      <button (click)="routeToUserPage()" class="btn btn-primary btn-bubble rounded-pill text-uppercase py-2 px-5">
          <span i18n="@@order-fail.take-home">Dashboard</span> 
      </button> 
    </div>
  </div>
</div>