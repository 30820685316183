<div class="card mt-2" *ngIf="student">
  <div class="card-body">
      <h5 class="card-title">{{getPersonName(student.person)}}</h5>
  </div>
</div>

<ul class="nav nav-tabs mt-2">
  <li class="nav-item">
    <a class="nav-link" href="#" [ngClass]="{active: currentFragment === 'lessons'}" routerLink="/school/{{_schoolId}}/student/{{_studentId}}/lessons">Lessons</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#" [ngClass]="{active: currentFragment === 'credits'}" routerLink="/school/{{_schoolId}}/student/{{_studentId}}/credits">Credits</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#" [ngClass]="{active: currentFragment === 'profile'}" routerLink="/school/{{_schoolId}}/student/{{_studentId}}/profile">Profile</a>
  </li>
</ul>
<router-outlet></router-outlet>
