import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ComponentEvent, StateAwareComponent } from 'src/app/model/rest/base';
import { ApiPersonalProfile, ApiFile, ApiCountry, ApiPerson, ApiPersonalProfileBaseWithPhoto } from 'src/app/model/rest/rest-model';
import { TeacherRestService } from 'src/app/services/rest/teacher-rest.service';
import { flatMap, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { FilesRestService } from 'src/app/services/rest/files-rest.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ProfilePhotoUrlExtractor } from 'src/app/utils/profile-photo-url-extractor';
import { Utils } from 'src/app/utils/utils';
import { PublicRestService } from 'src/app/services/rest/public-rest.service';
import { PublicRestProxyService } from 'src/app/services/public-rest-proxy.service';
import { LocaleManagerService } from 'src/app/services/locale-manager.service';
import { ManagerRestService } from 'src/app/services/rest/manager-rest.service';

export class TeacherPersonalProfileFormComponentEvent extends ComponentEvent {
  static ET_FORM_EDITABLE = 'underEdit';
  static ET_FORM_READ_ONLY = 'readOnly';

  static fromEditableFlag(editable: boolean) {
    const res = new TeacherPersonalProfileFormComponentEvent();
    if (editable) {
      res.eventType = this.ET_FORM_EDITABLE;
    } else {
      res.eventType = this.ET_FORM_READ_ONLY;
    }
    return res;
  }
}

@Component({
  selector: 'app-teacher-personal-profile-form',
  templateUrl: './teacher-personal-profile-form.component.html',
  styleUrls: ['./teacher-personal-profile-form.component.css']
})
export class TeacherPersonalProfileFormComponent extends StateAwareComponent<TeacherPersonalProfileFormComponentEvent> implements OnInit {

  _teacherId: number;
  genders = [ 'Male', 'Female' ];
  profile: ApiPersonalProfileBaseWithPhoto;
  _editable = true;
  uploadProgress: number = null;
  fileUrlBase = environment.fileEndpoint + '/img/';

  @ViewChild('fileInput', {static: false}) fileInput;
  countries: ApiCountry[];
  _schoolId: number;
  _componentRole: string;

  constructor(private teacherRest: TeacherRestService,
      private fileRest: FilesRestService,
      private managerRest: ManagerRestService,
      publicRest: PublicRestProxyService,
      localeService: LocaleManagerService) {
    super();
    publicRest.loadCountries(localeService.localeId).subscribe(countries => this.keepCountries(countries));
  }

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.loadProfile();
  }

  @Input()
  set componentRole(role: string) {
    this._componentRole = role;
    this.loadProfile();
  }

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.loadProfile();
  }


  keepCountries(countries: ApiCountry[]): void {
    this.countries = countries.concat().sort((l, r) => l.name.localeCompare(r.name));
  }

  @Input()
  set editable(editable: boolean) {
    this._editable = editable;
    this.stateEvent.emit(TeacherPersonalProfileFormComponentEvent.fromEditableFlag(this._editable));
  }

  public openFileChoose() {
    if (!this._editable) { return; }
    this.fileInput.nativeElement.click();
  }
  get editable() {
    return this._editable;
  }

  public getProfilePhoto(profile: ApiPersonalProfile) {
    return `url(${ProfilePhotoUrlExtractor.getProfilePhoto(profile)}`;
  }


  public onProfileImageUpload(files: File[]) {
    this.uploadProgress = 0;
    this.fileRest.saveFile(files[0]).subscribe( event => {
      if (event.type === HttpEventType.UploadProgress ) {
        this.uploadProgress = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        const file = event.body as ApiFile;
        this.profile.profilePhoto = file;
        this.uploadProgress = null;
      }
    }, () => this.uploadProgress = null);
  }

  public onSave() {
    this.editable = false;
    const saveObservable: Observable<ApiPersonalProfileBaseWithPhoto> = (this._componentRole === 'teacher') ?
      this.teacherRest.savePersonalProfile(this._teacherId, this.profile)
      : this.managerRest.saveTeacherPersonalProfile(this._schoolId, this._teacherId, this.profile);

    saveObservable.pipe(
      map(profile => Utils.jsonClone(profile))
    )
    .subscribe(profileAfterUpdate => {
      this.profile = this.fixDate(profileAfterUpdate);
    });
  }

  loadProfile(): any {
    if (!this._componentRole) {return; }
    if (this._componentRole === 'teacher') {
      this.loadProfileAsTeacher();
    } else if (this._componentRole === 'manager') {
      this.loadProfileAsManager();
    }
  }

  loadProfileAsManager() {
    if (this._teacherId == null || this._teacherId === undefined
      || this._schoolId == null || this._schoolId === undefined) {
        return;
      }
      this.managerRest.getTeacher(this._schoolId, this._teacherId).pipe(
        map( teacherProfile => teacherProfile.teacher.person),
        map ( person => {
          if (!person.personalProfile) {
            return this.createProfileFromPerson(person);
          }
          return person.personalProfile;
        }),
        map ( profile => Utils.jsonClone(profile)),
      ).subscribe( profile => {
        this.profile = this.fixDate(profile);
        this.stateEvent.emit(ComponentEvent.initialized());
      });

  }
  createProfileFromPerson(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
    const newProfile = new ApiPersonalProfile();
    newProfile.name = person.name;
    newProfile.surname = person.surname;
    newProfile.email = person.emailAddress;
    return newProfile;
  }

  loadProfileAsTeacher() {
    if (this._teacherId == null || this._teacherId === undefined) {
      return;
    }
    this.teacherRest.getPersonalProfile(this._teacherId)
    .pipe(
      flatMap( profile => (profile) ? of(profile) :
        this.teacherRest.getSelfPerson(this._teacherId)
        .pipe(
          map( person => this.createProfileFromPerson(person)
          )
        )
      ),
      map ( profile => Utils.jsonClone(profile))
    ).subscribe(profile => {
      this.profile = this.fixDate(profile);
      this.stateEvent.emit(ComponentEvent.initialized());
    });
  }

  fixDate(profile: ApiPersonalProfileBaseWithPhoto): ApiPersonalProfileBaseWithPhoto {
   if (!profile || !profile.birthDate) { return profile; }
   profile.birthDate = new Date(profile.birthDate);
   return profile;
  }

  ngOnInit() {
    this.editable = false;
  }

}
