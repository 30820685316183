import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Chapter, Exercise, Section} from "../../../../model/rest/cspa/struct";
import {ItemAvailability} from "../../../../model/rest/cspa/personal";

export class ExerciseSelectEvent {
  constructor(public chapter: Chapter, public section: Section, public exercise: Exercise) {}
}

@Component({
  selector: 'app-chapter-details',
  templateUrl: './chapter-details.component.html',
  styleUrls: ['./chapter-details.component.scss']
})
export class ChapterDetailsComponent implements OnInit {

  @Input()
  chapter: Chapter;
  @Input()
  availabilities: Map<string, ItemAvailability>;
  @Output()
  exerciseSelect = new EventEmitter<ExerciseSelectEvent>();

  constructor() { }

  onExerciseSelected(section: Section, exercise: Exercise) {
    if (!this.checkAvailability(section, exercise)) {
      return;
    }
    this.exerciseSelect.emit(new ExerciseSelectEvent(this.chapter, section, exercise));
  }

  checkAvailability(section: Section, exercise: Exercise) {
    const sectionFullPath = [this.chapter.path, section.path].join('_');
    const exerciseFullPath = [sectionFullPath, exercise.path].join('_');

    const sectionAv = this.availabilities.get(sectionFullPath);
    if (!sectionAv || !sectionAv.assigned || !sectionAv.available) {
      return false;
    }

    const exerciseAv = this.availabilities.get(exerciseFullPath);
    if (!exerciseAv || !exerciseAv.assigned || !exerciseAv.available) {
      return false;
    }
    return true;
  }

  isReady() {
    return this.chapter && this.availabilities;
  }

  selectedChapterSections() {
    return this.chapter.sections;
  }

  getSectionExercises(section: Section) {
    return section.exercises;
  }

  getExerciseAvailability(section: Section, exercise: Exercise) {
    const fullExercisePath = [this.chapter.path, section.path, exercise.path].join('_');
    return this.availabilities.get(fullExercisePath);
  }

  ngOnInit() {
  }

}
