<div class="bg-smoke bg-accent">
    <section class=" py-2">
      <div class="container text-center py-4">
        <h1 class=" text-white" i18n="@@starter.es.title">Start speaking Spanish today</h1>
        <h2 *ngIf="isAvailable()" class="display-6 text-white"><span i18n="@@starter.limited-offer">Limited offer - expires in:</span><br/><span>{{getTime()}}</span></h2>
      </div>
    </section>
    <section class="pb-2">
      <div class="container text-white text-center text-large">
        <ul class="list-inline dot-bullet">
          <li class="list-inline-item" i18n="@@starter.bullet.1">Try out different tutors.</li>
          <li class="list-inline-item" i18n="@@starter.bullet.2">See how much you improve in just three days.</li>
          <li class="list-inline-item" i18n="@@starter.bullet.3">Discover the unique experience of learning with the Callan Method.</li>
        </ul>
        <div class="mt-5" >
          <div class="chart-container"  *ngIf="isAvailable() || isBought()">
            <app-product-box [notAvailable]="getNotAvailable()" class="lessonBox starter"  *ngFor="let product of starter" [product]="product" desc=""></app-product-box>
          </div>
          <div *ngIf="!(isAvailable() || isBought())">
            <h4 i18n="@@starter.you-missed">You've missed it :(</h4>
            <h5>
              <span i18n="@@starter.you-missed.expl">The starter offer has expired. Please have a look at our regular plans</span>&nbsp;
              <a class="text-white" routerLink="/student/{_studentId}/buy-courses/product/sp" i18n="@@starter.you-missed.expl.here.link">HERE</a>
            </h5>
            <h5>
              <span i18n="@@starter.you-missed.questions">Questions? Need help?</span>&nbsp;
              <a class="text-white" href="mailto: support@callanonline.com?Subject=Message%20from%20COL%202.0" i18n="@@starter.you-missed.questions.contact-us.link">Contact us!</a></h5>
          </div>
        <div class="clearfix "></div>
        <p class="mt-1"><small i18n="@@starter.time-limit">*Lesson credits valid for 30 days. Any credits not used within 30 days of purchase become void.</small></p>
        </div>
      </div>
    </section>
    <section class="py-2">
      <div class="container my-4 row justify-content-center">
        <div class="col-12 col-md-6 offset-lg-3 text-white">
        <h5 class="text-white text-uppercase " i18n="@@starter.includes">Includes:</h5>
        <ul>
          <li i18n="@@starter.includes.1.es">3 online Spanish lesson credits</li>
          <li i18n="@@starter.includes.2">eBook for you level</li>
        </ul>
        </div>
      </div>
    </section>
    </div>
    <section >
      <div class="container pt-2 pb-5">
        <p i18n="@@starter.ebooks"><strong>Remember: </strong>To read your eBook, download the Callan App</p>
        <div class="row">
                    <div class="col-10 col-lg-8" style="margin-left:auto; margin-right: auto;">
                    <div class="row">
                    <div class="col-6 col-md-3">
                      <a href="http://app.callanonline.com/download/Callan_App_Windows.msi">
                      <img src="/assets/img/pc.jpg" th:src="@{/img/pc.jpg}" class="w-100 m-1" title="pc app" alt="pc">
                    </a>
                    </div>
                    <div class="col-6 col-md-3">
                     <a href="http://app.callanonline.com/download/Callan_App_Mac.dmg">
                       <img src="/assets/img/mac.jpg" th:src="@{/img/mac.jpg}" title="mac app" alt="mac" class="w-100 m-1">
                     </a>
                    </div>
                    <div class="col-6 col-md-3">
                    <a href="https://play.google.com/store/apps/details?id=pl.arceo.callan.callandigitalbooks" target="_blank">
                      <img src="/assets/img/gplay.jpg" th:src="@{/img/gplay.jpg}" title="google play store" alt="google play store" class="w-100 m-1">
                    </a>
                    </div>
                    <div class="col-6 col-md-3">
                      <a href="https://itunes.apple.com/gb/app/callan-method-app/id1099785360" target="_blank">
                      <img src="/assets/img/appstore.jpg" th:src="@{/img/appstore.jpg}" class="w-100 m-1" title="apple store" alt="apple store"></a>
                    
                    </div>
                    </div>
                    </div>
                  </div>
      </div>
    </section>