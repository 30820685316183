<p *ngIf="lessons">
  Total: {{lessons.length}}
</p>
<table class="table">
  <thead>
    <tr>
      <th>Date</th>
      <th>Status</th>
      <th>Course</th>
      <th>Type</th>
      <th>Teacher</th>
      <th>Students</th>
      <th>Flags</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let l of lessons">
      <td>{{lessonDate(l) | date: 'dd MMM yyyy HH:mm' }}</td>
      <td>{{lessonStatus(l)}}</td>
      <td>{{lessonCourse(l)}}</td>
      <td>{{lessonType(l)}}</td>
      <td>{{teacher(l)}}</td>
      <td>{{students(l)}}</td>
      <td>{{flags(l)}}</td>
    </tr>
  </tbody>
</table>
