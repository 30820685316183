import { Component, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { ApiProduct, ProductsUtils } from 'src/app/model/rest/products';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { StudentShoppingCartService } from 'src/app/services/student-shopping-cart.service';

@Component({
  selector: 'app-english-business-products',
  templateUrl: './english-business-products.component.html',
  styleUrls: ['./english-business-products.component.css']
})
export class EnglishBusinessProductsComponent implements OnInit {
  upfront: ApiProduct[];
  instalment: ApiProduct[];
  _currency: string;

  constructor(
    private studentCache: StudentCacheProxyService, 
    @Inject(LOCALE_ID) private locale: string,
    private shoppingCart: StudentShoppingCartService
    ) { }

  @Input()
  set currency(currency: string) {
    this._currency = currency;
    this.refreshProducts();
  }

  refreshProducts(): any {
    if (!this._currency) {return; }
    if (!this.locale) {
      this.locale = 'en';
    }
    this.locale = this.locale.toUpperCase();
    this.studentCache.listProducts(this.locale, this._currency).subscribe(products => this.filterProducts(products));
  }
  ngOnInit() {
  }
  filterProducts(products: ApiProduct[]): void {
    const filtered = ProductsUtils.filterByProductLabel(products, ProductsUtils.EN_BS_PRODUCT_LABEL);
    this.upfront = ProductsUtils.filterByVariantLabel(filtered, 'category:lessons.en.bs.upfront');
    this.instalment = ProductsUtils.filterByVariantLabel(filtered, 'category:lessons.en.bs.instalment');
  }

  addItem(product: ApiProduct) {
    this.shoppingCart.addItem(product)
  }

}
