import { Component, OnInit, Input } from '@angular/core';
import { ComponentEvent, Languages, StateAwareComponent } from 'src/app/model/rest/base';
import { TeacherRestService } from 'src/app/services/rest/teacher-rest.service';
import { ApiTeacherProfile, ApiTeacherProductCompetences, ApiCourseProduct, ApiCompetence} from 'src/app/model/rest/rest-model';
import { forkJoin, Observable, of } from 'rxjs';
import { exhaustMap, flatMap, map } from 'rxjs/operators';
import { Utils } from 'src/app/utils/utils';
import { PublicRestService } from 'src/app/services/rest/public-rest.service';
import { ManagerRestService } from 'src/app/services/rest/manager-rest.service';

export class TeacherProfileFormComponentEvent extends ComponentEvent {
  static ET_FORM_EDITABLE = 'underEdit';
  static ET_FORM_READ_ONLY = 'readOnly';

  static fromEditableFlag(editable: boolean) {
    const res = new TeacherProfileFormComponentEvent();
    if (editable) {
      res.eventType = this.ET_FORM_EDITABLE;
    } else {
      res.eventType = this.ET_FORM_READ_ONLY;
    }
    return res;
  }
}

export class FormBack {
  skype: string;
  language: string;
  timezoneCode: string;
  productCompetences = {};
}

export class ProductCompetencesBack {
  competences = {};
  description: string;
  accent: string;
  videoUrl: string;
}

@Component({
  selector: 'app-teacher-profile-form',
  templateUrl: './teacher-profile-form.component.html',
  styleUrls: ['./teacher-profile-form.component.css']
})
export class TeacherProfileFormComponent extends StateAwareComponent<TeacherProfileFormComponentEvent> implements OnInit {


  _editable = false;
  _teacherId: number;
  languages = Languages.values;
  formBack: FormBack = null;
  saving = false;
  _schoolId: number;
  _componentRole: string;

  constructor(private teacherRest: TeacherRestService,
    private managerRest: ManagerRestService) {
    super();
   }

  @Input()
  set editable(editable: boolean) {
    this._editable = editable;
  }

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.loadTeacherForm();
  }

  @Input()
  set componentRole(role: string) {
    this._componentRole = role;
    this.loadTeacherForm();
  }

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.loadTeacherForm();
  }

  get editable(): boolean {
    return this._editable;
  }

  get compEditable(): boolean {
    return this.editable && this._componentRole === 'manager';
  }

  showLang(lang: string) {
    if (this._componentRole === 'manager') {return true; }
    if (this.formBack.productCompetences[lang]) { return true; }
    return false;
  }

  private saveCompetence(competence: ApiTeacherProductCompetences) {
    if (this._componentRole === 'teacher') {
      return this.teacherRest.saveCompetences(this._teacherId, competence);
    } else if (this._componentRole === 'manager') {
      return this.managerRest.saveTeacherCompetences(this._schoolId, this._teacherId, competence);
    }
  }

  private saveProfile(profile: ApiTeacherProfile) {
    if (this._componentRole === 'teacher') {
      return this.teacherRest.saveProfile(this._teacherId, profile);
    } else if (this._componentRole === 'manager') {
      return this.managerRest.saveTeacherProfile(this._schoolId, this._teacherId, profile);
    }
  }

  public onSave() {
    this.saving = true;
    const teacherProfile  = this.mapTeacherProfile(this.formBack);
    const competences = this.mapLanguages(this.formBack.productCompetences);
    const competencesSaveObservables: Observable<ApiTeacherProductCompetences>[]
      = competences.map( competence => this.saveCompetence(competence));
    if (competencesSaveObservables.length === 0) {
      competencesSaveObservables.push(of(null));
    }
    this.saveProfile(teacherProfile)
    .pipe(
      exhaustMap(() => forkJoin(competencesSaveObservables)),
      flatMap(() => this.getProfile()),
      map ( profile => Utils.jsonClone(profile))
    )
    .subscribe( (profile: ApiTeacherProfile) => {
      this.formBack = this.mapProfileToFormBackends(profile);
      this.editable = false;
    }, null, () => this.saving = false);
  }

  public mapTeacherProfile(formBack: FormBack):
  ApiTeacherProfile {
    const res = new ApiTeacherProfile();
    res.language = formBack.language;
    res.skype = formBack.skype;
    // res.timezone = formBack.timezoneCode;
    return res;
  }

  public mapLanguages(productCompetence: any) {
    const res: ApiTeacherProductCompetences[] = [];

    for (const langCode in productCompetence) {
      if (productCompetence.hasOwnProperty(langCode)) {

        const langForm: ProductCompetencesBack = productCompetence[langCode];
        const resultProductCompetence = new ApiTeacherProductCompetences();
        resultProductCompetence.accent = langForm.accent;
        resultProductCompetence.productCompetenceDescription = langForm.description;
        resultProductCompetence.videoUrl = langForm.videoUrl;
        resultProductCompetence.product = new ApiCourseProduct();
        resultProductCompetence.product.code = langCode;
        resultProductCompetence.competences = [];

        for (const langCompetenceCode in langForm.competences) {
          if (langForm.competences.hasOwnProperty(langCompetenceCode)) {
            const compatenceSate: boolean = langForm.competences[langCompetenceCode];
            if (compatenceSate) {
              const competence = new ApiCompetence();
              competence.code = langCompetenceCode;
              resultProductCompetence.competences.push(competence);
            }
          }
        }

        res.push(resultProductCompetence);
      }
    }
    return res;
  }

  public activateLang(lang: string) {
    this.formBack.productCompetences[lang] = new ProductCompetencesBack();
  }

  loadTeacherForm(): any {
    if (!this._componentRole) {return; }
    if (this._componentRole === 'teacher') {
      if (!this._teacherId) { return; }
    } else if (this._componentRole === 'manager') {
      if (!this._teacherId || !this._schoolId) {return; }
    }

    this.getProfile().subscribe(
      profile => {
        this.formBack = this.mapProfileToFormBackends(profile);
        this.stateEvent.emit(ComponentEvent.initialized());
      }
    );
  }

  private getProfile() {
    if (this._componentRole === 'manager') {
      return this.managerRest.getTeacher(this._schoolId, this._teacherId);
    } else if (this._componentRole === 'teacher') {
      return this.teacherRest.getProfile(this._teacherId);
    }
  }

  mapProfileToFormBackends(profile: ApiTeacherProfile): FormBack {
    const formBack = new FormBack();
    if (!profile) {
      formBack.language = Languages.English.code;
      return formBack;
    }
    formBack.language = profile.language;
    formBack.skype = profile.skype;
    // formBack.timezoneCode = profile.timezone;
    if (profile.competences) {
      profile.competences.forEach( productCompetence => {
        const newProductCompetences = new ProductCompetencesBack();
        formBack.productCompetences[productCompetence.product.code] = newProductCompetences;
        productCompetence.competences
          .map( competence => competence.code)
          .forEach( competenceCode => newProductCompetences.competences[competenceCode] = true);
        newProductCompetences.accent = productCompetence.accent;
        newProductCompetences.description = productCompetence.productCompetenceDescription;
        newProductCompetences.videoUrl = productCompetence.videoUrl;
        });
    }

    return formBack;
  }

  ngOnInit() {
  }

}
