<div *ngIf="personScoresStruct && chapter" class="details-block mt-5">
  
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <p i18n="@@cspa.qa">Question & Answer <br>
        <small >Listen to questions you know from the lessons, record your answers and compare your pronunciation with the model answers. </small>
      </p>
    </div>
    <div class="col">
      <app-exercise-block [exercise]="exercise" [score]="personScoresStruct.exercises[exercise.id]"
      [section]="chapter.sections[0]"
      *ngFor="let exercise of chapter.sections[0].exercises"></app-exercise-block>
    </div>
  </div>
  <hr>

  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <p i18n="@@cspa.dict">Dictation <br>
        <small >Practice your listening skills and spelling.</small>
      </p>
    </div>
    <div class="col">
        <app-exercise-block [exercise]="exercise" [score]="personScoresStruct.exercises[exercise.id]"
      [section]="chapter.sections[1]"
      *ngFor="let exercise of chapter.sections[1].exercises"></app-exercise-block>
    </div>   
  </div>
  <hr>

  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <p i18n="@@cspa.extra">Extra <br>
        <small>Extra vocabulary practice with multiple choice, gap-fill and other exercises.</small>
      </p>
    </div>
    <div class="col">
        <app-exercise-block [exercise]="exercise" [score]="personScoresStruct.exercises[exercise.id]"
      [section]="chapter.sections[2]"
      *ngFor="let exercise of chapter.sections[2].exercises"></app-exercise-block>
    </div> 
  </div>
</div>
