<div class="card mt-2" *ngIf="teacherProfile">
    <div class="card-body">
        <h5 class="card-title">{{getPersonName(teacherProfile.teacher.person)}}</h5>
    </div>
  </div>
  
  <ul class="nav nav-tabs mt-2">
    <li class="nav-item">
      <a class="nav-link" href="#" [ngClass]="{active: currentFragment === 'lessons'}" routerLink="/school/{{_schoolId}}/teacher/{{_teacherId}}/lessons">Lessons</a>
      
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#" [ngClass]="{active: currentFragment === 'profile'}" routerLink="/school/{{_schoolId}}/teacher/{{_teacherId}}/profile">Profile</a>
    </li>
  </ul>
  <router-outlet></router-outlet>