import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {Dates} from 'src/app/utils/calendar-utils';
import {map, tap} from 'rxjs/operators';
import {
  ProductAvailabilityRequest,
  ScheduleReference,
  SimpleProductAvailability,
  SimpleScheduleEvents
} from "../../model/rest/booking-rest-v2";

export interface TeacherBookRestService {
  listAvailabilities(teacherId: number, focusDate: Date): Observable<SimpleProductAvailability[]>
  listLessons(teacherId: number, focusDate: Date): Observable<SimpleScheduleEvents[]>
  createAvailability(teacherId: number, request: ProductAvailabilityRequest): Observable<ScheduleReference>
  deleteAvailability(teacherId: number, availabilityId: number, eventId: number, applyRecurring: boolean): Observable<void>
}

@Injectable({
  providedIn: 'root'
})
export class TeacherBookRestServiceImpl implements TeacherBookRestService {

  constructor(private http: HttpClient) {}

  private buildUrl(teacherId: number) {
    return environment.bookingEndpoint + '/v2/teacher/' + teacherId;
  }

  public listAvailabilities(teacherId: number, focusDate: Date): Observable<SimpleProductAvailability[]> {
    let weekStart = Dates.getWeekStartDate(focusDate)
    const url = this.buildUrl(teacherId) + '/availabilities/events';
    return this.http.get<SimpleProductAvailability[]>(url, {
        params: {
          dateFrom: weekStart.toISOString(),
          dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        }
      }).pipe(
        tap(schedules => {
          schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
        }),
        map(worktimes => worktimes.map( wt => Object.assign(new SimpleProductAvailability(), wt)))
      );
  }

  public listLessons(teacherId: number, focusDate: Date): Observable<SimpleScheduleEvents[]> {
    let weekStart = Dates.getWeekStartDate(focusDate)
    const url = this.buildUrl(teacherId) + '/lessons';

      return this.http.get<SimpleScheduleEvents[]>(url, {
        params: {
          dateFrom: weekStart.toISOString(),
          dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        }
      }).pipe(
        tap( schedules => {
          schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
        })
      );
  }

  public createAvailability(teacherId: number, request: ProductAvailabilityRequest): Observable<ScheduleReference> {
    const url = this.buildUrl(teacherId) + '/availabilities';
    return this.http.post<ScheduleReference>(url, request)
  }

  public deleteAvailability(teacherId: number, availabilityId: number, eventId: number, applyRecurring: boolean): Observable<void> {
    const url = this.buildUrl(teacherId) + `/availabilities/${availabilityId}/events/${eventId}`;

      return this.http.delete<void>(url, {
        params: {
          'applyFuture': applyRecurring.toString()
        }
      });
  }
}
