import {Injectable} from '@angular/core';
import {StudentRestService} from './rest/student-rest.service';
import {ApiExercisesStructure, ApiPersonScores, ExerciseSet} from '../model/rest/cspa-rest-model';
import {Observable, of} from 'rxjs';
import {CspaRestService} from './rest/cspa-rest.service';
import {LangProductMapper} from '../utils/lang-mappers';
import {map, publish, refCount, switchMap, tap} from 'rxjs/operators';
import {
  ApiCourse,
  ApiLessonBundle,
  ApiLessonProgress,
  ApiPerson,
  ApiPersonalProfile,
  ApiPersonTechnicalProfile,
  ApiProductContext,
  ApiProvaContext,
  ApiTeacherProfile
} from '../model/rest/rest-model';
import {TimeUnits} from '../utils/calendar-utils';
import {AppEventsService} from './ctx/app-events.service';
import {ApiProduct} from '../model/rest/products';
import {SimpleLessonScheduleRequest, SimpleScheduleEvents} from "../model/rest/booking-rest-v2";

export class CacheEntry<T> {
  value: T;
  loadDate: number;
  validDate: number;

  constructor(value: T, validDate: number) {
    this.value = value;
    this.loadDate = new Date().getTime();
    this.validDate = this.loadDate + validDate;
  }

  isValid() {
    return new Date().getTime() < this.validDate;
  }
}

@Injectable({
  providedIn: 'root'
})
export class StudentCacheProxyService {

  private exerciseStructs: {[ langCode: string]: CacheEntry<ApiExercisesStructure>} = {};
  private lessonProgress: CacheEntry<ApiLessonProgress[]>;
  private productProgressEstimation: {[ langCode: string]: CacheEntry<ApiLessonProgress[]> } = {};
  private teachers: {[ teacherId: number]: CacheEntry<ApiTeacherProfile> } = {};
  private exerciseSets: CacheEntry<ExerciseSet[]>;
  private studentTechnicalProfile: CacheEntry<ApiPersonTechnicalProfile>;
  private studentCourses: {[ productCode: string]: CacheEntry<ApiCourse[]>} = {};
  private scores: CacheEntry<ApiPersonScores>;
  private selfPerson: CacheEntry<ApiPerson<ApiPersonalProfile>>;
  private allTeachers: CacheEntry<ApiTeacherProfile[]>;
  private productsList: { [cacheKey: string]: CacheEntry<ApiProduct[]>} = {};
  private currentTechnicalProfileLoading: Observable<ApiPersonTechnicalProfile> = null;
  private loadSelfPersonCurrentLoading: Observable<ApiPerson<ApiPersonalProfile>> = null;
  private loadProgressLoading: Observable<ApiLessonProgress[]> = null;
  private provaContexts: CacheEntry<ApiProvaContext[]>;


  private skipCspa = false;

  constructor(
    private studentRest: StudentRestService,
    private cspaRest: CspaRestService,
    private appEvent: AppEventsService
  ) {

    appEvent.technicalProfileUpdated.subscribe(
      profile => {
        this.studentTechnicalProfile = new CacheEntry(profile, TimeUnits.Minues(15).toMilis());
        this.selfPerson = null;
      }
    );

    appEvent.creditsUpdate.subscribe(() => {
      this.provaContexts = null;
    });
  }

  listProducts(lang: string, currency: string): Observable<ApiProduct[]> {
    const cacheKey = lang + '/' + currency;

    return this.loadIfNecesary( this.productsList[cacheKey], () =>
      this.studentRest.listProducts(lang, currency)
    , TimeUnits.Minues(15).toMilis()).pipe(
      tap ( entry => this.productsList[cacheKey] = entry),
      map ( entry => entry.value )
    );
  }

  reserveSchedule(studentId: number, lessonSchedule: SimpleLessonScheduleRequest): Observable<SimpleScheduleEvents> {
    return this.studentRest.reserveColSchedule(studentId, lessonSchedule).pipe(
      tap (schedule => {
        this.appEvent.scheduleReservedSubject.next(schedule);
        this.appEvent.creditsUpdate.next();
        })
    );
  }


  savePersonalProfile(studentId: number, profile: ApiPersonalProfile): Observable<ApiPersonalProfile> {
    return this.studentRest.savePersonalProfile(studentId, profile).pipe(
      map( newProfile => this.fixPersonalProfileDate(newProfile)),
      tap( newProfile => this.appEvent.personalProfileUpdated.next(newProfile)),
      tap( () => {
        this.selfPerson = null;
      })
    );
  }

  fixPersonalProfileDate(profile: ApiPersonalProfile): ApiPersonalProfile {
    if (!profile || !profile.birthDate) { return profile; }
    profile.birthDate = new Date(profile.birthDate);
    return profile;
   }

  addStudentProductContext(studentId: number, productCode: string): Observable<ApiProductContext> {
    return this.studentRest.addStudentProductContext(studentId, productCode).pipe(
      tap( () => this.lessonProgress = null ),
      switchMap(
        newContext =>
        this.reloadLessonProgress(studentId).pipe(
          map( () => newContext )
        )
      )
    );
  }

  private reloadLessonProgress(studentId: number): Observable<ApiLessonProgress[]> {
    return this.loadStudentLessonProgress(studentId).pipe(
      tap( newProgress => this.appEvent.lessonProgressUpdated.next(newProgress))
    );
  }

  chargeFreeCredit(studentId: number, productCode: string): Observable<ApiLessonBundle> {
    return this.studentRest.chargeFreeCredit(studentId, productCode).pipe(
      tap( lessonBundle => this.appEvent.creditsUpdate.next())
    );
  }

  updateIntroductionState(studentId: number, state: string): Observable<ApiPersonTechnicalProfile> {
    return this.studentRest.updateIntroductionState(studentId, state).pipe(
      tap( newProfile => this.studentTechnicalProfile = new CacheEntry(newProfile, TimeUnits.Minues(15).toMilis())),
      tap( newProfile => this.appEvent.technicalProfileUpdated.next(newProfile))
    );
  }

  saveStudentTechnicalProfile(studentId: number, profile: ApiPersonTechnicalProfile): Observable<ApiPersonTechnicalProfile> {
    return this.studentRest.saveStudentTechnicalProfile(studentId, profile).pipe(
      tap( newProfile => this.studentTechnicalProfile = new CacheEntry(newProfile, TimeUnits.Minues(15).toMilis())),
      tap( newProfile => this.appEvent.technicalProfileUpdated.next(newProfile))
    );
  }

  loadSelfPerson(studentId: number): Observable<ApiPerson<ApiPersonalProfile>> {
    if (this.loadSelfPersonCurrentLoading) {
      return this.loadSelfPersonCurrentLoading;
    }
    this.loadSelfPersonCurrentLoading = this.loadIfNecesary(this.selfPerson, () =>
      this.studentRest.loadSelfPerson(studentId)
    , TimeUnits.Minues(30).toMilis()).pipe(
      tap( entry => {
        if (entry && entry.value && entry.value.personalProfile) {
          entry.value.personalProfile = this.fixPersonalProfileDate(entry.value.personalProfile);
        }
      }),
      tap( entry => this.selfPerson = entry),
      map( entry => entry.value ),
      tap( () => this.loadSelfPersonCurrentLoading = null ),
      publish(),
      refCount()
    );

    return this.loadSelfPersonCurrentLoading;
  }

  getPersonScores(): Observable<ApiPersonScores> {
    if (this.skipCspa) {
      const emtpyRes = new ApiPersonScores();
      emtpyRes.chapters = [];
      return of(emtpyRes);
    }
    return this.loadIfNecesary(this.scores, () =>
      this.cspaRest.getPersonScores()
    , TimeUnits.Minues(15).toMilis()).pipe(
      tap( entry => this.scores = entry ),
      map( entry => entry.value )
    );
  }

  loadStudentAttendedCourses(studentId: number, productCode: string): Observable<ApiCourse[]> {
    return this.loadIfNecesary(this.studentCourses[productCode], () =>
    this.studentRest.loadStudentAttendedCourses(studentId, productCode)
    , TimeUnits.Minues(15).toMilis()).pipe(
      tap( entry => this.studentCourses[productCode] = entry ),
      map( entry => entry.value )
    );
  }


  loadStudentTechnicalProfile(studentId: number): Observable<ApiPersonTechnicalProfile> {
    if (this.currentTechnicalProfileLoading) {
      // console.log('found technical profile loading');
      return this.currentTechnicalProfileLoading;
    }

    // console.log('creating technical profile loading');
    this.currentTechnicalProfileLoading = this.loadIfNecesary(this.studentTechnicalProfile, () =>
      this.studentRest.loadStudentTechnicalProfile(studentId)
    , TimeUnits.Minues(30).toMilis()).pipe(
      tap( entry => this.studentTechnicalProfile = entry ),
      map( entry => entry.value ),
      tap( () => {
        // console.log('clearing technical profile loading');
        this.currentTechnicalProfileLoading = null; }
        ),
      publish(),
      refCount()
    );

    return this.currentTechnicalProfileLoading;
  }

  loadIfNecesary<T>(currentEntry: CacheEntry<T>, provider: () => Observable<T>, validTime: number):
  Observable<CacheEntry<T>> {
    if (currentEntry && currentEntry.isValid()) {
      return of(currentEntry);
    }
    return provider().pipe(
      map( item => new CacheEntry(item, validTime))
    );
  }

  public getExerciseSets(): Observable<ExerciseSet[]> {
    return this.loadIfNecesary(this.exerciseSets, () =>
      this.cspaRest.getExerciseSets(),
      TimeUnits.Hours(2).toMilis()
    ).pipe(
      tap( entry => this.exerciseSets = entry ),
      map( entry => entry.value )
    );
  }
  public findAllTeachers(studentId: number) {
    return this.loadIfNecesary(this.allTeachers, () =>
      this.studentRest.listTeachers(studentId).pipe(
        // put loaded teacher to teacher by id cache also
        tap (teachers =>
          teachers.forEach( profile => this.teachers[profile.teacher.id] = new CacheEntry(profile, TimeUnits.Hours(2).toMilis())))
      )
    ,
    TimeUnits.Hours(2).toMilis()).pipe(
      tap( entry => this.allTeachers = entry),
      map( entry => entry.value )
    );
  }

  public findTeachers(studentId: number, teachersIds: number[]): Observable<ApiTeacherProfile[]> {
    // collect valid teachers from cache
    const teachersFromCache = teachersIds
      .map( teacherId => this.teachers[teacherId])
      .filter ( teacherCacheEntry => teacherCacheEntry && teacherCacheEntry.isValid())
      .map ( teacherEntry => teacherEntry.value );

    const foundById: { [id: number]: ApiTeacherProfile } = {};
    teachersFromCache.forEach ( teacherProfle => foundById[teacherProfle.teacher.id] = teacherProfle);

    const teachersToAsk = teachersIds.filter ( teacherId => !foundById[teacherId]);

    let observable = of(null);
    if (teachersToAsk.length > 0) {
      observable = this.studentRest.listTeachers(studentId, teachersToAsk ).pipe(
        tap(
          teacherProfiles => teacherProfiles.forEach ( profile => foundById[profile.teacher.id] = profile )
        ),
        map(
          teacherProfiles =>
            teacherProfiles.forEach(
              profile => this.teachers[profile.teacher.id] = new CacheEntry(profile, TimeUnits.Hours(2).toMilis())
            )
        )
      );
    }

    return observable.pipe(
      map( () =>
        teachersIds.map( teacherId => foundById[teacherId])
      )
    );
  }

  loadProductProgressEstimation(studentId: number, productCode: string) {
    return this.loadIfNecesary(this.productProgressEstimation[productCode], () =>
      this.studentRest.loadProgressEstimation(studentId, productCode)
    , TimeUnits.Minues(15).toMilis()).pipe(
      tap( estimations => this.productProgressEstimation[productCode] = estimations),
      map( estimations => estimations.value )
    );
  }


  loadStudentLessonProgress(studentId: number): Observable<ApiLessonProgress[]> {
    if (this.loadProgressLoading) {
      return this.loadProgressLoading;
    }

    this.loadProgressLoading = this.loadIfNecesary(this.lessonProgress, () =>
      this.studentRest.loadStudentProgress(studentId)
    , TimeUnits.Minues(15).toMilis()).pipe(
      tap( progressEntry => this.lessonProgress = progressEntry),
      map ( progressEntry => progressEntry.value ),
      tap( () => this.loadProgressLoading = null),
      publish(),
      refCount()
    );

    return this.loadProgressLoading;
  }

  loadExerciseStructure(lang: string): Observable<ApiExercisesStructure> {
    if (this.skipCspa) {
      const emptyRes = new ApiExercisesStructure();
      emptyRes.chapters = [];
      return of(emptyRes);
    }
    return this.loadIfNecesary(this.exerciseStructs[lang],
      () =>
        this.cspaRest.getExerciseStructure(
          LangProductMapper.mapLangToCspa(lang)
        ).pipe(
          tap(struct => this.fixCspaStructNames(struct) )
        ), TimeUnits.Days(1).toMilis())
      .pipe(
        tap( structEntry => this.exerciseStructs[lang] = structEntry),
        map ( structEntry => structEntry.value )
      );
  }

  listProvaContexts(studentId: number) {
    return this.loadIfNecesary(this.provaContexts, () => this.studentRest.listProvaContexts(studentId), TimeUnits.Minues(15).toMilis())
    .pipe(map(structEntry => structEntry.value));
  }

  fixCspaStructNames(struct: ApiExercisesStructure): void {
    let chapterNumber: number;
    let currentLangCode: string;
    let exerciseNumber: number;

    for (const chapter of struct.chapters) {
      if (!currentLangCode || (currentLangCode !== chapter.langCode) ) {
        currentLangCode = chapter.langCode;
        chapterNumber = 1;
        exerciseNumber = 1;
      }
      chapter.shortName = String(chapterNumber);
      chapterNumber++;
      for (const section of chapter.sections) {
        exerciseNumber = 1;
        for (const exercise of section.exercises) {
          if ( !exercise.shortName ) {
            exercise.shortName = String(exerciseNumber);
          }
          exerciseNumber++;
        }
      }
    }
  }
}
