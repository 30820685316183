import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiLessonProgress,
  ApiPersonalProfile,
  ApiPersonTechnicalProfile,
  ApiTeacherProfile
} from 'src/app/model/rest/rest-model';
import {SimpleScheduleEvents} from "../../model/rest/booking-rest-v2";

@Injectable({
  providedIn: 'root'
})
export class AppEventsService {
  public scheduleReservedSubject = new Subject<SimpleScheduleEvents>();
  public personalProfileUpdated = new Subject<ApiPersonalProfile>();
  public technicalProfileUpdated = new Subject<ApiPersonTechnicalProfile>();
  public teacherProfileUpdated = new Subject<ApiTeacherProfile>();
  public switchLangCode = new ReplaySubject<string>();
  public lessonsUpdated = new Subject<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>();
  public creditsUpdate = new  Subject<any>();
  lessonProgressUpdated = new Subject<ApiLessonProgress[]>();
  private _credits: { [lang: string]: BehaviorSubject<number> } = {};

  public credits(lang: string): BehaviorSubject<number> {
    let result = this._credits[lang];
    if (!result) {
      result = new BehaviorSubject<number>(0);
      this._credits[lang] = result;
    }
    return result;
  }

  constructor() { }
}
