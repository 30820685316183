

<div class="progress-board-scroll-wrapper">
  <div class="progress-board">
    <div *ngIf="exerciseStructure && personScores" >
      
      <div *ngFor="let chapter of exerciseStructure.chapters" class="chart" [ngClass]="{selected: chapter==currentChapter}">

        <app-circle-chart [progressValue]="personScores.chapterProgress[chapter.id]*100"
        [color]="chapter.code" [description]="chapter.shortName" (click)="chooseChapter(chapter)" ></app-circle-chart>
        
      </div>
    </div>
    
  </div>
</div>
<div class="stage-board">
    <app-stage-details [personScoresStruct]="personScores" [chapter]="currentChapter"></app-stage-details>
</div>