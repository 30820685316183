import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { TeacherRestService } from 'src/app/services/rest/teacher-rest.service';
import { Pageable, Page } from 'src/app/model/rest/base';
import { ActivatedRoute } from '@angular/router';
import { ApiLearningUnitStudent, ApiLessonMetric, ApiPerson, ApiPersonalProfile } from 'src/app/model/rest/rest-model';

@Component({
  selector: 'app-teacher-create-lesson',
  templateUrl: './teacher-create-lesson.component.html',
  styleUrls: ['./teacher-create-lesson.component.css']
})
export class TeacherCreateLessonComponent implements OnInit {
  private studentSearchQueryTerms = new Subject<string>();
  teacherId: number;
  students: ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>[];
  student: ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>;
  metric = new ApiLessonMetric();

  constructor(
    private teacherRest: TeacherRestService,
    private route: ActivatedRoute
  ) { }

  public searchStudent(query: string) {
    this.studentSearchQueryTerms.next(query);
  }

  public chooseStudent(student: ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>) {
    this.student = student;
  }

  public clearStudents() {
    this.students = null;
  }
  ngOnInit() {
    this.route.parent.paramMap.subscribe(params =>
      this.teacherId = Number(params.get('teacherId'))
    );

  this.studentSearchQueryTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap( query => this.searchForStudents(query)),
      map( (students: Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>) => students.content )
    ).subscribe(students => this.students = students);
  }


  searchForStudents(query: string): any {
    return this.teacherRest.queryForStudents(this.teacherId, query, Pageable.of(0, 5, null));
  }

}
