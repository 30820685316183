<div class="text-center mb-5">
  <p class="text-primary text-uppercase" i18n="@@root.static.main-header">Stop studying, start speaking!</p>
  <h2 class="mb-4" i18n="@@root.static.second-header">Online English and Spanish lessons with native speakers</h2>
  <p class="text-muted f-14" i18n="Introduction to the callan method on root page@@root.static.method-lead"> 
    With Callan Online, you learn a language by talking and listening to native speakers.<br/>
    Speak, correct, and repeat. No studying, no time-wasting.<br/>
    Ready to start speaking?
  </p>
</div>
<div class="d-flex flex-column align-items-center text-center">
  <a class="btn btn-primary text-uppercase px-5 py-3 mb-2" routerLink="/login" i18n="@@root.login">Log in</a>
  <a class="btn btn-link f-12 text-decoration-none" routerLink="/register" i18n="@@root.register">No account? Register here.</a>
</div>
