<ng-template [ngLoading]="!loaded" type="spinner">
  <div class="table-responsive" *ngIf="lastPage && results">
    <table class="table table-borderless pe-none" >
      <thead>
        <tr>
          <th i18n="Event date@@student-purchase-history.table.date">Date</th>
          <th i18n="Course name@@student-purchase-history.table.course">Course</th>
          <th i18n="Bundle name@@student-purchase-history.table.name">Name</th>
          <th i18n="Bundle price@@student-purchase-history.table.price">Price</th>
          <th i18n="Number of credits in bundle@@student-purchase-history.table.credits">Credits</th>
          <th i18n="Bundle expiry date@@student-purchase-history.table.expiry-date">Expiry Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let bundle of results">
          <td>
            {{bundle.date | date: 'dd MMM yyyy HH:mm'}}
          </td>
          <td>
            {{bundle.product.name}}
          </td>
          <td>
            {{bundle.name}}
          </td>
          <td>
            {{bundle.price}}
          </td>
          <td>
            {{bundle.available}} / {{bundle.registeredLessons}}
          </td>
          <td>
            {{bundle.expiryDate | date: 'dd/MM/yyyy'}}
          </td>
        </tr>
        <tr *ngIf="hasNext" class="pe-auto">
          <td colspan="6" class="text-center"> 
            <a (click)="next()" tabindex=""><i class="fa fa-angle-double-down justify-content-center"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <p *ngIf="lastPage && results.length==0" i18n="Description for empty purchase result@@student-purchase-history.no-results">
    Didn't find any purchases
  </p>
</ng-template>
