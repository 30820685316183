import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {ErrorBase} from '../../model/errors/ErrorBase';
import {HttpErrorsService} from './http-errors.service';

@Injectable({
  providedIn: 'root'
})
/*
  Interceptor for http request repsponsible to prepare common error structure
*/
export class HttpErrorsInterceptorService implements HttpInterceptor {

  constructor(private httpErrors: HttpErrorsService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

      return next.handle(req).pipe(catchError(err => {
        let error = err.error as ErrorBase;
        if (err.status === 0 ) {
          error = new ErrorBase();
          error.errorCode = 'CONNECTION_ERROR';
          error.interactive = false;
          error.userMessage = 'Lost connection. Check your Internet connection or try again later.';
          error.developerMessage = 'Request failed. Check the connection or server status.';
        } else if (!error) {
          error = new ErrorBase();
          error.developerMessage = err.error;
          error.userMessage = 'Server Error';
          error.errorCode = 'HTTP:${err.status}';
        } else if (error && error.interactive) {
          // skip regular error processing
          return throwError(err);
        }
        if(err.status != 401) this.httpErrors.handleError(error);
        return throwError(err);
      }));
    }
}
