import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudentContextService } from 'src/app/services/ctx/student-context.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-student-teacher-details-page',
  templateUrl: './student-teacher-details-page.component.html',
  styleUrls: ['./student-teacher-details-page.component.css']
})
export class StudentTeacherDetailsPageComponent implements OnInit, OnDestroy {

  studentId: string;
  teacherId: string;
  langCode: string;
  langCodeSubscription: Subscription;

  constructor(activatedRoute: ActivatedRoute,
    studentContext: StudentContextService) {
    activatedRoute.parent.paramMap.subscribe ( params => this.studentId = params.get('studentId'));
    activatedRoute.paramMap.subscribe( params => this.teacherId = params.get('teacherId'));
    this.langCodeSubscription = studentContext.langCodeSubject.subscribe( langCode => this.langCode = langCode);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.langCodeSubscription.unsubscribe();
  }

}
