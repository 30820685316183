import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CacheEntry } from './student-cache-proxy.service';
import { map, tap } from 'rxjs/operators';
import { PublicRestService } from './rest/public-rest.service';
import { TimeUnits } from '../utils/calendar-utils';
import { ApiCountry } from '../model/rest/rest-model';

@Injectable({
  providedIn: 'root'
})
export class PublicRestProxyService {
  private countriesCache: {[ langCode: string]: CacheEntry<ApiCountry[]> } = {};

  constructor(private publicRest: PublicRestService) { }

  loadCountries(lang: string): Observable<ApiCountry[]> {
    return this.loadIfNecesary(this.countriesCache[lang], () => this.publicRest.listCountries(lang), TimeUnits.Minues(15).toMilis()).pipe(
      tap( resultEntry => this.countriesCache[lang] = resultEntry),
      map( rE => rE.value )
    );
  }

  private loadIfNecesary<T>(currentEntry: CacheEntry<T>, provider: () => Observable<T>, validTime: number):
  Observable<CacheEntry<T>> {
    if (currentEntry && currentEntry.isValid()) {
      return of(currentEntry);
    }
    return provider().pipe(
      map( item => new CacheEntry(item, validTime))
    );
  }

}
