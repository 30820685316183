import { Component, OnInit, Input } from "@angular/core";
import { ApiProduct } from "src/app/model/rest/products";
import { StudentShoppingCartService } from "src/app/services/student-shopping-cart.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-product-box",
  templateUrl: "./product-box.component.html",
  styleUrls: ["./product-box.component.css"],
})
export class ProductBoxComponent implements OnInit {
  constructor(private shoppingCart: StudentShoppingCartService) {}

  @Input()
  product: ApiProduct;
  @Input()
  desc: String = "";
  @Input()
  notAvailable: String = null;

  ngOnInit() {}

  addItem() {
    this.shoppingCart.addItem(this.product);
  }

  getPricePerLesson() {
    if (!this.product) return;
    const price = this.product.defaultPrice.price;
    let weeks = 0;
    if (this.product?.defaultVariantName?.description)
      weeks = +this.product.defaultVariantName.description.split(" ")[0];
    else if (this.isPlanFlexible())
      weeks = +this.product.defaultVariantName.name.split(" ")[0];
    const currency = this.product.defaultPrice.currency;
    return `${(price / weeks).toFixed(2)} ${currency}`;
  }

  isPlanFlexible() {
    return this.product && this.product?.code.includes("pag");
  }
  isEnbus() {
    return this.product?.productCode.includes("en.bs");
  }

  getProductType() {
    return this.product?.variantLabels[0].split(".").reverse()[0];
  }

  getWeeksAmount() {
    return this.product?.defaultVariantName?.name.split(" ")[2];
  }
  getLessonsAmount() {
    return this.product?.defaultVariantName?.description.split(" ")[0];
  }
  getCreditsAmount() {
    return this.product?.defaultVariantName.name.split(" ")[0];
  }
  getCourseName() {
    if (this.product?.productCode.includes("en.bs")) return "en.bs";
    else if (this.product?.productCode.includes("sp")) return "sp";
    else return "en";
  }
}
