<form>
  <div class="row">
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select name="productCode" (change)="updateReport()" class="form-select" [(ngModel)]="productCode">
          <option *ngFor="let langCode of langCodes" [value]="langCode">{{getLangName(langCode)}}</option>
        </select>
        <label for="productCode">Product</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select (change)="updateReport()" name="preselectedPeriod" class="form-select" [(ngModel)]="preselectedPeriod" [ngClass]="{'select-not-empty' : preselectedPeriod}">
          <option value="0">current month</option>
          <option value="-1">previous month</option>
          <option value="-2">2 months ago</option>
          <option value="-3">3 months ago</option>
          <option value="-4">4 months ago</option>
          <option value="-5">5 months ago</option>
          <option value="-6">6 months ago</option>
          <option value="-7">7 months ago</option>
        </select>
        <label for="preselectedPeriod">Date</label>
      </div>
    </div>
  </div>
</form>
<div class="table-responsive">
<table class="table">
  <thead>
    <tr>
      <th>complete</th>
      <th>student no show</th>
      <th>invalidated by student</th>
      <th>invalidated by teacher</th>
      <th>canceled</th>
    </tr>
  </thead>
  <tbody>
    <tr  *ngIf="reportData">
      <td>{{reportData.complete}}</td>
      <td>{{reportData.studentNoShow}}</td>
      <td>{{reportData.invalidatedByStudent}}</td>
      <td>{{reportData.invalidatedByTeacher}}</td>
      <td>{{reportData.cancelled}}</td>
    </tr>
  </tbody>
</table>
</div>
