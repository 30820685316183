<div class="card bg-linear text-center">
  <ng-container *ngIf="!isPlanFlexible() && !isEnbus()">
    <div class="card-header p-0 border-0 text-uppercase">
      <p class="name">
        <span i18n="@@product-box.type"
          >{getProductType(), select, light {Light} std {Standard} int
          {Intensive}}</span
        >
        - {{ getWeeksAmount() }}
        <span i18n="@@product-box.weeks-plural"
          >{getWeeksAmount(), plural, =1 {week} other {weeks} }</span
        >
        (<span i18n="@@product-box.course-name"
          >{getCourseName(), select, en {English} sp {Spanish} en.bs {Business
          English}}</span
        >)
      </p>
    </div>

    <div class="card-body py-3">
      <h3 class="mb-0">
        <span class="text-white price">
          {{ product.defaultPrice.price }}
        </span>
        <span class="text-white currency">
          {{ product.defaultPrice.currency }}
        </span>
      </h3>
      <p class="text-muted description">
        {{ getLessonsAmount() }}
        <span i18n="@@product-box.lesson-plural"
          >{getLessonsAmount(), plural, =1 {lesson} other {lessons} }</span
        >
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="isPlanFlexible()">
    <div class="card-header p-0 border-0 text-uppercase">
      <p class="name" *ngIf="isPlanFlexible()">
        {{ getCreditsAmount() }}
        <span i18n="@@product-box.credits-plural"
          >{getCreditsAmount(), plural, =1 {credit} other {credits} }</span
        >
        (<span i18n="@@product-box.course-name"
          >{getCourseName(), select, en {English} sp {Spanish} en.bs {Business
          English}}</span
        >)
      </p>
    </div>

    <div class="card-body py-3">
      <h3 class="mb-0">
        <span class="text-white price">
          {{ product.defaultPrice.price }}
        </span>
        <span class="text-white currency">
          {{ product.defaultPrice.currency }}
        </span>
      </h3>
    </div>
  </ng-container>
  <ng-container *ngIf="isEnbus()">
    <div class="card-header p-0 border-0 text-uppercase">
      <p class="name">
        <span i18n="@@product-box.course-name"
          >{getCourseName(), select, en {English} sp {Spanish} en.bs {Business
          English}}</span
        >
        -
        <span i18n="@@product-box.type-enbus"
          >{getProductType(), select, instalment {1 instalment} upfront {whole
          course}}</span
        >
      </p>
    </div>

    <div class="card-body py-3">
      <h3 class="mb-0">
        <span class="text-white price">
          {{ product.defaultPrice.price }}
        </span>
        <span class="text-white currency">
          {{ product.defaultPrice.currency }}
        </span>
      </h3>
      <p class="text-muted description">
        {{ getLessonsAmount() }}
        <span i18n="@@product-box.lesson-plural"
          >{getLessonsAmount(), plural, =1 {lesson} other {lessons} }</span
        >
        + eBook
      </p>
    </div>
  </ng-container>
  <div class="card-footer p-0 border-0">
    <button
      *ngIf="!notAvailable"
      class="btn btn-primary mb-2"
      (click)="addItem()"
      i18n="@@common.Buy"
      style="min-width: 120px"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasCart"
    >
      Buy
    </button>
    <span *ngIf="!notAvailable">{{ notAvailable }}</span>
    <p class="buy-desc text-muted f-14 mb-0">
      <span>{{ getPricePerLesson() }}</span>
      /
      <span i18n="@@product-box.lesson-plural"
        >{1, plural, =1 {lesson} other {lessons} }</span
      >
    </p>
  </div>
</div>
