import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {flatMap, tap} from 'rxjs/operators';
import {ExerciseSet} from '../../model/rest/cspa-rest-model';
import {ApiLessonProgress, ApiTeacherProfile,} from 'src/app/model/rest/rest-model';
import {LangProductMapper} from 'src/app/utils/lang-mappers';
import {StudentCacheProxyService} from '../student-cache-proxy.service';
import {AppEventsService} from './app-events.service';
import {LocalStorage} from "ngx-store";
import {AuthServiceProvider} from "../col2/auth.service";


export class StudentProfileData {
  studentId: number;
  schoolId: number;
}

@Injectable({
  providedIn: 'root'
})
/*
Service responsible for keeping the application context for student role
*/
export class StudentContextService {
  profileData: StudentProfileData;
  private _supportedLangs: string[];

  langCodeSubject = new BehaviorSubject<string>(null);
  profileDataSubject = new BehaviorSubject<StudentProfileData>(null);
  supportedLangsSubject = new BehaviorSubject<string[]>([]);
  _currentLang: string;
  @LocalStorage()
  private lastLang: string;
  @LocalStorage()
  private lastStudentId: number;


  public switchLang(lang: string) {
    if (!lang) {
      if (this.lastLang && this.lastStudentId && this.lastStudentId === this.profileData.studentId) {
        this.switchLang(this.lastLang);
        return;
      }
      if (this._supportedLangs.indexOf('en') >= 0) {
        this.switchLang('en');
        return;
      } else if (this._supportedLangs.length > 0) {
        this.switchLang(this._supportedLangs[0]);
        return;
      } else { return; }
    }
    if (this._supportedLangs.indexOf(lang) < 0) { return; }
    this.lastLang = lang;
    this.lastStudentId = this.profileData.studentId;
    this.currentLangCode = lang;
  }

  private loadStudentLessonProgress(): Observable<ApiLessonProgress[]> {
    return this.studentCacheProxy.loadStudentLessonProgress(this.profileData.studentId)
    .pipe(
      tap( progress => this.mergeSupportedLangsFromProgress(progress)),
      );
  }

  mergeSupportedLangsFromProgress(progresses: ApiLessonProgress[]): void {
    if (progresses == null) { return; }
    const langArray: string[] = [];

    progresses
      .filter( p => p.productCode != null)
      .forEach( p => langArray.push (p.productCode) );

      this.mergeSupportedLangs(langArray);
  }

  public findTeachers(...teacherId: number[]): Observable<ApiTeacherProfile[]> {
    return this.studentCacheProxy.findTeachers(this.profileData.studentId, teacherId);
  }

  private loadExercisesSets(): Observable<ExerciseSet[]> {
    return this.studentCacheProxy.getExerciseSets()
      .pipe(
        tap( eSets => this.mergeSupportedLangs(
          eSets
            .map( eSet => eSet.code )
            .map( setCode => LangProductMapper.mapLangFromCspa(setCode) )
        ))
      );
  }

  constructor(
    private authService: AuthServiceProvider,
   private studentCacheProxy: StudentCacheProxyService,
   private appEvent: AppEventsService
    ) {
      this.clearSupportedLangs();
      this.authService.get().subscribe(api => {

          this.profileData = {
            studentId : api.getStudentId(),
            schoolId : api.getSchoolId()
          };
          this.profileDataSubject.next(this.profileData);
          this.loadStudentLessonProgress().pipe(
            flatMap( () => this.loadExercisesSets() )
          ).subscribe (() => this.switchLang(null));
      });

      appEvent.lessonProgressUpdated.subscribe(
        newProgress => this.mergeSupportedLangsFromProgress(newProgress)
      );
    }

    public set supportedLangs(langs: string[]) {
      this._supportedLangs = langs;
      this.supportedLangsSubject.next(langs);
    }

    public get supportedLangs() {
      return this._supportedLangs ;
    }

    private clearSupportedLangs() {
      this._supportedLangs = [];
    }

    private set currentLangCode(lang: string) {
      this._currentLang = lang;
      this.langCodeSubject.next(lang);
      this.appEvent.switchLangCode.next(lang);
    }

    private mergeSupportedLangs(langs: string[]) {
      const correctLangs = langs
        .filter(l => LangProductMapper.langCodes.indexOf(l) >= 0);
      const langsToAdd = this._supportedLangs.filter(l => correctLangs.indexOf(l) < 0);
      langsToAdd.forEach(l => correctLangs.push(l));
      this.supportedLangs = correctLangs;
    }

}
