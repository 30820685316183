import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ThemeService} from 'src/app/services/theme.service';
import {AuthImplService, AuthServiceProvider} from "../../../services/col2/auth.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teacher-menu',
  templateUrl: './teacher-menu.component.html',
  styleUrls: ['./teacher-menu.component.css']
})
export class TeacherMenuComponent implements OnInit {
  teacherId: string;
  theme: string

  constructor(private route: ActivatedRoute,
    private authService: AuthServiceProvider,
    public themeService: ThemeService,
    public authImplService: AuthImplService
    ) { }

  public logout(): void {
    this.authService.get().subscribe(api => api.logout());
  }
  ngOnInit() {
    this.route.children[0].paramMap.subscribe(
      params => {
        this.teacherId = params.get('teacherId');
      }
    );
    this.themeService.getTheme().subscribe(theme =>
      this.theme = theme
    )
  }

  goToNewCallan(){
    this.authImplService.getAccessCode()
    .subscribe(res => {
      const redirectUrl = encodeURIComponent(btoa("teacher/panel/worktimes"))
      window.location.href = `${environment.areaServerBase}/oauth?code=${res}&state=${redirectUrl}`
    })
  }
}
