import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudentPersonalProfileFormComponent, StudentPersonalProfileFormComponentEvent
} from 'src/app/components/student/student-personal-profile-form/student-personal-profile-form.component';
import { StudentTechnicalProfileFormComponent, StudentTechnicalProfileFormComponentEvent
} from 'src/app/components/student/student-technical-profile-form/student-technical-profile-form.component';
import { ComponentEvent } from 'src/app/model/rest/base';

@Component({
  selector: 'app-student-profile-page',
  templateUrl: './student-profile-page.component.html',
  styleUrls: ['./student-profile-page.component.css']
})
export class StudentProfilePageComponent implements OnInit {
  studentId: number;


  @ViewChild('studentPersonalProfileFormComponent', {static: true})
  private studentProfileForm: StudentPersonalProfileFormComponent;
  @ViewChild('studentTechnicalProfileFormComponent', { static: true})
  private studentTechnicalProfileForm: StudentTechnicalProfileFormComponent;
  personalProfileUnderEdit: boolean;
  technicalProfileUnderEdit: boolean;
  personalProfileReady = false;
  technicalProfileReady = false;

  constructor(private route: ActivatedRoute) { }

  personalProfileEvent( event: StudentPersonalProfileFormComponentEvent) {
    if (event.eventType === StudentPersonalProfileFormComponentEvent.ET_FORM_EDITABLE) {
      this.personalProfileUnderEdit = true;
    } else if (event.eventType === StudentPersonalProfileFormComponentEvent.ET_FORM_READ_ONLY) {
      this.personalProfileUnderEdit = false;
    } else if (event.eventType === ComponentEvent.ET_INITIALIZED) {
      this.personalProfileReady = true;
    }
  }

  technicalProfileEvent( event: StudentTechnicalProfileFormComponentEvent) {
    if (event.eventType === StudentTechnicalProfileFormComponentEvent.ET_FORM_EDITABLE) {
      this.technicalProfileUnderEdit = true;
    } else if (event.eventType === StudentTechnicalProfileFormComponentEvent.ET_FORM_READONLY) {
      this.technicalProfileUnderEdit = false;
    }  else if (event.eventType === ComponentEvent.ET_INITIALIZED) {
      this.technicalProfileReady = true;
    }
  }

  editPersonalProfile() {
    this.studentProfileForm.editable = true;
  }

  editTechnicalProfile() {
    this.studentTechnicalProfileForm.editable = true;
  }

  ngOnInit() {
    this.route.parent.paramMap.subscribe( params => {
      this.studentId = Number(params.get('studentId'));
    });
  }

}
