<div class="card" >
  <div class="card-body">
    <h5 class="card-title">Teacher personal informations
        <a (click)="editPersonalProfile()" *ngIf="!personalProfileUnderEdit" class="float-end"><i class="fas fa-pencil-alt float-end"></i></a>
    </h5>
    <app-teacher-personal-profile-form #teacherPersonalProfileFormComponent componentRole="manager" [schoolId]="schoolId" [teacherId]="teacherId" (stateEvent)="onPersonalProfileEvent($event)"></app-teacher-personal-profile-form>
  </div>
</div>

<div class="card mt-2 mb-4" [hidden]="!teacherProfileReady">
    <div class="card-body">
      <h5 class="card-title">Your personal informations
          <a (click)="editTeacherProfile()" *ngIf="!teacherProfileUnderEdit" class="float-end"><i class="fas fa-pencil-alt float-end"></i></a>
      </h5>
      <app-teacher-profile-form #teacherProfileFormComponent componentRole="manager" [schoolId]="schoolId" [teacherId]="teacherId" (stateEvent)="onTeacherProfileEvent($event)"></app-teacher-profile-form>
    </div>
  </div>

  <div class="card mt-2 mb-4">
    <div class="card-body">
      <app-teacher-allowances #teacherAllowancesComponent componentRole="manager" [schoolId]="schoolId" [teacherId]="teacherId"></app-teacher-allowances>
    </div>
  </div>
