import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-circle-chart',
  templateUrl: './circle-chart.component.html',
  styleUrls: ['./circle-chart.component.css']
})
export class CircleChartComponent implements OnInit {

  constructor() { }

  _progressValue = 0;
  _description: string;
  _firstLine: string;
  _secondLine: string;


  @Input()
  color = 'en-st1';

  @Input()
  public set description(desc: string) {
    this._description = desc;
    this.prepareDescriptionLines();
  }

  @Input()
  public set progressValue(value: number) {
    this._progressValue = Math.round(value);
    this.prepareDescriptionLines();
  }

  public get firstLine(): string {
    return this._firstLine;
  }

  public get secondLine(): string {
    return this._secondLine;
  }

  private prepareDescriptionLines() {
    this._firstLine = null;
    this._secondLine = null;

    if (this._description) {
      this._firstLine = this._description;
      this._secondLine = String(this._progressValue) + '%';
    } else {
      this._firstLine = String(this._progressValue) + '%';
      this._secondLine = null;
    }
  }

  public get progressValue(): number {
    return this._progressValue;
  }


  ngOnInit() {
    this.prepareDescriptionLines();
  }

}
