import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AuthServiceProvider} from "../../services/col2/auth.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
/*
logout landing page - is not reponsible for making logout logic
but only for displaying after logout page.
*/
export class LogoutComponent implements OnInit {

  homepageUrl = environment.staticPagesBase;

  constructor(authService: AuthServiceProvider) {
    authService.get().subscribe(api =>
      api.clear()
    )
  }

  ngOnInit() {
  }

}
