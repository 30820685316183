<!-- <div class="lesson-status-legent-wrapper">
    <div class="lesson-status-legend" >
        <span class="badge booked">Reserved</span>
        <span class="badge due">Start &lt; 12h</span>
        <span class="badge in-progress">In progress</span>
        <span class="badge complete">Complete</span>
        <span class="badge cancel">Cancelled</span>
        <div class="badge teacher-invalidate">Cancelled by Tutor</div>
    </div>
</div> -->

<div *ngIf="!hasResults()"
    class="text-muted text-center my-3">
    Please wait...
</div>
<div *ngIf="hasResults() && getTotalResults() == 0"
    class="text-muted text-center my-3">
    No results found.
</div>
<ul *ngIf="hasResults() && getTotalResults() > 0"  class="list-unstyled  lessons-list">
    <li class="row  mb-2 px-2 py-3" *ngFor="let lesson of getLessonsToDisplay()">
        <div class="col">
            <div class="row">
                <div class="col-12 col-lg-3 mb-2 mb-lg-0 d-flex">
                  <div  class="rounded-circle img-thumbnail" style="width: 4rem; height: fit-content;">
                      <div class="profile-photo rounded-circle" [style.background-image]="'url(' + getPersonProfilePhoto(lesson.students[0].person) +')'">
                      </div>
                  </div>
                  <div class="d-flex flex-column d-lg-none ms-auto gap-1">
                    <span class="badge" [ngClass]='lessonBadgeClass(lesson)'>
                        {{getLessonStatus(lesson) }}
                    </span>
                    <span [ngClass]="{'badge bg-danger': isRedLabel(lesson)}">
                        {{getProductVersion(lesson)}}
                    </span>
                  </div>
                </div>
                <div class="col-12 col-lg-4 mb-2 mb-lg-0">
                    <p class="text-muted f-14 mb-0">Student:</p>
                    <h6 class="mt-0">{{getStudentNames(lesson)}}</h6>
                </div>
                <div class="col-12 col-lg-5 mb-2 mb-lg-0">
                    <p class="text-muted f-14 mb-0">Date:</p>
                    <p >{{getLessonDate(lesson) | date: 'EEEE, dd MMM yyyy'}} {{getLessonDate(lesson) | date: 'HH:mm'}}</p>
                </div>
            </div>
        </div>
        <div class="col-4 col-lg-2 d-none d-lg-flex">
          <div class="d-flex flex-column m-auto gap-1">
            <span class="badge" [ngClass]='lessonBadgeClass(lesson)'>
              {{getLessonStatus(lesson) }}
            </span>
            <span class="badge" [ngClass]="{'bg-danger': isRedLabel(lesson), 'bg-secondary': !isRedLabel(lesson)}">
              {{getProductVersion(lesson)}}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-3 col-lg-2 d-lg-flex">
            <button *ngIf="mayViewLesson(lesson)"
                (click)="openDetails(lesson, $event)"
                class="btn btn-outline-secondary text-white rounded-pill d-block w-100 my-auto"
                >View details</button>
            <!--<button *ngIf="mayCancel(lesson)" (click)="showLessonCancelation(lesson)" class="btn btn-sm btn-outline-danger d-block w-100">Cancel</button>-->
        </div>
    </li>
</ul>

<div *ngIf="mayExtend() || mayCollapse()" class="justify-content-center d-flex">
  <a *ngIf="mayCollapse()" (click)="changePageSize(-10)" tabindex="" class="me-2"><i class="fa fa-angle-double-up justify-content-center"></i></a>
  <a *ngIf="mayExtend()" (click)="changePageSize(10)" tabindex=""><i class="fa fa-angle-double-down justify-content-center"></i></a>
</div>

<app-modal #cancelLessonModal>
    <div class="modal-content" *ngIf="_clLessonToCancel">
        <div class="modal-header">
            <h5 class="modal-title" >Cancel the Lesson</h5>
            <button type="button" class="btn-close" (click)="cancelLessonModal.hide()"  aria-label="Close"></button>
        </div>
        <form #f="ngForm" (ngSubmit)="f.valid && cancelLesson()">
            <div class="modal-body">
                <p>You are going to cancel the lesson planned on {{_clLessonToCancel.lessonMetric.plannedStartDate | date: 'medium'}} with {{getStudentNames(_clLessonToCancel)}}</p>
                <div *ngIf="!isCancelable(_clLessonToCancel)" class="alert alert-danger">
                    The lesson was confirmed therefore it can be only invalidated. Are you sure ?
                </div>
                <div class="form-group">
                    <label>Cancelation Reason</label>
                    <textarea
                    #reason="ngModel"
                    name="reason"
                    class="form-control"
                    required
                    [(ngModel)]="_clReason"
                    [ngClass]="{ 'is-invalid': f.submitted && reason.invalid }"
                    ></textarea>
                    <div *ngIf="f.submitted && reason.invalid" class="invalid-feedback">
                        <div *ngIf="reason.errors.required">Cancelation reason is required</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-danger">Cancel The Lesson</button>
            </div>
        </form>
    </div>
</app-modal>

