import { Component, Inject, LOCALE_ID } from '@angular/core';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { StarterComponentBase } from '../english-starter-page/english-starter-page.component';
import { AppEventsService } from 'src/app/services/ctx/app-events.service';
import { StudentShoppingCartService } from 'src/app/services/student-shopping-cart.service';



@Component({
  selector: 'app-spanish-starter-page',
  templateUrl: './spanish-starter-page.component.html',
  styleUrls: ['./spanish-starter-page.component.css']
})
export class SpanishStarterPageComponent extends StarterComponentBase {
  getProductCode(): string {
    return 'sp';
  }
  getStarterCategoryId(): string {
    return 'category:lessons.sp.starter';
  }

  constructor(
    studentCache: StudentCacheProxyService,
    appEvents: AppEventsService, 
    @Inject(LOCALE_ID) locale: string,
    shoppingCart: StudentShoppingCartService) { 
      super(studentCache, appEvents, locale, shoppingCart); 
    }

}
