
<div class="d-flex overflow-scroll" [ngClass]="{'has-selection' : _selectedTeacherIds.length > 0}">
    <a tabindex="" (click)="selectTeacher(null)"
      class="d-flex flex-column p-2 text-decoration-none teacher-card"
      [ngClass]="{'active-teacher': this._selectedTeacherIds.length === 0}">
      <div class="rounded-circle mt-1 mx-auto img-bg" style="width: 3rem; height: 3rem;"></div>
      <span i18n="All teachers selection on teachers list filter@@student-teachers-filter.all-teachers"
        class="text-break text-muted mt-auto">
        All Teachers
      </span>
    </a>
    <a tabindex="" (click)="selectTeacher(teacher)"
      class="d-flex flex-column teacher-card p-2 text-decoration-none"
      *ngFor="let teacher of teachers"
      [ngClass]="{'active-teacher': isActive(teacher)}">
      <div  class="rounded-circle float-start mt-1 mb-2" style="margin: auto;width: 3rem;">
        <div class="profile-photo rounded-circle" [style.background-image]="'url(' + getTeacherPhoto(teacher) +')'">
        </div>
      </div>
      <span class="text-break text-muted">
        {{getTeacherName(teacher)}}
      </span>
    </a>
</div>



