import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AnalyticsService, Event} from 'src/app/services/analytics.service';
import {LocaleManagerService} from 'src/app/services/locale-manager.service';
import {AuthServiceProvider} from "../../services/col2/auth.service";

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.css']
})
/*
OAuth component - watches for code and state params. After finished token obtain component
switches the page to the url from state param or just to the main "/" if state is missing
*/
export class OauthComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthServiceProvider,
    private router: Router,
    private analytics: AnalyticsService,
    private localeManage: LocaleManagerService) {
      this.activatedRoute.queryParams.subscribe(params => {
          this.processOauthCode(params);
      });
  }

  private processOauthCode(params) {
    // prevent language auto switch on the authorization time
    this.localeManage.langSwitchLock = true;
    const oauthCode = this.mapOauthParams(params);
    this.authService.get().subscribe(api => {
      api.authorizeCode(oauthCode.code).subscribe(() => {
        this.analytics.event(Event.ACT_ENG_LOGIN, new Event(Event.CAT_ENG));
        if (oauthCode.state) {
          const finalState = api.resolveState(oauthCode.state);
          this.router.navigateByUrl(finalState).then(() => {
            // unlock language switching and initialize
            this.localeManage.langSwitchLock = false;
            this.localeManage.establishUiLanguage();
          });
        } else {
          this.router.navigate(['/']);
        }
      })
    });
  }

  private mapOauthParams(params): any {
    const res = {code: params.code, state: null};
    if (params.state) {
      res.state = atob(params.state);
    }
    return res;
  }

  ngOnInit() {
  }

}
