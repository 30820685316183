import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { StudentRestService } from 'src/app/services/rest/student-rest.service';
import { tap } from 'rxjs/operators';
import { ApiLearningUnitTeacher, ApiLessonInstance, ApiPersonalProfile } from 'src/app/model/rest/rest-model';
import { PersonNameExtractor, ProfilePhotoUrlExtractor } from 'src/app/utils/profile-photo-url-extractor';


export enum LessonConfirmEvent {
  CONFIRMED, CANCELLED, CANCELLING, IDLE
}


@Component({
  selector: 'app-lesson-confirmation',
  templateUrl: './lesson-confirmation.component.html',
  styleUrls: ['./lesson-confirmation.component.css']
})
export class LessonConfirmationComponent implements OnInit {

  _studentId: number;
  _lessonId: number;
  lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  _cancelling = false;
  @Output()
  interact = new EventEmitter<LessonConfirmEvent>();

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    this.loadLessonData();
  }

  @Input()
  set lessonId(lessonId: number) {
    this._lessonId = lessonId;
    this.loadLessonData();
  }

  loadLessonData() {
    if (!this._studentId || !this._lessonId) {
      return;
    }

    this.studentRest.loadLesson(this._studentId, this._lessonId)
    .pipe(
      tap(lesson => this.storeLesson(lesson))
    ).subscribe();
  }

  storeLesson(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): void {
    this.lesson = lesson;
  }

  onConfirm() {
    this.interact.emit(LessonConfirmEvent.CONFIRMED);
  }

  onCancel() {
    this.interact.emit(LessonConfirmEvent.CANCELLING);
    this._cancelling = true;
    this.studentRest.cancelLesson(this._studentId, this._lessonId, 'system cancellation on confirmation screen')
    .subscribe( () => {
      this.interact.emit(LessonConfirmEvent.CANCELLED);
      this._cancelling = false;
    }
      , () => {
        this.interact.emit(LessonConfirmEvent.IDLE);
        this._cancelling = false;
      });
  }

  isCancelling() {
    return this._cancelling;
  }

  getTeacherName(): string {
    if (!this.lesson) {
      return '';
    }
    return PersonNameExtractor.getPersonName(this.lesson.teacher.person);
  }

  getTeacherPhoto(): string {
    if (!this.lesson) {
      return '';
    }
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(this.lesson.teacher.person);
  }

  getLessonTime(): Date {
    if (!this.lesson) {
      return null;
    }

    return this.lesson.lessonMetric.plannedStartDate;

  }


  constructor(
    private studentRest: StudentRestService
  ) {
  }

  ngOnInit() {
  }

}
