import { Component, OnInit } from '@angular/core';
import { Cart, Product } from 'src/app/model/rest/products';
import { switchMap, tap } from 'rxjs/operators'
import { StudentShoppingCartService } from 'src/app/services/student-shopping-cart.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {

  isLoading = false
  cart: Cart
  products: Product[]
  constructor(
    private shoppingCart: StudentShoppingCartService
    ) { }

  ngOnInit(): void {
    this.shoppingCart.getCart().pipe(
      tap(cart => {
        this.cart = cart
        this.products = this.cart.items
      }),
      switchMap(_ => this.shoppingCart.orderComplete)
    ).subscribe( _ => {
      this.isLoading = !this.isLoading
    })
  }

  isCartEmpty() {
    return !this.cart?.total
  }

  getTotal() {
    return `${this.cart.total} ${this.shoppingCart.getCurrencySign()}`
  }

  checkout() {
    this.shoppingCart.checkout()
  }
}
