<div class="card mb-5">
  <div class="card-body">
      <app-reports-filter (generateReport)="updateReport($event)"></app-reports-filter>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <app-teachers-lesson-types-report #reportView [schoolId]="schoolId"></app-teachers-lesson-types-report>
  </div>
</div>
