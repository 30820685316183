<div class="min-vh-100 bg-radial">
  <div class="bg-secondary mb-5">
    <div class="container">
      <nav class="navbar navbar navbar-expand navbar-dark">
        <a class="navbar-brand col-3 col-sm-2 col-md-2" href="#" routerLink="/school/{{schoolId}}/dashboard"><img [src]="themeService.getLogoPath()" class="img-fluid" alt="Callan Online Logo"/></a>

        <!--Add here-->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarWebsite">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!--Add here -->


        <div class="collapse navbar-collapse col-9 col-sm-10 col-md-10" id="navbarWebsite">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="user-dropdown" data-bs-toggle="dropdown">
                  <i class="fa fa-user"></i>
              </a>
              <div class="dropdown-menu">
                  <a class="dropdown-item" routerLink="/school/{{schoolId}}/dashboard">Dashboard</a>
                  <a class="dropdown-item" routerLink="/school/{{schoolId}}/reports/teacher-lesson-types">Teacher Lesson Types</a>
                  <a class="dropdown-item" routerLink="/school/{{schoolId}}/reports/lessons">Lessons detailed</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" (click)="logout()">Logout</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>


  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>

