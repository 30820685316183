<section class="mb-5">
  <div class="container px-md-5">
    <div class="course-sp">
      <div class="course">
        <h4 class="mb-0" i18n="@@spanishcourses_page_title">General Spanish</h4>
      </div>
      <!-- <div class="card-body">
        <p i18n="@@courses_description_1">Our courses are all about speaking fluently, confidently, and without translating. In Callan Online lessons you communicate and interact with your tutors all the time. As a result, your skills in listening, speaking and reading improve dramatically.</p>
        <p>
          <span i18n="@@spanishcourses_page_intro_line1">The Callan Method Spanish course comprises 6 Stages corresponding roughly to CEFR levels A1-B1.</span>
          <span i18n="@@spanishcourses_page_intro_line2">At 5 lessons a week, you will go from complete beginner to intermediate in 54 weeks (on average 9 weeks per Stage).</span>
        </p>
        <h5 class="text-primary" i18n="@@coursePage.pricing.title">Pricing</h5>
        <p i18n="@@coursePage.pricing.intro.week-plans.es">Learn Spanish regularly with light, standard or intensive. Choose how often you want to have lessons and for how long. Book 3, 5 or 10 lessons each week and make steady progress.</p>
        <p i18n="@@coursePage.pricing.intro.payg">Or buy a package of PAYG credits and reserve lessons whenever you want. These credits are valid for 6 months regardless of how many you buy.</p>

      </div> -->
    </div>
  </div>
</section>

<section class="mb-5" [hidden]="hideStarterOffers" >
  <div class="container px-md-5">
    <div class="card">
      <div class="card-body">
        <app-student-starters-offer (stateEvent)="onStarterOfferEvent($event)" [studentId]="studentId" [langCode]="productCode"></app-student-starters-offer>
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <div class="container">
    <nav>
      <div class="nav nav-tabs justify-content-center text-uppercase" id="nav-tab" role="tablist">
        <button class="nav-link text-uppercase px-2 px-md-4 active" id="nav-light-tab" data-bs-toggle="tab"
          data-bs-target="#nav-light" type="button" role="tab"
          aria-controls="nav-light" aria-selected="true"
          i18n="spanish courses light plan|header of the box@@plans_light_3lessons.title"
          >Light</button>
        <button class="nav-link text-uppercase px-2 px-md-4" id="nav-standard-tab" data-bs-toggle="tab"
          data-bs-target="#nav-standard" type="button" role="tab"
          aria-controls="nav-standard" aria-selected="false"
          i18n="spanish courses standard plan|header of the box@@plans_standard_5lessons.title"
          >Standard</button>
        <button class="nav-link text-uppercase px-2 px-md-4" id="nav-intensive-tab" data-bs-toggle="tab"
          data-bs-target="#nav-intensive" type="button" role="tab"
          aria-controls="nav-intensive" aria-selected="false"
          i18n="spanish courses intensive plan|header of the box@@plans_intensive_10lessons.title"
          >Intensive</button>
        <button class="nav-link text-uppercase px-2 px-md-4" id="nav-payg-tab" data-bs-toggle="tab"
          data-bs-target="#nav-payg" type="button" role="tab"
          aria-controls="nav-payg" aria-selected="false"
          i18n="spanish courses pyg plan|header of the box@@plans_payasyougo"
          >Flexible</button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-light" role="tabpanel" aria-labelledby="nav-light-tab">
        <p class="text-muted text-uppercase text-center f-14 my-4" i18n="spanish courses light plan|header of the box@@plans_light_3lessons.desc">3 lessons / week</p>
        <div class="row justify-content-center g-3">
          <!-- <p class="card-text" i18n="Introduction to light products list@@spanish-core-product.light.intro">Choose the duration of your plan and use your credits to reserve 3 lessons every week.</p> -->
          <div class="col-10 col-sm-8 col-md-4 col-lg-3 mb-3 h-100" *ngFor="let product of lights">
            <app-product-box [product]="product" desc="3 lessons / week"></app-product-box>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="nav-standard" role="tabpanel" aria-labelledby="nav-standard-tab">
        <p class="text-muted text-uppercase text-center f-14 my-4" i18n="spanish courses standard plan|header of the box@@plans_standard_5lessons.desc">5 lessons / week</p>
        <div class="row justify-content-center g-3">
          <!-- <p class="card-text" i18n="Introduction to standard products list@@spanish-core-product.standard.intro">Choose the duration of your plan and use your credits to reserve 5 lessons every week.</p> -->
          <div class="col-10 col-sm-8 col-md-4 col-lg-3 mb-3 h-100" *ngFor="let product of std">
            <app-product-box [product]="product" desc="5 lessons / week"></app-product-box>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="nav-intensive" role="tabpanel" aria-labelledby="nav-intensive-tab">
        <p class="text-muted text-uppercase text-center f-14 my-4" i18n="spanish courses intensive plan|header of the box@@plans_intensive_10lessons.desc">10 lessons / week</p>
        <div class="row justify-content-center g-3">
          <!-- <p class="card-text" i18n="Introduction to intensive products list@@spanish-core-product.intensive.intro"> Choose the duration of your plan and use your credits to reserve 10 lessons every week. (For example two lessons a day)</p> -->
          <div class="col-10 col-sm-8 col-md-4 col-lg-3 mb-3 h-100" *ngFor="let product of intensive">
            <app-product-box [product]="product" desc="10 lessons / week"></app-product-box>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="nav-payg" role="tabpanel" aria-labelledby="nav-payg-tab">
        <div class="row justify-content-center g-3">
          <!-- <p class="card-text" i18n="spanish courses pay as you go plans intro|short introduction of the pyg plans@@spanishcourses_page_pyg_intro">Buy a package of credits and book lessons whenever you want. These credits are valid for <br> 6 months regardless of how many you buy.</p> -->
          <div class="col-10 col-sm-8 col-md-4 col-lg-3 mt-4 mb-3 h-100" *ngFor="let product of pag">
            <app-product-box [product]="product" desc=""></app-product-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <div class="container px-md-5">
    <div class="card">
      <div class="card-header">
        <h4 class="mb-0" i18n="spanish courses ebook|ebooks for your lessons@@plans_ebooks_title">Ebooks for your lessons </h4>
      </div>
      <div class="card-body">
        <p class="mb-4 mb-md-5" i18n="spanish courses ebook plans intro|short introduction of the ebook @@spanishcourses_page_ebook_intro">Each Stage comes with a corresponding eBook with integrated audio. Use it to revise and practice listening comprehension and spelling after lessons. You should also have it handy during the lessons.</p>
        <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5 justify-content-start">
          <div class="col" *ngFor="let product of books">
            <div class="book-product mx-auto">
              <img class="w-100" src="{{getImage(product)}}">
              <div class="w-100 mt-1 text-center text-muted">
                  {{product.defaultPrice.price}} {{product.defaultPrice.currency}}
              </div>
              <button class="btn btn-outline-primary d-block w-100 mt-1 mb-4"
                (click)="addItem(product)"
                i18n="@@common.Buy"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasCart"
              >Buy</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer border-0">
        <!-- <p class="text-center" i18n="english courses ebook in app|books available in app@@plans_ebooks_in_app">Any eBooks you buy will be displayed in the Callan app. Download it using the links below.</p> -->
        <div class="row justify-content-center">
          <!-- <div class="col-6 col-md-3">
            <a href="http://app.callanonline.com/download/Callan_App_Windows.msi">
              <img src="/assets/img/pc.jpg" class="w-100 m-1" title="pc app" alt="pc">
            </a>
          </div>
          <div class="col-6 col-md-3">
            <a href="http://app.callanonline.com/download/Callan_App_Mac.dmg">
              <img src="/assets/img/mac.jpg"  title="mac app" alt="mac" class="w-100 m-1">
            </a>
          </div> -->
          <div class="col-6 col-md-4 col-xl-3 text-end">
            <a class="btn btn-secondary px-3 py-2 me-4" href="https://play.google.com/store/apps/details?id=pl.arceo.callan.callandigitalbooks" target="_blank">
              <img src="/assets/img/Google_Play_Store_badge_EN.svg" title="google play store" alt="google play store" class="w-100">
            </a>
          </div>
          <div class="col-6 col-md-4 col-xl-3 text-start">
            <a class="btn btn-secondary px-3 py-2" href="https://itunes.apple.com/gb/app/callan-method-app/id1099785360" target="_blank">
              <img src="/assets/img/Download_on_the_App_Store_Badge.svg" class="w-100" title="apple store" alt="apple store">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <div class="container px-md-5">
    <div class="card">
      <div class="card-header">
        <h4 class="mb-0" i18n="Introduction to light products list@@spanish-core-product.level-comparsion.heading">How do the Callan Method stages equate to CEFR levels?</h4>
      </div>
      <div class="card-body">
        <p i18n="@@spanish-core-product.level-comparsion.expl">The comparison of Callan Method stages to the tests shown is approximate.</p>
        <div class="table-responsive">
          <table class="table pe-none mt-3">
            <thead>
              <tr>
                <th scope="col">CEFR Level<sup>1</sup></th>
                <th scope="col">Callan stages</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A1</td>
                <td>1/2</td>
              </tr>
              <tr>
                <td>A2</td>
                <td>3/4</td>
              </tr>
              <tr>
                <td>B1</td>
                <td>5/6</td>
              </tr>
            </tbody>
          </table>
        </div>
        <ol class="text-muted ps-3" i18n="@@spanish-core-product.level-comparsion.methods-list">
          <li>
            <p class="fw-bold f-14 mb-1">Common European Framework of Reference for Languages</p>
            <p class="f-12">
              The Common European Framework of Reference is a guideline used to describe the linguistic competence of learners of foreign languages across Europe and, increasingly, in other countries.
              It is now widely used by publishers and test developers.
            </p>
          </li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <div class="container px-md-5">
    <div class="card">
      <div class="card-header">
        <h4 class="mb-0"i18n="@@spanish-core-product.stage-duration-question">How long it takes to complete each stage?</h4>
      </div>
      <div class="card-body">
        <p i18n="spanish courses time to complete the stage|short paragraph about the lesson pace@@spanishcourses_page_stage_pace">
          The amount of time needed to complete each stage varies across individual students, and depends on external factors, such as quality of connection and frequency of lessons.<br/>
          Stages vary in length as well, so some of them can be completed faster than others. On average, the time frame for particular stages is as follows:
        </p>
        <div class="table-responsive">
          <table class="table pe-none mt-3">
            <thead>
              <tr>
                <th scope="col" i18n="english courses table header in time to complete the stage|stage@@englishcourses_page_stage">Stage</th>
                <th scope="col">1</th>
                <th scope="col">2</th>
                <th scope="col">3</th>
                <th scope="col">4</th>
                <th scope="col">5</th>
                <th scope="col">6</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-muted">
                <th scope="row" class="f-12" i18n="english courses table header in time to complete the stage|No of lessons@@englishcourses_page_nlessons">No of<br/>lessons</th>
                <td class="f-12">30-35</td>
                <td class="f-12">35-40</td>
                <td class="f-12">40-45</td>
                <td class="f-12">50-55</td>
                <td class="f-12">50-55</td>
                <td class="f-12">50-55</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <div class="container px-md-5" i18n="spanish courses page influence progress|last part of page@@spanishcourses_page_influence_progress">
    <p class="text-center mb-5 h4">What factors can influence my progress?</p>
    <div class="row text-center justify-content-center">
      <div class="col-12 col-md-6 col-xl-4">
        <p>Frequency of lessons</p>
        <p class="text-muted">Every lesson begins with a revision of material from previous lessons. The more often you take lessons, the better you remember the revised material, and the less repetition you need. If you do less repetition, you have more time for new work and you progress faster. </p>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <p>Connection quality</p>
        <p class="text-muted">It's very important that your tutors and you can hear each other clearly. You need to learn correct pronunciation from them, and they need to correct any mistakes that you make. Your tutor and you will speak simultaneously a lot of the time, so it's important that there is no lag. If there are sound issues, your lessons will be less efficient and your progress will be slower.</p>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <p>Exercises</p>
        <p class="text-muted">Between lessons, you will be able to do exercises that will help you revise on your own as well as practice spelling. If you do these exercises regularly, you will remember previous lessons better and need less repetition.</p>
      </div>
    </div>
  </div>
</section>

<section class="text-center pb-5">
  <h4 class="mb-4" i18n="@@spanish-core-product.faq-question">More questions about the Courses or Plans? </h4>
  <a href="contact-us.html#faqs" class="btn btn-primary rounded-pill px-5 py-3" i18n="@@spanish-core-product.faq.link">Go to FAQ</a>
</section>
