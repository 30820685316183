<div *ngIf="lessonDetails" class="pt-1">
  <!-- lesson details card -->
  <div class="card mb-2">
    <div class="card-body">
      <h5 class="card-title">{{lessonDetails.course.name}}
        <span class="text-muted">on {{getLessonDate() | date: 'EEE, dd MMM yyyy HH:mm'}}</span>
        <small>
          <span class="text-muted" *ngIf="getStartDate()"> ( started: {{getStartDate() | date: 'HH:mm'}} )</span>
          <span class="ms-1" [ngClass]="{'badge bg-danger': isRedLabel()}">
            {{getProductVersion()}}
          </span>
        </small>
      </h5>
      <div class="row">
        <div class="col-sm-auto order-sm-1">
          <div>
            <div  class="rounded-circle img-thumbnail float-start" style="margin: auto;width: 28px; margin-right: 5px;">
              <div class="profile-photo rounded-circle" [style.background-image]="'url(' + getPersonPhoto(lessonDetails.teacher.person) +')'"></div>
            </div>
            <div style="width: 200px;">
              {{getPersonName(lessonDetails.teacher.person)}}
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="mt-1">
              <div  class="rounded-circle img-thumbnail float-start" style="margin: auto;width: 28px; margin-right: 5px;">
                <div class="profile-photo rounded-circle" [style.background-image]="'url(' + getPersonPhoto(lessonDetails.students[0].person) +')'"></div>
              </div>
              <div style="width: 200px;">
                {{getPersonName(lessonDetails.students[0].person)}}
              </div>
            </div>
        </div>
        <div class="col-sm">
          <dl class="row">
            <dt class="col-sm-3 pt-2">Lesson type:</dt>
            <dd class="col-sm-9">
              <form #typeFrm="ngForm" (ngSubmit)="typeFrm.form.valid && updateLessonType()" class="form-inline">
                <div class="form-group me-2 mb-2">
                  <select
                  name="lessonType"
                  [(ngModel)]="lessonDetails.lessonType"
                  class="form-control">
                      <option value="Standard">Standard</option>
                      <option value="Revision">Revision</option>
                      <option value="QuickStageRevision">Quick Stage Revision</option>
                      <option value="Introduction">Introduction</option>
                      <option value="Exam">Exam</option>
                      <option value="Prova">Prova</option>
                  </select>
                </div>
                <button *ngIf="typeFrm.form.dirty" type="submit" class="mb-2 btn btn-primary">Update</button>
              </form>
              </dd>
            <dt class="col-sm-3">Status:</dt>
            <dd class="col-sm-9">{{lessonDetails.lessonStatus}}</dd>
            <dt class="col-sm-3" *ngIf="hasComment(lessonDetails)">Lesson comment:</dt>
            <dd class="col-sm-9" *ngIf="hasComment(lessonDetails)">{{getComment(lessonDetails)}}</dd>
            <dt class="col-sm-3" *ngIf="hasPrivateComment(lessonDetails)">Private comment:</dt>
            <dd class="col-sm-9" *ngIf="hasPrivateComment(lessonDetails)">{{getPrivateComment(lessonDetails)}}</dd>
            <dt class="col-sm-3" *ngIf="hasStudentMessage()">Message to student:</dt>
            <dd class="col-sm-9" *ngIf="hasStudentMessage()">{{lessonDetails.studentMessage}}</dd>
            <dt class="col-sm-3" *ngIf="hasFlags()">Flags:</dt>
            <dd class="col-sm-9" *ngIf="hasFlags()">
              <table class="table table-borderless">
                <tbody>
                  <tr *ngFor="let flag of lessonDetails.flags">
                    <td>
                      {{flag.date | date: 'dd/MM/yy HH:mm'}}
                    </td>
                    <td>
                      {{getFlagTypeName(flag.flagType)}}
                    </td>
                    <td>
                      {{flag.description}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </dd>
          </dl>
        </div>
      </div>
      <div *ngIf="lessonDetails.cancellationDetails">
        Cancellation details:
        <dl class="row">
          <dt class="col-sm-3">date</dt>
          <dd class="col-sm-9">{{lessonDetails.cancellationDetails.cancelationDate | date: 'EEE, dd MMM yyyy HH:mm '}}</dd>
          <dt class="col-sm-3">reason:</dt>
          <dd class="col-sm-9">{{lessonDetails.cancellationDetails.cancellationReason}}</dd>
        </dl>
      </div>
      <div *ngIf="studentProgress">
        Current student progress:
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th></th>
                <th>Start page</th>
                <th>New work page</th>
                <th>Reading</th>
                <th>Last headword</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th></th>
                <td>{{studentProgress.start}}</td>
                <td>{{studentProgress.nwp}}</td>
                <td>{{studentProgress.reading}}</td>
                <td>{{studentProgress.hw}}</td>
              </tr>
              <tr *ngIf="estimatedProgress">
                <th>Estimated:</th>
                <td>{{estimatedProgress.start}}</td>
                <td>{{estimatedProgress.nwp}}</td>
                <td>{{estimatedProgress.reading}}</td>
                <td>{{estimatedProgress.hw}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <button class="btn btn-primary" *ngIf="mayBook()" (click)="book()">Book</button>
      <button class="btn btn-primary" *ngIf="mayConfirm()" (click)="confirm()">Confirm</button>
    </div>
  </div>
  <!-- attach the lesson flag -->
  <div class="card mb-2">
    <div class="card-body">
      <h6 class="card-title">Add the flag</h6>
      <form #flagForm="ngForm" class="form" (ngSubmit)="flagForm.form.valid && saveFlag()">
        <div class="form-row required">
          <div class="form-group col">
            <label>Flag type</label>
            <select class="form-control"
              name="flagType"
              [(ngModel)]="_newFlagType"
              required
            >
              <option *ngFor="let flagType of flagTypes" value="{{flagType}}">{{getFlagTypeName(flagType)}}</option>
            </select>
          </div>
          <div class="form-group col">
            <label>description</label>
            <textarea
            name="flagDescription"
            [(ngModel)]="_newFlagDescription"
            rows="1" class="form-control"></textarea>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Add Flag</button>
      </form>
    </div>
  </div>
  <!-- finished lesson progress -->
  <div class="card mb-2" *ngIf="lessonDetails && lessonDetails.progressCommited">
    <div class="card-body">
      <h6 class="card-title">Lesson Progress</h6>
      <form #progFrm="ngForm" (ngSubmit)="progFrm.form.valid && updateProgress()">
        <div class="form-row">
          <div class="form-group col required">
            <label>Start page</label>
            <input
            class="form-control"
            type="number"
            name="comStart"
            [(ngModel)]="lessonDetails.progressCommited.start"
            #comStart="ngModel"
            [ngClass]="{ 'is-invalid': progFrm.submitted && comStart.invalid }"
            required
            >
          </div>
          <div class="form-group col required">
            <label>New work page</label>
            <input
            class="form-control"
            type="number"
            name="comdNwp"
            #comNwp="ngModel"
            [(ngModel)]="lessonDetails.progressCommited.nwp"
            [ngClass]="{ 'is-invalid': progFrm.submitted && comNwp.invalid }"
            required
            >
          </div>
          <div class="form-group col">
            <label>Reading</label>
            <input
            class="form-control"
            type="number"
            name="comReading"
            #comReading="ngModel"
            [(ngModel)]="lessonDetails.progressCommited.reading"
            [ngClass]="{ 'is-invalid': progFrm.submitted && comReading.invalid }"

            >
          </div>
          <div class="form-group col required">
            <label>Last headword</label>
            <input
            class="form-control"
            type="text"
            name="comHw"
            [(ngModel)]="lessonDetails.progressCommited.hw"
            #comHw="ngModel"
            [ngClass]="{ 'is-invalid': progFrm.submitted && comHw.invalid }"
            required
            >
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Update</button>
      </form>
    </div>
  </div>
  <!-- cancellation section -->
  <div class="card mb-2" *ngIf="mayCancel()">
    <div class="card-body">
      <h6 class="card-title">Cancellation</h6>
      <form #cncFrm="ngForm" (ngSubmit)="cncFrm.form.valid && cancel()">
        <div class="form-group required">
          <label>Reason:</label>
          <input class="form-control"
          name="reason"
          [(ngModel)]="_cancelReason"
          required>
        </div>
        <button type="submit" class="btn btn-danger">Cancel</button>
      </form>
    </div>
  </div>
  <!-- squander section -->
  <div class="card mb-2" *ngIf="maySquander()">
    <div class="card-body">
      <h6 class="card-title">Squander Lesson</h6>
      <form #sqFrm="ngForm" (ngSubmit)="sqFrm.form.valid && squander()">
        <div class="form-group required">
          <label>Reason:</label>
          <input class="form-control"
          name="reason"
          [(ngModel)]="_squanderReason"
          required>
        </div>
        <button type="submit" class="btn btn-danger">Squader</button>
      </form>
    </div>
  </div>
  <!-- lesson start section -->
  <div class="card mb-2" *ngIf="mayStart()">
    <div class="card-body">
      <h6 class="card-title">Start the lesson</h6>
      <form #stFrm="ngForm" (ngSubmit)="stFrm.form.valid && start()">
        <div class="form-group">
          <label>Start Date: (leave empty if has to be default)</label>
          <input class="form-control"
          name="startDate"
          placeholder="dd-MM-yyyy HH:mm"
          [(ngModel)]="_startDate">
        </div>
        <button type="submit" class="btn btn-primary">Start</button>
      </form>
    </div>
  </div>
  <!-- lesson finish section -->
  <div class="card mb-2" *ngIf="mayFinish()">
    <div class="card-body">
      <h6 class="card-title">Finish the lesson</h6>
      <form #finFrm="ngForm" (ngSubmit)="finFrm.form.valid && finish()">
        <div class="form-row">
          <div class="form-group col">
            <label>Finish Date</label>
            <input class="form-control"
            name="finishDate"
            [(ngModel)]="_finishDate"
            placeholder="dd-MM-yyyy HH:mm">
          </div>
          <div class="form-group col required">
            <label>Start page</label>
            <input
            class="form-control"
            type="number"
            name="comStart"
            [(ngModel)]="_finishProgress.start"
            #comStart="ngModel"
            [ngClass]="{ 'is-invalid': finFrm.submitted && comStart.invalid }"
            required
            >
          </div>
          <div class="form-group col required">
            <label>New work page</label>
            <input
            class="form-control"
            type="number"
            name="comdNwp"
            #comNwp="ngModel"
            [(ngModel)]="_finishProgress.nwp"
            [ngClass]="{ 'is-invalid': finFrm.submitted && comNwp.invalid }"
            required
            >
          </div>
          <div class="form-group col">
            <label>Reading</label>
            <input
            class="form-control"
            type="number"
            name="comReading"
            #comReading="ngModel"
            [(ngModel)]="_finishProgress.reading"
            [ngClass]="{ 'is-invalid': finFrm.submitted && comReading.invalid }"

            >
          </div>
          <div class="form-group col required">
            <label>Last headword</label>
            <input
            class="form-control"
            type="text"
            name="comHw"
            [(ngModel)]="_finishProgress.hw"
            #comHw="ngModel"
            [ngClass]="{ 'is-invalid': finFrm.submitted && comHw.invalid }"
            required
            >
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Finish</button>
      </form>
    </div>
  </div>
  <!-- lesson timing -->
  <div class="card mb-2" *ngIf="mayUpdateLessonTime()">
    <div class="card-body">
      <h6>Lesson execution time</h6>
      <form #timeFrm="ngForm" class="form" (ngSubmit)="timeFrm.form.valid && updateLessonTime()">
        <div class="form-row">
          <div class="form-group col">
            <label>Started at</label>
            <input
              name="startTime"
              type="text"
              placeholder="dd-MM-yyyy HH:mm"
              class="form-control"
              [(ngModel)]="_startDate">
          </div>
          <div class="form-group col">
            <label>Duration (minutes)</label>
            <input
            name="duration"
            class="form-control"
            type="number"
            [(ngModel)]="_lessonDuration">
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Update</button>
      </form>
    </div>
  </div>
  <!-- lesson message -->
  <div class="card mb-2">
    <div class="card-body">
      <h6 class="card-title">Lesson messages</h6>
      <form class="form" #lMsgForm="ngForm" (ngSubmit)="lMsgForm.form.valid && saveComment()">
        <div class="form-row">
          <div class="form-group col">
            <label>Lesson private comment</label>
            <textarea class="form-control"
            name="privateComment"
            [(ngModel)]="_privateComment"
            required
            ></textarea>
          </div>
        </div>
        <button class="btn btn-primary" type="submit">Save</button>
      </form>
    </div>
  </div>
  <!-- student message -->
  <div class="card mb-2">
      <div class="card-body">
        <h6 class="card-title">Student messages</h6>
        <form class="form" #sMsgForm="ngForm" (ngSubmit)="sMsgForm.form.valid && saveStudentMessage()">
          <div class="form-row">
            <div class="form-group col">
              <label>Message to student</label>
              <textarea class="form-control"
              name="privateComment"
              [(ngModel)]="_messageToStudent"
              required
              ></textarea>
            </div>
          </div>
          <button class="btn btn-primary" type="submit">Save</button>
        </form>
      </div>
    </div>
  <!-- student current context -->
  <div class="card mb-2">
    <div class="card-body">
      <h6 class="card-title">Student context</h6>
      <form #sctxForm="ngForm" (ngSubmit)="sctxForm.form.valid && saveStudentContext()">
        <div class="form-row">
          <div *ngIf="studentContext" class="col-md-6 form-group required">
            <label>Coming Lesson Type</label>
            <select
              class="form-control"
              name="commingLessonType"
              [(ngModel)]="studentContext.nextLessonType"
              required
            >
            <option value="Standard">Standard</option>
            <option value="Revision">Revision</option>
            <option value="QuickStageRevision">Quick Stage Revision</option>
            <option value="Introduction">Introduction</option>
            <option value="Exam">Exam</option>
            <option value="Exam">Prova</option>
            </select>
          </div>
          <div *ngIf="studentContext" class="col-md-6 form-group required">
            <label>Student's Stage</label>
            <select
              class="form-control"
              name="studentsStage"
              [(ngModel)]="studentContext.currentCourse"
              required
            >
            <option *ngFor="let course of productCourses" [ngValue]="course">{{course.name}}</option>
            </select>
          </div>
          <button type="submit" class="btn btn-primary me-2">Update</button>
          <button type="button" class="btn btn-success" (click)="migrateToNewEnglishConfirmationModal.show()" *ngIf="isOldEnglishVersion()">Migrate to new English</button>
        </div>
      </form>
    </div>
  </div>
  <div class="card mb-2">
    <div class="card-header">
        <h5 class="card-title">Lesson history</h5>
    </div>
    <div class="card-body">

      <div class="table-responsive">
        <table class="table table-condensed">
          <thead>
            <tr>
              <th></th>
              <th>Date</th>
              <th>Start</th>
              <th>Nwp</th>
              <th>Read</th>
              <th>Headword</th>
              <th>Tutor</th>
              <th>Comment</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr [ngClass]="getLessonColorClass(lesson)" *ngFor="let lesson of history">
              <td >{{lesson.lessonType}}</td>
              <td>{{getHistoryDate(lesson) | date: 'dd/MM/yy HH:mm'}}</td>
              <td>{{lesson.progressCommited?.start }}</td>
              <td>{{lesson.progressCommited?.nwp }}</td>
              <td>{{lesson.progressCommited?.reading }}</td>
              <td>{{lesson.progressCommited?.hw }}</td>
              <td>{{getPersonName(lesson.teacher.person)}}</td>
              <td>
                <span *ngIf="hasComment(lesson)">lesson comment: {{getComment(lesson)}}<br></span>
                <span *ngIf="hasPrivateComment(lesson)">lesson private comment: {{getPrivateComment(lesson)}}<br></span>
              </td>
              <td>
                <div class="lesson-table-tooltip">
                  <i *ngIf="lesson.flags.length > 0" class="fas fa-flag"></i>
                  <div class="lesson-table-tooltip-content p-2 rounded shadow">
                    <ul class="list-unstyled">
                      <li *ngFor="let flag of lesson.flags">
                          <strong>{{getFlagTypeName(flag.flagType)}}</strong><span *ngIf="flag.description">:<br>
                          with description: {{flag.description}}
                          </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="lesson-table-tooltip">
                  <i *ngIf="lesson.studentMessage" class="fas fa-comments"></i>
                  <div class="lesson-table-tooltip-content p-2 rounded shadow">
                    <span *ngIf="lesson.studentMessage">message to the student: {{lesson.studentMessage}}<br></span>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="hasHistoryNext">
              <td colspan="9" class="text-center"> <a (click)="historyNext()" tabindex=""><i class="fa fa-angle-double-down justify-content-center"></i></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<app-modal #notificationConfirmationModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" >Notification confirmation</h5>
      <button type="button" class="btn-close" (click)="notificationConfirmationModal.hide()"  aria-label="Close"></button>
    </div>
    <div class="modal-body">
      Notification was sent.
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="notificationConfirmationModal.hide()">Ok</button>
    </div>
  </div>
</app-modal>
<app-modal #migrateToNewEnglishConfirmationModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Migrate to new English confirmation</h5>
      <button type="button" class="btn-close" (click)="migrateToNewEnglishConfirmationModal.hide()" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      Are you sure you want to migrate to new English
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="migrateToNewEnglishConfirmationModal.hide()">Cancel</button>
      <button type="submit" class="btn btn-primary" [disabled]="isMigratingInProgress" (click)="migrateToNewEnglish()">
        Confirm
        <div *ngIf="isMigratingInProgress" class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
  </div>
</app-modal>
