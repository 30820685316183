import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/model/rest/products';
import { StudentShoppingCartService } from 'src/app/services/student-shopping-cart.service';

@Component({
  selector: 'app-shopping-cart-product',
  templateUrl: './shopping-cart-product.component.html',
  styleUrls: ['./shopping-cart-product.component.css']
})
export class ShoppingCartProductComponent implements OnInit {

  @Input() product: Product
  constructor(
    private shoppingCart: StudentShoppingCartService
  ) { }

  ngOnInit(): void {
  }

  getName() {
    return this.product.product.defaultVariantName?.name || this.product.product.defaultProductName?.name
  }

  getQuantity() {
    return this.product.quantity
  }

  getSum() {
    return `${(this.getQuantity() * this.product.product.defaultPrice.price).toFixed(2)} 
    ${this.shoppingCart.getCurrencySign()}`
  }

  increment() {
    this.shoppingCart.increment(this.product)
  }

  decrement() {
    this.shoppingCart.decrement(this.product)
  }

  delete() {
    this.shoppingCart.delete(this.product)
  }
}
