<div *ngIf="show()" class="alert alert-danger">
  <strong i18n="Introduction for student missing data box@@student-missing-data-box.intro">We need to know a bit more about you to offer you the best experience. Please complete your profile:</strong>

  <form
  #mdForm="ngForm" (ngSubmit)="mdForm.form.valid && saveData()">
    <div class="row">
      <div *ngIf="readNationality" class="col-12 col-md-6 col-xl-4 mb-4">
        <label for="nationality" i18n="@@student-missing-data-box.form.nationality.label">Where are you from?</label>
        <select
          id="nationality"
          name="nationality"
          #nationality="ngModel"
          [ngClass]="{ 'is-invalid': mdForm.submitted && nationality.invalid}"
          [(ngModel)]="_personalProfile.nationalityIso"
          class="form-control"
          required>
          <option *ngFor="let n of countries" [value]="n.isoCode">{{n.name}}</option>
          </select>
        <div *ngIf="mdForm.submitted && nationality.invalid" class="invalid-feedback">
            <div *ngIf="nationality.errors.required" i18n="@@common.field-required">Field is required</div>
        </div>
      </div>
      <div *ngIf="readCountry" class="col-12 col-md-6 col-xl-4 mb-4">
        <label for="country" i18n="@@student-missing-data-box.form.location.label">Where do you live?</label>
        <select
          id="country"
          name="country"
          #country="ngModel"
          [ngClass]="{ 'is-invalid': mdForm.submitted && country.invalid}"
          [(ngModel)]="_personalProfile.countryIso"
          class="form-control"
          required>
          <option *ngFor="let c of countries" [value]="c.isoCode">{{c.name}}</option>
          </select>
        <div *ngIf="mdForm.submitted && country.invalid" class="invalid-feedback">
            <div *ngIf="country.errors.required" i18n="@@common.field-required">Field is required</div>
        </div>
      </div>
      <div *ngIf="readSkype" class="col-12 col-md-6 col-xl-4 mb-4">
        <label i18n="@@student-missing-data-box.form.skype.label">Skype:</label>
        <input
            type="text"
            id="skype"
            name="skype"
            #skype="ngModel"
            [(ngModel)]="_technicalProfile.skype"
            [ngClass]="{ 'is-invalid': mdForm.submitted && skype.invalid}"
            class="form-control"
            >

      </div>
    </div>
    <div class="col-12"  *ngIf="readSkype">
      <small i18n="@@student-missing-data-box.form.skype-help">
          Where to find <strong>Skype ID</strong>? -
          Tap or click your profile picture in Skype, then tap or click Skype profile.
          Your username can be:
          a unique ID (<strong>live:738293dhgt183849</strong>) or
          a unique name (<strong>happy.robin83</strong>)
      </small>
    </div>
    <div class="text-end">
        <button class="btn btn-primary" i18n="@@common.Save">Save</button>
    </div>
  </form>
</div>
