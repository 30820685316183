import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NoMenuComponent} from './layout/no-menu/no-menu.component';
import {LoginComponent} from './auth/login/login.component';
import {ForbiddenComponent} from './auth/forbidden/forbidden.component';
import {LogoutComponent} from './auth/logout/logout.component';
import {AuthGuardService} from './services/col2/auth-guard.service';
import {StudentMenuComponent} from './layout/with-menu/student-menu/student-menu.component';
import {TeacherMenuComponent} from './layout/with-menu/teacher-menu/teacher-menu.component';
import {OauthComponent} from './auth/oauth/oauth.component';
import {RootComponent} from './auth/root/root.component';
import {RoleGuardService} from './services/col2/role-guard.service';
import {BuyCoursesComponent} from './pages/student/buy-courses/buy-courses.component';
import {StudentProfilePageComponent} from './pages/student/student-profile-page/student-profile-page.component';
import {WalletComponent} from './pages/student/wallet/wallet.component';
import {StudentLessonDetailsComponent} from './pages/student/student-lesson-details/student-lesson-details.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {TeacherCreateLessonComponent} from './pages/teacher/teacher-create-lesson/teacher-create-lesson.component';
import {TeacherProfilePageComponent} from './pages/teacher/teacher-profile-page/teacher-profile-page.component';
import {TeacherLessonDetailsComponent} from './pages/teacher/teacher-lesson-details/teacher-lesson-details.component';
import {StudentDashboardComponent} from './pages/dashboard/student-dashboard/student-dashboard.component';
import {TeacherDashboardComponent} from './pages/dashboard/teacher-dashboard/teacher-dashboard.component';
import {ManagerMenuComponent} from './layout/with-menu/manager-menu/manager-menu.component';
import {ManagerDashboardComponent} from './pages/dashboard/manager-dashboard/manager-dashboard.component';
import {
  ManagerStudentDetailsLayoutComponent
} from './layout/manager-student-details-layout/manager-student-details-layout.component';
import {
  ManagerStudentCreditsPageComponent
} from './pages/manager/manager-student-credits-page/manager-student-credits-page.component';
import {
  StudentReserveLessonPageComponent
} from './pages/student/student-reserve-lesson/student-reserve-lesson-page.component';
import {CourseProductsPageComponent} from './pages/student/course-products-page/course-products-page.component';
import {BreadcrumEntry} from './components/breadcrumb/breadcrumb.component';
import {
  StudentTeachersListPageComponent
} from './pages/student/student-teachers-list-page/student-teachers-list-page.component';
import {
  StudentTeacherDetailsPageComponent
} from './pages/student/student-teacher-details-page/student-teacher-details-page.component';
import {RegisterComponent} from './auth/register/register.component';
import {EmptyNavBarComponent} from './layout/with-menu/empty-nav-bar/empty-nav-bar.component';
import {
  ManagerStudentLessonsPageComponent
} from './pages/manager/manager-student-lessons-page/manager-student-lessons-page.component';
import {
  ManagerTeacherDetailsLayoutComponent
} from './layout/manager-teacher-details-layout/manager-teacher-details-layout.component';
import {
  ManagerTeacherLessonsPageComponent
} from './pages/manager/manager-teacher-lessons-page/manager-teacher-lessons-page.component';
import {
  TeachersLessonTypesReportPageComponent
} from './pages/manager/teachers-lesson-types-report-page/teachers-lesson-types-report-page.component';
import {LessonsReportPageComponent} from './pages/manager/lessons-report-page/lessons-report-page.component';
import {
  ManagerLessonDetailsPageComponent
} from './pages/manager/manager-lesson-details-page/manager-lesson-details-page.component';
import {
  ManagerTeacherProfilePageComponent
} from './pages/manager/manager-teacher-profile-page/manager-teacher-profile-page.component';
import {
  ManagerStudentProfilePageComponent
} from './pages/manager/manager-student-profile-page/manager-student-profile-page.component';
import {ContainerLayoutComponent} from './layout/container-layout/container-layout.component';
import {OpenComponent} from './auth/open/open.component';
import {
  ProvaBookedTutorialComponent
} from './pages/student/tutorials/prova-booked-tutorial/prova-booked-tutorial.component';
import {
  ReserveLessonConfirmationPageComponent
} from './pages/student/reserve-lesson-confirmation-page/reserve-lesson-confirmation-page.component';
import {OrderSuccessComponent} from './pages/order-success/order-success.component';
import {OrderFailComponent} from './pages/order-fail/order-fail.component';
import {UserRole} from "./services/col2/auth.service";


const routes: Routes = [
  // paths without menu
  {
    path: '', component: EmptyNavBarComponent, children: [
      { path: 'forbidden', component: ForbiddenComponent},
      { path: '', component: RootComponent},
      { path: 'logout', component: LogoutComponent},
      { path: 'order-success', component: OrderSuccessComponent},
      { path: 'order-fail', component: OrderFailComponent}
    ]
  },
  { path: '', component: NoMenuComponent, children : [
    { path: '404', component: NotFoundComponent},
    { path: 'login', component: LoginComponent},
    { path: 'oauth', component: OauthComponent},
    { path: 'register', component: RegisterComponent},
    { path: 'open', component: OpenComponent}
  ]},
  // with empty navbar
  // student no menu pages
  {
    path: 'student/:studentId',
    canActivate: [AuthGuardService, RoleGuardService],
    data: {roles: [UserRole.Student]},
    children: [
      {
        path: 'tutorials',
        children: [
          {
            path: 'prova-booked',
            component: ProvaBookedTutorialComponent
          }
        ]
      },
      {
        path: 'lessons/:lessonId/confirmation',
        component: ReserveLessonConfirmationPageComponent
      }
    ]
  },
  {
    path: '', component: StudentMenuComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    data: {roles: [UserRole.Student]},
    children: [
      {
        path: 'student/:studentId',
        children: [
          {
            path: '', component: ContainerLayoutComponent,
            children: [
              {
                path: 'dashboard', component: StudentDashboardComponent,
                data: {breadcrumb: null}
              },
              {
                path: 'buy-courses', component: BuyCoursesComponent,
                data: {breadcrumb: [new BreadcrumEntry('Buy Courses', null)]}
              },
              {
                path: 'profile', component: StudentProfilePageComponent,
                data: {breadcrumb: [new BreadcrumEntry('Profile', null)]}
              },
              {
                path: 'wallet/:lang', component: WalletComponent,
                data: {breadcrumb: [new BreadcrumEntry('Transactions', null)]}
              },
              {
                path: 'lessons/reserve', component: StudentReserveLessonPageComponent,
                data: {breadcrumb: [new BreadcrumEntry('Reserve a lesson', null)]}
              },
              {
                path: 'lessons/:lessonId', component: StudentLessonDetailsComponent,
                data: {breadcrumb: [new BreadcrumEntry('Lesson details', null)]}
              },
              {
                path: 'teachers', component: StudentTeachersListPageComponent,
                data: {breadcrumb: [new BreadcrumEntry('Tutors', null)]}
              },
              {
                path: 'teachers/:teacherId', component: StudentTeacherDetailsPageComponent,
                data: {breadcrumb: [new BreadcrumEntry('Tutors', '/teachers'), new BreadcrumEntry('Tutor Details', null)]}
              }
            ]
          },
          {
            path: 'buy-courses/product/:productCode', component: CourseProductsPageComponent,
            data: {breadcrumb:
              [new BreadcrumEntry('Buy Courses', '/buy-courses'), new BreadcrumEntry('Course Products Page', null)]}
          },
        ]
      }
    ]
  },
  // teacher pages
  {
    path: '', component: TeacherMenuComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    data: {roles: [UserRole.Teacher]},
    children: [
      {
        path: 'teacher/:teacherId',
        children: [
          {
          path: 'dashboard', component: TeacherDashboardComponent
          },
          {
            path: 'lessons/new', component: TeacherCreateLessonComponent
          },
          {
            path: 'profile', component: TeacherProfilePageComponent
          },
          {
            path: 'lessons/:lessonId/students/:studentId/details', component: TeacherLessonDetailsComponent
          }
        ]
      }
    ]
  },
  // manager pages
  {
    path: '', component: ManagerMenuComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    data: {roles: [UserRole.Admin]},
    children: [
      {
        path: 'school/:schoolId',
        children: [
          {
            path: 'dashboard', component: ManagerDashboardComponent
          },
          {
            path: 'student/:studentId', component: ManagerStudentDetailsLayoutComponent,
            children: [
              {
                path: 'credits', component: ManagerStudentCreditsPageComponent
              },
              {
                path: 'lessons', component: ManagerStudentLessonsPageComponent
              },
              {
                path: 'lessons/:lessonId', component: ManagerLessonDetailsPageComponent
              },
              {
                path: 'profile', component: ManagerStudentProfilePageComponent
              }
            ]
          },
          {
            path: 'teacher/:teacherId', component: ManagerTeacherDetailsLayoutComponent,
            children: [
              {
                path: 'lessons', component: ManagerTeacherLessonsPageComponent
              },
              {
                path: 'lessons/:lessonId', component: ManagerLessonDetailsPageComponent
              },
              {
                path: 'profile', component: ManagerTeacherProfilePageComponent
              }
            ]
          },
          {
            path: 'reports',
            children: [
              {
                path: 'teacher-lesson-types', component: TeachersLessonTypesReportPageComponent
              },
              {
                path: 'lessons', component: LessonsReportPageComponent
              }
            ]
          }
        ]
      }
    ]
  },
  { path: '**', redirectTo: '/404'}
];

@NgModule({
  exports: [
    RouterModule
  ],
  imports: [
    RouterModule.forRoot(routes)
  ],
  declarations: []
})
export class AppRoutingModule { }
