import { Component, OnInit, Input } from '@angular/core';
import { LangProductMapper } from 'src/app/utils/lang-mappers';
import { ReportsRestService } from 'src/app/services/rest/reports-rest.service';
import { Dates } from 'src/app/utils/calendar-utils';
import { ApiTeacherProfile, EntityRelatedRow } from 'src/app/model/rest/rest-model';
import { map, tap } from 'rxjs/operators';

class ReportData {
  complete = 0;
  studentNoShow = 0;
  invalidatedByStudent = 0;
  invalidatedByTeacher = 0;
  cancelled = 0;
}

@Component({
  selector: 'app-teacher-quick-lesson-counter',
  templateUrl: './teacher-quick-lesson-counter.component.html',
  styleUrls: ['./teacher-quick-lesson-counter.component.css']
})
export class TeacherQuickLessonCounterComponent implements OnInit {
  dateFrom: Date;
  dateTo: Date;
  reportData: ReportData;

  constructor(
    private reportService: ReportsRestService
  ) { }

  _teacherId: number;
  preselectedPeriod = '0';
  productCode: string;
  langCodes = LangProductMapper.activeLangCodes;

  getLangName(langCode: string) {
    return LangProductMapper.mapLangCodeToLangName(langCode);
  }

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
  }

  calculateDates(): any {
    const preselectedPeriod = parseInt(this.preselectedPeriod, 0);
    this.dateFrom = Dates.monthStartDate( preselectedPeriod);
    this.dateTo = new Date();
    if (preselectedPeriod !== 0) {
      this.dateTo = Dates.monthFinishDate(preselectedPeriod);
    }
  }

  mapResults(reportRows: EntityRelatedRow<ApiTeacherProfile>[]) {
    const res = new ReportData();
    for (const row of reportRows) {
      switch (row.values[0] as number) {
        case 4:
         res.complete = row.values[1]; break;
        case 5:
         res.cancelled = row.values[1]; break;
        case 6:
         res.invalidatedByTeacher = row.values[1]; break;
        case 7:
         res.invalidatedByStudent = row.values[1]; break;
        case 8:
         res.studentNoShow = row.values[1]; break;
      }
    }
    return res;
  }

  updateReport(): any {
    this.calculateDates();
    this.reportService.listLessonTypesReportForTeacher(this._teacherId,
      this.dateFrom, this.dateTo, this.productCode)
      .pipe(
        map( rows => this.mapResults(rows)),
        tap( reportData => this.reportData = reportData)
      )
      .subscribe();
  }


  ngOnInit() {
    this.updateReport();
  }

}
