import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  ApiTeacherProfile, ApiTeacherProductCompetences, ApiLessonInstance, ApiLearningUnitStudent,
  ApiLessonType, ApiCompetence, ApiCourse, ApiLessonMetric, ApiLessonProgress,
  ApiLessonFlag, ApiLessonMessage, ApiPersonalProfile, ApiPerson, ApiPersonTechnicalProfile,
  ApiTeacherLessonInstance,
  ApiProductContext, ApiProductGift, ApiLearningUnitTeacher, ApiPersonalProfileBaseWithPhoto, ApiProductContextBase
} from 'src/app/model/rest/rest-model';
import { Observable, of } from 'rxjs';
import { Pageable, Page } from 'src/app/model/rest/base';
import { tap } from 'rxjs/operators';
import { Dates } from 'src/app/utils/calendar-utils';
import {TeacherBookRestService, TeacherBookRestServiceImpl} from "./teacher-book-rest.service";
import {TeacherBookRestBridgeService} from "./teacher-book-rest-bridge.service";
import {
  ProductAvailabilityRequest,
  ScheduleReference,
  SimpleProductAvailability,
  SimpleScheduleEvents
} from "../../model/rest/booking-rest-v2";

@Injectable({
  providedIn: 'root'
})
export class TeacherRestService implements TeacherBookRestService {
  private bookingRest: TeacherBookRestBridgeService | TeacherBookRestServiceImpl
  constructor(private http: HttpClient,
              private bookingBridge: TeacherBookRestBridgeService,
              private bookingRestV2: TeacherBookRestServiceImpl) {
    this.bookingRest = environment.scheduleBridgeEnabled?
      bookingBridge : bookingRestV2
  }

  private buildUrl(teacherId: number): string {
    return environment.apiEndpoint + '/teacher/' + teacherId;
  }

  public getProfile(teacherId: number):
  Observable<ApiTeacherProfile> {
    const url = this.buildUrl(teacherId) + '/profile';

    return this.http.get<ApiTeacherProfile>(url);
  }

  public saveProfile(teacherId: number, profile: ApiTeacherProfile):
  Observable<ApiTeacherProfile> {
    const url = this.buildUrl(teacherId) + '/profile';

    return this.http.put<ApiTeacherProfile>(url, profile);
  }

  public getPersonalProfile(teacherId: number):
  Observable<ApiPersonalProfile> {
    const url = this.buildUrl(teacherId) + '/person/profile';

    return this.http.get<ApiPersonalProfile>(url);
  }

  public getSelfPerson(teacherId: number):
  Observable<ApiPerson<ApiPersonalProfile>> {
    const url = this.buildUrl(teacherId) + '/person';

    return this.http.get<ApiPerson<ApiPersonalProfile>>(url);
  }

  public getStudent(teacherId: number, studentId: number) {
    const url = this.buildUrl(teacherId) + '/students/' + studentId;

    return this.http.get<ApiPerson<ApiPersonalProfile>>(url);
  }

  public getStudentPersonalProfile(teacherId: number, studentId: number) {
    const url = this.buildUrl(teacherId) + '/students/' + studentId + '/person/profile';

    return this.http.get<ApiPersonalProfile>(url);
  }

  public getStudentTechnicalProfile(teacherId: number, studentId: number) {
    const url = this.buildUrl(teacherId) + '/students/' + studentId + '/person/technical-profile';

    return this.http.get<ApiPersonTechnicalProfile>(url);
  }

  public savePersonalProfile(teacherId: number, profile: ApiPersonalProfileBaseWithPhoto):
  Observable<ApiPersonalProfileBaseWithPhoto> {
    const url = this.buildUrl(teacherId) + '/person/profile';

    return this.http.put<ApiPersonalProfile>(url, profile);
  }

  public saveCompetences(teacherId: number, competences: ApiTeacherProductCompetences):
  Observable<ApiTeacherProductCompetences> {
    const url = this.buildUrl(teacherId) + '/profile/competences';

    return this.http.patch<ApiTeacherProductCompetences>(url, competences);
  }

  public listUpcomingLessons(teacherId: number, pageable: Pageable):
  Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    const url = this.buildUrl(teacherId) + '/lessons/upcoming';

    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {
      params: Pageable.appedPageableParams(new HttpParams(), pageable)
    });
  }

  public getLessonById(teacherId: number, lessonId: number, studentId: number):
  Observable<ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/students/' + studentId;

    return this.http.get<ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url).pipe(
      tap( lesson => {
        if (!lesson) {return; }
        lesson.createDate = Dates.parse(String(lesson.createDate));
        lesson.lessonMetric.plannedStartDate = Dates.parse(String(lesson.lessonMetric.plannedStartDate));
        lesson.lessonMetric.started = Dates.parse(String(lesson.lessonMetric.started));
      })
    );
  }

  public listIncompleteLessons(teacherId: number, pageable: Pageable):
  Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    const url = this.buildUrl(teacherId) + '/lessons/incomplete';

    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {
      params: Pageable.appedPageableParams(new HttpParams(), pageable)
    });
  }

  public listPastLessons(teacherId: number, pageable: Pageable):
  Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    const url = this.buildUrl(teacherId) + '/lessons/past';

    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {
      params: Pageable.appedPageableParams(new HttpParams(), pageable)
    });
  }

  public listLessonHistory(
    teacherId: number,
    lessonId: number,
    studentId: number,
    pageable: Pageable
    ):
    Observable<Page<ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
      const url = this.buildUrl(teacherId)
        + '/lessons/' + lessonId
        + '/students/' + studentId
        + '/history';

        return this.http.get<Page<ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {
          params: Pageable.appedPageableParams(new HttpParams(), pageable)
        });
    }

    public queryForStudents(teacherId: number, query: string, pageable: Pageable):
    Observable<Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>> {
      const url = this.buildUrl(teacherId) + '/students';

      return this.http.get<Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>>(url, {
        params: Pageable.appedPageableParams(new HttpParams(), pageable)
        .append('query', query)
      });
    }

    public queryForStudentsById(teacherId: number, studentIds: number[]): Observable<Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>> {
      const url = this.buildUrl(teacherId) + '/students';
      if ( !studentIds || studentIds.length === 0) {
        return of(Page.empty());
      }

      let params = new HttpParams();
      studentIds.forEach( id => params = params.append('id', id.toString()));

      return this.http.get<Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>>(url, {
        params: params
      });
    }

    public createLesson(teacherId: number, courseCode: string, competence: string, lessonType: ApiLessonType):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.buildUrl(teacherId) + '/lessons';

      const lesson = new ApiLessonInstance();
      lesson.course = new ApiCourse();
      lesson.course.code = courseCode;
      lesson.competence = new ApiCompetence();
      lesson.competence.code = competence;
      lesson.lessonType = lessonType.toString();

      return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, lesson);
    }

    public updateLessonTime(teacherId: number, lessonId: number, metric: ApiLessonMetric):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/time';

      return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, metric);
    }

    public addLessonStudent(teacherId: number, lessonId: number, studentId: number):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/students/' + studentId;

      return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, {});
    }

    public updateLessonProgress(teacherId: number, lessonId: number, progress: ApiLessonProgress):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
        const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/progress';

        return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, progress);
    }

    public createVideoClassroom(teacherId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
        const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/classroom';

        return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, {});
    }

    public createSkypeClassroom(teacherId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/skype';

      return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, {});
  }

    public bookLesson(teacherId: number, lessonId: number):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/status/booked';

      return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, {});
    }

    public commitLessonBooking(teacherId: number, lessonId: number):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/status/due';

      return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, {});
    }

    public startLesson(teacherId: number, lessonId: number, startDate: Date):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/status/start';
      let params = new HttpParams();
      if (startDate) {
        params = params.append('startDate', startDate.toISOString());
      }
      return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, {}, { params: params });
    }

    public cancelLesson(teacherId: number, lessonId: number, reason: string):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/status/cancel';

      return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, reason);
    }

    public finishLesson(teacherId: number, lessonId: number, progress: ApiLessonProgress, finishDate: Date):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/status/finish';
      let params = new HttpParams();
      if (finishDate) {
        params = params.append('finishDate', finishDate.toISOString());
      }
      return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, progress, { params: params });
    }

    public registerLessonFlag(teacherId: number, lessonId: number, lessonFlag: ApiLessonFlag):
    Observable<ApiLessonFlag> {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/flags';

      return this.http.post<ApiLessonFlag>(url, lessonFlag);
    }

    public squanderLesson(teacherId: number, lessonId: number, reason: string):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/status/squander';

      return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, reason);
    }

    public postLessonComment(teacherId: number, lessonId: number, message: ApiLessonMessage):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/messages';

      return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, message);
    }

    public postLessonMessageForStudent(teacherId: number, lesssonId: number, studentId: number, message: string):
    Observable<ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.buildUrl(teacherId) + '/lessons/' + lesssonId + '/students/' + studentId + '/messages';

      return this.http.post<ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, message);
    }

    public getStudentProgress (teacherId: number, studentId: number) {
      const url = this.buildUrl(teacherId) + '/students/' + studentId + '/progress';

      return this.http.get<ApiLessonProgress[]>(url);
    }

    public estimateNextProgress(teacherId: number, studentId: number, baseProgress: ApiLessonProgress, limit?: number) {
      const url = this.buildUrl(teacherId) + '/students/' + studentId + '/progress/estimate';

      let params = new HttpParams();
      if (limit !== undefined) {
        params = params.append( 'limit', limit.toString());
      }

      return this.http.post<ApiLessonProgress[]>(url, baseProgress, { params: params});

    }

    public updateLessonType(teacherId: number, lessonId: number, lessonType: string ) {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/type';

      return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, lessonType);
    }

    public getStudentProductContext(teacherId: number, studentId: number, productCode: string) {
      const url = this.buildUrl(teacherId) + '/students/' + studentId + '/product-context/' + productCode;

      return this.http.get<ApiProductContext>(url);
    }

    public saveStudentProductContext(teacherId: number, studentId: number, productCode: string, context: ApiProductContext) {
      const url = this.buildUrl(teacherId) + '/students/' + studentId + '/product-context/' + productCode;
      let newStudentContext = JSON.parse(JSON.stringify(context))

      return this.http.put<ApiProductContext>(url, context);
    }

    public getProductCourses(productCode: string) {
      const url = environment.apiEndpoint + '/teacher/product/' + productCode + '/courses';
      return this.http.get<ApiCourse[]>(url);
    }

    public previewLessons(teacherId: number, ids: number[]) {
      const url = this.buildUrl(teacherId) + '/lessons';

      let params = new HttpParams();
      ids.forEach( id => params = params.append('id', id.toString()));
      params = params.append('mappingMode', 'Preview');

      return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]>(url, { params: params });
    }

    public notifyStudentAboutLessonStart(teacherId: number, lessonId: number) {
      const url = this.buildUrl(teacherId) + '/lessons/' + lessonId + '/notifications/lesson-start';

      return this.http.post<void>(url, {});
    }

    public listAllowances(teacherId: number) {
      const url = this.buildUrl(teacherId) + '/allowance';

      return this.http.get<String[]>(url);
    }

    public saveAllowances(teacherId: number, allowances: String[], activated: boolean) {
      const mode = activated ? 'enabled' : 'disabled';
      const url = this.buildUrl(teacherId) + '/allowance/' + mode;

      return this.http.patch<void>(url, allowances);
    }

    public findStudentProductGift(teacherId: number, studentId: number, courseCode: string): Observable<ApiProductGift> {
      return this.http.get<ApiProductGift>(`${this.buildUrl(teacherId)}/students/${studentId}/gifts/${courseCode}`);
    }

    public giveStudentProductGift(teacherId: number, studentId: number, courseCode: string): Observable<void> {
      return this.http.post<void>(`${this.buildUrl(teacherId)}/students/${studentId}/gifts/${courseCode}`, {});
    }

    public updateProductVersion(teacherId: number, studentId: number, productCode: string, version: string) {
      return this.http.patch<ApiProductContextBase>(
        `${this.buildUrl(teacherId)}/students/${studentId}/product-context/${productCode}/product-version`,
        version
      );
    }

  createAvailability(teacherId: number, request: ProductAvailabilityRequest): Observable<ScheduleReference> {
    return this.bookingRest.createAvailability(teacherId, request)
  }

  deleteAvailability(teacherId: number, availabilityId: number, eventId: number, applyRecurring: boolean): Observable<void> {
    return this.bookingRest.deleteAvailability(teacherId, availabilityId, eventId, applyRecurring)
  }

  listAvailabilities(teacherId: number, focusDate: Date): Observable<SimpleProductAvailability[]> {
    return this.bookingRest.listAvailabilities(teacherId, focusDate)
  }

  listLessons(teacherId: number, focusDate: Date): Observable<SimpleScheduleEvents[]> {
    return this.bookingRest.listLessons(teacherId, focusDate)
  }
}
