import {Component, Input, OnInit} from '@angular/core';

export interface PageableComponent {
  nextPage();
  prevPage();
  firstPage();
  lastPage();
  nthPage(page: string);
  getPageNumber(): number;
  getPagesNumber(): number;
  hasNext(): boolean;
  hasPrev(): boolean;
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input()
  pageableComponent: PageableComponent

  constructor() { }

  hasPagination(){
    if (!this.pageableComponent) return false;
    return this.pageableComponent.hasNext() || this.pageableComponent.hasPrev();
  }

  getMiddlePages() {
    const currentPage = this.pageableComponent.getPageNumber()
    const allPages = this.pageableComponent.getPagesNumber()
    if(allPages === 3) 
      return [2]
    else if(allPages === 4) 
      return [2, 3]
    else if(allPages > 4) {
      if(currentPage < 4) {
        return [2, 3, 4]
      }
      else if(allPages - currentPage < 3) {
        return [allPages - 3, allPages - 2, allPages - 1]
      }
      else {
        return [currentPage - 1, currentPage, currentPage + 1]
      }
    }
  }

  ngOnInit() {
  }

}
