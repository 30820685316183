import { Component, OnInit, Input } from '@angular/core';
import { CspaRestService } from 'src/app/services/rest/cspa-rest.service';
import { StudentContextService } from 'src/app/services/ctx/student-context.service';
import { Observable } from 'rxjs';
import { ApiPersonScores, PersonChapter, PersonSection, PersonExercise, ApiExercisesStructure,
  Chapter } from 'src/app/model/rest/cspa-rest-model';
import { tap, timeoutWith } from 'rxjs/operators';
import { StateAwareComponent, ComponentEvent } from 'src/app/model/rest/base';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';

export class PersonScoresStruct {
  chapters: {[index: number]: PersonChapter };
  sections: {[index: number]: PersonSection };
  exercises: {[index: number]: PersonExercise };
  chapterProgress: {[index: number]: number};
}

export class CspaSummaryComponentEvent extends ComponentEvent {

}

@Component({
  selector: 'app-cspa-summary',
  templateUrl: './cspa-summary.component.html',
  styleUrls: ['./cspa-summary.component.css']
})
export class CspaSummaryComponent extends StateAwareComponent<CspaSummaryComponentEvent> implements OnInit {
  private _langCode: string;
  scores: ApiPersonScores;
  exerciseStructure: ApiExercisesStructure;
  personScores: PersonScoresStruct;
  currentChapter: Chapter;


  @Input()
  set langCode(langCode: string) {
    this._langCode = langCode;
    if (!langCode) {return; }
    this.studentRestCache.loadExerciseStructure(langCode).subscribe(
      struct => {
        this.exerciseStructure = struct;
        this.calculateProgress();
        this.chooseChapter(null);
      }
    );
  }

  public chooseChapter(chapter: Chapter): void {
    this.currentChapter = chapter;
  }

  public reloadCspaScores(): Observable<ApiPersonScores> {
    this.eventLoading();
    return this.studentRestCache.getPersonScores()
    .pipe(tap(scores => this.scores = scores),
      tap(scores => this.buildScores(scores)),
      tap(scores => this.eventLoaded())
    );
  }

  buildScores(scores: ApiPersonScores): void {
    this.personScores = new PersonScoresStruct();
    this.personScores.chapters = {};
    this.personScores.exercises = {};
    this.personScores.sections = {};
    this.personScores.chapterProgress = {};

    scores.chapters.forEach( chapter => {
      this.personScores.chapters[chapter.id] = chapter;
      chapter.sections.forEach( section => {
        this.personScores.sections[section.id] = section;
        section.exercises.forEach( exercise => {
          this.personScores.exercises[exercise.id] = exercise;
        });
      });
    });
    this.calculateProgress();
  }

  calculateProgress (): void {
    if (!this.personScores || !this.exerciseStructure) { return; }
    this.exerciseStructure.chapters.forEach( chapter => {
      if (this.personScores.chapters[chapter.id]) {
        let chapterProgress = 0;
        let totalExercises = 0;
        chapter.sections.forEach( section => {
          totalExercises += section.exercises.length;
          section.exercises.forEach( exercise => {
            chapterProgress += this.personScores.exercises[exercise.id].score;
          });
        });
        if (totalExercises === 0) {
          chapterProgress = 0;
        } else {
          chapterProgress /= totalExercises;
        }
        this.personScores.chapterProgress[chapter.id] = chapterProgress;
      }
    });
  }

  onCircleClick() {
  }

  get langCode(): string {
    return this._langCode;
  }

  constructor(private cspaRest: CspaRestService,
    private studentContext: StudentContextService,
    private studentRestCache: StudentCacheProxyService) {
      super();
     }

  ngOnInit() {
    this.studentContext.profileDataSubject.subscribe(
      () => this.reloadCspaScores().subscribe()
    );
  }

}
