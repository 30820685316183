<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col">
        <h4 class="align-self-center">{{getTeacherName()}}</h4>
        <p class="text-muted f-14 mb-0">{{getLessonDate() | date: 'dd MMM yyyy HH:mm'}}</p>
        <p class="text-muted f-14 mb-0">
          <span *ngIf="!isProva()">{{getCourseName()}}</span>
          <span *ngIf="isProva()" >
            <span i18n="@@common.langName">{getCourseLangName(), select, English {English} Spanish {Spanish} Business English {Business English} English For Children {English For Children}}</span>&nbsp;
            <span i18n="@@student-comming-lesson.prova-postfix">- PROVA</span>
          </span>
          -
          <span i18n="@@student-lesson-details.lessonStatus" >{getStatusDescription(), select,
            Booked {lesson is reserved.}
            Due {waiting for lesson start.}
            Cancel {lesson is cancelled.}
            InProgress {lesson is in progress.}
            Initializing {invalid lesson status. Please report the problem.}
            InvalidatedByStudent {you have invalidated the lesson.}
            InvalidatedByTeacher {tutor has invalidated the lesson.}
            Complete {lesson complete.}
            StudentNoShow {tutor has reported that you were absent.}
          }</span>
        </p>
      </div>
      <div class="col-auto d-flex">
        <div *ngIf="getTeacherPhoto()" class="rounded-circle img-thumbnail mb-auto my-md-auto me-3" style="width: 64px;">
          <div class="profile-photo rounded-circle" [style.background-image]="'url(' + getTeacherPhoto() +')'">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body lh-sm">
    <div *ngIf="!isFinished() && isProva()" >
      <h5 class="text-primary" i18n="@@student-lesson-details.lesson-plan.title">Lesson Plan</h5>
      <p *ngIf="isEnglishCore()" i18n="@@student-lesson-details.lesson-plan.en.prova-body">
        The teacher will introduce you to the principles of the Callan Method and check your current level of English. They will ask you questions and evaluate the vocabulary, grammar and pronunciation you use in your answers. They will then tell you which of the 12 Stages of the course you are in.
      </p>
      <p *ngIf="isSpanish()" i18n="@@student-lesson-details.lesson-plan.es.prova-body">
        The teacher will introduce you to the principles of the Callan Method and check your current level of English. They will ask you questions and evaluate the vocabulary, grammar and pronunciation you use in your answers. They will then tell you which of the 12 Stages of the course you are in.
      </p>
      <p *ngIf="isBusiness()" i18n="@@student-lesson-details.lesson-plan.bs.prova-body">
        The teacher will introduce you to the principles of the Callan Method and check your current level of English. They will ask you questions and evaluate the vocabulary, grammar and pronunciation you use in your answers. They will then tell you which of the 12 Stages of the course you are in.
      </p>
    </div>

    <!-- !(invalidated || cancelled ) && !studentNoShow -->
    <div *ngIf="!(isInvalidated() || isCancelled()) && !isStudentNoShow()" class="mb-4">
      <ng-container *ngIf="!isProva()">
        <h5 class="text-primary" i18n="@@student-lesson-details.lesson-plan.title">Lesson Plan</h5>
        <!-- future && mt 12 && !next -->
        <p *ngIf="isFuture() && !isClose()" i18n="Lesson plan is hidden for lessons in more than 12 hours|Information about hidden lesson plan the time is more than 12 hours@@student-lesson-details.lesson-plan.hidden-for-late">
          Lesson plan will appear here 12 hours before the lesson starts and if there are no lessons reserved before this one.
        </p>
        <!-- future && lt 12 && !next-->
        <p *ngIf="isFuture() && isClose() && !isNext()" i18n="Lesson plan is hidden if is not a next@@student-lesson-details.lesson-plan.hidden-for-not-next">
          Lesson plan will appear here if there are no lessons reserved before this one.
        </p>
        <!-- (isClose && isNext) || (!isFuture && !isComplete) -->
        <div *ngIf="(isClose() && isNext()) || (!isFuture() && !isComplete())">
          <div class="row mb-3" *ngIf="progress">
            <div class="col-12 col-md-6 col-lg-4">
              <p class="mb-2">
                <span i18n="@@student-lesson-details.lesson-plan.start-page">Revision start page:</span>
                <span class="float-end">{{progress.start}}</span>
              </p>
              <p class="mb-2">
                <span i18n="@@student-lesson-details.lesson-plan.new-work-page">New work start page:</span>
                <span class="float-end">{{progress.nwp}}</span>
              </p>
              <p class="mb-0">
                <span i18n="@@student-lesson-details.lesson-plan.reading-start-page">Reading start page:</span>
                <span class="float-end">{{getRecomendedReading()}}</span>
              </p>
            </div>
          </div>
          <p i18n="Lesson may be modified by teacher|Inform student that lesson plan may be changed@@student-lesson-details.lesson-plan.may-be-modified">Remember that your tutor may change the above lesson plan, if necessary.</p>
        </div>
      </ng-container>
      <!-- complete && !studentNoShow -->
      <div *ngIf="isComplete() && !isStudentNoShow()">
        <div class="row mb-3" *ngIf="lesson.progressCommited">
          <div class="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
            <p class="mb-2">
              <span i18n="@@student-lesson-details.last-progress.revision-start-page">Revision start page:</span>
              <span class="float-end">{{lesson.progressCommited.start}}</span>
            </p>
            <p class="mb-2">
              <span i18n="@@student-lesson-details.last-progress.new-work-page">New work page:</span>
              <span class="float-end">{{lesson.progressCommited.nwp}}</span>
            </p>
            <p class="mb-2">
              <span i18n="@@student-lesson-details.last-progress.last-word">Last word:</span>
              <span class="float-end">{{lesson.progressCommited.hw}}</span>
            </p>
            <p class="mb-0">
              <span i18n="@@student-lesson-details.last-progress.reading-page">Reading until page:</span>
              <span class="float-end">{{lesson.progressCommited.reading}}</span>
            </p>
          </div>
          </div>
      </div>
    </div>
    <!-- !( invalidated || cancelled) -->
    <div *ngIf="!(isInvalidated() || isCancelled() || isStudentNoShow())">
      <h5 class="text-primary" i18n="@@student-lesson-details.important.title">Important</h5>
      <!-- !isFinished && !inProgress -->
      <p *ngIf="!(isComplete() || isInProgress())" i18n="Follow instructions|tell student to follow instructions to complete the lesson@@student-lesson-details.important.tips.follow-instructions">
        Two minutes before the lesson, log in and follow the instructions at the top of the dashboard.
      </p>
      <!-- inProgress-->
      <p *ngIf="isInProgress()" i18n="@@student-lesson-details.important.lesson-in-progress">
          This lesson is in progress.<br>
          Follow the instruction at the top of the screen.
      </p>
      <!-- complete -->
      <p *ngIf="isComplete()" i18n="@@student-lesson-details.important.complete-exercises">
        For best result complete every Dictation at least three times and complete Extra Practice and Questions and Answers section.
      </p>
    </div>
    <!-- (!finished && !inProgress) || cancelled - to reduce -->
    <div *ngIf="!(isFinished() || isInProgress()) || isCancelled()">
      <h5 class="text-primary" i18n="@@student-lesson-details.cancellation.title">Cancellation</h5>
      <!-- future && mt 12  -->
      <p *ngIf="isFuture() && !isClose() && !isCancelled()" i18n="Cancellation is allowed|tell student that he may cancel the lesson@@student-lesson-details.cancellation.allowed">
        You can cancel this lesson at any time more than 12 hours before it starts.
      </p>
      <!-- !finished && lt 12 !inProgress -->
      <p *ngIf="isClose() && !isCancelled()" i18n="Cancellation is not allowed|tell student that cancellation is not allowed@@student-lesson-details.cancellation.not-allowed">
        This lesson will start in less than 12 hours, it can’t be cancelled.
      </p>
      <div *ngIf="isCancelled() && !hasCancellationDetails()" i18n="Lesson is cancelled without reason|Explain lesson is cancelled but we don't have details@@student-lesson-details.cancellation.lesson-cancelled-no-reason">
          <p>This lesson has been cancelled.</p>
          <p>We don't have enough information to display lesson cancellation details.</p>
          <p>The lesson credit has been returned to your account.</p>
      </div>
      <!-- cancelled && byStudent-->
      <div *ngIf="isCancelled() && hasCancellationDetails() && isByStudent()" i18n="Lesson is cancelled|Explain lesson is cancelled@@student-lesson-details.cancellation.lesson-cancelled">
          <p>This lesson has been cancelled by you more than 12 hours before its start.</p>
          <p><strong>Reason:</strong> {{getCancellationReason()}}</p>
          <p>The lesson credit has been returned to your account.</p>
      </div>
      <!-- cancelled && byTutor -->
      <div *ngIf="isCancelled() && hasCancellationDetails() && !isByStudent()" i18n="Lesson is cancelled by teacher|Explain lesson is cancelled by teacher@@student-lesson-details.cancellation.lesson-cancelled-by-teacher">
          <p>This lesson has been cancelled by your tutor.</p>
          <p><strong>Reason:</strong> {{getCancellationReason()}}</p>
          <p>The lesson credit has been returned to your account. We apologise for the inconvenience.</p>
      </div>
    </div>
    <!-- complete && !studentNoShow -->
    <div *ngIf="isComplete() && !isStudentNoShow() && getMessage()">
      <h5 class="text-primary" i18n="Tutor message on lesson complete@@student-lesson-details.complete.message">Message from the tutor</h5>
      <p>
          {{getMessage()}}
      </p>
    </div>
    <!-- (complete && studentNoShow) || (invalidated && tutorNoShow) -->
    <div *ngIf="(isComplete() && isStudentNoShow()) || (isInvalidated() && isTutorNoShow())">
      <h5 class="text-primary" i18n="@@student-lesson-details.lesson.title">Lesson</h5>
      <!-- invalidated && tutorNoShow-->
      <div *ngIf="(isInvalidated() && isTutorNoShow())" i18n="Tutor no show|Explain and apologise about tutor absence@@student-lesson-details.lesson.tutor-no-show">
          <p>You reported that the tutor was absent.</p>
          <p>The lesson credit has been returned to your account.</p>
          <p>We apologise for the inconvenience.</p>
      </div>
      <!-- complete && studentNoShow -->
      <div *ngIf="(isComplete() && isStudentNoShow())" i18n="Student no show|Explain student no show and tell about ability to cancel the lesson@@student-lesson-details.lesson.student-no-show">
        <p>Your tutor reported that you were absent.</p>
        <p>We hope you’re ok.</p>
        <p>Please remember you can cancel your lessons at any time more than 12 hours before they start.</p>
      </div>
    </div>
  </div>
</div>
