

<form *ngIf="profile" #technicalProfileForm="ngForm" (ngSubmit)="technicalProfileForm.form.valid && onSave()">
  <div class="row">
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select class="form-select"
          id="language"
          #language="ngModel"
          [(ngModel)]="profile.lang"
          name="language"
          [attr.disabled]="editable ? null : ''"
          required
          [ngClass]="{ 'is-invalid': technicalProfileForm.submitted && language.invalid, 'select-not-empty' : profile.lang }"
          >
            <option *ngFor="let lang of languages"
              [value]="lang.code">
              {{lang.name}}
            </option>
        </select>
        <label for="language" i18n="@@student-technical-profile.form.lang.label">Language</label>
        <div *ngIf="technicalProfileForm.submitted && language.invalid" class="invalid-feedback">
          <div *ngIf="language.errors.required" i18n="@@common.field-required">Field is required</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input type="tel" class="form-control" id="phone"
            placeholder="Phone"
            #phone="ngModel"
            [(ngModel)]="profile.phone" name="phone"
            [ngClass]="{ 'is-invalid': technicalProfileForm.submitted && phone.invalid }"
            [attr.disabled]="editable ? null : ''"
            >
        <label i18n="@@student-technical-profile.form.phone.label" for="phone">Phone</label>
        <div *ngIf="technicalProfileForm.submitted && phone.invalid" >
            <span *ngIf="phone.errors.required" class="d-block invalid-feedback f-12" i18n="@@common.field-required">Field is required</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input type="text" class="form-control" id="skype"
            placeholder="Skype"
            #skype="ngModel"
            [(ngModel)]="profile.skype" name="skype"
            [ngClass]="{ 'is-invalid': technicalProfileForm.submitted && skype.invalid }"
            [attr.disabled]="editable ? null : ''"
            >
        <i class="fab fa-skype text-primary skype-icon"></i>
        <label i18n="@@student-technical-profile.form.skype.label" for="skype">Skype</label>
      </div>
    </div>

</div>
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        name="notifBookingConfirm"
        [(ngModel)]="profile.notifBookingConfirm"
        [attr.disabled]="editable ? null : ''"
        >
      <label class="form-check-label f-14"  i18n="@@student-technical-profile.form.booking-confirmations.label">
        Receive booking confirmations
      </label>
    </div>
  </div>
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        name="notifOffers"
        [(ngModel)]="profile.notifOffers"
        [attr.disabled]="editable ? null : ''"
        >
      <label class="form-check-label f-14"  i18n="@@student-technical-profile.form.receive-offers.label">
        Receive offers and discounts
      </label>
    </div>
  </div>
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        name="allowBookingTeacherChange"
        [(ngModel)]="profile.allowBookingTeacherChange"
        [attr.disabled]="editable ? null : ''"
        >
      <label class="form-check-label f-14"  i18n="Checkbox if student accepts change the tutor on lesson cancel@@student-technical-profile.form.accept-tutors-change.label">
        When my tutor cancels a lesson, find me another
      </label>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <button [hidden]="!editable" class="btn btn-primary me-3" i18n="@@common.Save">Save</button>
    <button type="button" [hidden]="!editable" class="btn btn-secondary" (click)="editable = false;" i18n="@@common.Cancel">Cancel</button>
  </div>

</form>
