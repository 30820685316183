import { Component, OnInit, Input } from '@angular/core';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { tap } from 'rxjs/operators';
import { ApiTeacherProfile, ApiTeacherProductCompetences, ApiLearningUnitTeacher } from 'src/app/model/rest/rest-model';
import { ProfilePhotoUrlExtractor, PersonNameExtractor } from 'src/app/utils/profile-photo-url-extractor';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-student-teacher-details',
  templateUrl: './student-teacher-details.component.html',
  styleUrls: ['./student-teacher-details.component.css']
})
export class StudentTeacherDetailsComponent implements OnInit {

  _langCode: string;
  _studentId: number;
  _teacherId: number;
  teacherData: { teacher: ApiLearningUnitTeacher; competence: ApiTeacherProductCompetences; };
  videoUrl: any;

  constructor(
    private studentProxy: StudentCacheProxyService,
    private _sanitizer: DomSanitizer
  ) { }

  @Input()
  set langCode(langCode: string) {
    this._langCode = langCode;
    this.reloadTeacher();
  }

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    this.reloadTeacher();
  }

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.reloadTeacher();
  }

  ngOnInit() {
  }

  getPhoto(teacher: ApiLearningUnitTeacher) {
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(teacher && teacher.person ? teacher.person : null);
  }

  getName(teacher: ApiLearningUnitTeacher) {
    return PersonNameExtractor.getPersonName(teacher && teacher.person ? teacher.person : null);
  }

  reloadTeacher(): any {
    if (!this._langCode || !this._studentId || !this._teacherId) { return; }
    this.studentProxy.findTeachers(this._studentId, [this._teacherId]).pipe(
      tap( teacherProfiles => this.storeTeacher(teacherProfiles))
    ).subscribe();
  }
  storeTeacher(teacherProfiles: ApiTeacherProfile[]): void {
    this.teacherData = null;
    this.videoUrl = null;

    if (!teacherProfiles || teacherProfiles.length < 1) {return; }
    const teacherProfile = teacherProfiles[0];
    const competence = teacherProfile.competences.find( c => c.product.code === this._langCode);
    if (!competence) {return; }

    this.teacherData = {
      teacher: teacherProfile.teacher,
      competence: competence
    };
    if (competence.videoUrl) {
      this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(competence.videoUrl);
    }
  }

}
