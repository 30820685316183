import { Component, OnInit, Input } from '@angular/core';
import { Exercise, Section, PersonExercise, ApiExerciseSession } from 'src/app/model/rest/cspa-rest-model';
import { CspaRestService } from 'src/app/services/rest/cspa-rest.service';
import { environment } from 'src/environments/environment';
import { StudentContextService } from 'src/app/services/ctx/student-context.service';

@Component({
  selector: 'app-exercise-block',
  templateUrl: './exercise-block.component.html',
  styleUrls: ['./exercise-block.component.css']
})
export class ExerciseBlockComponent implements OnInit {
  _exercise: Exercise;
  _score: PersonExercise;
  _section: Section;
  description: string;
  colorClasses: string[];

  constructor(
    private cspaRest: CspaRestService,
    private studentContext: StudentContextService
  ) { }

  @Input()
  set exercise(e: Exercise) {
    this._exercise = e;
    if (e) {
      this.description = e.shortName;
    }
    this.calculateProgressClasses();
  }

  @Input()
  set score(s: PersonExercise) {
    this._score = s;
    this.calculateProgressClasses();
  }

  @Input()
  set section(s: Section) {
    this._section = s;
    this.calculateProgressClasses();
  }

  openExercise() {
    if (this._score == null || this._exercise == null || this._section == null ) {
      return;
    }
    if (!this._score.enabled) {
      return;
    }

    this.cspaRest.createExerciseSession(this._exercise).subscribe(session => {
      this.redirectToSession(session);
    });
  }

  redirectToSession(session: ApiExerciseSession): any {
    const exerciseStartUlr = environment.casaRoot
    + '/app/main#!/cspa/startExercise/' + this._exercise.id
    + '?sessionId=' + session.id
    + '&questionId=' + session.questions[0].def.id
    + '&backUrl='
    + encodeURIComponent(
      environment.serverBase
      + '/student/'
      + this.studentContext.profileData.studentId
      + '/dashboard');

    window.location.href = exerciseStartUlr;
  }

  private calculateProgressClasses() {
    if ( this._score == null || this._exercise == null || this._section == null) {
      this.colorClasses = ['disabled'];
      return;
     }
     if (!this._score.enabled) {
       this.colorClasses = ['disabled'];
       return;
     }

     if (this._score.score === 0) {
       if (this._score.lastSubmitDate != null) {
        this.colorClasses = ['failed'];
       } else {
         this.colorClasses = ['open-exercise'];
       }
       return;
     }

     switch (this._section.type) {
      case 'DICT':
        if (this._score.score >= 0.9) {
          this.colorClasses = ['perfect'];
        } else if (this._score.score >= 0.5) {
          this.colorClasses = ['pass'];
        } else if (this._score.score >= 0.4) {
          this.colorClasses = ['notpass'];
        } else {
          this.colorClasses = ['failed'];
        }
        break;
      case 'QA':
      case 'EP':
        if (this._score.score >= 0.95) {
          this.colorClasses = ['perfect'];
        } else if (this._score.score >= 0.8) {
          this.colorClasses = ['pass'];
        } else if (this._score.score >= 0.5) {
          this.colorClasses = ['notpass'];
        } else {
          this.colorClasses = ['failed'];
        }
        break;
     }
  }

  ngOnInit() {
    this.calculateProgressClasses();
  }

}
