import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {StudentCacheProxyService} from './student-cache-proxy.service';
import {ApiPersonTechnicalProfile} from '../model/rest/rest-model';
import {AppEventsService} from './ctx/app-events.service';
import {PlatformLocation} from '@angular/common';
import {environment} from 'src/environments/environment';
import {LocalStorage} from 'ngx-store';
import {AnalyticsService, Event} from './analytics.service';
import {AuthServiceProvider, AuthService} from "./col2/auth.service";

/** service responsible for locale management - its initialized in app.component */
@Injectable({
  providedIn: 'root'
})
export class LocaleManagerService {

  baseHref: string;
  private supportedUiLanguages = environment.supportedUiLanguages;
  /** last ui language choosen by the user. Stored in local storage to be used in index.html for quick language change
   * script, invoked before any other resource load
   */
  @LocalStorage()
  lastPreferredLanguage: string;
  /**
   * if the value is true Locale Manager will skip language change detection with automatic change -
   * it's used in oauth procedure to avoid redirection during the authorization procedure
   */
  langSwitchLock = false;

  constructor(authService: AuthServiceProvider,
    private studentCache: StudentCacheProxyService,
    appEvents: AppEventsService,
    private platformLocation: PlatformLocation,
    private analytics: AnalyticsService,
    @Inject(LOCALE_ID) public localeId: string) {
    // subcribe for user auth profile loaded just after the page load - its the time when auth details are filled and
    // request for user profile may be called to received data about the language
    authService.get()
    .subscribe(
      api => {
        this.detectUserPreferredLanguage(api);
      }
    );
    this.baseHref = platformLocation.getBaseHrefFromDOM();
    // listen to technical profile changes to react for the event
    appEvents.technicalProfileUpdated.subscribe(profile => this.setupUserPrefferedLanguage(profile));
  }

  /**
   * Try to find the user language setup
   * @param serverProfile user auth profile received from auth server
   */
  private detectUserPreferredLanguage(api: AuthService): void {
    // cant detect the user lanugage
    try {
      if (api.getStudentId())
        this.studentCache.loadStudentTechnicalProfile(api.getStudentId()).subscribe(
          profile => this.setupUserPrefferedLanguage(profile)
        );
    } catch (e) {
      return;
    }
  }

  /**
   * detect current ui language basing on the baseHref value
   */
  public getCurrentUiLanguage(): string {
    let res = 'en';
    if (this.baseHref === '/') {
      return res;
    }
    for (const supportedLang of this.supportedUiLanguages) {
      if (this.baseHref === '/' + supportedLang + '/') {
        res = supportedLang;
        break;
      }
    }

    return res;
  }

  /**
   * Method allows to allign the requested ui language to the application capabilities.
   * In example if requested language is 'ja' (Japanise) but the application still doesn't support it
   * method has to return the language code which would be the best instead. Currently for
   * all wrong maches 'en' will be returned.
   * @param lang requested language code
   */
  public allignLang(lang: string): string {
    let alligned = lang;
    if (!alligned) {
      alligned = 'en';
    }
    if (this.supportedUiLanguages.indexOf(alligned) < 0) {
      alligned = 'en';
    }
    return alligned;
  }

  /**
   * Detect the ui language, compare with user selection and switch UI if required
   * @param profile user technical profile
   */
  private setupUserPrefferedLanguage(profile: ApiPersonTechnicalProfile): void {
    const preferredLang = this.allignLang(profile.lang);

    this.lastPreferredLanguage = preferredLang;
    // save value to local storage manually manually too

    const currentLang = this.getCurrentUiLanguage();
    if (preferredLang !== currentLang && environment.profileName !== 'local') {
      this.switchLanguage(preferredLang);
    } else {
      console.log('lang event to ' + preferredLang);
      this.analytics.event(Event.ACT_ENG_LANG_SELECT, new Event(Event.CAT_ENG, preferredLang));
    }
  }

  /**
   * Method allows to do the language switch manually to the user selection. It's used
   * by oauth after succesfull authorization.
   */
  public establishUiLanguage() {
    this.switchLanguage(this.lastPreferredLanguage);
  }

  /**
   * Switch user interface to the target language
   * @param toLang target language
   */
  public switchLanguage(toLang: string) {
    // this lock allows the application to prevent automatical language switch and is used by oauth
    if (this.langSwitchLock) {
      return;
    }
    toLang = this.allignLang(toLang);
    if (toLang === this.getCurrentUiLanguage()) {
      return;
    }
    this.lastPreferredLanguage = toLang;
    const pathName: string = (this.platformLocation as any).location.pathname;
    console.log(pathName);
    const pathWithoutLangPreffix = this.removePathLangPreffix(pathName);
    let finalPath = pathWithoutLangPreffix;
    if (toLang !== 'en') {
      finalPath = '/' + toLang + finalPath;
    }
    window.location.href = finalPath;
  }

  private removePathLangPreffix(pathName: string) {
    for (const lang of this.supportedUiLanguages) {
      const langPreffix = '/' + lang;
      if (pathName === langPreffix) {
        return '/';
      }
      if (pathName.startsWith(langPreffix + '/')) {
        return pathName.substring(langPreffix.length);
      }
    }
    return pathName;
  }
}
