import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TeachersLessonTypesReportComponent
} from 'src/app/components/manager/teachers-lesson-types-report/teachers-lesson-types-report.component';
import { ReportGenericFilterData } from 'src/app/components/manager/reports-filter/reports-filter.component';

@Component({
  selector: 'app-teachers-lesson-types-report-page',
  templateUrl: './teachers-lesson-types-report-page.component.html',
  styleUrls: ['./teachers-lesson-types-report-page.component.css']
})
export class TeachersLessonTypesReportPageComponent implements OnInit {
  schoolId: string;
  @ViewChild('reportView', {static: true})
  reportView: TeachersLessonTypesReportComponent;

  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.parent.parent.paramMap.subscribe(params => this.schoolId = params.get('schoolId'));
  }

  public updateReport(data: ReportGenericFilterData) {
    this.reportView.updateReport(data);
  }

  ngOnInit() {
  }

}
