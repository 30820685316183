import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-profile-photo-editor',
  templateUrl: './profile-photo-editor.component.html',
  styleUrls: ['./profile-photo-editor.component.css']
})
export class ProfilePhotoEditorComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

  }

}
