import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {Dates} from 'src/app/utils/calendar-utils';
import {map, tap} from 'rxjs/operators';
import {
  ApiLessonEventModel,
  LessonScheduleEventReference,
  SimpleLessonScheduleRequest,
  SimpleScheduleEvents,
  SimpleTeacherProductAvailability
} from "../../model/rest/booking-rest-v2";

export interface StudentBookRestService {
  getLessonScheduleEventReference(studentId: number, scheduleId: number): Observable<LessonScheduleEventReference<ApiLessonEventModel>>
  listStudentSchoolTeachersAvailability(studentId: number, focusDate: Date, productCode: string): Observable<SimpleTeacherProductAvailability[]>
  listStudentLessonSchedules(studentId: number, focusDate: Date, productCode: string): Observable<SimpleScheduleEvents[]>
  reserveColSchedule(studentId: number, request: SimpleLessonScheduleRequest): Observable<SimpleScheduleEvents>
}

@Injectable({
  providedIn: 'root'
})
export class StudentBookRestServiceImpl implements StudentBookRestService {
  constructor(private http: HttpClient) { }

  prepareUrl(studentId: number): string {
    return environment.bookingEndpoint + '/student/' + studentId;
  }
  prepareUrlV2(studentId: number): string {
    return environment.bookingEndpoint + '/v2/students/' + studentId;
  }

  getLessonScheduleEventReference(studentId: number, scheduleId: number):
  Observable<LessonScheduleEventReference<ApiLessonEventModel>> {

    const url = this.prepareUrlV2(studentId) + `/schedule/${scheduleId}/event`;
    return this.http.get<LessonScheduleEventReference<ApiLessonEventModel>>(url)
  }

  listStudentSchoolTeachersAvailability(studentId: number, focusDate: Date, productCode: string):
  Observable<SimpleTeacherProductAvailability[]> {
    let weekStart = Dates.getWeekStartDate(focusDate)

    const url = this.prepareUrlV2(studentId) + '/teacher-availability';
    return this.http.get<SimpleTeacherProductAvailability[]>(url, {
      params: {
        dateFrom: weekStart.toISOString(),
        dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        productCode: productCode
      }
    }).pipe(
      tap( schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      }),
      map(worktimes => worktimes.map(worktime => Object.assign(new SimpleTeacherProductAvailability(), worktime)))
    );
  }

  listStudentLessonSchedules(studentId: number, focusDate: Date, productCode: string):
  Observable<SimpleScheduleEvents[]> {
    let weekStart = Dates.getWeekStartDate(focusDate)
    const url = this.prepareUrlV2(studentId) + '/schedules';

      return this.http.get<SimpleScheduleEvents[]>(url, {
        params: {
          dateFrom: weekStart.toISOString(),
          dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
          productCode: productCode,
          type: "lesson"
        }
      }).pipe(
        tap( schedules => {
          schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
        })
      );
  }

  reserveColSchedule(studentId: number, request: SimpleLessonScheduleRequest):
  Observable<SimpleScheduleEvents> {
    const url = this.prepareUrlV2(studentId) + '/schedules';
    return this.http.post<SimpleScheduleEvents>(url, request, {
      params: {
        type: "col_lesson"
      }
    }).pipe(
      tap( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate)))
      )
    )
  }
}
