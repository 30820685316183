import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {StudentCacheProxyService} from "../../../services/student-cache-proxy.service";
import {StudentRestService} from "../../../services/rest/student-rest.service";
import {StateAwareComponent} from "../../../model/rest/base";
import {StudentProgressComponentEvent} from "../student-progress/student-progress.component";
import {ApiCourseContext, ApiProductContext, ApiLessonInstanceBase} from "../../../model/rest/rest-model";
import {Dates} from "../../../utils/calendar-utils";

@Component({
  selector: 'app-student-context-based-progress',
  templateUrl: './student-context-based-progress.component.html',
  styleUrls: ['./student-context-based-progress.component.css']
})
export class StudentContextBasedProgressComponent extends StateAwareComponent<StudentProgressComponentEvent> implements OnInit, OnDestroy {

  @Input()
  set studentId(value: number) {
    this._studentId = value;
    this.fireUpdate();
  }
  get studentId(){return this._studentId;}
  @Input() isTutorial: boolean
  @Input() isShowReserveProva: boolean
  @Output() reserveProvaEvent = new EventEmitter
  _studentId: number;
  private _lang: string;
  private courseContexts: ApiCourseContext[];
  private currentIndex: number = null;
  @Input()
  public set langCode(lang: string) {
    this._lang = lang;
    this.fireUpdate();
  }

  constructor(
    private studentCache: StudentCacheProxyService,
    private studentRest: StudentRestService) {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  private fireUpdate() {
    if (!this.studentId || !this._lang) return;
    this.eventLoading();
    this.studentRest.findProductContext(this.studentId, this._lang).subscribe(
      {
        next: context => {
          this.eventLoaded();
          this.afterDataLoad(context);
        },
        error: () => this.eventLoaded()
      }
    )
  }

  private afterDataLoad(context: ApiProductContext) {
    this.currentIndex = null;
    if (!context) return;
    this.courseContexts = context.courseContexts;
    if (this.courseContexts.length > 0) {
      this.currentIndex = this.courseContexts.length - 1;
    }
  }

  hasAnyProgress() {
    return this.currentIndex != null;
  }

  getCurrentCourseName() {
    return this.getContext().course.name;
  }

  hasPrev() {
    return this.currentIndex != null && this.currentIndex > 0;
  }

  hasNext() {
    return this.currentIndex != null && this.currentIndex < this.courseContexts.length - 1;
  }

  moveCourse(number: number) {
    const prevValue = this.currentIndex;
    this.currentIndex += number;
    if (this.currentIndex < 0 || this.currentIndex >= this.courseContexts.length) {
      this.currentIndex = prevValue;
    }
  }

  hasNoProgress() {
    return this.loaded && this.currentIndex == null;
  }

  getProgress() {
    const result = this.getContext().lessonsProgress;
    return result != null ? result * 100 : 0;
  }

  getRevisionProgress() {
    return this.getContext().revisionProgress ? this.getContext().revisionProgress * 100 : 0;
  }

  getQuickStageRevisionProgress() {
    if (!this.hasQs()) return 0;
    if (this.hasAnyLesson()) return 100;
    return this.getContext().quickStageRevisionProgress ? this.getContext().quickStageRevisionProgress * 100 : 0;
  }

  getLessonsUntilPromotion() {
    return this.getContext().estimatedUntilFinish;
  }

  hasAnyLesson() {
    return this.getContext().lessons > 0;
  }

  private static getLessonStartDate(lesson: ApiLessonInstanceBase) {
    if (!lesson || !lesson.lessonMetric || !lesson.lessonMetric.started) return null;
    return Dates.parse(String(lesson.lessonMetric.started));
  }

  getFirstLessonDate() {
    return StudentContextBasedProgressComponent.getLessonStartDate(this.getContext().firstLesson);
  }

  private getContext() {
    return this.courseContexts[this.currentIndex];
  }

  getNumberOverallLessons() {
    return this.getContext().overall;
  }

  getLastLessonDate() {
    const context = this.getContext();
    const allDates = [
      this.getLastExamDate(),
      StudentContextBasedProgressComponent.getLessonStartDate(context.lastQuickStageRevision),
      StudentContextBasedProgressComponent.getLessonStartDate(context.lastRegularLesson),
      StudentContextBasedProgressComponent.getLessonStartDate(context.lastRevision)
    ].filter( el => el != null)
      .sort((l,r) => l.getTime() - r.getTime());
    if (allDates.length === 0) return null;
    return allDates[allDates.length - 1];
  }

  getQuickStageRevisions() {
    return this.getContext().quickStageRevisions;
  }

  getRegular() {
    return this.getContext().lessons;
  }

  getLastExamDate() {
    return StudentContextBasedProgressComponent.getLessonStartDate(this.getContext().lastExam);
  }

  private hasQs() {
    const context = this.getContext();
    if (!context) return false;
    if (context.quickStageRevisions > 0) return true;
    return false;
  }

  getQsProgressWidth() {
    const progress = this.getQuickStageRevisionProgress();
    return this.widthStyle(progress);
  }

  getExamProgressWidth() {
    const progress = this.getContext().exams ? 100 : 0;
    return this.widthStyle(progress);
  }

  getRevisionProgressWidth() {
    const progress = this.getRevisionProgress();
    return this.widthStyle(progress);
  }

  getLessonProgressWidth() {
    const progress = this.getProgress();
    return this.widthStyle(progress);
  }

  private widthStyle(width: number) {
    return {width: `${width}%`};
  }

  getExamProgressDescription() {
    return this.getContext().exams ? '✓' : '---';
  }

  reserveProvaClicked() {
    this.reserveProvaEvent.emit();
  }
}
