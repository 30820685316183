<div class="card mt-2">
  <div class="card-body">
      <app-reports-filter withLessonType="true" withLessonStatus="true" withLessonFlag="true" (generateReport)="updateReport($event)"></app-reports-filter>
  </div>
</div>
<div class="card mt-2">
  <div class="card-body">
    <app-lessons-report #reportView [schoolId]="schoolId"></app-lessons-report>
  </div>
</div>
