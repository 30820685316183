import { ValidatorFn, AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, Input } from '@angular/core';


@Directive({
    selector: '[appAfterDate]',
    providers: [{provide: NG_VALIDATORS, useExisting: AppAfterDateValidatorDirective, multi: true}]
  })
  export class AppAfterDateValidatorDirective implements Validator {
    @Input('appAfterDate')
    appAfterDate: Date;

    validate(control: AbstractControl): {[key: string]: any} | null {
      return this.appAfterDate ? dateAfter(this.appAfterDate)(control)
        : null;
    }
  }

export function dateAfter(date: Date): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {

        let forbidden = true;
        const value = control.value;
        if (value) {
            if (new Date(value).getTime() > new Date(date).getTime()) {
                forbidden = false;
            }
        }
        return forbidden ? {'invalidDate': {value: control.value}} : null;
      };

}
