<div class="form-group search-group" *ngIf="!student">
  <label class="control-label">Search student by name:</label>
  <div class="input-group">
    <input class="form-control" #studentSearch placeholder="search for a student..." (input)="searchStudent(studentSearch.value)" (blur)="clearStudents()">
    <div class="input-group-append">
      <span class="input-group-text" ><i class="fa fa-search"></i></span>
    </div>
  </div>
  <ul class="input-results">
    <li *ngFor="let student of students" (mousedown)="chooseStudent(student)">
        <a class="small">{{student.person.name}} {{student.person.surname}}</a>
    </li>
  </ul>
</div>
<div class="card mt-2" *ngIf="student">
  <div class="card-body">
    <form>
      <div class="form-row">
        <p>Student details:</p>
      </div>
      <div class="form-row">
        <div class="form-group col-12 col-sm-6">
          <label>Name</label>
          <input 
            type="text"
            [(ngModel)]="student.person.name"
            name="studentName"
            readonly
            class="form-control">
        </div>
        <div class="form-group col-12 col-sm-6">
            <label>Surname</label>
            <input 
              type="text"
              name="studentSurname"
              readonly
              [(ngModel)]="student.person.surname"
              class="form-control">
          </div>
      </div>
    </form>
  </div>
</div>
