import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { StudentContextService } from 'src/app/services/ctx/student-context.service';
import { ApiTeacherProfile } from 'src/app/model/rest/rest-model';
import { PersonNameExtractor, ProfilePhotoUrlExtractor } from 'src/app/utils/profile-photo-url-extractor';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { StaticPagesUrlProviderService } from 'src/app/services/shared/url-provider.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-student-teacher-list',
  templateUrl: './student-teacher-list.component.html',
  styleUrls: ['./student-teacher-list.component.css']
})
export class StudentTeacherListComponent implements OnInit , OnDestroy {

  _availableTeachersIds: number[];
  teachers: ApiTeacherProfile[];
  _selectedTeacherIds = [];

  @Input()
  langCode: string;

  @Output()
  selectedTeachersUpdate = new EventEmitter<number[]>();

  teachersUrlSubscription: Subscription;
  teachersUrl: { urlBase: string; productCode: string; };

  constructor(private studentContext: StudentContextService,
    teachersUrlProvider: StaticPagesUrlProviderService) {
    this.teachersUrlSubscription = teachersUrlProvider.teachersBaseSubject.subscribe( url => this.teachersUrl = url );
  }

  ngOnDestroy(): void {
    this.teachersUrlSubscription.unsubscribe();
  }

  @Input()
  set availableTeachersIds(teachersIds: number[]) {
    this._availableTeachersIds = teachersIds;
    this.updateTeachersList();
  }

  selectTeacher(profile: ApiTeacherProfile) {
    if (!profile) {
      this._selectedTeacherIds = [];
      this.selectedTeachersUpdate.emit(this._selectedTeacherIds);
      return;
    }
    const teacherFoundIndex = this._selectedTeacherIds.indexOf(profile.teacher.id);
    if (teacherFoundIndex >= 0) {
      this._selectedTeacherIds.splice(teacherFoundIndex, 1);
      this.selectedTeachersUpdate.emit(this._selectedTeacherIds);
      return;
    }
    this._selectedTeacherIds.push(profile.teacher.id);
    this.selectedTeachersUpdate.emit(this._selectedTeacherIds);
  }

  updateTeachersList(): any {
    if (this._availableTeachersIds == null || this._availableTeachersIds === undefined) {
      this.teachers = null;
    }
    this.studentContext.findTeachers(...this._availableTeachersIds)
    .subscribe(
      teachers => this.teachers = teachers
    );
  }

  getTeacherDescription(teacherProfile: ApiTeacherProfile) {
    const competence = teacherProfile.competences.find( c => c.product.code === this.langCode );
    if (!competence || !competence.productCompetenceDescription) {return ''; }
    let res = competence.productCompetenceDescription;
    if (res.length > 120 ) {
      res = res.slice(0, 120);
    }
    return res;
  }

  getTeacherDetailsUrl(teacherProfile: ApiTeacherProfile) {
     return this.teachersUrl.urlBase + '/' + teacherProfile.teacher.id + '?productCode=' + this.teachersUrl.productCode;
  }

  getTeacherName(teacherProfile: ApiTeacherProfile) {
    if (!teacherProfile || !teacherProfile.teacher) {
      return PersonNameExtractor.getPersonName(null);
    }
    return PersonNameExtractor.getPersonName(teacherProfile.teacher.person);
  }

  getTeacherPhoto(teacherProfile: ApiTeacherProfile) {
    if (!teacherProfile || !teacherProfile.teacher) {
      return ProfilePhotoUrlExtractor.getPersonProfilePhoto(null);
    }
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(teacherProfile.teacher.person);
  }

  isActive(teacher: ApiTeacherProfile) {
    return this._selectedTeacherIds.indexOf(teacher.teacher.id) >= 0
  }
  
  ngOnInit() {
  }

}
