import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthServiceProvider} from "./auth.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
/*
Authorization guard
*/
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthServiceProvider) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.authService.get().pipe(
          map(api => {
            if(api.isTokenValid()) return true
            api.logout()
            return false
          })
        )
    }
}
