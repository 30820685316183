import { Component, OnInit } from '@angular/core';
import { HttpErrorsService } from '../../services/col2/http-errors.service';
import { ErrorBase } from '../../model/errors/ErrorBase';
import { Observable, throwError , Observer, Subscription} from 'rxjs';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';


@Component({
  selector: 'app-http-error-message',
  templateUrl: './http-error-message.component.html',
  styleUrls: ['./http-error-message.component.css'],
  animations: [
  trigger('errorBoxState', [
    state('hide', style({
      transform: 'translateY(100%)',
      opacity: '0'
    })),
    state('show',   style({
      transform: 'translateY(0)',
      opacity: '1'
    })),
    transition('hide => show', animate('400ms ease-in')),
    transition('show => hide', animate('400ms ease-out'))
  ])
]
})
/*
Component registers as errors observer on the error service (which is incorporating with the interceptor)
*/

export class HttpErrorMessageComponent implements OnInit {

  constructor(private errorsService: HttpErrorsService) { }
  error: ErrorBase;
  errorsToShow: ErrorBase[] = [];
  currentUpdateTimer: any;
  state = 'hide';

  handleError(error: ErrorBase) {
    this.errorsToShow.push(error);
    this.showNextError();
  }

  displayError(error: ErrorBase) {
    this.error = error;
    this.state = 'show';
  }

  hideError() {
    this.error = null;
    this.state = 'hide';
  }

  showNextError() {
    if (this.currentUpdateTimer) {
      return;
    }
    if (this.errorsToShow.length === 0) {
      return;
    }
    this.displayError(this.errorsToShow[0]);

    this.currentUpdateTimer = setTimeout(() => {
      this.hideError();
      this.errorsToShow.splice(0, 1);
      this.currentUpdateTimer = null;
      this.showNextError();
    }, 4000);
  }

  ngOnInit() {
    this.errorsService.errors$.subscribe(err => {
      this.handleError(err);
    });
  }

}
