<div class="card-body">
    <ng-template [ngLoading]="!loaded" type="spinner">
        <div class="row align-items-end g-0 ">
            <div class="stage-list-container col">
                <div class="stage-list">
                <app-chapter-progress-circle
                    *ngFor="let avChapter of getAvailabileChapters()"
                    [chapter]="avChapter"
                    [availability]="getChapterAvailability(avChapter)"
                    (chapterClick)="onChapterClick(avChapter)"
                    [ngClass]="getChapterSelectionClass(avChapter)">
                </app-chapter-progress-circle>
                </div>
            </div>

        </div>
        <div class="text-center mt-2">
            <div class="row g-0 align-items-center">
                <div class="col-auto col-sm-2 text-end">
                    <a [hidden]="!hasPrev()" class="stage-btn cursor-pointer text-muted" 
                        (click)="onFooterInteract('left')"  tabindex="" appKidsRotation>
                        <i class="fas fa-chevron-left"></i>
                    </a>
                </div>
                <div class="col text-center">
                    <h2>{{getChapterName()}}</h2>
                </div>
                <div class="col-auto col-sm-2 text-start">
                    <a [hidden]="!hasNext()" class="stage-btn cursor-pointer text-muted" 
                        (click)="onFooterInteract('right')"  tabindex="" appKidsRotation>
                        <i class="fas fa-chevron-right"></i>
                    </a>
                </div>
            </div>

            <app-chapter-details
                [chapter]="getSelectedChapter()"
                [availabilities]="getAvailabilities()"
                (exerciseSelect)="onExerciseSelect($event)"
            ></app-chapter-details>
        </div>
    </ng-template>
</div>
