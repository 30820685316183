import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd } from '@angular/router';

export class BreadcrumEntry {
  name: string;
  url: string;

  constructor(name: string, url: string) {
    this.name = name;
    this.url = url;
  }
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: BreadcrumEntry[] = [];
  _studentId: number;

  constructor(
    private router: Router,
    ) {
      this.breadcrumbs.push(null);
      this.router.events.subscribe( e => {
        if (!(e instanceof ActivationEnd)) {return; }
        const breadcrumbArray = e.snapshot.data['breadcrumb'] as BreadcrumEntry[];
        if (breadcrumbArray === undefined) {return; }
        this.breadcrumbs.splice(1, this.breadcrumbs.length - 1);
        if (breadcrumbArray == null ) {
          return;
        } else {
          breadcrumbArray.map( be => {
            const mapped = new BreadcrumEntry(be.name, be.url);
            if (mapped.url) {
              mapped.url = '/student/' + this._studentId + mapped.url;
            }
            return mapped;
          }).forEach (
            be => this.breadcrumbs.push(be)
          );
        }
      });
  }

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    if (!studentId) {return; }
    // TODO: extract it somehow to avoid translations
    this.breadcrumbs[0] = new BreadcrumEntry('Dashboard', '/student/' + studentId + '/dashboard');
  }

  ngOnInit() {
  }

}
