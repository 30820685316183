import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Chapter, ExerciseSet} from "../../model/rest/cspa/struct";
import {ExerciseSession,  ItemAvailability} from "../../model/rest/cspa/personal";

export interface NewCspaRestService {
  listExerciseSets(): Observable<ExerciseSet[]>;
  listChapters(path: string, updatedAfter?: number): Observable<Chapter[]>;
  startExerciseSession(exercisePath: string): Observable<ExerciseSession>;
  startExerciseSessionById(exerciseId: number): Observable<ExerciseSession>;
  listAvailabilities(path: string, depth: number): Observable<ItemAvailability[]>;
}

@Injectable({
  providedIn: 'root'
})
export class CspaRestServiceNew implements NewCspaRestService {

  private apiEndpoint = environment.cspaApiEndpoint;
  constructor(private http: HttpClient) {
  }

  private buildPath(path: string) {
    return this.apiEndpoint + '/api' + path;
  }

  public listExerciseSets(): Observable<ExerciseSet[]> {
    return this.http.get<ExerciseSet[]>(this.buildPath('/sets'));
  }

  /** not used in standard context */
  startExerciseSessionById(exerciseId: number): Observable<ExerciseSession> {
    throw new Error('Method not supported');
  }

  public listChapters(path: string, updatedAfter?: number): Observable<Chapter[]> {
    let params = new HttpParams();
    if (updatedAfter != null) {
      params = params.append('updatedAfter', updatedAfter.toString());
    }
    return this.http.get<Chapter[]>(this.buildPath('/sets/' + path) , {params});
  }

  public startExerciseSession(exercisePath: string): Observable<ExerciseSession> {
    return this.http.post<ExerciseSession>(this.buildPath('/sessions/create'), exercisePath);
  }

  public listAvailabilities(path: string, depth: number): Observable<ItemAvailability[]> {
    const params = new HttpParams().append('path', path).append('depth', depth.toString());
    return this.http.get<ItemAvailability[]>(this.buildPath('/availability'), {params});
  }

}
