<div class="card mt-2 mb-2" >
  <div class="card-header">
    <app-pagination [pageableComponent]="futureLessons" class="float-end"></app-pagination>
    Future lessons <span class="badge bg-primary">{{futureLessons.getTotalResults()}}</span>
  </div>
  <div class="card-body">
    <app-teacher-lessons #futureLessons (lessonDetailsEvent)="openLessonDetails($event)" componentRole="manager" [schoolId]="schoolId" [teacherId]="teacherId" [lessonType]="lessonTypes.Upcoming"></app-teacher-lessons>
  </div>
</div>

<div class="card mb-2" >
  <div class="card-header">
    <app-pagination [pageableComponent]="incompleteLessons" class="float-end"></app-pagination>
    Incomplete lessons <span class="bg-primary badge">{{incompleteLessons.getTotalResults()}}</span>
  </div>
  <div class="card-body">
    <app-teacher-lessons #incompleteLessons (lessonDetailsEvent)="openLessonDetails($event)" componentRole="manager" [schoolId]="schoolId" [teacherId]="teacherId" [lessonType]="lessonTypes.Incomplete"></app-teacher-lessons>
  </div>
</div>

<div class="card mb-2" >
  <div class="card-header">
    <app-pagination [pageableComponent]="pastLessons" class="float-end"></app-pagination>
    Past lessons <span class="badge bg-primary">{{pastLessons.getTotalResults()}}</span>
  </div>
  <div class="card-body">
    <app-teacher-lessons #pastLessons (lessonDetailsEvent)="openLessonDetails($event)"componentRole="manager" [schoolId]="schoolId" [teacherId]="teacherId" [lessonType]="lessonTypes.Past"></app-teacher-lessons>
  </div>
</div>
