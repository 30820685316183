import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { ApiStudentSchedule, ApiTeacherWorktime } from 'src/app/model/rest/booking-rest.model';
import { ApiCourseProduct, ApiCompetence, ApiLearningUnitTeacher } from 'src/app/model/rest/rest-model';
import { Dates } from 'src/app/utils/calendar-utils';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StudentBookRestOldService {
  schedules: Array<Array<ApiStudentSchedule>> = [];
  constructor(private http: HttpClient) { }
  mocked = true;

  prepareUrl(studentId: number): string {
    return environment.bookingEndpoint + '/student/' + studentId;
  }

  listTeachersWeekAvailability(studentId: number, focusDate: Date, productCode: string):
    Observable<ApiTeacherWorktime[]> {
    const url = this.prepareUrl(studentId) + '/availability/product/' + productCode ;
    let mocked = this.mocked;
    mocked = false;
    if (!mocked) {
      return this.http.get<ApiTeacherWorktime[]>(url, {
        params: {
          'focusDate': focusDate.toISOString()
        }
      }).pipe(
        // fix dates
        tap( worktimes => worktimes.forEach ( worktime => worktime.startDate = Dates.parse(String(worktime.startDate)))),
        // map to instance of ApiTeacherWorktime
        map( worktimes => worktimes.map ( worktime => Object.assign(new ApiTeacherWorktime(), worktime)) )
      );
    }

    const res: ApiTeacherWorktime[] = [];
    for (let i = -7; i < 7; i++) {
      let wt = new ApiTeacherWorktime();
      wt.startDate = new Date();
      wt.startDate.setTime(focusDate.getTime() + i * 1000 * 60 * 60 * 24);
      wt.startDate.setHours(8);
      wt.startDate.setMinutes(0);
      wt.duration = 1000 * 60 * 60 * 8;
      wt.competences = [new ApiCompetence(), new ApiCompetence()];
      wt.competences[0].code = 'A';
      wt.competences[1].code = 'B';
      wt.product = new ApiCourseProduct();
      wt.product.code = 'en';
      wt.teacher = new ApiLearningUnitTeacher();
      wt.teacher.id = 1;
      res.push(wt);
      wt = new ApiTeacherWorktime();
      wt.startDate = new Date();
      wt.startDate.setTime(focusDate.getTime() + i * 1000 * 60 * 60 * 24);
      wt.startDate.setHours(8);
      wt.startDate.setMinutes(0);
      wt.duration = 1000 * 60 * 60 * 8;
      wt.competences = [new ApiCompetence(), new ApiCompetence()];
      wt.competences[0].code = 'A';
      wt.competences[1].code = 'B';
      wt.product = new ApiCourseProduct();
      wt.product.code = 'en';
      wt.teacher = new ApiLearningUnitTeacher();
      wt.teacher.id = 2;
      res.push(wt);
    }

    return of(res);
  }

  listStudentWeekSchedules(studentId: number, focusDate: Date):
    Observable<ApiStudentSchedule[]> {
    const url = this.prepareUrl(studentId) + '/schedules';
    let mocked = this.mocked;
    mocked = false;
    if (!mocked) {
      return this.http.get<ApiStudentSchedule[]>(url, {
        params: {
          'focusDate': focusDate.toISOString()
        }
      }).pipe(
        // convert dates
        tap ( schedules => schedules.forEach(schedule => schedule.startTime = Dates.parse(String( schedule.startTime )))),
      );
    }

    const week = Dates.dateToWeekNumber(focusDate);
    if (!this.schedules[week]) {
      this.schedules[week] = [];
    }
    return of(this.schedules[week]);
  }

  reserveSchedule(studentId: number, schedule: ApiStudentSchedule):
    Observable<ApiStudentSchedule> {
    const url = this.prepareUrl(studentId) + '/schedules';
    let mocked = this.mocked;
    mocked = false;
    if (!mocked) {
      return this.http.put<ApiStudentSchedule>(url, schedule);
    }

    const week = Dates.dateToWeekNumber( schedule.startTime);
    if (!this.schedules[week]) {
      this.schedules[week] = [];
    }
    this.schedules[week].push(schedule);
    return of(schedule);
  }
}
