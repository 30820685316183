<div class="row">
  <div class="col-12">
    <div class="card mb-2 px-0">
      <div class="card-body pb-0 pt-2">
        <app-student-main-calendar [filterTeachersIds]="filterTeachersIds" (availableTeachersIdsUpdate)="updateAvailableTeachers($event)" [studentId]="studentId" [langCode]="langCode" [_introductionState]="_introductionState"></app-student-main-calendar>
      </div>
      <div class="p-2 border-top border-secondary">
        <app-student-teacher-list #studentCalendar [langCode]="langCode" (selectedTeachersUpdate)="updateTeachersFilter($event)" [availableTeachersIds]="availableTeachersIds"></app-student-teacher-list>
      </div>
    </div>
  </div>
</div>
<div class="card mb-2">
  <!-- <div class="card-header">
      <span i18n="@@student-reserve-lesson-page.credits.title">My credits</span>
      <button class="btn btn-primary btn-sm float-end" routerLink="/student/{{studentId}}/buy-courses/product/{{langCode}}" i18n="@@student-reserve-lesson-page.credits.buy-more.btn">Buy More</button>
  </div>
  <div class="card-body"> -->
    <app-student-credits #studentCreditsComponent [langCode]="langCode" [studentId]="studentId"></app-student-credits>
  <!-- </div> -->
</div>

<app-modal #freeCreditModal id="free-credit-modal">
  <div class="modal-content bg-primary border-0">
    <div class="modal-body">
      <p class="mb-0" style="color: #ffffff;" i18n='@@induction-box.modal-calendar'>You have received a free lesson credit. You can now book your free trial lesson via the booking calendar.</p>
      <button class="btn btn-sm btn-outline-accent rounded-pill px-3 float-end" i18n="@@common.ok" (click)="closeCalendarPopover()">Ok</button>
    </div>
  </div>
</app-modal>
