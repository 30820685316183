
<app-lesson-confirmation *ngIf="isShowConfirmation()" [studentId]="studentId"
[lessonId]="lessonId" (interact)="onLessonConfirmationInteract($event)"></app-lesson-confirmation>

<div *ngIf="!isShowConfirmation()" class="text-center">
  <h1 class="tutorial-intro">
    Ok, your trial lesson booking was cancelled.<br>
    <strong>Would you like to book another date now?</strong>
  </h1>
  <div class="mt-5" >
    <a routerLink="/student/{{studentId}}/lessons/reserve" class="btn btn-primary btn-lg mb-4 mt-5">Yes, take me to the calendar please.</a><br>
    <a routerLink="/student/{{studentId}}/dashboard" class="btn btn-danger">Thanks, I'll do it later.</a>
  </div>
</div>
