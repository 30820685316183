import { Injectable } from '@angular/core';
import {StudentBookRestService} from "./student-book-rest.service";
import {
  ApiLessonEventModel, EventReference, EventTime, LessonReference,
  LessonScheduleEventReference, ProductAvailabilityDetails, ScheduleReference, SimpleLessonScheduleRequest,
  SimpleScheduleEvents, SimpleTeacherProductAvailability, TeacherReference
} from "../../model/rest/booking-rest-v2";
import {ApiStudentSchedule, ApiTeacherWorktime} from "../../model/rest/booking-rest.model";
import {Observable, of} from "rxjs";
import {StudentBookRestOldService} from "./student-book-rest-old.service";
import {map, switchMap} from "rxjs/operators";
import {ApiCompetence, ApiLearningUnitTeacher, ApiLessonInstance, ApiProductContext} from "../../model/rest/rest-model";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class StudentBookRestBridgeService implements StudentBookRestService {

  constructor(private restOld: StudentBookRestOldService,
              private http: HttpClient) {}

  getLessonScheduleEventReference(studentId: number, scheduleId: number): Observable<LessonScheduleEventReference<ApiLessonEventModel>> {
    let res = new LessonScheduleEventReference<ApiLessonEventModel>()
    res.eventData = new ApiLessonEventModel()
    res.eventData.lesson = new LessonReference()
    res.eventData.lesson.lessonId = scheduleId
    return of(res);
  }

  listStudentLessonSchedules(studentId: number, focusDate: Date, productCode: string): Observable<SimpleScheduleEvents[]> {
    return this.restOld.listStudentWeekSchedules(studentId, focusDate).pipe(
      map(res => res.map(wt => this.apiStudentScheduleSimpleScheduleEvents(wt)))
    )
  }

  listStudentSchoolTeachersAvailability(studentId: number, focusDate: Date, productCode: string): Observable<SimpleTeacherProductAvailability[]> {
    return this.restOld.listTeachersWeekAvailability(studentId, focusDate, productCode).pipe(
      map(res => res.map(wt => this.apiTeacherWorktimeToSimpleTeacherProductAvailability(wt)))
    )
  }

  reserveColSchedule(studentId: number, request: SimpleLessonScheduleRequest): Observable<SimpleScheduleEvents> {
    return this.findProductContext(studentId, request.course.product.code)
      .pipe(
        map ( context => context ? context : new ApiProductContext()),
        map(ctx => this.simpleLessonScheduleRequestToApiStudentSchedule(request, ctx)),
        switchMap(req => this.restOld.reserveSchedule(studentId, req)),
        map(res => this.apiStudentScheduleSimpleScheduleEvents(res))
    )
  }

  private findProductContext(studentId: number, productCode: string) {
    const url = environment.apiEndpoint + '/student/' + studentId + '/progress/' + productCode + '/context';

    return this.http.get<ApiProductContext>(url);
  }

  apiTeacherWorktimeToSimpleTeacherProductAvailability(src: ApiTeacherWorktime) {
    let details = new ProductAvailabilityDetails()
    details.product = src.product
    details.competences = src.competences
    details.overtake = src.minimumOvertake

    let event = new EventTime()
    event.eventDate = src.startDate
    event.duration = src.duration

    let target = new SimpleTeacherProductAvailability()
    target.teacher = new TeacherReference()
    target.teacher.id = src.teacher.id
    target.details = details
    target.events = new Array<EventReference>()
    target.events.push(event)
    return target
  }

  simpleLessonScheduleRequestToApiStudentSchedule(src: SimpleLessonScheduleRequest, ctx: ApiProductContext) {
    let target = new ApiStudentSchedule()
    target.startTime = src.time.starting
    target.duration = src.duration
    target.lesson = new ApiLessonInstance();
    target.lesson.lessonType = ctx.nextLessonType;
    target.course = src.course
    target.competence = src.competence
    target.teacher = this.teacherReferenceToApiLearningUnitTeacher(src.teacher)
    target.timezone = src.time.timezone.toString()
    return target
  }

  apiStudentScheduleSimpleScheduleEvents(src: ApiStudentSchedule) {
    let event = new EventReference()
    event.eventDate = src.startTime
    event.duration = src.duration

    let details = new ProductAvailabilityDetails()
    details.product = src.course.product
    details.competences = new Array<ApiCompetence>(src.competence)

    let target = new SimpleScheduleEvents()
    target.schedule = new ScheduleReference()
    target.schedule.id = src.lesson.id
    target.events = new Array<EventReference>()
    target.events.push(event)
    return target
  }

  teacherReferenceToApiLearningUnitTeacher(src: TeacherReference) {
    let target = new ApiLearningUnitTeacher()
    target.id = src.id
    return target
  }
}
