import { Component, ComponentFactoryResolver, Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'placeholder',
  template: `
    <span class="placeholder rounded"></span>
  `
})
class Placeholder {
  constructor(private renderer: Renderer2, private elRef: ElementRef) {
    this.elRef.nativeElement.className= 'placeholder-glow me-3'
  }
}

@Component({
  selector: 'spinner',
  template: `
    <div class="spinner-border text-secondary m-auto">
      <span class="visually-hidden">Loading...</span>
    </div>
  `
})
class Spinner {
  constructor(private renderer: Renderer2, private elRef: ElementRef) {
    this.elRef.nativeElement.className= 'd-flex'
  }
}

@Directive({
  selector: '[ngLoading]'
})
export class LoadingDirective{

  @Input() public set ngLoading(isLoading: boolean) {
    if(isLoading)
    {
      this.viewContainer.clear();
      if(this.type === "spinner") {
        const spinner = this.componentFactoryResolver.resolveComponentFactory(Spinner)
        this.viewContainer.createComponent(spinner);    
      }
      //default loading - placeholder
      else {
        const placeholder = this.componentFactoryResolver.resolveComponentFactory(Placeholder)
        this.viewContainer.createComponent(placeholder);    
      }
  }
    else {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
  @Input() type: string

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
  }
}
