<div class="alert alert-danger" *ngIf="missingTeacherProfile">
  Your teacher profile is missing, please go to the <a href="#" routerLink="/teacher/{{teacherId}}/profile">Teacher profile</a>.
</div>

<div class="card mb-4 mb-md-5">
  <div class="card-body">
    <app-teacher-main-calendar [teacherId]="teacherId"></app-teacher-main-calendar>
  </div>
</div>

<div class="card mb-4 mb-md-5">
  <div class="card-header">
    Quick Lesson Report
  </div>
  <div class="card-body">
    <app-teacher-quick-lesson-counter [teacherId]="teacherId"></app-teacher-quick-lesson-counter>
  </div>
</div>

<div class="card mb-4 mb-md-5" [hidden]="futureLessonsLoading">
  <div class="card-header">
    Future lessons 
    <span class="badge bg-primary" *ngIf="upcomingLessons.getTotalResults()">
      {{upcomingLessons.getTotalResults()}}
    </span>
  </div>
  <div class="card-body">
    <app-teacher-lessons 
      #upcomingLessons
      (lessonDetailsEvent)="openLessonDetails($event)"
      componentRole="teacher"
      (stateEvent)="onFutureLessonsEvent($event)"
      [teacherId]="teacherId"
      [lessonType]="lessonTypes.Upcoming"
    ></app-teacher-lessons>
  </div>
  <div class="card-footer">
    <app-pagination [pageableComponent]="upcomingLessons"></app-pagination>
  </div>
</div>

<div class="card mb-4 mb-md-5" [hidden]="incompleteLessonsLoading">
  <div class="card-header">
    Lesson details incomplete <span class="badge bg-primary">{{incompleteLessons.getTotalResults()}}</span>
  </div>
  <div class="card-body">
    <app-teacher-lessons #incompleteLessons (lessonDetailsEvent)="openLessonDetails($event)" componentRole="teacher" (stateEvent)="onIncompleteLessonsEvent($event)" [teacherId]="teacherId" [lessonType]="lessonTypes.Incomplete"></app-teacher-lessons>
  </div>
  <div class="card-footer">
    <app-pagination [pageableComponent]="incompleteLessons"></app-pagination>
  </div>
</div>

  <div class="card mb-4 mb-md-5" [hidden]="pastLessonsLoading">
    <div class="card-header">
        Past lessons <span class="badge bg-primary">{{pastLessons.getTotalResults()}}</span>
    </div>
    <div class="card-body">
      <app-teacher-lessons #pastLessons (lessonDetailsEvent)="openLessonDetails($event)" componentRole="teacher" (stateEvent)="onPastLessonsEvent($event)" [teacherId]="teacherId" [lessonType]="lessonTypes.Past"></app-teacher-lessons>
    </div>
    <div class="card-footer">
      <app-pagination [pageableComponent]="pastLessons"></app-pagination>
    </div>
  </div>
