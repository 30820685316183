<div class="row border-top border-2 border-secondary mb-2 px-2 py-3">
  <div class="col-12 mb-2 d-flex d-lg-none">
    <div  class="rounded-circle img-thumbnail" style="width: 4rem; height: fit-content;">
      <div  class="profile-photo rounded-circle" [style.background-image]="'url(' + (commingLessonDetails.teacher?getPersonPhoto(commingLessonDetails.teacher.person):getPersonPhoto(null)) +')'">
      </div>
    </div>
    <div id="comming-lesson-dialog" class="d-lg-none ms-auto my-auto">
      <p *ngIf="isLessonGoingToStartRegulary()" class="text-center mb-0">
        <span i18n="comming lesson will start in|prephase for lesson time down counter@@student-comming-lesson.lesson-starts">Your lesson will start in:</span><br>
        <strong class="text-success">{{timePhrase}}</strong>
      </p>

      <p class="text-center" *ngIf="isTeacherMissingToStartLesson()">
          <span i18n="waiting for teacher for comming lesson|Information for the student if teacher didn't start a lesson in time yet@@student-comming-lesson.wating-for-teacher">Waiting for teacher</span><br/>
        <strong class="text-danger">{{timePhrase}}</strong>
      </p>

      <p class="text-center" *ngIf="isLessonReadyToStart()">
        <span i18n="teacher is ready for comming lesson|Teacher is telling he is ready@@student-comming-lesson.teacher-ready">I'm ready for our lesson.</span><br>
        <span *ngIf="mayEnterRoomBySkype()"><span i18n="teacher asks for a call|Teacher is asking student for a call by skype@@student-comming-lesson.call-me-by">Please call me by</span> <strong> {{getLessonSkypeAddress()}}</strong></span>
        <span *ngIf="mayEnterVideoRoom()" i18n="join our room|Ask student to join a video room@@student-comming-lesson.join-room">Please join our classroom.</span>
      </p>
    </div>
  </div>
  <div class="col-12 col-lg-4 col-xl-3 mb-2 mb-lg-0 d-flex border-lg-right border-secondary">
    <div  class="rounded-circle img-thumbnail d-none d-lg-block me-lg-3" style="width: 4rem; height: fit-content;">
      <div  class="profile-photo rounded-circle" [style.background-image]="'url(' + (commingLessonDetails.teacher?getPersonPhoto(commingLessonDetails.teacher.person):getPersonPhoto(null)) +')'">
      </div>
    </div>
    <div>
      <p class="text-muted f-14 mb-0">Teacher:</p>
      <h6 class="mt-0">{{getTeacherName()}}</h6>
    </div>
  </div>
  <div class="col-12 col-lg-2 col-xl-3 mb-2 mb-lg-0 border-lg-right border-secondary" *ngIf="hasCommingDetails()">
    <p class="text-muted f-14 mb-0">Course:</p>
    <span *ngIf="!isProva()">{{getCourseName()}}</span>
    <span *ngIf="isProva()" >
      <span i18n="@@common.langName">{getCourseLangName(), select, English {English} Spanish {Spanish} Business English {Business English} English For Children {English For Children}}</span>&nbsp;
      <span i18n="@@student-comming-lesson.prova-postfix">- PROVA</span>
    </span>
  </div>
  <div class="col-12 col-lg-2 col-xl-3 mb-2 mb-lg-0 border-lg-right border-secondary" *ngIf="hasCommingDetails()">
    <p class="text-muted f-14 mb-0">Planned to start:</p>
    <span>
        {{getPlannedStartDate() | date: 'dd MMM yyyy'}}
        {{getPlannedStartDate() | date: 'HH:mm'}}
    </span>
  </div>
  <div class="col-4 col-lg-3 d-none d-lg-block">
    <div id="comming-lesson-dialog_lg" class="text-muted f-14">
      <p *ngIf="isLessonGoingToStartRegulary()">
        <span i18n="comming lesson will start in|prephase for lesson time down counter@@student-comming-lesson.lesson-starts">Your lesson will start in:</span><br>
        <strong class="text-white">{{timePhrase}}</strong>
      </p>

      <p *ngIf="isTeacherMissingToStartLesson()">
          <span i18n="waiting for teacher for comming lesson|Information for the student if teacher didn't start a lesson in time yet@@student-comming-lesson.wating-for-teacher">Waiting for teacher:</span><br/>
        <strong class="text-white">{{timePhrase}}</strong>
      </p>

      <p *ngIf="isLessonReadyToStart()">
        <span i18n="teacher is ready for comming lesson|Teacher is telling he is ready@@student-comming-lesson.teacher-ready">I'm ready for our lesson.</span><br>
        <span *ngIf="mayEnterRoomBySkype()"><span i18n="teacher asks for a call|Teacher is asking student for a call by skype@@student-comming-lesson.call-me-by">Please call me by</span> <strong> {{getLessonSkypeAddress()}}</strong></span>
        <span *ngIf="mayEnterVideoRoom()" i18n="join our room|Ask student to join a video room@@student-comming-lesson.join-room">Please join our classroom.</span>
      </p>
    </div>
  </div>
  <div class="col-12 col-lg-3 mx-auto mt-4">
    <div id="comming-lesson-actions" class="row text-center flex-column">
      <div class="text-lg-right mx-auto mx-lg-0 mt-4 mt-lg-0 me-lg-3" *ngIf="mayEnterVideoRoom()" >
          <a class="btn btn-primary rounded-pill"
            id="comming-lesson-actions-classroom"
            [target]="getTarget()"
            tabindex=""
            (click)="handleEnterRoomEvent(roomUrl)"
            [href]="getRoomUrl()"
            i18n="enter the video room button@@student-comming-lesson.enter-room.btn"
          >
            Enter classroom
          </a>
          <a (click)="openSkypeHelp()"
            tabindex=""
            class="d-block"
            [target]="getTarget()"
            i18n="call teacher instructions@@student-comming-lesson.call-instructions.btn"
          >
            <small>Connection problems?</small>
          </a>
      </div>
      <div class="text-lg-right mx-auto mx-lg-0 mt-lg-0 me-lg-3" *ngIf="mayEnterRoomBySkype()">
          <a
          id="comming-lesson-actions-skype"
          class="btn btn-primary rounded-pill"
          tabindex=""
          [href]="getRoomUrl()"
          (click)="handleEnterRoomEvent(roomUrl)"
          tabindex=""
          i18n="call skype button@@student-comming-lesson.call-skype.btn">Call Skype</a>
          <a
        (click)="moveToInstuctions()"
        tabindex=""
        class="d-block"
        [target]="getTarget()"
        i18n="call teacher instructions@@student-comming-lesson.call-instructions.btn"
        ><small>What now?</small></a>
      </div>

      <span
        class="d-block w-100 text-muted"
        *ngIf="!mayEnterClassroom()"
        tabindex=""
        ><span i18n="@@student-comming-lesson.what-now">Classroom closed</span></span>
  </div>
  </div>
</div>

<app-modal #classroomFallback>
  <div class="modal-content">
    <div class="modal-header">

      <h5 class="modal-title" i18n="@@student-coming-lesson.skype-fallback-dialog.title">
        Connection problems?
      </h5>
      <button type="button" class="btn-close" (click)="classroomFallback.hide()"  aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <p>
        <span i18n="@@student-coming-lesson.skype-fallback-dialog.about-refresh">If you can’t see or hear the teacher in the Classroom, please refresh the page.</span>
        <i class="fas fa-redo ms-2"></i>
      </p>
      <p>
        <span i18n="@@student-coming-lesson.skype-fallback-dialog.about-teacher-skype">
          If it doesn't help, call them on Skype. The teacher’s Skype name is:
        </span>
        <a class="ms-2" [href]="getTeacherSkypeUrl()" >
          {{getTeacherSkype()}}
        </a>
      </p>
      <div class="text-end">
        <button type="button" class="btn btn-primary" (click)="classroomFallback.hide()" i18n="@@common.Ok">OK</button>
      </div>
    </div>
  </div>
</app-modal>
