import { Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, of, ReplaySubject } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { StudentContextService } from './ctx/student-context.service';
import { AppEventsService } from './ctx/app-events.service';
declare let gtag: Function;


export class Event {

  static CAT_ENG = 'engagement';
  static CAT_RESERV = 'reservations';
  static CAT_IND = 'induction';
  static CAT_LESSON = 'lessons';

  static ACT_ENG_LOGIN = 'login';
  static ACT_ENG_CHOOSE_PROD = 'choose_prod';
  static ACT_ENG_LANG_SELECT = 'lang_select';

  static ACT_LESSON_RESERVE = 'lessson_reserve';
  static ACT_LESSON_CANCEL = 'lesson_cancel';
  static ACT_LESSON_TEACHER = 'lesson_teacher';
  static ACT_LESSON_TIME = 'lesson_time';

  static ACT_IND_SAVE_LANG = 'ind_save_lang';
  static ACT_IND_CHOOSE_PROD = 'ind_choose_prod';
  static ACT_IND_SAVE_PROFILE = 'ind_save_profile';
  static ACT_IND_GET_CREDIT = 'ind_get_credit';
  static ACT_IND_CSPA = 'ind_cspa';
  static ACT_IND_CALENDAR_DATE = 'ind_calendar_date';
  static ACT_IND_OPEN_RESERVE_DIALOG = 'ind_open_reserve';
  static ACT_IND_CLOSE_RESERVE_DIALOG = 'ind_close_reserve';
  static ACT_IND_FILTER_TEACHERS = 'ind_filter_teachers';
  static ACT_IND_FINISH = 'ind_finish';

  static ACT_LS_ROOM = 'create_room';
  static ACT_LS_ENTER_ROOM = 'enter_room';
  static ACT_CONVERSION = 'conversion';

  public static catInductionWithLang(langCode: string) {
    return this.CAT_IND + '_' + langCode;
  }

  constructor(
    public eventCategory: string,
    public eventLabel?: string,
    public value?: number,
    public currency?: string,
    public transactionId?: string ) { }

    toGevent(sendTo: string) {
      const res = {};
      if (this.eventCategory) {
        res['event_category'] = this.eventCategory;
      }
      if (this.eventLabel) {
        res['event_label'] = this.eventLabel;
      }
      if (this.value !== undefined ) {
        res['value'] = this.value;
      }
      if (this.currency) {
        res['currency'] = this.currency;
      }
      if (this.transactionId) {
        res['transaction_id'] = this.transactionId;
      }
      res['send_to'] = sendTo;
      return res;
    }
}

export enum Group {
  ANALYTICS, ADS
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService  {

  private static mapGroupName(group: Group, trackingLabel?: string) {
    let result = environment.analyticsUA;

    if (group === undefined || group == null) {
      group = Group.ANALYTICS;
    }

    if (group === Group.ADS) {
      result = environment.adsTrackingCode;
    }

    if (trackingLabel) {
      result += '/' + trackingLabel;
    }

    return result;
  }

  handleTeacherLessonDetails(url: string): Observable<string> {
    if (url === '/my/teacher/lessons/details/students/details') {
      return of('/my/teacher/lessons/details');
    }
    return of(url);
  }

  constructor(router: Router, private appEvents: AppEventsService) {
    router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      this.mapUrlToAnalytics(event.urlAfterRedirects).pipe(
        flatMap( url => this.filterOauthEntryPoint(url)),
        flatMap( url => this.handleTeacherLessonDetails(url))
      ).subscribe(
        finalUrl => this.pageView(finalUrl)
      );
    });

    // translate langcode subject with skipping the first empty value
    appEvents.switchLangCode.subscribe(
      lang => this.event(Event.ACT_ENG_CHOOSE_PROD, new Event(Event.CAT_ENG, lang))
    );

  }

  public init(): void {
    gtag('js', new Date());
    gtag('config', environment.adsTrackingCode);
  }

  extractLangForStudentDashboard(url: string): Observable<string> {
    if (url !== '/my/student/details/dashboard') {
      return of(url);
    }

  }

  mapUrlToAnalytics(url: string): Observable<string> {
    let finalUrl = '/my' + url;
    // replace 'school/:id' with 'school'
    finalUrl = finalUrl.replace(/school\/\d+/g, 'school');
    finalUrl = finalUrl.replace(/student\/\d+/g, 'student');
    finalUrl = finalUrl.replace(/students\/\d+/g, 'students');
    finalUrl = finalUrl.replace(/lessons\/\d+/g, 'lessons/details');
    finalUrl = finalUrl.replace(/teacher\/\d+/g, 'teacher');
    return of(finalUrl);
  }


  filterOauthEntryPoint(finalUrl: string): Observable<string> {
    const oauthPrefix = '/my/oauth';
    if (!finalUrl.startsWith(oauthPrefix)) {
      return of(finalUrl);
    }

    return of(oauthPrefix);
  }


  pageView(url: string, group?: Group, trackingLabel?: string) {
    gtag('config', AnalyticsService.mapGroupName(group, trackingLabel), {'page_path': url});
  }

  event(action: string, event: Event, group?: Group, trackingLabel?: string) {
    const gEvent = event.toGevent(AnalyticsService.mapGroupName(group, trackingLabel));
    gtag('event', action, gEvent);
    /*
    console.log('event action: ', action);
    console.log(event);
    */
  }
}
