import {Component, Input, OnInit} from '@angular/core';
import {forkJoin, Observable, zip} from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {CspaRestServiceNew} from "../../../../services/rest/new-cspa-rest.service";
import {Chapter, ExerciseSet} from "../../../../model/rest/cspa/struct";
import {ChapterAvailability, ItemAvailability} from "../../../../model/rest/cspa/personal";
import {ExerciseSelectEvent} from "../chapter-details/chapter-details.component";
import {environment} from "../../../../../environments/environment";
import {StateAwareComponent} from "../../../../model/rest/base";
import {CspaSummaryComponentEvent} from "../../cspa-summary/cspa-summary.component";
import {WindowsManagerService} from "../../../../services/windows-manager.service";
import {AuthServiceProvider} from "../../../../services/col2/auth.service";
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'app-exercise-set-host',
  templateUrl: './exercise-set-host.component.html',
  styleUrls: ['./exercise-set-host.component.scss']
})
export class ExerciseSetHostComponent extends StateAwareComponent<CspaSummaryComponentEvent> implements OnInit {

  private _setPath: string;
  setDefinition: ExerciseSet;
  chaptersByPath: Map<string, Chapter>;
  availabilityByPath: Map<string, ItemAvailability>;
  availableChapters: Chapter[];
  selectedChapter: Chapter;
  prevChapter: Chapter;
  selectedIndex = 0;
  nextChapter: Chapter;

  @Input()
  set setPath(value: string) {
    this._setPath = value;
    console.log(`setting cspa course to ${value}`);
    this.reloadComponentData();
  }

  constructor(private rest: CspaRestServiceNew,
              private authService: AuthServiceProvider,
              private webSocket: WebSocketService,
              private windowManager: WindowsManagerService) {
    super()
  }

  reloadComponentData(){
    if (!this._setPath) return;
    this.eventLoading();
    console.log(`loading cspa data for ${this._setPath}`);
    this.loadExerciseSetData(this._setPath)
      .subscribe( _ => {
        this.eventLoaded();
        this.selectChapter(this.findTheLatestChapter());
      })
  }

  selectChapter(path: string) {
    console.log(`selecting the chapter ${path}`);
    this.prevChapter = this.nextChapter = this.selectedChapter = null;
    // iterate to find the path and fill previous and next elements also
    for (const chapter of this.availableChapters) {
      if (chapter.path === path) {
        this.selectedChapter = chapter;
        continue;
      }
      if (this.selectedChapter) {
        this.nextChapter = chapter;
        break;
      }
      this.prevChapter = chapter;
    }
    this.selectedChapter = this.chaptersByPath.get(path);
    this.selectedIndex = this.availableChapters.indexOf(this.selectedChapter);

    console.log(`chapter selected ${this.selectedChapter}, with index ${this.selectedIndex}`);
  }

  getChapterSelectionClass(chapter: Chapter) {
    if (chapter === this.selectedChapter) {
      return 'selected';
    } else if (chapter === this.prevChapter) {
      return 'prev';
    } else if (chapter === this.nextChapter) {
      return 'next';
    }
    if (this.availableChapters.indexOf(chapter) < this.selectedIndex) {
      return 'left';
    } else {
      return 'right';
    }
  }

  findTheLatestChapter(): string {
    console.log(`searching for the latest chapter.`);
    const res = this.availableChapters?.map( ch => this.availabilityByPath.get(ch.path))
    ?.filter( av => av != null)
    ?.sort( (l, r) => {
        const lCasted = l as ChapterAvailability;
        const rCasted = r as ChapterAvailability;
        const lSubmit = lCasted.lastSubmit ? lCasted.lastSubmit : 0;
        const rSubmit = rCasted.lastSubmit ? rCasted.lastSubmit : 0;
        return rSubmit - lSubmit;
      }
    )[0]?.path;
    console.log('found the latest chapter: ${res}');
    return res;
  }

  /* events handling */
  onChapterClick(chapter: Chapter) {
    this.selectChapter(chapter.path);
  }

  getChapterName() {
    if (!this.selectedChapter) {
      return '';
    }
    return this.selectedChapter.shortName;
  }


  onFooterInteract(event: string) {
    if (event === 'left' && this.prevChapter) {
      this.selectChapter(this.prevChapter.path);
    } else if (event === 'right' && this.nextChapter) {
      this.selectChapter(this.nextChapter.path);
    }
  }

  /* view accessors */

  hasNext() {
    return !!this.nextChapter;
  }

  hasPrev() {
    return !!this.prevChapter;
  }

  getSelectedChapter() {
    return this.selectedChapter;
  }

  getAvailabilities() {
    return this.availabilityByPath;
  }

  getAvailabileChapters() {
    if (!this.availableChapters) {}
    return this.availableChapters;
  }

  getChapterAvailability(chapter: Chapter) {
    return this.availabilityByPath.get(chapter.path);
  }

  public getTopBarTitle() {
    if (!this.setDefinition || !this.selectChapter) {
      return '';
    }
    return this.setDefinition.name;
  }

  /* data loading and preparation */
  loadExerciseSetData(path: string): Observable<any> {
    console.log(`starting exercise host. Loading data for ${path}.`)
    return zip(
      this.rest.listExerciseSets(),
      this.rest.listChapters(path + '_'),
      this.rest.listAvailabilities(path + '_', 3)
    ).pipe(
      tap(([setsDefinition, chaptersDefinition, availabilities]: [ExerciseSet[], Chapter[], ItemAvailability[]])  => {
        console.log(`data loaded: sets size: ${setsDefinition.length}, chapters: ${chaptersDefinition.length}, availabilities: ${availabilities.length}`);
        this.prepareSetDefinition(setsDefinition);
        this.prepareChaptersDefinitionsMap(chaptersDefinition);
        const avByPath = this.prepareAvailabilitiesByPath(availabilities);
        this.extractAvailableChapters(chaptersDefinition, avByPath);
      }
    ));
  }

  extractAvailableChapters(chaptersDefinition: Chapter[], avByPath: Map<string, ItemAvailability>) {
    console.log(`extracting available chapters`);
    this.availableChapters = chaptersDefinition
      .sort((l, r) => l.orderNumber - r.orderNumber)
      .filter( c => {
        const chapterAv = avByPath.get(c.path);
        return chapterAv && chapterAv.assigned && chapterAv.available;
      });
    console.log(`got ${this.availableChapters.length} available chapters.`);
  }

  prepareAvailabilitiesByPath(availablities: ItemAvailability[]) {
    console.log(`preparing availability map`);
    this.availabilityByPath = new Map(availablities.map(av => [av.path, av]));
    console.log(`availability map prepared with size ${this.availabilityByPath.size}`);
    return this.availabilityByPath;

  }

  prepareChaptersDefinitionsMap(chaptersDefinition: Chapter[]) {
    console.log(`preparing chapters definition map`);
    this.chaptersByPath = new Map(
      chaptersDefinition.map(ch => [ch.path, ch])
    );
    console.log(`got chapters definition map with size ${this.chaptersByPath.size}`);
  }

  prepareSetDefinition(setsDefinition: ExerciseSet[]) {
    console.log(`preparing set definition for currnent path`);
    this.setDefinition = setsDefinition.find( s => s.path === this._setPath);
    console.log(`got set definition ${this.setDefinition.name}`);
  }

  ngOnInit() {
  }

  onExerciseSelect(event: ExerciseSelectEvent) {
    const path = `${event.chapter.path}_${event.section.path}_${event.exercise.path}`;
    const pathAsUrl = path.split('_').join('/');

    forkJoin(this.rest.startExerciseSession(path), this.authService.get())
    .subscribe(
      ([session, api]) => {
        const token = api.getAccessToken();
        const url = `${environment.cspaUi}/token-auth?token=${token}&showMenu=0&closeOnFinish=1&state=/exercises/${pathAsUrl}/session/${session.deviceUUID}/question/0`;
        const sessionSubscription = this.webSocket.establish(environment.cspaWsEndpoint).pipe(
          switchMap(connection => connection.subscribe(
            `/session/${session.deviceUUID}/status`
          ))
        ).subscribe( message => {
          if (message === 'closed') {
            this.windowManager.closeWindow(session.deviceUUID);
            sessionSubscription.unsubscribe();
            this.reloadComponentData();
          } else {
            console.log(`got message: ${message}`);
          }
        })
        this.windowManager.openWindow(session.deviceUUID, url, "_blank");
      }
    )
  }
}
