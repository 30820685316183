<div class="card mb-3">
    <div class="card-header d-flex align-items-center">
            <span i18n="@@student-wallet-page.purchase-history.title">Purchase history</span>
            <app-tooltip class="ms-auto">
              <content>
                <p class="mb-0" i18n='@@induction-box.tooltip-purchase-history'>Details of all plans that were allocated to your account, including those that have expired.</p>
              </content>
            </app-tooltip>
    </div>
    <div class="card-body">
        <app-student-purchase-history [studentId]="studentId"></app-student-purchase-history>
    </div>
</div>

<div class="card mb-2">
    <div class="card-header d-flex align-items-center">
        <span i18n="@@student-wallet-page.credit-balance-history.title">Lesson credits balance history</span>
        <app-tooltip class="ms-auto">
          <content>
            <p class="mb-0" i18n='@@induction-box.tooltip-credit-balance'>Account of all credits going into and out of your account.</p>
          </content>
        </app-tooltip>
    </div>
    <div class="card-body">
        <app-student-transactions [studentId]="studentId" [lang]="lang"></app-student-transactions>
    </div>
</div>



