<div id="tut-reserveProva" class="card-header d-flex align-items-center">
  <span class="my-auto me-auto" i18n="@@student-dashboard.progress-box.title">Progress</span>
  <div class="btn-group float-end" role="group" *ngIf="hasAnyProgress()">
    <button [disabled]="!hasPrev()" (click)="moveCourse(-1)"
      class="btn btn-outline-secondary rounded-start">
      <i class="fa fa-chevron-left text-success f-14"></i>
    </button>
    <button class="btn btn-outline-secondary text-white pe-none f-14">
      {{getCurrentCourseName()}}
    </button>
    <button  [disabled]="!hasNext()" (click)="moveCourse(1)"
      class="btn btn-outline-secondary rounded-end">
      <i class="fa fa-chevron-right text-danger f-14"></i>
    </button>
  </div>
  <app-tooltip class="ms-3">
    <content>
      <p class="mb-0" i18n='@@induction-box.tooltip-progress'>This box helps you track your progress through the course. It shows you which Stage you're in now, Stages that you have already completed, and how many lessons you've taken.</p>
    </content>
  </app-tooltip>
</div>
<div class="card-body">
  <ng-template [ngLoading]="!loaded" type="spinner">
    <div class="p-2" *ngIf="hasNoProgress()"
        i18n="Explain student about missing progress@@student-progress.missing-progress-expl">
        Before you start learning, we need to know your level.
        <br>
        After your level test, you will find your results here.
    </div>

    <div *ngIf="hasAnyProgress()" class="row">
      <div class="col-12 col-md-6 border-md-right border-secondary d-flex
        flex-column justify-content-between">
        <div class="row mb-3">
          <div class="col">
            <span i18n="text in progress block title before stage number@@student-progress.current-stage">
              New Work
            </span>
          </div>
          <div class="col">
            <div class="row">
              <div class="col d-flex justify-content-around">
                <div class="progress my-2 w-75 me-3 d-md-none d-lg-flex">
                  <div class="progress-bar bg-success" role="progressbar" [ngStyle]="getLessonProgressWidth()"></div>
                </div>
                <span class="text-success progress-text text-center">{{getProgress() | number:'1.0-0'}}%</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <span i18n="@@student-progress.exam">
              Exam
            </span>
          </div>
          <div class="col">
            <div class="row">
              <div class="col d-flex justify-content-around">
                <div class="progress my-2 w-75 me-3 d-md-none d-lg-flex">
                  <div class="progress-bar bg-warning" role="progressbar" [ngStyle]="getExamProgressWidth()" ></div>
                </div>
                <span class="text-warning progress-text text-center">{{getExamProgressDescription()}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <span i18n="@@student-progress.revision-progress">
              Full Stage Revision
            </span>
          </div>
          <div class="col">
            <div class="row">
              <div class="col d-flex justify-content-around">
                <div class="progress my-2 w-75 me-3 d-md-none d-lg-flex">
                  <div class="progress-bar bg-info" role="progressbar" [ngStyle]="getRevisionProgressWidth()" ></div>
                </div>
                <span class="text-info progress-text text-center">{{getRevisionProgress() | number:'1.0-0'}}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- lesson dates -->
      <div class="card-text col-12 col-md-6">
        <div *ngIf="hasAnyLesson() && getFirstLessonDate()">
          <div class="row mb-4">
            <div class="col-6">
              <p i18n="Label for the date of first lesson taken in stage@@student-progress.summary.stage-start-date"
                class="text-muted mb-0 f-14">Start date:</p>
              <p>{{getFirstLessonDate() | date: 'dd/MM/yy'}}</p>
            </div>
            <div class="col-6">
              <p i18n="Label for the date of last lesson taken in stage@@student-progress.summary.stage-end-date"
                class="text-muted mb-0 f-14">End date:</p>
              <p>{{getLastLessonDate() | date: 'dd/MM/yy'}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <p i18n="Label for number of lessons taken on the stage@@student-progress.summary.lessons-taken"
                class="text-muted mb-0 f-14">Lessons taken:</p>
              <p>{{getNumberOverallLessons()}}</p>
            </div>
            <div class="col-6">
              <p i18n="@@student-progress.summary.last-exam"
                class="text-muted mb-0 f-14">Last exam date:</p>
              <p *ngIf="getLastExamDate()">{{getLastExamDate() | date: 'dd/MM/yy'}}</p>
              <p *ngIf="!getLastExamDate()">---</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div class="card-footer text-end">
  <button *ngIf="!isShowReserveProva"
    class="btn btn-primary rounded-pill btn-bubble"
    routerLink="/student/{{studentId}}/lessons/reserve"
    i18n="@@student-dashboard.reserve-lesson.btn">
    Reserve a lesson
  </button>
  <button *ngIf="isShowReserveProva"
    class="btn btn-primary rounded-pill btn-bubble"
    routerLink="/student/{{studentId}}/lessons/reserve"
    i18n="@@student-dashboard.reserve-prova.btn">
    Reserve a trial lesson
  </button>
</div>
