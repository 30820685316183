<section class="mb-5">
  <div class="container px-md-5">
    <div class="course-en">
      <div class="course">
        <h4 class="mb-0" i18n="@@englishcourses_page_title">General English</h4>
      </div>
      <!-- <div class="card-body">
        <p i18n="@@courses_description_1">Our courses are all about speaking fluently, confidently, and without translating. In Callan Online lessons you communicate and interact with your tutors all the time. As a result, your skills in listening, speaking and reading improve dramatically.</p>
        <p>
          <span i18n="@@englishcourses_page_intro_line1">The Callan Method English course comprises 12 Stages corresponding roughly to CEFR levels A1-C1. </span>
          <span i18n="@@englishcourses_page_intro_line2">At 5 lessons a week, you will go from complete beginner to upper intermediate in 120 weeks (on average 10 weeks per Stage).</span>
        </p>
        <h5 class="text-primary" i18n="@@coursePage.pricing.title">Pricing</h5>
        <p i18n="@@coursePage.pricing.intro.week-plans.en">Learn Spanish regularly with light, standard or intensive. Choose how often you want to have lessons and for how long. Book 3, 5 or 10 lessons each week and make steady progress.</p>
        <p i18n="@@coursePage.pricing.intro.payg">Or buy a package of PAYG credits and reserve lessons whenever you want. These credits are valid for 6 months regardless of how many you buy.</p>
      </div> -->
    </div>
  </div>
</section>

<section class="mb-5" [hidden]="hideStarterOffers" >
  <div class="container px-md-5">
    <div class="card">
      <div class="card-body">
        <app-student-starters-offer (stateEvent)="onStarterOfferEvent($event)" [studentId]="studentId" [langCode]="productCode"></app-student-starters-offer>
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <div class="container">
    <nav>
      <div class="nav nav-tabs justify-content-center text-uppercase" id="nav-tab" role="tablist">
        <button class="nav-link text-uppercase px-2 px-md-4 active" id="nav-light-tab" data-bs-toggle="tab"
          data-bs-target="#nav-light" type="button" role="tab"
          aria-controls="nav-light" aria-selected="true"
          i18n="english courses light plan|header of the box@@plans_light_3lessons.title"
          >Light</button>
        <button class="nav-link text-uppercase px-2 px-md-4" id="nav-standard-tab" data-bs-toggle="tab"
          data-bs-target="#nav-standard" type="button" role="tab"
          aria-controls="nav-standard" aria-selected="false"
          i18n="english courses standard plan|header of the box@@plans_standard_5lessons.title"
          >Standard</button>
        <button class="nav-link text-uppercase px-2 px-md-4" id="nav-intensive-tab" data-bs-toggle="tab"
          data-bs-target="#nav-intensive" type="button" role="tab"
          aria-controls="nav-intensive" aria-selected="false"
          i18n="english courses intensive plan|header of the box@@plans_intensive_10lessons.title"
          >Intensive</button>
        <button class="nav-link text-uppercase px-2 px-md-4" id="nav-payg-tab" data-bs-toggle="tab"
          data-bs-target="#nav-payg" type="button" role="tab"
          aria-controls="nav-payg" aria-selected="false"
          i18n="english courses pyg plan|header of the box@@plans_payasyougo"
          >Flexible</button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-light" role="tabpanel" aria-labelledby="nav-light-tab">
        <p class="text-muted text-uppercase text-center f-14 my-4" i18n="english courses light plan|header of the box@@plans_light_3lessons.desc">3 lessons / week</p>
        <div class="row justify-content-center g-3">
          <!-- <p class="card-text" i18n="english courses weekly plans|weekly plans accordion@@plans_light_3lessons_accordion">Choose the duration of your plan and use your credits to reserve 3 lessons every week.</p> -->
          <div class="col-10 col-sm-8 col-md-4 col-lg-3 mb-3 h-100" *ngFor="let product of lights">
            <app-product-box [product]="product" desc="3 lessons / week"></app-product-box>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="nav-standard" role="tabpanel" aria-labelledby="nav-standard-tab">
        <p class="text-muted text-uppercase text-center f-14 my-4" i18n="english courses standard plan|header of the box@@plans_standard_5lessons.desc">5 lessons / week</p>
        <div class="row justify-content-center g-3">
          <!-- <p class="card-text" i18n="english courses weekly plans|weekly plans accordion@@plans_standard_5lessons_accordion">Choose the duration of your plan and use your credits to reserve 5 lessons every week.</p> -->
          <div class="col-10 col-sm-8 col-md-4 col-lg-3 mb-3 h-100" *ngFor="let product of std">
            <app-product-box [product]="product" desc="5 lessons / week"></app-product-box>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="nav-intensive" role="tabpanel" aria-labelledby="nav-intensive-tab">
        <p class="text-muted text-uppercase text-center f-14 my-4" i18n="english courses intensive plan|header of the box@@plans_intensive_10lessons.desc">10 lessons / week</p>
        <div class="row justify-content-center g-3">
          <!-- <p class="card-text" i18n="english courses weekly plans|weekly plans accordion@@plans_intensive_10lessons_accordion"> Choose the duration of your plan and use your credits to reserve 10 lessons every week. (For example two lessons a day)</p> -->
          <div class="col-10 col-sm-8 col-md-4 col-lg-3 mb-3 h-100" *ngFor="let product of intensive">
            <app-product-box [product]="product" desc="10 lessons / week"></app-product-box>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="nav-payg" role="tabpanel" aria-labelledby="nav-payg-tab">
        <div class="row justify-content-center g-3 mt-5">
          <!-- <p class="card-text" i18n="english courses pay as you go plans intro|short introduction of the pyg plans@@englishcourses_page_pyg_intro">Buy a package of credits and book lessons whenever you want. These credits are valid for <br> 6 months regardless of how many you buy.</p> -->
          <div class="col-10 col-sm-8 col-md-4 col-lg-3 mb-3 h-100" *ngFor="let product of pag">
            <app-product-box [product]="product" desc=""></app-product-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <div class="container px-md-5">
    <div class="card">
      <div class="card-header">
        <h4 class="mb-0" i18n="english courses ebook|ebooks for your lessons@@plans_ebooks_title">Ebooks for your lessons </h4>
      </div>
      <div class="card-body">
        <p class="mb-4 mb-md-5" i18n="english courses ebook plans intro|short introduction of the ebook @@englishcourses_page_ebook_intro">Each Stage comes with a corresponding eBook with integrated audio. Use it to revise and practice listening comprehension and spelling after lessons. You should also have it handy during the lessons.</p>
        <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5 justify-content-start">
          <div class="col" *ngFor="let product of books">
            <div class="book-product mx-auto">
              <img class="w-100" src="{{getImage(product)}}">
              <div class="w-100 mt-1 text-center text-muted">
                  {{product.defaultPrice.price}} {{product.defaultPrice.currency}}
              </div>
              <button class="btn btn-outline-primary d-block w-100 mt-1 mb-4"
                (click)="addItem(product)"
                i18n="@@common.Buy"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasCart"
              >Buy</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer border-0">
        <!-- <p class="text-center" i18n="english courses ebook in app|books available in app@@plans_ebooks_in_app">Any eBooks you buy will be displayed in the Callan app. Download it using the links below.</p> -->
        <div class="row justify-content-center">

          <div class="col-6 col-md-4 col-xl-3 text-end">
            <a class="btn btn-secondary px-4 py-3" href="https://play.google.com/store/apps/details?id=pl.arceo.callan.callandigitalbooks" target="_blank">
              <img src="/assets/img/Google_Play_Store_badge_EN.svg" title="google play store" alt="google play store" class="w-100">
            </a>
          </div>
          <div class="col-6 col-md-4 col-xl-3 text-start">
            <a class="btn btn-secondary px-4 py-3" href="https://itunes.apple.com/gb/app/callan-method-app/id1099785360" target="_blank">
              <img src="/assets/img/Download_on_the_App_Store_Badge.svg" class="w-100" title="apple store" alt="apple store">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <div class="container px-md-5">
      <div class="card">
        <div class="card-header">
          <h4 class="mb-0" i18n="@@english-core-product.level-comparsion.title">  How do the Callan Method stages equate to Trinity, TOEFL, Cambridge English, and IELTS examinations?</h4>
        </div>
        <div class="card-body">
          <p i18n="@@english-core-product.level-comparsion.expl">The comparison of Callan Method stages to the tests shown is approximate. That said, the language requirements for each of the Cambridge English tests have been covered at the Callan levels indicated.</p>
          <div class="table-responsive">
            <table class="table pe-none mt-3">
              <thead>
                <tr>
                  <th scope="col">CEFR Level<sup>1</sup></th>
                  <th scope="col">Trinity GESE<sup>2</sup></th>
                  <th scope="col">Trinity ISE<sup>3</sup></th>
                  <th scope="col">TOEFL iBT<sup>4</sup></th>
                  <th scope="col">Cambridge English<sup>5</sup></th>
                  <th scope="col">IELTS<sup>6</sup></th>
                  <th scope="col">Callan stages</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>A1</td>
                  <td>Grade 2</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>3/4</td>
                </tr>
                <tr>
                  <td>A2</td>
                  <td>Grade 3 & 4</td>
                  <td>ISE 0</td>
                  <td>-</td>
                  <td>KET</td>
                  <td>3.0-3.5</td>
                  <td>4/5</td>
                </tr>
                <tr>
                  <td>B1</td>
                  <td>Grade 5 & 6</td>
                  <td>ISE I</td>
                  <td>57-86</td>
                  <td>PET</td>
                  <td>4.0-4.5</td>
                  <td>end of 6</td>
                </tr>
                <tr>
                  <td>B2</td>
                  <td>Grade 7, 8 & 9</td>
                  <td>ISE II</td>
                  <td>87-109</td>
                  <td>FCE</td>
                  <td>5.0-6.5</td>
                  <td>end of 10</td>
                </tr>
                <tr>
                  <td>C1</td>
                  <td>Grade 10 & 11</td>
                  <td>ISE III</td>
                  <td>110-120</td>
                  <td>CAE</td>
                  <td>7.0-8.0</td>
                  <td>end of 12</td>
                </tr>
                <tr>
                  <td>C2</td>
                  <td>Grade 12</td>
                  <td>ISE IV</td>
                  <td>-</td>
                  <td>CPE</td>
                  <td>8.5-9.0</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </div>
          <ol class="text-muted ps-3" i18n="@@english-core-product.level-comparsion.examinations-list">
            <li>
              <p class="fw-bold f-14 mb-1">Common European Framework of Reference for Languages</p>
              <p class="f-12">
                The Common European Framework of Reference is a guideline used to describe the linguistic competence of learners of foreign languages across Europe and, increasingly, in other countries.
                It is now widely used by publishers and test developers.
              </p>
            </li>
            <li>
              <p class="fw-bold f-14 mb-1">Trinity: Graded Examinations in Spoken English</p>
              <p class="f-12">Trinity’s Graded Examinations in Spoken English are one-to-one, face-to-face assessments of speaking and listening skills. </p>
            </li>
            <li>
              <p class="fw-bold f-14 mb-1">Trinity: Integrated Skills in English</p>
              <p class="f-12">Trinity's Integrated Skills in English is a four skills qualification assessing students’ ability to interact in English through the use of integrated reading and writing tasks and integrated speaking and listening tasks. </p>
            </li>
            <li>
              <p class="fw-bold f-14 mb-1">TOEFL iBT</p>
              <p class="f-12">The TOEFL iBT test measures a learner’s ability to use and understand English at university level. It evaluates how well the test taker combines listening, reading, speaking and writing skills to perform academic tasks.</p>
            </li>
            <li>
              <p class="fw-bold f-14 mb-1">Cambridge English Language assessment</p>
              <p class="f-12">This suite of tests constitutes possibly the most well-known tests in the world for English as a Foreign Language. They each measure a learner’s ability in all the four skills.</p>
            </li>
            <li>
              <p class="fw-bold f-14 mb-1">The International English Language Testing System</p>
              <p class="f-12">IELTS is an international standardised test, jointly managed by the British Council, IDP: IELTS Australia and Cambridge English Language Assessment. It is one of the major English-language tests in the world.</p>
            </li>
          </ol>
        </div>
      </div>
  </div>
</section>

<section class="mb-5">
  <div class="container px-md-5">
    <div class="card">
      <div class="card-header">
        <h4 class="mb-0" i18n="@@englishcourse.how-long">How long it takes to complete each stage?</h4>
      </div>
      <div class="card-body">
        <p i18n="english courses time to complete the stage|short paragraph about the lesson pace@@englishcourses_page_stage_pace">
          The amount of time needed to complete each stage varies across individual students, and depends on external factors, such as quality of connection and frequency of lessons.<br/>
          Stages vary in length as well, so some of them can be completed faster than others. On average, the time frame for particular stages is as follows:
        </p>
        <div class="table-responsive">
          <table class="table pe-none mt-3">
            <thead>
              <tr>
                <th scope="col" i18n="english courses table header in time to complete the stage|stage@@englishcourses_page_stage">Stage</th>
                <th scope="col">1</th>
                <th scope="col">2</th>
                <th scope="col">3</th>
                <th scope="col">4</th>
                <th scope="col">5</th>
                <th scope="col">6</th>
                <th scope="col">7</th>
                <th scope="col">8</th>
                <th scope="col">9</th>
                <th scope="col">10</th>
                <th scope="col">11</th>
                <th scope="col">12</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-muted">
                <th scope="row" class="f-12" i18n="english courses table header in time to complete the stage|No of lessons@@englishcourses_page_nlessons">No of<br/>lessons</th>
                <td class="f-12">22-32</td>
                <td class="f-12">37-47</td>
                <td class="f-12">38-48</td>
                <td class="f-12">51-61</td>
                <td class="f-12">48-58</td>
                <td class="f-12">42-52</td>
                <td class="f-12">45-55</td>
                <td class="f-12">44-54</td>
                <td class="f-12">61-71</td>
                <td class="f-12">59-69</td>
                <td class="f-12">65-75</td>
                <td class="f-12">64-74</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <div class="container px-md-5"  i18n="english courses page influence progress|last part of page@@englishcourses_page_influence_progress">
    <p class="text-center mb-5 h4">What factors can influence my progress?</p>
    <div class="row text-center justify-content-center">
      <div class="col-12 col-md-6 col-xl-4">
        <p>Frequency of lessons</p>
        <p class="text-muted">Every lesson begins with a revision of material from previous lessons. The more often you take lessons, the better you remember the revised material, and the less repetition you need. If you do less repetition, you have more time for new work and you progress faster. </p>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <p>Connection quality</p>
        <p class="text-muted">It's very important that your tutors and you can hear each other clearly. You need to learn correct pronunciation from them, and they need to correct any mistakes that you make. Your tutor and you will speak simultaneously a lot of the time, so it's important that there is no lag. If there are sound issues, your lessons will be less efficient and your progress will be slower.</p>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <p>Exercises</p>
        <p class="text-muted">Between lessons, you will be able to do exercises that will help you revise on your own as well as practice spelling. If you do these exercises regularly, you will remember previous lessons better and need less repetition.</p>
      </div>
    </div>
  </div>
</section>

<section class="text-center pb-5">
  <h4 class="mb-4" i18n="english courses page last sentence|last part of page@@englishcourses_page_more_question">More questions about the Courses or Plans? </h4>
  <a href="contact-us.html#faqs" class="btn btn-primary rounded-pill px-5 py-3" i18n="english courses page go to faq|go to faq@@button_gotofaq">Go to FAQ</a>
</section>

