<div class="row" *ngIf="courseProgress && courseProgress.course">
  <div class="col-12 col-md-4 order-md-2 text-uppercase text-center">
      <h5>{{courseProgress!.course!.name}}</h5>
  </div>
  <div class="col-6 col-md-4 order-md-1">
    <button *ngIf="hasPrev" (click)="moveCourse(-1)"  class="btn btn-link float-start">
      <i class="fa fa-chevron-left"></i>&nbsp;<span i18n="@@student-progress.prev-stage.link">previous stage</span>
    </button>
  </div>

  <div class="col-6 col-md-4 order-md-3">
      <button  *ngIf="hasNext" (click)="moveCourse(1)"  class="btn btn-link float-end">
        <span i18n="@@student-progress.next-stage.link">next stage</span>&nbsp;<i class="fa fa-chevron-right"></i>
      </button>
  </div>


</div>
<div class="p-2" *ngIf="courseProgress && (!courseProgress.productProgress || courseProgress.productProgress.start == 1)" i18n="Explain student about missing progress@@student-progress.missing-progress-expl">
  Before you start learning, we need to know your level.
  <br>
  After your level test, you will find your results here.</div>
<div *ngIf="courseProgress && courseProgress.productProgress && courseProgress.productProgress.start != 1" class="row">

  <!-- progress circle -->
  <div class="col-md-4 text-center border-end">
    <div *ngIf="courseProgress && courseProgress.productProgress">
      <div class="chart-wrapper mt-2 mb-2">
        <app-circle-chart [progressValue]="courseProgress.productProgress.courseProgress*100"
        [color]="'blue'" ></app-circle-chart>
    </div>
      <div class="clearfix"></div>
      <!--<span class="fw-bold">Overal: {{courseProgress.productProgress.productProgress*100 | number:'1.0-0'}}%</span><br/> -->
      <span class="fw-bold"><span i18n="text in progress block title before stage number@@student-progress.current-stage">Current stage:</span> {{courseProgress.productProgress.courseProgress*100 | number:'1.0-0'}}%</span>
    </div>
  </div>

  <!-- promotion estimation -->
  <div class="card-text col-12 col-md-4 ">
    <div *ngIf="courseProgress">
      <div *ngIf="courseProgress.lessonsUntilPromotion != null" class="text-center mt-3 " >
        <span i18n="@@student-progress.stage-complete-approximation.prefix">You need approximately </span><br/>
        <span class="fw-bold text-uppercase" i18n="@@student-progress.stage-complete-approximation.lessons-number">{courseProgress!.lessonsUntilPromotion, plural, other {{{courseProgress!.lessonsUntilPromotion}} lessons}}</span><br/>
        <span i18n="@@student-progress.stage-complete-approximation.postfix">to complete this Stage</span>
      </div>
    </div>
  </div>

  <!-- lesson dates -->
  <div class="card-text col-12 col-md-4 border-start">
    <div *ngIf="courseProgress && courseProgress.firstLessonDate && courseProgress.numberOfLessons">
      <div class="text-center ">
        <span i18n="Label for the date of first lesson taken in stage@@student-progress.summary.stage-start-date">Start date:</span><br/>
        <span class="fw-bold">{{courseProgress!.firstLessonDate | date: 'dd/MM/yy'}}</span>
      </div>
      <div class="text-center mt-1">
        <span i18n="Label for number of lessons taken on the stage@@student-progress.summary.lessons-taken">Lessons taken:</span><br/>
        <span class=" fw-bold">{{courseProgress!.numberOfLessons}}</span>
      </div>
      <div class="text-center mt-1" *ngIf="courseProgress.lastLessonDate">
        <span i18n="Label for the date of last lesson taken in stage@@student-progress.summary.stage-end-date">End date:</span><br/>
        <span class=" fw-bold">{{courseProgress!.lastLessonDate | date: 'dd/MM/yy'}}</span>
      </div>
    </div>
  </div>
</div>
