import { Observable, of, Observer, Subscription } from 'rxjs';

export class PropertyObserver {
    public static createPropertyObserver<T>(observers: Observer<T>[], currentValue: T): Observable<T> {
        return new Observable<T>(observer => {
          observers.push(observer);
          observer.next(currentValue);
          return {
            unsubscribe() {
              observers.splice(observers.indexOf(observer), 1);
            }
          };
        });
      }

      public static notifyValueHasChanged<T>(observers: Observer<T>[], currentValue: T): void {
        observers.forEach( o => o.next(currentValue));
      }

      public static unsuscribe(subscription: Subscription): Subscription {
        if (subscription == null) { return null; }
        subscription.unsubscribe();
        return null;
      }
}
