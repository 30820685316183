import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-winter-banner',
  templateUrl: './winter-banner.component.html',
  styleUrls: ['./winter-banner.component.css']
})
export class WinterBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
