import { Injectable } from '@angular/core';
import { StudentContextService, StudentProfileData } from '../ctx/student-context.service';
import { combineLatest, merge, BehaviorSubject } from 'rxjs';
import { StudentCacheProxyService } from '../student-cache-proxy.service';
import { AppEventsService } from '../ctx/app-events.service';
import { ApiPersonTechnicalProfile } from 'src/app/model/rest/rest-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StaticPagesUrlProviderService {
  teachersBaseSubject = new BehaviorSubject<{urlBase: string, productCode: string}>(null);
  staticBase = new BehaviorSubject<string>(null);

  constructor(private studentContext: StudentContextService,
    private studentProxy: StudentCacheProxyService,
    private appEvents: AppEventsService) {

    studentContext.profileDataSubject.subscribe( profileData => {
      const technicalProfileObservable = merge(
        studentProxy.loadStudentTechnicalProfile(profileData.studentId),
        appEvents.technicalProfileUpdated
      );
      combineLatest(studentContext.langCodeSubject, technicalProfileObservable).subscribe(
        ([langCode, technicalProfile]) => this.updateUrl(profileData, langCode, technicalProfile)
      );
    });
  }

  updateUrl(profileData: StudentProfileData, langCode: string, technicalProfile: ApiPersonTechnicalProfile): void {
    if (!profileData) {return; }
    let lang = 'en';
    if (technicalProfile && technicalProfile.lang) {
      lang = technicalProfile.lang;
    }
    const urlBase = environment.staticPagesBase + '/' + lang + '/school/' + profileData.schoolId + '/teachers';
    this.teachersBaseSubject.next({
      urlBase: urlBase,
      productCode: langCode
    });
    const langPart = lang === 'en' ? '' : '/' + lang ;
    console.log(environment.staticPagesBase);
    console.log(langPart);
    this.staticBase.next(environment.staticPagesBase + langPart);
  }
}
