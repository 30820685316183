import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-xmas-banner',
  templateUrl: './xmas-banner.component.html',
  styleUrls: ['./xmas-banner.component.css']
})
export class XmasBannerComponent implements OnInit {

  constructor() { }

  @Input()
  studentId: number;
  @Input()
  langCode: string;

  ngOnInit() {
  }

}
