import {Component, OnInit} from '@angular/core';
import {AuthServiceProvider} from "../../services/col2/auth.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private authService: AuthServiceProvider) { }

  ngOnInit() {
    this.authService.get().subscribe(api =>
      api.startRegistration('/')
    );
  }

}
