import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TeacherRestService } from 'src/app/services/rest/teacher-rest.service';
import { forkJoin, Observable, of, Subscription, interval } from 'rxjs';
import { Pageable, Page } from 'src/app/model/rest/base';
import { ApiLessonInstance,
  ApiLessonProgress, ApiTeacherLessonInstance, LessonStatusUtils, ApiLessonStatus,
  ApiLessonFlag, ApiCourse, ApiProductContext,
  ApiPersonTechnicalProfile, ApiLessonMessage, ApiCountry, ApiLearningUnitTeacher, ApiPersonalProfile, ApiPerson, ApiCourseBase } from 'src/app/model/rest/rest-model';
import {tap, flatMap, switchMap, map, finalize} from 'rxjs/operators';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PersonNameExtractor, ProfilePhotoUrlExtractor } from 'src/app/utils/profile-photo-url-extractor';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Dates, TimeUnits } from 'src/app/utils/calendar-utils';
import { PublicRestProxyService } from 'src/app/services/public-rest-proxy.service';
import { AnalyticsService, Event } from 'src/app/services/analytics.service';
import { LocaleManagerService } from 'src/app/services/locale-manager.service';

@Component({
  selector: 'app-teacher-lesson-details',
  templateUrl: './teacher-lesson-details.component.html',
  styleUrls: ['./teacher-lesson-details.component.css']
})
export class TeacherLessonDetailsComponent implements OnInit, OnDestroy {

  public static READING_RECOMENDATION = 22;

  @ViewChild('flagModal', {static: true}) flagModal: ModalComponent;
  @ViewChild('cancelLessonModal', {static: true}) cancelLessonModal: ModalComponent;
  @ViewChild('notificationConfirmationModal', {static: true}) notificationConfirmationModal: ModalComponent;
  @ViewChild('migrateToNewEnglishConfirmationModal', {static: true}) migrateToNewEnglishConfirmationModal: ModalComponent;
  _teacherId: number;
  _lessonId: number;
  _studentId: number;

  isComming = false;
  readyToStart = false;
  isRunning = false;
  cancelable = false;
  needConfirmation = false;
  isComplete = false;
  shouldBeStarted = false;
  shouldBeFinished = false;
  canUpdatePlannedProgress = false;
  canUpdateProgress = false;
  plannedLessonFinish: Date;
  progressCommitLimit: Date;
  plannedLessonStart: Date;
  isMigratingInProgress = false;


  historyPageable: Pageable;
  lessonDetails: ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  history: ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[];
  lastHistoryPage: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  hasHistoryNext = false;
  studentTechnicalProfile: ApiPersonTechnicalProfile;
  newLessonType: string;

  // dialogs
  _clReason: string;
  _fFlags: {[name: string]: boolean};
  _fDescription: string;
  _fExistingFlags: string[];
  _fAllFlags = ApiLessonFlag.flagsTyppes;
  currentProgress: ApiLessonProgress;
  estimatedProgress: ApiLessonProgress;
  productCourses: ApiCourse[];
  studentContext: ApiProductContext;
  isSkype: boolean;
  roomUrl: SafeResourceUrl;
  timingSubscription: Subscription;
  mayBeStartedTime = false;
  allowNotification = true;
  countries: ApiCountry[];
  private courseLoaded: ApiCourseBase;


  constructor(
    private route: ActivatedRoute,
    private teacherRest: TeacherRestService,
    private _santinizer: DomSanitizer,
    publicRest: PublicRestProxyService,
    private analytics: AnalyticsService,
    localeService: LocaleManagerService
  ) {
    publicRest.loadCountries(localeService.localeId).subscribe(countries => this.countries = countries);
   }

   private getStudentPerson() {
    if (!this.lessonDetails
      || !this.lessonDetails.students
      || this.lessonDetails.students.length < 1
      || !this.lessonDetails.students[0].person) {
        return null;
      }
      return this.lessonDetails.students[0].person;
   }

   getStudentGender() {
    const studentPerson = this.getStudentPerson();
    if (!studentPerson || !studentPerson.personalProfile || !studentPerson.personalProfile.gender) {
      return '';
    }

    return studentPerson.personalProfile.gender;
   }

   getStudentCountry() {
     if (!this.countries) {
       return '';
     }
     const studentPerson = this.getStudentPerson();
     if (!studentPerson || !studentPerson.personalProfile || !studentPerson.personalProfile.countryIso) {
       return '';
     }

     const studentCountry = this.countries.find( c => c.isoCode === studentPerson.personalProfile.countryIso );
     if (!studentCountry) { return ''; }
     return studentCountry.name;

   }

   getStudentNationality() {
    if (!this.countries) {
      return '';
    }
    const studentPerson = this.getStudentPerson();
    if (!studentPerson || !studentPerson.personalProfile || !studentPerson.personalProfile.nationalityIso) {
      return '';
    }
    const studentCountry = this.countries.find( c => c.isoCode === studentPerson.personalProfile.nationalityIso );
    if (!studentCountry) { return ''; }
    return studentCountry.name;
  }

   ngOnDestroy(): void {
    this.timingSubscription.unsubscribe();
  }

  public getHistoryDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!lesson || !lesson.lessonMetric) { return null; }
    if (!lesson.lessonMetric.started ) {
      return lesson.lessonMetric.plannedStartDate;
    }
    return lesson.lessonMetric.started;
  }

  private setLessonDetails(lessonDetails: ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!lessonDetails.message) {
      lessonDetails.message = new ApiLessonMessage();
    }
    this.lessonDetails = lessonDetails;

    this.fillMissingLessonDetails(lessonDetails);
    const lessonStatus = ApiLessonStatus[this.lessonDetails.lessonStatus];

    this.isComming = (LessonStatusUtils.isComming.indexOf(lessonStatus) >= 0);
    this.readyToStart = (lessonStatus === ApiLessonStatus.Due);
    this.isRunning = (lessonStatus === ApiLessonStatus.InProgress);
    this.needConfirmation = lessonStatus === ApiLessonStatus.Booked;
    this.isComplete = lessonStatus === ApiLessonStatus.Complete;
    this.canUpdatePlannedProgress = this.isComming;
    this.plannedLessonStart = new Date(lessonDetails.lessonMetric.plannedStartDate);
    this.newLessonType = lessonDetails.lessonType;
    this.roomUrl = null;
    this.isSkype = false;
    if (lessonDetails.roomUrl) {
      if (lessonDetails.roomUrl.startsWith('skype:')) {
        this.isSkype = true;
        this.roomUrl = this._santinizer.bypassSecurityTrustResourceUrl(lessonDetails.roomUrl);
      } else if (lessonDetails.roomUrl.startsWith('video_room_url:')) {
        this.roomUrl = this._santinizer.bypassSecurityTrustResourceUrl(lessonDetails.roomUrl.substring('video_room_url:'.length));
      } else {
        this.roomUrl = null;
      }
    }
    this.plannedLessonFinish = new Date(
      this.plannedLessonStart.getTime()
      + lessonDetails.lessonMetric.plannedDuration);
    this.progressCommitLimit = new Date(
      this.plannedLessonStart.getTime()
        + 1000 * 60 * 60 * 6);

    this.updateTimingVariables();
  }

  updateTimingVariables(): void {
    if (!this.lessonDetails) {return; }
    const lessonStatus = ApiLessonStatus[this.lessonDetails.lessonStatus];
    this.shouldBeStarted = (this.plannedLessonStart.getTime() < new Date().getTime() && this.isComming);
    this.shouldBeFinished = (
      this.plannedLessonFinish.getTime() < new Date().getTime()
      && this.shouldBeStarted);
      this.canUpdateProgress =
      this.shouldBeFinished || this.isRunning || this.shouldBeStarted
      || (this.isComplete && this.progressCommitLimit.getTime() > new Date().getTime());

    this.mayBeStartedTime = Dates.diff(new Date(), this.plannedLessonStart) < TimeUnits.Minues(15).toMilis();
    this.cancelable = LessonStatusUtils.cancelable.indexOf(lessonStatus) >= 0 && !this.shouldBeStarted && !this.mayBeStartedTime;
  }

  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.loadData();
  }

  set lessonId(lessonId: number) {
    this._lessonId = lessonId;
    this.loadData();
  }

  set studentId(studentId: number) {
    this._studentId = studentId;
    this.loadData();
  }

  public getStudentName(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!lesson || !lesson.students || lesson.students.length === 0) {
      return 'Unknown student';
    }
    return PersonNameExtractor.getPersonName(lesson.students[0].person);
  }

  public getTeacherName(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!lesson || !lesson.teacher || !lesson.teacher.person) {
      return 'Unknown teacher';
    }
    return PersonNameExtractor.getPersonName(lesson.teacher.person);
  }

  public getStudentPhoto(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!lesson || !lesson.students || lesson.students.length === 0) {
      return ProfilePhotoUrlExtractor.getPersonProfilePhoto(null);
    } else {
      return ProfilePhotoUrlExtractor.getPersonProfilePhoto(lesson.students[0].person);
    }
  }

  public getLessonDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.lessonMetric.plannedStartDate;
  }

  public getLessonStartedDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.lessonMetric.started;
  }

  public cancelLesson() {
    let cancelationObservable: Observable<any>;
    if ((this.lessonDetails.lessonStatus === 'Booked' || this.lessonDetails.lessonStatus === 'Initializing') && !this.mayBeStartedTime) {
      cancelationObservable = this.teacherRest.cancelLesson(this._teacherId, this.lessonDetails.id, this._clReason);
    } else if (this.lessonDetails.lessonStatus === 'Due' || this.lessonDetails.lessonStatus === 'Booked') {
      cancelationObservable = this.teacherRest.squanderLesson(this._teacherId, this._lessonId, this._clReason);
    }

    if (cancelationObservable) {
      cancelationObservable.pipe( switchMap( () => this.loadLessonDetails()))
      .subscribe();
    }

    this.cancelLessonModal.hide();
  }

  public showCancelLesson() {
    this._clReason = null;
    this.cancelLessonModal.show();
  }

  private doubleVideoReservationLocked = false;
  public reserveVideoClassroom() {
    if (this.doubleVideoReservationLocked) return;
    this.doubleVideoReservationLocked = true;
    this.teacherRest.createVideoClassroom(this._teacherId, this._lessonId).pipe(switchMap( () => this.loadLessonDetails()))
      .pipe(
        finalize( () => {
          this.doubleVideoReservationLocked = false
        })
      )
      .subscribe(
      () => this.analytics.event(Event.ACT_LS_ROOM, new Event(Event.CAT_LESSON, 'video-room'))
    );
  }

  public reserveSkypeClassroom() {
    this.teacherRest.createSkypeClassroom(this._teacherId, this._lessonId).pipe(switchMap( () => this.loadLessonDetails())).subscribe(
      () => this.analytics.event(Event.ACT_LS_ROOM, new Event(Event.CAT_LESSON, 'skype'))
    );
  }

  public getRevisionPage() {
    return Math.max(this.currentProgress.start - 6, 1);
  }

  public getRecomendedReading() {
    if (!this.currentProgress || !this.currentProgress.nwp) {
      return 1;
    }
    return Math.max(this.currentProgress.nwp - TeacherLessonDetailsComponent.READING_RECOMENDATION, 1);
  }

  public fHasFlag(type: string) {
    return this._fExistingFlags.some ( f => f === type );
  }

  public fGetFlagDescription(type: string) {
    return ApiLessonFlag.getFLagDescription(type);
  }

  public enterTheRoom() {
    let observable = of(null);
    if (this.lessonDetails.lessonStatus === 'Due') {
      observable = this.teacherRest.startLesson(this._teacherId, this._lessonId, null).pipe(
        switchMap( () => this.loadLessonDetails())
      );
    }
    observable.subscribe( () => {
    });
  }

  public fAskForComment(): boolean {
    let res = false;
    for (const key in this._fFlags) {
      if (this._fFlags.hasOwnProperty(key)) {
        const value = this._fFlags[key];
        if (value && ApiLessonFlag.descriptionRequiringFlags.some( f => f === key)) {
          res = true;
          break;
        }
      }
    }
    return res;
  }

  public showFlagsModal() {
    this._fFlags = {};
    this._fDescription = null;
    this._fExistingFlags = this.lessonDetails.flags.map( f => f.flagType );
    this._fExistingFlags.forEach ( f => this._fFlags[f] = true );
    this.flagModal.show();
  }

  public postFlag() {
    let postObservable = of<any>(null);
    for (const flagType in this._fFlags) {
      if (this._fFlags.hasOwnProperty(flagType)) {
        const enabled = this._fFlags[flagType];
        if (!enabled || this._fExistingFlags.indexOf(flagType) >= 0) { continue; }
        const newFlag = new ApiLessonFlag();
        newFlag.date = new Date();
        if (ApiLessonFlag.descriptionRequiringFlags.some( f => f === flagType)) {
          newFlag.description = this._fDescription;
        }
        newFlag.flagType = flagType;
        newFlag.status = ApiLessonFlag.StatusSubmitted;
        postObservable = postObservable.pipe(
          switchMap( () => this.teacherRest.registerLessonFlag(this._teacherId, this.lessonDetails.id, newFlag))
        );
      }
    }
    postObservable.pipe( switchMap( () => this.loadLessonDetails() ))
    .subscribe();
    this.flagModal.hide();
  }

  sendStudentStartNotification() {
    this.allowNotification = false;
    this.teacherRest.notifyStudentAboutLessonStart(this._teacherId, this._lessonId).subscribe(
      () => {
        this.allowNotification = true;
        this.notificationConfirmationModal.show();
      }, () => this.allowNotification = true
    );
  }

  loadProgressData() {
    if (LessonStatusUtils.plannedLessons.indexOf(ApiLessonStatus[this.lessonDetails.lessonStatus]) < 0) {
      return of(null);
    }
    return this.teacherRest.getStudentProgress(this._teacherId, this._studentId).pipe(
      // find related progress
      map( allProgresses => allProgresses.find( progress => progress.productCode === this.lessonDetails.course.product.code)),
      // create new if is first lesson
      map( currentProductProgress => {
        if (currentProductProgress) {return currentProductProgress; }
        const newProgress = new ApiLessonProgress();
        newProgress.courseCode = this.lessonDetails.course.code;
        newProgress.productCode = this.lessonDetails.course.product.code;
        newProgress.hw = '';
        newProgress.nwp = 0;
        newProgress.start = 0;
        return newProgress;
      } ),
      // keep it
      tap( currentProgress => this.currentProgress = currentProgress),
      // ask for progress
      flatMap( currentProgress => this.teacherRest.estimateNextProgress(this._teacherId, this._studentId, currentProgress)),
      // get as single from the commection
      map ( estimatedProgresses => estimatedProgresses[0]),
      // keep it
      tap ( estimatedProgress => this.estimatedProgress = estimatedProgress)
    );
  }

  loadAvailableStudentCourses() {
    return this.teacherRest.getProductCourses(this.lessonDetails.course.product.code).pipe(
      tap ( courses => this.productCourses = courses)
    );
  }

  loadStudentContext() {
    return this.loadAvailableStudentCourses().pipe(
      switchMap( () => this.teacherRest.getStudentProductContext(this._teacherId, this._studentId, this.lessonDetails.course.product.code)),
      map ( context => context  ? context : new ApiProductContext()),
      tap ( context => this.studentContext = context ),
      tap ( context => {
        if (!context.currentCourse) { this.studentContext.currentCourse = this.productCourses[0] }
        context.currentCourse = this.productCourses.find( c => c.code === context.currentCourse.code );
        this.courseLoaded = context.currentCourse;
      })
    );

  }

  loadLessonDetails(): Observable<ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {

    return this.teacherRest.getLessonById(this._teacherId, this._lessonId, this._studentId).pipe(
      tap( lessonDetails => this.setLessonDetails(lessonDetails)),
      tap( () => this.loadProgressData().subscribe()),
      tap( () => this.loadStudentContext().subscribe())
    );
  }

  fillMissingLessonDetails(lessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): void {
    if (lessonDetails != null && lessonDetails !== undefined
      && (lessonDetails.progressCommited == null || lessonDetails.progressCommited === undefined)) {
        const comProgress = new ApiLessonProgress();
        lessonDetails.progressCommited = comProgress;
      }
  }

  loadLessonHistoryPage(): Observable<Page<ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    return this.teacherRest.listLessonHistory(this._teacherId, this._lessonId, this._studentId, this.historyPageable).pipe(
      tap( lessonHistoryPage => {
        this.lastHistoryPage = lessonHistoryPage;
        this.history = this.history.concat(lessonHistoryPage.content);
        this.hasHistoryNext = !lessonHistoryPage.last;
      })
    );
  }

  public startLesson() {
    // this.teacherRest.updateLessonProgress(this._teacherId, this._lessonId, this.lessonDetails.plannedProgress)
    of(null)
    .pipe(
      switchMap( () => this.teacherRest.startLesson(this._teacherId, this._lessonId, null)),
      switchMap( () => this.loadLessonDetails())
    ).subscribe();
  }

  public confirm() {
    // this.teacherRest.updateLessonProgress(this._teacherId, this._lessonId, this.lessonDetails.plannedProgress)
    of(null)
    .pipe(
      switchMap(() => this.teacherRest.commitLessonBooking(this._teacherId, this._lessonId)),
      switchMap( () => this.loadLessonDetails())
    ).subscribe();
  }

  public studentNoShow() {
    const needToStart = this.isComming;
    const needToFinish = needToStart || this.isRunning;
    const finishDate = (!this.isRunning && this.shouldBeFinished) ? this.plannedLessonFinish : null;
    let finalObservable = this.prepareToStart();

    if (needToFinish) {
      finalObservable = finalObservable.pipe(
        switchMap( () => this.teacherRest.finishLesson(this._teacherId, this._lessonId, this.currentProgress, finishDate))
      );
    } else {
      finalObservable = finalObservable.pipe(
        switchMap( () => this.teacherRest.updateLessonProgress(this._teacherId, this._lessonId, this.currentProgress))
      );
    }

    finalObservable = finalObservable.pipe(
      switchMap( () => this.registerNoStudentFlag() )
    );

    finalObservable.pipe(
      switchMap( () => this.loadLessonDetails() )
    ).subscribe(null, () => this.loadLessonDetails());
  }

  private registerNoStudentFlag() {
    const flag = new ApiLessonFlag();
    flag.flagType = ApiLessonFlag.FtStudentNotShow;
    return this.teacherRest.registerLessonFlag(this._teacherId, this._lessonId, flag);
  }

  private prepareToStart() {
    let finalObservable = of(null);
    const needToConfirm = this.lessonDetails.lessonStatus === 'Booked';
    const needToStart = this.isComming;
    const startDate = (this.shouldBeFinished) ? this.plannedLessonStart : null;

    if (needToConfirm) {
      finalObservable = finalObservable.pipe(
        switchMap (() => this.teacherRest.commitLessonBooking(this._teacherId, this._lessonId)));
    }
    if (needToStart) {
      finalObservable = finalObservable.pipe(
        switchMap(() => this.teacherRest.startLesson(this._teacherId, this._lessonId, startDate)));
    }
    return finalObservable;
  }

  public commitLesson() {
    const needToStart = this.isComming;
    const needToFinish = needToStart || this.isRunning;
    const finishDate = (!this.isRunning && this.shouldBeFinished) ? this.plannedLessonFinish : null;

    let finalObservable = this.prepareToStart();

    // update lesson type
    if (this.newLessonType !== this.lessonDetails.lessonType) {
      finalObservable = finalObservable.pipe(
        switchMap( () => this.teacherRest.updateLessonType(this._teacherId, this._lessonId, this.newLessonType))
      );
    }

    // finish the lesson with confirming progress or just commit the progress
    if (needToFinish) {
      finalObservable = finalObservable.pipe(
        switchMap( () => this.teacherRest.finishLesson(this._teacherId, this._lessonId, this.lessonDetails.progressCommited, finishDate))
      );
    } else {
      finalObservable = finalObservable.pipe(
        switchMap( () => this.teacherRest.updateLessonProgress(this._teacherId, this._lessonId, this.lessonDetails.progressCommited))
      );
    }

    // save students context
    finalObservable = finalObservable.pipe(
      switchMap( () => this.teacherRest.saveStudentProductContext(
        this._teacherId,
        this._studentId,
        this.lessonDetails.course.product.code,
        this.studentContext)
        )
    );

    if (this.lessonDetails.message) {
      finalObservable = finalObservable.pipe(
        switchMap( () => this.teacherRest.postLessonComment(this._teacherId, this._lessonId, this.lessonDetails.message))
      );
    }

    if (this.lessonDetails.studentMessage) {
      finalObservable = finalObservable.pipe(
        switchMap(
          () => this.teacherRest
            .postLessonMessageForStudent(this._teacherId, this._lessonId, this._studentId, this.lessonDetails.studentMessage)
          )
      );
    }
    finalObservable.pipe(
      finalize(() => this.loadLessonDetails().subscribe())
    ).subscribe()
    // finalObservable.pipe(
    //   tap( lessonDetails => this.setLessonDetails(lessonDetails)),
    //   switchMap( () => this.loadLessonDetails())
    // ).subscribe(null, () => this.loadLessonDetails());
  }

  historyNext() {
    if (!this.hasHistoryNext) { return; }
    this.historyPageable = this.historyPageable.next();
    this.loadLessonHistoryPage().subscribe();
  }

  loadData(): any {
    if (this._lessonId == null || this._lessonId === undefined
      || this._teacherId == null || this._teacherId === undefined
      || this._studentId == null || this._studentId === undefined) {
        return;
      }
      this.historyPageable = Pageable.of(0, 10, ['lessonStudent.metricDetails.plannedStartDate ,DESC']);
      this.history = [];
      forkJoin(
        this.loadStudentTeachnicalProfile(),
        this.loadLessonDetails(),
        this.loadLessonHistoryPage()
      ).subscribe();
  }
  loadStudentTeachnicalProfile(): Observable<ApiPersonTechnicalProfile> {
    return this.teacherRest.getStudentTechnicalProfile(this._teacherId, this._studentId).pipe(
      tap( studentTechnicalProfile => this.studentTechnicalProfile = studentTechnicalProfile)
    );
  }

  ngOnInit() {
    this.route.parent.paramMap.subscribe( params => this.teacherId = Number(params.get('teacherId')));
    this.route.paramMap.subscribe( params => {
      this.lessonId = Number(params.get('lessonId'));
      this.studentId = Number(params.get('studentId'));
    });
    this.timingSubscription = interval(5000).pipe(
      tap(() => this.updateTimingVariables())
    ).subscribe();
  }

  private courseCodeChecked: string = null;
  private hasGift = false;

  haveGift() {
    if (!this.isComplete || !this.studentContext || !this.studentContext.currentCourse) return false;
    if (!this.courseLoaded || this.studentContext.currentCourse.code !== this.courseLoaded.code) return false;
    if (this.lessonDetails.lessonType !== 'Prova') return false;
    if (!this.courseCodeChecked || this.courseCodeChecked !== this.courseLoaded.code) {
      this.courseCodeChecked = this.courseLoaded.code;
      this.hasGift =false;
      if (!this.courseCodeChecked) return false;
      this.teacherRest.findStudentProductGift(this._teacherId, this._studentId, this.courseCodeChecked)
        .subscribe( gift => {
          this.hasGift = gift == null
        })
    }
    return this.hasGift;
  }


  giveGift() {
    if (this.haveGift()) {
      this.teacherRest.giveStudentProductGift(this._teacherId, this._studentId, this.courseCodeChecked)
        .subscribe( _ => this.hasGift = false)
    }
  }

  getGiftButtonDescription() {
    return `Give ${this.studentContext.currentCourse.name} EBook`;
  }

  buildVersionNumber(courseCode: string, productVersion: string) {
    if (!courseCode.startsWith("en.") && !productVersion) return null;
    if (!productVersion) return '2nd Ed.';
    const intVer = parseInt(productVersion);
    if (isNaN(intVer)) return `v: ${productVersion}`;
    const lastDigit = intVer % 10;
    let postfix: string;
    if (intVer > 3 && intVer < 20) postfix = 'th';
    else if (lastDigit == 1) postfix = 'st';
    else if (lastDigit == 2) postfix = 'nd';
    else if (lastDigit == 3) postfix = 'rd';
    else postfix = 'th';
    return `${intVer}${postfix} Ed.`;
  }

  getLessonProductVersion(): string {
    return this.buildVersionNumber(this.lessonDetails.course.code, this.lessonDetails.productVersion);
  }

  isLessonInOldVersion() {
    if (!this.lessonDetails.course.code.startsWith('en.')) return false;
    if (this.lessonDetails.productVersion == null) return true;
    return false;
  }

  getProductVersion() {
    return this.buildVersionNumber(this.lessonDetails.course.code, this.studentContext?.productVersion);
  }
  isRedLabel() {
    return this.lessonDetails.course.code.startsWith('en.') && this.studentContext?.productVersion == null
  }

  isOldEnglishVersion() {
    return this.lessonDetails.course.code.startsWith('en.') && this.studentContext?.productVersion == null
  }

  migrateToNewEnglish() {
    this.isMigratingInProgress = true
    this.teacherRest.updateProductVersion(this._teacherId, this._studentId, this.lessonDetails.course.product.code, "3").pipe(
      finalize(() => {
        this.isMigratingInProgress = false
        this.migrateToNewEnglishConfirmationModal.hide()
        this.loadData()
      })
    ).subscribe()
  }
}
