import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {LessonDetailsEvent, LessonType} from 'src/app/components/student/student-lessons/student-lessons.component';
import { ApiLessonInstance } from 'src/app/model/rest/rest-model';

@Component({
  selector: 'app-manager-student-lessons-page',
  templateUrl: './manager-student-lessons-page.component.html',
  styleUrls: ['./manager-student-lessons-page.component.css']
})
export class ManagerStudentLessonsPageComponent implements OnInit {
  schoolId: string;
  studentId: string;
  lessonTypes = LessonType;

  constructor(activatedRoute: ActivatedRoute,
    private router: Router) {
    activatedRoute.parent.paramMap.subscribe( params => {
      this.schoolId = params.get('schoolId');
      this.studentId = params.get('studentId');
    });
  }

  ngOnInit() {
  }

  openLessonDetails(event: LessonDetailsEvent) {
    if (event.event.altKey || event.event.shiftKey) {
      const urlTree = this.router.createUrlTree(['school', this.schoolId, 'student', this.studentId, 'lessons', event.lesson.id]);
      const url = this.router.serializeUrl(urlTree)
      const handle = window.open(url, '_blank');
      handle.blur()
      window.focus()
      return;
    }
    this.router.navigate(['school', this.schoolId, 'student', this.studentId, 'lessons', event.lesson.id ]);
  }

}
