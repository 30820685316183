<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-10 p-0">
            <h5 class="font-weight-bold mb-0">{{ getName() }}</h5>
        </div>
        <div class="col-2 p-0 text-end">
            <button class="btn" (click)="delete()">
                <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" style="scale: 1.4;">
                    <path fill="#d93120" d="M7.042 16.042q-.459 0-.782-.323-.322-.323-.322-.802V5.542h-.98v-.604h3.063v-.792h3.958v.792h3.083v.604h-.979v9.375q0 .479-.323.802-.322.323-.802.323Zm6.437-10.5H6.542v9.375q0 .229.146.375.145.146.354.146h5.916q.188 0 .354-.157.167-.156.167-.364Zm-4.75 8.416h.604V7.021h-.604Zm1.938 0h.604V7.021h-.604ZM6.542 5.542v9.896-.521Z"/>
                </svg>
            </button>
        </div>
    </div>
    <div class="row align-items-center justify-content-between">
        <div class="col px-0">
            <label class="text-muted text-xsmall d-block">Amount</label>
            <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn py-2 px-3" (click)="decrement()">-</button>
                <button type="button" class="btn py-2 px-3 pe-none">{{ getQuantity() }}</button>
                <button type="button" class="btn py-2 px-3" (click)="increment()">+</button>
            </div>
        </div>
        <div class="col text-end mt-4">
            <span>{{ getSum() }}</span>
        </div>
    </div>
</div>