import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ApiPerson, ApiPersonalProfile } from 'src/app/model/rest/rest-model';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { StudentDashboardService } from 'src/app/services/student-dashboard.service';
import { ThemeService } from 'src/app/services/theme.service';
import { PersonNameExtractor } from 'src/app/utils/profile-photo-url-extractor';
import {Subscription} from "rxjs";
import {AuthServiceProvider} from "../../../services/col2/auth.service";

@Component({
  selector: 'app-student-main-nav',
  templateUrl: './student-main-nav.component.html',
  styleUrls: ['./student-main-nav.component.css']
})
export class StudentMainNavComponent implements OnInit, OnDestroy {


  @ViewChild('main', {static: true})
    public mainMenuRef: ElementRef;

  @ViewChild('profileMenu', {static: true})
    public profileMenu: ElementRef;

  @Input() activeSegments: string[]
  @Input() lang: string
  @Input() isOffcanvas: boolean = false

  selfPerson: ApiPerson<ApiPersonalProfile>;
  studentId: string;
  public hasBack: boolean = false;
  private nestedStack: HTMLElement[] = [];
  links = document.getElementsByClassName('nav-item')
  theme: string;
  private themeSubscription: Subscription;

  constructor(private authService: AuthServiceProvider,
    private activatedRoute: ActivatedRoute,
    private studentCacheProxy: StudentCacheProxyService,
    private router: Router,
    private dashboardService: StudentDashboardService,
    public themeService: ThemeService
    ) { }

  ngOnInit(): void {
    this.activatedRoute.children[0].paramMap.subscribe( params => {
      this.studentId = params.get('studentId');
      this.loadSelf();
    });
    for (let i = 0; i < this.links.length; i++) {
      this.links[i].addEventListener("click", () => {
        if(this.nestedStack) this.nestedStack.pop();
        this.hasBack = false
      })
    }
    this.themeSubscription = this.themeService.getTheme().subscribe(
      theme => this.theme = theme
    )
  }

  loadSelf(): any {
    if (!this.studentId) { return; }
    this.studentCacheProxy.loadSelfPerson(Number(this.studentId)).pipe(
      tap( selfPerson => this.selfPerson = selfPerson)
    ).subscribe();
  }

  isActivePath(segment: string) {
    return this.activeSegments[3]?.includes(segment)? true : false
  }

  getDismiss() {
    return this.isOffcanvas && "offcanvas"
  }

  isHidden(menu: HTMLElement) {
    if (!this.nestedStack.length && menu === this.mainMenuRef.nativeElement)
      return false;
    return this.nestedStack[this.nestedStack.length - 1] !== menu;
  }

  getName(person: ApiPerson<ApiPersonalProfile>) {
    return PersonNameExtractor.getPersonName(person);
  }

  onOpen(menu: HTMLElement) {
    this.nestedStack.push(menu);
    this.hasBack = true;
  }

  onBack() {
    this.nestedStack.pop();
    this.hasBack = this.nestedStack.length > 0;
  }

  onClose(): void {
    if(this.nestedStack)
      this.nestedStack.pop();
    this.hasBack = false
  }

  public logout(): void {
    this.authService.get().subscribe(api => api.logout());
  }

  getStudentName(){
    return this.selfPerson? `${this.selfPerson?.name} ${this.selfPerson?.surname}` : null
  }

  goToDashboardTo(chosenSection: string) {
    this.dashboardService.setChosenSection(chosenSection)
      this.router.navigate(
        [`/student/${this.studentId}/dashboard`],
      )
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }
}
