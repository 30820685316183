import { Injectable } from '@angular/core';
import { Observable, throwError , Observer, Subscription} from 'rxjs';
import { ErrorBase } from '../../model/errors/ErrorBase';

@Injectable({
  providedIn: 'root'
})
/*
Error handling service which incorporates with the error interceptor to provide error observable
*/
export class HttpErrorsService {

  constructor() { }

  private observers: Observer<ErrorBase>[] = [];

  errors$ = new Observable<ErrorBase>((observer) => {
    this.observers.push(observer);
    const service = this;
    return {unsubscribe() {
      service.observers.splice(service.observers.indexOf(observer), 1);
    }};
  });
  handleError(error: ErrorBase): void {
    if (this.observers) { this.observers.forEach(observer =>
      observer.next(error)
    );
    }
  }
}
