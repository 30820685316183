import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManagerRestService } from 'src/app/services/rest/manager-rest.service';
import { ApiLessonInstance, ApiProductContext,
  ApiPerson, ApiCourse, ApiLessonStatus, ApiLessonProgress,
  ApiLessonMetric, ApiLessonFlag, ApiLessonMessage, ApiTeacherLessonInstance, ApiLearningUnitTeacher, ApiPersonalProfile } from 'src/app/model/rest/rest-model';
import { Dates } from 'src/app/utils/calendar-utils';
import { PersonNameExtractor, ProfilePhotoUrlExtractor } from 'src/app/utils/profile-photo-url-extractor';
import {tap, map, switchMap, finalize} from 'rxjs/operators';
import { Observable, empty } from 'rxjs';
import { Page, Pageable } from 'src/app/model/rest/base';
import { LessonTypeColors } from 'src/app/utils/utils';
import {ModalComponent} from "../../../components/modal/modal.component";

@Component({
  selector: 'app-manager-lesson-details-page',
  templateUrl: './manager-lesson-details-page.component.html',
  styleUrls: ['./manager-lesson-details-page.component.css']
})
export class ManagerLessonDetailsPageComponent implements OnInit {

  @ViewChild('migrateToNewEnglishConfirmationModal', {static: true}) migrateToNewEnglishConfirmationModal: ModalComponent;
  _lessonId: number;
  _schoolId: number;
  _lessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  studentContext: ApiProductContext;
  productCourses: ApiCourse[];
  _cancelReason: string;
  _startDate: string;
  _finishProgress = new ApiLessonProgress();
  _finishDate: string;
  _squanderReason: string;
  _lessonDuration: number;
  _newFlagType: string;
  _newFlagDescription: string;
  _privateComment: string;
  _messageToStudent: string;
  studentProgress: ApiLessonProgress;
  estimatedProgress: ApiLessonProgress;
  flagTypes = ApiLessonFlag.allFlagTypes;
  historyPageable: Pageable;
  lastHistoryPage: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  history: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[] = [];
  hasHistoryNext: boolean;
  public isMigratingInProgress: boolean = false;
  private _studentId: number;

  set lessonId(lessonId: number) {
    this._lessonId = lessonId;
    this.loadData();
  }

  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.loadData();
  }

  set studentId(studentId: number) {
    this._studentId = studentId
  }
  get studentId() {
    return this._studentId;
  }

  set lessonDetails(lessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    this._lessonDetails = lessonDetails;
    this.fillLessonDuration();
    this.fillLessonMessages();
  }

  get lessonDetails() {
    return this._lessonDetails;
  }

  constructor(activatedRoute: ActivatedRoute,
    private managerRest: ManagerRestService) {
    activatedRoute.paramMap.subscribe( params => {
      this.lessonId = Number(params.get('lessonId'))
    });
    activatedRoute.parent.paramMap.subscribe( params => {
      this.studentId = Number(params.get('studentId'))
    });
    activatedRoute.parent.parent.paramMap.subscribe( params => {
      this.schoolId = Number(params.get('schoolId'))
    });
  }

  // helper function - used in getters / setters
  fillLessonMessages() {
    this._privateComment = this._messageToStudent = null;
    if (!this.lessonDetails || !this.lessonDetails.message) {
      return;
    }
    this._privateComment = this.lessonDetails.message.privateLessonComment;
    this._messageToStudent = this.lessonDetails.message.message;
  }

  fillLessonDuration() {
    this._startDate = Dates.simpleDateFormat(this.lessonDetails.lessonMetric.started);
    this._lessonDuration = this.lessonDetails.lessonMetric.duration;
    if (this._lessonDuration) {
      this._lessonDuration /= 1000 * 60;
      this._lessonDuration = Math.round(this._lessonDuration);
    }
  }

  // main load section
  loadData() {
    if (!this._schoolId || !this._lessonId) {
      return;
    }

    this.historyPageable = Pageable.of(0, 10, ['lessonStudent.metricDetails.plannedStartDate ,DESC']);
    this.history = [];

    // the order is important and calls are dependent on it
    this.loadLessonDetails().pipe(
      switchMap(() => this.loadProductCourses()),
      switchMap(() => this.loadStudentContext()),
      switchMap(() => this.loadStudentProgress()),
      switchMap(() => this.estimateNextProgress()),
      switchMap(() => this.loadLessonHistoryPage())
    )
    .subscribe();
  }

  // main data loaders

  loadLessonDetails() {
    if (!this._schoolId || !this._lessonId) {
      return empty();
    }

    return this.managerRest.getLessonDetails(this._schoolId, this._lessonId)
    .pipe(
      tap( lessonDetails =>
        this.lessonDetails = lessonDetails),
    );
  }

  loadProductCourses(): Observable<ApiCourse[]> {
    if (!this.lessonDetails) {
      return  empty();
    }

    return this.managerRest.listProductCourses(this._schoolId, this.lessonDetails.course.product.code)
    .pipe(
      tap(courses => this.productCourses = courses)
    );
  }

  loadStudentContext() {
    if (!this.lessonDetails || !this.lessonDetails.teacher || !this.lessonDetails.students || this.lessonDetails.students.length !== 1) {
      return empty();
    }

    return this.managerRest.getStudentProductContext(
      this._schoolId,
      this.lessonDetails.students[0].id,
      this.lessonDetails.course.product.code)
    .pipe(
      tap(sc => this.studentContext = sc),
      tap(sc => this.updateContextCourse())
    );
  }

  loadStudentProgress() {
    if (!this.lessonDetails) {
      return empty();
    }

    return this.managerRest.getStudentProgress(
      this._schoolId,
      this.lessonDetails.students[0].id).pipe(
        map ( p => p.find( item => item.productCode === this.lessonDetails.course.product.code )),
        map( p => this.createIfEmptyProgress(p)),
        tap( p => this.studentProgress = p),
      );
  }

  estimateNextProgress() {
    return this.managerRest.getStudentProgressNextEstimation(
      this._schoolId,
      this.lessonDetails.students[0].id,
      this.studentProgress,
      1
    ).pipe(
      map ( pA => pA[0]),
      tap ( p => this.estimatedProgress = p)
    );
  }

  loadLessonHistoryPage(): Observable<Page<ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    return this.managerRest.listLessonHistory(this._schoolId,
      this._lessonId,
      this.lessonDetails.students[0].id, this.historyPageable).pipe(
      tap( lessonHistoryPage => {
        this.lastHistoryPage = lessonHistoryPage;
        this.history = this.history.concat(lessonHistoryPage.content);
        this.hasHistoryNext = !lessonHistoryPage.last;
      })
    );
  }

  // loaders helpers

  private createIfEmptyProgress(progress: ApiLessonProgress) {
    if (progress) {
      return progress;
    }
    progress = new ApiLessonProgress();
    progress.courseCode = this.lessonDetails.course.code;
    progress.productCode = this.lessonDetails.course.product.code;
    progress.nwp = 0;
    progress.start = 0;
    progress.reading = 0;
    return progress;
  }

  updateContextCourse() {
    if (!this.studentContext || !this.productCourses || !this.studentContext.currentCourse) {
      return;
    }
    this.studentContext.currentCourse = this.productCourses.find ( c => c.code === this.studentContext.currentCourse.code );
  }

  getLessonStatus() {
    if (!this.lessonDetails) {
      return null;
    }
    return ApiLessonStatus[this.lessonDetails.lessonStatus];
  }

  // view accessors

  public mayBook() {
    if (this.getLessonStatus() !== ApiLessonStatus.Initializing) {
      return false;
    }
    return true;
  }

  public mayConfirm() {
    if (this.getLessonStatus() !== ApiLessonStatus.Booked) {
      return false;
    }
    return true;
  }

  public mayCancel() {
    if (this.getLessonStatus() === ApiLessonStatus.Booked /* ||
    (this.getLessonStatus() === ApiLessonStatus.Due
      && Dates.diff(new Date(), this.getLessonDate()) > TimeUnits.Hours(12).toMilis()) */
      ) {
        return true;
      }
  }

  public mayStart() {
    if (this.getLessonStatus() === ApiLessonStatus.Due) {
      return true;
    }
    return false;
  }

  public mayFinish() {
    if (this.getLessonStatus() === ApiLessonStatus.InProgress) {
      return true;
    }
    return false;
  }

  public maySquander() {
    if (this.getLessonStatus() === ApiLessonStatus.Due && !this.mayCancel()) {
      return true;
    }

    return false;
  }

  public mayUpdateLessonTime() {
    if (this.getLessonStatus() === ApiLessonStatus.Complete) {
      return true;
    }

    return false;
  }

  hasPrivateComment(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (lesson
      && lesson.message
      && lesson.message.privateLessonComment
      && lesson.message.privateLessonComment.length > 1) {
      return true;
    }
    return false;
  }

  getPrivateComment(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!this.hasPrivateComment(lesson)) {
      return '';
    }
    return  lesson.message.privateLessonComment;
  }

  hasComment(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (lesson
      && lesson.message
      && lesson.message.lessonComment
      && lesson.message.lessonComment.length > 1) {
        return true;
      }
      return false;
  }

  getComment(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!this.hasComment(lesson)) {
      return '';
    }
    return lesson.message.lessonComment;
  }

  hasStudentMessage() {
    if (this.lessonDetails
      && this.lessonDetails.studentMessage
      && this.lessonDetails.studentMessage.length > 1) {
      return true;
    }

    return false;
  }

  public hasFlags() {
    if (!this.lessonDetails || !this.lessonDetails.flags || this.lessonDetails.flags.length === 0) {
      return false;
    }

    return true;
  }

  public getLessonDate() {
    if (!this.lessonDetails || !this.lessonDetails.lessonMetric) {
      return null;
    }
    return this.lessonDetails.lessonMetric.plannedStartDate;
  }

  public getStartDate() {
    if (!this.lessonDetails || !this.lessonDetails.lessonMetric) {
      return null;
    }
    return this.lessonDetails.lessonMetric.started;
  }

  public getDuration() {
    if (!this.lessonDetails || !this.lessonDetails.lessonMetric) {
      return null;
    }
    return Dates.toTimeStr(this.lessonDetails.lessonMetric.duration);
  }

  public getFlagTypeName(flag: string) {
    return ApiLessonFlag.getFLagDescription(flag);
  }

  public getPersonName(person: ApiPerson<ApiPersonalProfile>) {
    return PersonNameExtractor.getPersonName(person);
  }

  public getPersonPhoto(person: ApiPerson<ApiPersonalProfile>) {
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(person);
  }

  public getLessonColorClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return LessonTypeColors.getLessonHistoryTableColorClass(lesson);
  }

  // lesson actions

  public book() {
    this.managerRest.bookLesson(this._schoolId, this._lessonId).pipe(
      tap( lessonDetails => this.lessonDetails = lessonDetails)
    ).subscribe();
  }

  public confirm() {
    this.managerRest.commitLessonBooking(this._schoolId, this._lessonId).pipe(
      tap ( l => this.lessonDetails = l)
    ).subscribe();
  }

  public cancel() {
    this.managerRest.cancelLesson(this._schoolId, this._lessonId, this._cancelReason).pipe(
      tap( l => this.lessonDetails = l)
    ).subscribe();
  }

  public squander() {
    this.managerRest.squanderLesson(this._schoolId, this._lessonId, this._squanderReason).pipe(
      tap( l => this.lessonDetails = l)
    ).subscribe();
  }

  public start() {
    this.managerRest.startLesson(this._schoolId, this._lessonId, Dates.simpleDateTimeParse(this._startDate)).pipe(
      tap( l => this.lessonDetails = l)
    ).subscribe();
  }

  public finish() {
    this.managerRest.finishLesson(this._schoolId, this._lessonId, this._finishProgress, Dates.simpleDateTimeParse(this._finishDate))
    .pipe(
      tap( l => this.lessonDetails = l)
    ).subscribe();
  }

  public updateLessonType() {
    this.managerRest.updateLessonType(this._schoolId, this._lessonId, this.lessonDetails.lessonType).pipe(
      tap( l => this.lessonDetails = l)
    ).subscribe();
  }

  public updateProgress() {
    this.managerRest.updateLessonProgress(this._schoolId, this._lessonId, this.lessonDetails.progressCommited).pipe(
      tap( l => this.lessonDetails = l),
      switchMap( () => this.loadStudentProgress())
    ).subscribe();
  }

  public updateLessonTime() {
    const metric = new ApiLessonMetric();
    metric.started = Dates.simpleDateTimeParse(this._startDate);
    metric.duration = this._lessonDuration * 1000 * 60;
    this.managerRest.updateLessonMetric(this._schoolId, this._lessonId, metric)
    .pipe(
      tap ( l => this.lessonDetails = l)
    ).subscribe();
  }

  public saveFlag() {
    const flag = new ApiLessonFlag();
    flag.flagType = this._newFlagType;
    flag.description = this._newFlagDescription;

    this.managerRest.registerLessonFlag(this._schoolId, this._lessonId, flag)
    .pipe(
      switchMap( () => this.loadLessonDetails())
    ).subscribe(
      () => {
        this._newFlagDescription = null;
        this._newFlagType = null;
      }
    );
  }

  public saveStudentMessage() {
    this.managerRest.postLessonMessageForStudent(this._schoolId, this._lessonId, this._lessonDetails.students[0].id, this._messageToStudent)
    .pipe(
      tap ( l => this.lessonDetails = l)
    ).subscribe();
  }

  public saveComment() {
    const message = new ApiLessonMessage();
    message.privateLessonComment = this._privateComment;
    this.managerRest.postLessonComment(this._schoolId, this._lessonId, message).pipe(
      tap ( l => this.lessonDetails = l)
    ).subscribe();
  }

  public saveStudentContext() {
    this.managerRest.saveStudentProductContext(
      this._schoolId,
      this.lessonDetails.students[0].id,
      this.lessonDetails.course.product.code,
      this.studentContext)
      .pipe(
        tap (ctx => this.studentContext = ctx),
        tap (ctx => this.updateContextCourse())
        )
      .subscribe();
  }

  historyNext() {
    if (!this.hasHistoryNext) { return; }
    this.historyPageable = this.historyPageable.next();
    this.loadLessonHistoryPage().subscribe();
  }

  public getHistoryDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!lesson || !lesson.lessonMetric) { return null; }
    if (!lesson.lessonMetric.started ) {
      return lesson.lessonMetric.plannedStartDate;
    }
    return lesson.lessonMetric.started;
  }

  ngOnInit() {
  }

  migrateToNewEnglish() {
    this.isMigratingInProgress = true
    this.managerRest.updateProductVersion(this._schoolId, this.studentId, this.lessonDetails.course.product.code, "3").pipe(
      finalize(() => {
        this.isMigratingInProgress = false
        this.migrateToNewEnglishConfirmationModal.hide()
      })
    ).subscribe()
  }

  getProductVersion() {
    if(this.lessonDetails.course.code.startsWith('en.') && this.studentContext?.productVersion == null)
      return 'v: 2'
    else if(this.studentContext?.productVersion != null)
      return `v: ${this.studentContext.productVersion}`
  }
  isRedLabel() {
    return this.lessonDetails.course.code.startsWith('en.') && this.studentContext?.productVersion == null
  }

  isOldEnglishVersion() {
    return this.lessonDetails.course.code.startsWith('en.') && this.studentContext?.productVersion == null
  }

}
