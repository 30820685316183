import { Component, OnInit, OnDestroy } from '@angular/core';
import { StudentContextService } from 'src/app/services/ctx/student-context.service';
import { combineLatest, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-student-teachers-list-page',
  templateUrl: './student-teachers-list-page.component.html',
  styleUrls: ['./student-teachers-list-page.component.css']
})
export class StudentTeachersListPageComponent implements OnInit, OnDestroy {
  studentId: string;
  langCode: string;
  langCodeSubscription: Subscription;

  constructor(studentContext: StudentContextService,
    activatedRoute: ActivatedRoute
    ) {
      activatedRoute.parent.paramMap.subscribe ( params => this.studentId = params.get('studentId'));
      this.langCodeSubscription = studentContext.langCodeSubject.subscribe ( langCode => this.langCode = langCode );
    }

  ngOnDestroy(): void {
    this.langCodeSubscription.unsubscribe();
  }

  ngOnInit() {
  }

}
