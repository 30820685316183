import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { createPopper } from "@popperjs/core";

@Component({
  selector: "app-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.css"],
})
export class TooltipComponent implements OnInit {
  @ViewChild("tooltipBtn", { static: true })
  tooltipBtn: ElementRef;
  @ViewChild("tooltip", { static: true })
  tooltip: ElementRef;
  @ViewChild("screenDim", { static: true })
  screenDim: ElementRef;

  popperInstance;
  @Input() set showContent(value: boolean) {
    if (value) {
      this.show();
      this.toolbarIsShown = true;
    } else {
      this.hide();
      this.toolbarIsShown = false;
    }
    this.popperInstance?.update();
  }
  @Input() cancelable? = true;
  toolbarIsShown: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.popperInstance = createPopper(
      this.tooltipBtn.nativeElement,
      this.tooltip.nativeElement,
      {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [-210, 8],
            },
          },
        ],
      }
    );

    this.tooltipBtn.nativeElement.addEventListener("click", () => {
      if (this.toolbarIsShown) this.hide();
      else this.show();
      this.toolbarIsShown = !this.toolbarIsShown;
      this.popperInstance?.update();
    });
  }

  clickDim() {
    if (this.cancelable) {
      this.toolbarIsShown = false;
      this.hide();
    }
  }

  private hide() {
    this.tooltip.nativeElement.classList.remove("tooltip-appear");
    this.screenDim.nativeElement.classList.remove("dim-appear");
    setTimeout(() => {
      this.tooltip.nativeElement.classList.add("tooltip-disappear");
      this.screenDim.nativeElement.classList.add("dim-disappear");
    }, 1);
    setTimeout(() => {
      this.tooltip.nativeElement.setAttribute("hidden", "");
      this.screenDim.nativeElement.setAttribute("hidden", "");
    }, 300);
  }

  private show() {
    this.tooltip.nativeElement.classList.remove("tooltip-disappear");
    this.screenDim.nativeElement.classList.remove("dim-disappear");
    this.tooltip.nativeElement.classList.add("tooltip-appear");
    this.screenDim.nativeElement.classList.add("dim-appear");
    this.tooltip.nativeElement.removeAttribute("hidden");
    this.screenDim.nativeElement.removeAttribute("hidden");
  }
}
