import {
  ApiCompetence,
  ApiCourse,
  ApiCourseProduct,
  ApiLearningUnitStudentBase,
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiPersonalProfile,
} from "./rest-model";

export class ApiBase {
  id: number;
}

export class ApiStudentSchedule extends ApiBase {
  startTime: Date;
  duration: number;
  lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  course: ApiCourse;
  competence: ApiCompetence;
  teacher: ApiLearningUnitTeacher;
  students: ApiLearningUnitStudentBase[];
  timezone: string;
}

export class ApiTeacherScore {
  teacher: ApiLearningUnitTeacher;
  score: number;
}

export class ApiTeacherScoringFact extends ApiBase {
  studentRelated: ApiLearningUnitStudentBase;
  score: number;
  tag: string;
  addDate: Date;
  removeDate: Date;
}

export class ApiTeacherWorktime extends ApiBase {
  teacher: ApiLearningUnitTeacher;
  startDate: Date;
  duration: number;
  product: ApiCourseProduct;
  competences: ApiCompetence[];
  minimumOvertake: number;
}

export enum RecurringType {
  Single,
  Daily,
  Weekly,
  Monthly,
}

export class RecurringTypeUtils {
  public static values = [
    RecurringType.Single,
    RecurringType.Daily,
    RecurringType.Weekly,
    RecurringType.Monthly,
  ];
  static days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  public static getName(type: RecurringType) {
    switch (type) {
      case RecurringType.Single:
        return "Single";
      case RecurringType.Monthly:
        return "Monthly";
      case RecurringType.Daily:
        return "Daily";
      case RecurringType.Weekly:
        return "Weekly";
    }
  }
  public static getRecurring(recurringType: RecurringType, weekDays: string[]) {
    let recurring: string
    let type = this.values[+recurringType]
    switch (type) {
      case RecurringType.Single:
        recurring = null;
        break
      case RecurringType.Monthly:
        recurring = "m:1";
        break
      case RecurringType.Daily:
        recurring = "w:1";
        break
      case RecurringType.Weekly:
        recurring = "w:1";
        break
    }
    if (type == RecurringType.Daily && weekDays.length > 0) {
      weekDays.forEach(d => {
        let dayIndex = this.days.indexOf(d)
        if (dayIndex > -1)
          recurring = recurring.concat(`:${dayIndex + 1}`)
      })
    }
    return recurring
  }
}

export class ApiWorktimeDefinition extends ApiBase {
  static days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  recurring: RecurringType;
  recurringFinish: Date;
  baseWorktime: ApiTeacherWorktime;
  timezoneOffset: number;
  weekDays: string[];
}
