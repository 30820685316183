import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd, UrlSegment } from '@angular/router';
import { ManagerRestService } from 'src/app/services/rest/manager-rest.service';
import { tap } from 'rxjs/operators';
import { ApiTeacherProfile, ApiPerson, ApiPersonalProfile } from 'src/app/model/rest/rest-model';
import { PersonNameExtractor } from 'src/app/utils/profile-photo-url-extractor';

@Component({
  selector: 'app-manager-teacher-details-layout',
  templateUrl: './manager-teacher-details-layout.component.html',
  styleUrls: ['./manager-teacher-details-layout.component.css']
})
export class ManagerTeacherDetailsLayoutComponent implements OnInit {

  _schoolId: number;
  _teacherId: number;
  teacherProfile: ApiTeacherProfile;
  currentFragment: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private mangerRest: ManagerRestService
  ) { }

  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.loadTeacher();
  }

  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.loadTeacher();
  }

  getPersonName(person: ApiPerson<ApiPersonalProfile>) {
    return PersonNameExtractor.getPersonName(person);
  }

  loadTeacher() {
    if (!this._schoolId || !this._teacherId) {
      return;
    }

    this.mangerRest.getTeacher(this._schoolId, this._teacherId).pipe(
      tap( teacherProfile => this.teacherProfile = teacherProfile )
    ).subscribe();
  }


  ngOnInit() {
    this.determineActiveButton(this.activeRoute.firstChild.snapshot.url);
    this.activeRoute.parent.paramMap.subscribe( params => this.schoolId = Number(params.get('schoolId')));
    this.activeRoute.paramMap.subscribe( params => this.teacherId = Number(params.get('teacherId')));
    this.router.events.subscribe( e => this.handleRouterEvent(e));
  }

  handleRouterEvent(e: any): void {
    if (!(e instanceof ActivationEnd)) {
      return;
    }
    this.determineActiveButton(e.snapshot.url);
  }

  determineActiveButton(urls: UrlSegment[]): void {
    if (urls.length > 0) {
      const lastPath = urls[urls.length - 1].path;
      if ( lastPath === 'lessons') {
        this.currentFragment = 'lessons';
      } else if (lastPath === 'profile') {
        this.currentFragment = 'profile';
      }
    }
  }


}
