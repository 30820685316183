<div id="screenDim"
     #screenDim
     hidden
     class="screen-dim"
     (click)="clickDim()">
</div>
<button
  #tooltipBtn
  type="button"
  class="btn p-0 tutorial"
  data-bs-toggle="tooltip"
  data-bs-placement="left"
  id="tooltip-btn"
  [ngClass]="{'pe-none': !cancelable, 'active': toolbarIsShown}"
>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="2 2 20 20">
    <g id="tooltip" transform="translate(-176 -32)">
      <path id="Path_7262" data-name="Path 7262" d="M-1.82-7.84l.126-1.274H-.658L-.784-7.84ZM-3.066-5.964l.294-.266h1.764L-1.666-.8h1.05l-.07.56L-.994,0H-2.716l.658-5.432H-3.136Z" transform="translate(189.875 48)"/>
    </g>
  </svg>
</button>
<div role="tooltip" #tooltip id="tooltip" class="tutorial" hidden>
  <ng-content select="content"></ng-content>
</div>
