<div class="card">
  <div class="card-header">
    <span i18n="Main page title@@student-teacher-details.title">Teacher Profile</span>
  </div>
  <div class="card-body">
    <div *ngIf="teacherData" class="row">
      <div class="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 mb-3 mb-md-5">
        <div  class="img-thumbnail">
          <div class="profile-photo" [style.background-image]="'url(' + getPhoto(teacherData.teacher) + ')'">
          </div>            
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-8 col-xl-9 mb-3 mb-md-5 d-flex flex-column justify-content-between">
        <div>
          <h4>{{getName(teacherData.teacher)}}</h4>
          <p *ngIf="teacherData.competence" class="text-muted">
            {{teacherData.competence.productCompetenceDescription}}
          </p>
        </div>
        <p *ngIf="teacherData.competence" class="text-primary text-end mb-0">
          <span i18n="@@tstudent-eacher-details.accent">Accent: </span>
          {{teacherData.competence.accent}}
        </p>
      </div>
    </div>
    <div *ngIf="videoUrl" class="row">
      <div class="col-12">
        <div class="ratio ratio-16x9">
          <iframe  [src]="videoUrl"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>