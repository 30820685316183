import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { WebStorageModule } from 'ngx-store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './auth/login/login.component';
import { NoMenuComponent } from './layout/no-menu/no-menu.component';
import { JwtInterceptorService } from './services/col2/jwt-interceptor.service';
import { HttpErrorsInterceptorService } from './services/col2/http-errors-interceptor.service';
import { HttpErrorMessageComponent } from './errors/http-error-message/http-error-message.component';
import { StudentMenuComponent } from './layout/with-menu/student-menu/student-menu.component';
import { TeacherMenuComponent } from './layout/with-menu/teacher-menu/teacher-menu.component';
import { OauthComponent } from './auth/oauth/oauth.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { RootComponent } from './auth/root/root.component';
import { ForbiddenComponent } from './auth/forbidden/forbidden.component';
import { CspaSummaryComponent } from './components/student/cspa-summary/cspa-summary.component';
import { StageDetailsComponent } from './components/student/cspa-summary/stage-details/stage-details.component';
import { CircleChartComponent } from './components/circle-chart/circle-chart.component';
import { ExerciseBlockComponent } from './components/student/cspa-summary/exercise-block/exercise-block.component';
import { BuyCoursesComponent } from './pages/student/buy-courses/buy-courses.component';
import { StudentLessonsComponent } from './components/student/student-lessons/student-lessons.component';
import { StudentProgressComponent } from './components/student/student-progress/student-progress.component';
import { StudentCreditsComponent } from './components/student/student-credits/student-credits.component';
import { StudentProfilePageComponent } from './pages/student/student-profile-page/student-profile-page.component';
import { WalletComponent } from './pages/student/wallet/wallet.component';
import { StudentLessonDetailsComponent } from './pages/student/student-lesson-details/student-lesson-details.component';
import { StudentPersonalProfileFormComponent
  } from './components/student/student-personal-profile-form/student-personal-profile-form.component';
import { StudentTechnicalProfileFormComponent
} from './components/student/student-technical-profile-form/student-technical-profile-form.component';
import { StudentPurchaseHistoryComponent } from './components/student/student-purchase-history/student-purchase-history.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TeacherLessonsComponent } from './components/teacher/teacher-lessons/teacher-lessons.component';
import { TeacherCreateLessonComponent } from './pages/teacher/teacher-create-lesson/teacher-create-lesson.component';
import { TeacherProfilePageComponent } from './pages/teacher/teacher-profile-page/teacher-profile-page.component';
import { TeacherPersonalProfileFormComponent
} from './components/teacher/teacher-personal-profile-form/teacher-personal-profile-form.component';
import { TeacherProfileFormComponent } from './components/teacher/teacher-profile-form/teacher-profile-form.component';
import { TeacherLessonDetailsComponent } from './pages/teacher/teacher-lesson-details/teacher-lesson-details.component';
import { WeekCalendarComponent } from './components/calendar/week-calendar/week-calendar.component';
import { TeacherMainCalendarComponent } from './components/teacher/teacher-main-calendar/teacher-main-calendar.component';
import { ModalComponent } from './components/modal/modal.component';
import { StudentMainCalendarComponent } from './components/student/student-main-calendar/student-main-calendar.component';
import { StudentDashboardComponent } from './pages/dashboard/student-dashboard/student-dashboard.component';
import { TeacherDashboardComponent } from './pages/dashboard/teacher-dashboard/teacher-dashboard.component';
import { ProfilePhotoEditorComponent } from './components/profile-photo-editor/profile-photo-editor.component';
import { ManagerMenuComponent } from './layout/with-menu/manager-menu/manager-menu.component';
import { ManagerDashboardComponent } from './pages/dashboard/manager-dashboard/manager-dashboard.component';
import { SchoolPeopleListComponent } from './components/manager/school-people-list/school-people-list.component';
import { ManagerStudentDetailsLayoutComponent } from './layout/manager-student-details-layout/manager-student-details-layout.component';
import { ManagerStudentCreditsPageComponent } from './pages/manager/manager-student-credits-page/manager-student-credits-page.component';
// tslint:disable-next-line:max-line-length
import { CreateStudentsCreditsBundleComponent } from './components/manager/create-students-credits-bundle/create-students-credits-bundle.component';
import { StudentReserveLessonPageComponent } from './pages/student/student-reserve-lesson/student-reserve-lesson-page.component';
import { StudentCommingLessonComponent } from './components/student/student-comming-lesson/student-comming-lesson.component';
import { StudentTransactionsComponent } from './components/student/student-transactions/student-transactions.component';
import { StudentTeacherListComponent } from './components/student/student-teacher-list/student-teacher-list.component';
import { CourseProductsPageComponent } from './pages/student/course-products-page/course-products-page.component';
import { EnglishCoreProductsComponent } from './pages/student/english-core-products/english-core-products.component';
import { SpanishCoreProductsComponent } from './pages/student/spanish-core-products/spanish-core-products.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { EnglishBusinessProductsComponent } from './pages/student/english-business-products/english-business-products.component';
import { AppAfterDateValidatorDirective } from './validators/after-date-validator-validator';
import { StudentTeachersListPageComponent } from './pages/student/student-teachers-list-page/student-teachers-list-page.component';
import { StudentTeachersGridComponent } from './components/student/student-teachers-grid/student-teachers-grid.component';
import { StudentTeacherDetailsPageComponent } from './pages/student/student-teacher-details-page/student-teacher-details-page.component';
import { StudentTeacherDetailsComponent } from './components/student/student-teacher-details/student-teacher-details.component';
import { RegisterComponent } from './auth/register/register.component';
import { EmptyNavBarComponent } from './layout/with-menu/empty-nav-bar/empty-nav-bar.component';
import { PageFooterComponent } from './layout/page-footer/page-footer.component';
import { ManagerStudentLessonsPageComponent } from './pages/manager/manager-student-lessons-page/manager-student-lessons-page.component';
import { ManagerTeacherDetailsLayoutComponent } from './layout/manager-teacher-details-layout/manager-teacher-details-layout.component';
import { ManagerTeacherLessonsPageComponent } from './pages/manager/manager-teacher-lessons-page/manager-teacher-lessons-page.component';
import { ReportsFilterComponent } from './components/manager/reports-filter/reports-filter.component';
import { TeachersLessonTypesReportComponent
} from './components/manager/teachers-lesson-types-report/teachers-lesson-types-report.component';
import { TeachersLessonTypesReportPageComponent
} from './pages/manager/teachers-lesson-types-report-page/teachers-lesson-types-report-page.component';
import { LessonsReportComponent } from './components/manager/lessons-report/lessons-report.component';
import { LessonsReportPageComponent } from './pages/manager/lessons-report-page/lessons-report-page.component';
import { StudentMissingDataBoxComponent } from './components/student/student-missing-data-box/student-missing-data-box.component';
import { ManagerLessonDetailsPageComponent } from './pages/manager/manager-lesson-details-page/manager-lesson-details-page.component';
import { TeacherQuickLessonCounterComponent
} from './components/teacher/teacher-quick-lesson-counter/teacher-quick-lesson-counter.component';
import { ManagerTeacherProfilePageComponent } from './pages/manager/manager-teacher-profile-page/manager-teacher-profile-page.component';
import { TeacherAllowancesComponent } from './components/teacher/teacher-allowances/teacher-allowances.component';
import { ManagerStudentProfilePageComponent } from './pages/manager/manager-student-profile-page/manager-student-profile-page.component';
import { ProductBoxComponent } from './components/product-box/product-box.component';
import { ContainerLayoutComponent } from './layout/container-layout/container-layout.component';
import { EnglishStarterPageComponent } from './pages/student/english-starter-page/english-starter-page.component';
import { SpanishStarterPageComponent } from './pages/student/spanish-starter-page/spanish-starter-page.component';
import { StudentStartersOfferComponent } from './components/student/student-starters-offer/student-starters-offer.component';
import { OpenComponent } from './auth/open/open.component';
import { ProvaBookedTutorialComponent } from './pages/student/tutorials/prova-booked-tutorial/prova-booked-tutorial.component';
import { ReserveLessonConfirmationPageComponent
} from './pages/student/reserve-lesson-confirmation-page/reserve-lesson-confirmation-page.component';
import { LessonConfirmationComponent } from './components/student/lesson-confirmation/lesson-confirmation.component';
import { DiscreteProgressComponent } from './components/discrete-progress/discrete-progress.component';
import { XmasBannerComponent } from './components/xmas-banner/xmas-banner.component';
import { FriendBannerComponent } from './components/friend-banner/friend-banner.component';
import { StudentContextBasedProgressComponent } from './components/student/student-context-based-progress/student-context-based-progress.component';
import {ExerciseSetHostComponent} from "./components/student/new-cspa-summary/exercise-set-host/exercise-set-host.component";
import {ExerciseBoxComponent} from "./components/student/new-cspa-summary/exercise-box/exercise-box.component";
import {ChapterProgressCircleComponent} from "./components/student/new-cspa-summary/chapter-progress-circle/chapter-progress-circle.component";
import {ChapterDetailsComponent} from "./components/student/new-cspa-summary/chapter-details/chapter-details.component";
import { WinterBannerComponent } from './components/winter-banner/winter-banner.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ShoppingCartHeaderComponent } from './components/student/shopping-cart-header/shopping-cart-header.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { ShoppingCartProductComponent } from './components/shopping-cart-product/shopping-cart-product.component';
import { OrderSuccessComponent } from './pages/order-success/order-success.component';
import { OrderFailComponent } from './pages/order-fail/order-fail.component';
import { StudentMainNavComponent } from './components/student/student-main-nav/student-main-nav.component';
import { LoadingDirective } from './directives/loading.directive';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { StudentCommingLessonListComponent } from './components/student/student-comming-lesson-list/student-comming-lesson-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NoMenuComponent,
    HttpErrorMessageComponent,
    StudentDashboardComponent,
    TeacherDashboardComponent,
    StudentMenuComponent,
    TeacherMenuComponent,
    OauthComponent,
    LogoutComponent,
    RootComponent,
    ForbiddenComponent,
    CspaSummaryComponent,
    StageDetailsComponent,
    CircleChartComponent,
    ExerciseBlockComponent,
    BuyCoursesComponent,
    StudentLessonsComponent,
    StudentProgressComponent,
    StudentCreditsComponent,
    StudentProfilePageComponent,
    WalletComponent,
    StudentLessonDetailsComponent,
    StudentPersonalProfileFormComponent,
    StudentTechnicalProfileFormComponent,
    StudentPurchaseHistoryComponent,
    NotFoundComponent,
    TeacherLessonsComponent,
    TeacherCreateLessonComponent,
    TeacherProfilePageComponent,
    TeacherPersonalProfileFormComponent,
    TeacherProfileFormComponent,
    TeacherLessonDetailsComponent,
    WeekCalendarComponent,
    TeacherMainCalendarComponent,
    ModalComponent,
    StudentMainCalendarComponent,
    ProfilePhotoEditorComponent,
    ManagerMenuComponent,
    ManagerDashboardComponent,
    SchoolPeopleListComponent,
    ManagerStudentDetailsLayoutComponent,
    ManagerStudentCreditsPageComponent,
    CreateStudentsCreditsBundleComponent,
    StudentReserveLessonPageComponent,
    StudentCommingLessonComponent,
    StudentTransactionsComponent,
    StudentTeacherListComponent,
    CourseProductsPageComponent,
    EnglishCoreProductsComponent,
    SpanishCoreProductsComponent,
    BreadcrumbComponent,
    EnglishBusinessProductsComponent,
    AppAfterDateValidatorDirective,
    StudentTeachersListPageComponent,
    StudentTeachersGridComponent,
    StudentTeacherDetailsPageComponent,
    StudentTeacherDetailsComponent,
    RegisterComponent,
    EmptyNavBarComponent,
    PageFooterComponent,
    ManagerStudentLessonsPageComponent,
    ManagerTeacherDetailsLayoutComponent,
    ManagerTeacherLessonsPageComponent,
    ReportsFilterComponent,
    TeachersLessonTypesReportComponent,
    TeachersLessonTypesReportPageComponent,
    LessonsReportComponent,
    LessonsReportPageComponent,
    StudentMissingDataBoxComponent,
    ManagerLessonDetailsPageComponent,
    TeacherQuickLessonCounterComponent,
    ManagerTeacherProfilePageComponent,
    TeacherAllowancesComponent,
    ManagerStudentProfilePageComponent,
    ProductBoxComponent,
    ContainerLayoutComponent,
    EnglishStarterPageComponent,
    SpanishStarterPageComponent,
    StudentStartersOfferComponent,
    OpenComponent,
    ProvaBookedTutorialComponent,
    ReserveLessonConfirmationPageComponent,
    LessonConfirmationComponent,
    DiscreteProgressComponent,
    XmasBannerComponent,
    FriendBannerComponent,
    StudentContextBasedProgressComponent,
    ExerciseSetHostComponent,
    ExerciseBoxComponent,
    ChapterProgressCircleComponent,
    ChapterDetailsComponent,
    WinterBannerComponent,
    PaginationComponent,
    ShoppingCartHeaderComponent,
    ShoppingCartComponent,
    ShoppingCartProductComponent,
    OrderSuccessComponent,
    OrderFailComponent,
    StudentMainNavComponent,
    LoadingDirective,
    TooltipComponent,
    StudentCommingLessonListComponent
  ],
  imports: [
    BrowserModule,
     HttpClientModule,
     AppRoutingModule,
     FormsModule,
     WebStorageModule,
     BrowserAnimationsModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'en' },
              { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true},
            { provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptorService, multi: true}],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
