<div class="min-vh-100 bg-dark-linear">
  <div class="container-fluid px-0">
    <div class="row py-5 border-bottom border-2 border-secondary">
      <h4 class="text-center">
        <span i18n="@@booking-confirmation.received">Hi, I've received your booking.<br>
          I'll see you at </span><strong>{{getLessonTime() | date: 'HH:mm'}}</strong><br><span i18n="@@common.on-a-EEEE-dd-MMMM">
            on </span><strong>{{getLessonTime() | date: 'EEEE dd MMMM'}}</strong>!
      </h4>
    </div>
    <div class="row">
      <div class="col-auto mx-auto">
        <div class="text-center py-5">
          <div class="rounded-circle profile-photo mb-5"
            [style.background-image]="'url(' + getTeacherPhoto() +')'">
          </div>

          <div class="text-center mx-5" *ngIf="isCancelling()">
            <h4 i18n="@@booking-confirmation.cancelling">Cancelling, please wait...</h4>
          </div>
          <div class="text-center mx-5" *ngIf="!isCancelling()">
            <button class="btn btn-lg btn-primary px-4 py-2 rounded-pill" (click)="onConfirm()" i18n="@@booking-confirmation.button-OK">Okay, I'll be there!</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
