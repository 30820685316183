<form #newBundleForm="ngForm" (submit)="newBundleForm.valid && registerBundle()">

  <div class="form-row">
    <div class="form-group col-auto">
      <label>Product</label>
      <select 
      name="product"
      #product="ngModel"
      [(ngModel)]="newBundle.product"
      class="form-control"
      [ngClass]="{ 'is-invalid': newBundleForm.submitted && product.invalid }"
      required>
        <option *ngFor="let prod of availableProducts" [ngValue]="prod">{{prod.name}}</option>
      </select>
    </div>
    <div class="form-group col-auto">
      <label>Number of lessons</label>
      <input 
      type="number" 
      class="form-control"
      name="lessonsNb"
      #lessonsNb="ngModel"
      [(ngModel)]="newBundle.available"
      required
      [ngClass]="{ 'is-invalid': newBundleForm.submitted && lessonsNb.invalid }"
      >
    </div>
    <div class="form-group col-auto">
      <label>Expiry date</label>
      <input
      type="date" 
      class="form-control"
      name="expiryDate"
      #expiryDate="ngModel"
      [(ngModel)]="newBundle.expiryDate"
      required
      [ngClass]="{ 'is-invalid': newBundleForm.submitted && expiryDate.invalid }">
    </div>
    <div class="form-group col">
      <label>name</label>
      <input 
      type="text" 
      class="form-control"
      name="name"
      #name="ngModel"
      [(ngModel)]="newBundle.name"
      required
      [ngClass]="{ 'is-invalid': newBundleForm.submitted && name.invalid }"
      minlength="2">
    </div>
    <div class="form-group col-auto">
      <label>price</label>
      <input
      type="text" 
      class="form-control"
      name="price"
      #price="ngModel"
      [(ngModel)]="newBundle.price"
      required
      [ngClass]="{ 'is-invalid': newBundleForm.submitted && price.invalid }"
      minlength="2">
    </div>
    
    
    
  </div>
  <div class="form-row">
   
    <div class="form-group col">
      <button class="btn btn-primary">Add the credits bundle</button>
    </div>
  </div>

</form>