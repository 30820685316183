import { environment } from "src/environments/environment";
import { AuthServiceProvider } from "./col2/auth.service";
import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";


@Injectable({
    providedIn: 'root'
  })
export class SentryService{
    constructor(authProvider: AuthServiceProvider){
        if(environment.logToSentry){
            authProvider.get().subscribe((authProvider) => {
                const scope = Sentry.getCurrentScope();
                scope.addEventProcessor( event => {
                    Sentry.setTag("userId", authProvider.getUserId())
                    const subject = authProvider.getSubject().join("/")
                    Sentry.setTag("userEmail", authProvider.getUserEmail())
                    Sentry.setTag("subject", subject)
                    return new Promise(resolve =>
                        resolve({
                        ...event
                        })
                    )
                })
            })
         }
    }

    static async initSentry(){
        if(environment.logToSentry){
            Sentry.init({
                dsn: "https://4b3c85042329fe7a23a982064fd2ad2e@o334018.ingest.us.sentry.io/4507407385886720",
                environment: environment.profileName,
                integrations: [
                  Sentry.browserTracingIntegration({
                    tracingOrigins: [environment.sentryTarget],
                }),
                ],
                
                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,
              });
        }
    }
}