import {Injectable} from '@angular/core';
import {TeacherRestService} from '../rest/teacher-rest.service';
import {Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {ApiTeacherProfile} from 'src/app/model/rest/rest-model';
import {AuthServiceProvider} from "../col2/auth.service";

@Injectable({
  providedIn: 'root'
})
export class TeacherContextService {
  private teacherProfile: ApiTeacherProfile;

  constructor(
    private teacherRest: TeacherRestService,
    private authService: AuthServiceProvider
  ) { }

  private getTeacherId(): Observable<number> {
    return this.authService.get().pipe(
      map( api => api.getTeacherId())
    );
  }

  private reloadTeacherProfile(): Observable<ApiTeacherProfile> {
    return this.getTeacherId().pipe(
      switchMap( teacherId => this.teacherRest.getProfile(teacherId))
    );
  }

  public getTeacherProfile() {
    if (this.teacherProfile) {
      return of(this.teacherProfile);
    } else {
      return this.reloadTeacherProfile()
      .pipe( tap( teacherProfile => this.teacherProfile = teacherProfile ));
    }
  }

}
