<div class="bg-radial min-vh-100">
  <div class="container pt-5">
    <nav class="navbar">
        <a class="navbar-brand" href="#" routerLink="/" >
          <img [src]="themeService.getLogoPath()" alt="Callan Online Logo"  class="d-inline-block align-top">
        </a>
    </nav>
  </div>
  <div class="container">
    <div class="row text-center">
      <div class="col-12 col-md-7 mx-auto">
        <img src="https://assets.callan.app/callanonline/404_icon.png" alt="Not Found" style="max-width: 15rem;">
        <p class="text-muted text-uppercase f-64 mb-0" i18n="@@not-found.title">Whoops!</p>
        <h1 class="mb-5" i18n="@@not-found.details">Whatever you’re looking for isn’t here.</h1>
        <div class="error-actions">
          <a routerLink="/" class="btn btn-primary rounded-pill btn-bubble px-5 py-2">
            <span class="text-uppercase" i18n="@@not-found.take-home">Home</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
