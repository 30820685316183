<app-modal #noCreditsModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 i18n="No credits modal title@@student-calendar.no-credits-modal.title">Not enough credits</h5>
      <button type="button" class="btn-close" (click)="noCreditsModal.hide()"  aria-label="Close"></button>
    </div>
    <div class="modal-body" i18n="No credits modal body@@student-calendar.no-credits-modal.body">
      Not enough credits to reserve next lesson. You need to buy more.
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="noCreditsModal.hide()"
        i18n="No credits modal skip buying credits button@@student-calendar.no-credits-modal.skip">Not Now</button>
      <button type="button" class="btn btn-primary" routerLink="/student/{{studentId}}/buy-courses/product/{{langCode}}" href="#"
        i18n="No credits modal, open buy page@@student-calendar.no-credits-modal.buy-credits">Buy More</button>
    </div>
  </div>

</app-modal>
<app-modal #reserveErrorModal>
  <div class="modal-content">
    <div class="modal-header" >
      <span i18n="Reservation error modal title@@student-calendar.reservation-error-modal.title">Unexpected error when trying to reserve a lesson.</span>
      <button type="button" class="btn-close" (click)="reserveErrorModal.hide()"  aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <p>{{_errorMessage}}</p>
    </div>
    <div class="modal-footer">
        <button type="submit" (click)="reserveErrorModal.hide()" class="btn btn-primary" i18n="@@common.Ok">Ok</button>
    </div>
  </div>
</app-modal>
<app-modal #scheduleLessonModal>
  <div class="modal-content border-accent">
    <div class="modal-header border-bottom border-accent px-4">
      <h5 class="modal-title" i18n="Lesson schedule modal, reserve lesson on|short sentence in modal title before scheduled lesson time@@student-calendar.schedule-modal.reserve-on">
        Reserve a lesson
      </h5>
      <button type="button" class="btn btn-sm btn-close my-auto" (click)="scheduleLessonModal.cancel()"  aria-label="Close"></button>
    </div>
    <form #f="ngForm" (ngSubmit)="scheduleLesson()">
      <div class="modal-body">
        <div class="form-group" *ngIf="!_slShowTeachers">
          <p>
            <span
            i18n="Lesson schedule modal, automatical teacher selection with manual availability@@student-calendar.schedule-modal.teacher-auto-select">
              We will now find an available tutor and book your lesson. <br>
                  If you wish, you can choose your tutor</span>
            <a tabindex (click)="_slShowTeachers=true" i18n="Lesson schedule modal - link for manual teacher selection@@student-calendar.schedule-modal.teacher-auto-select.manual-link">manually here</a>.
          </p>
        </div>
        <div *ngIf="_slShowTeachers">
          <p *ngIf="_slTeacherIds.length !== 1"
          i18n="Lesson schedule modal, select teacher list prefix@@student-calendar.schedule-modal.teacher-list.title">Click on the tutor to select:</p>
          <div tabindex="" *ngFor="let teacherId of _slTeacherIds" (click)="_slPrefferedTeacher=teacherId" class="row teacher-card rounded-3 align-items-center p-2 mx-2 mb-2 gx-3" [ngClass]="{'active': teacherId === _slPrefferedTeacher}">
            <div class="col-12 col-sm-auto">
              <div *ngIf="getProfilePhoto(teacherId)" class="rounded-circle img-thumbnail float-start" style="margin: auto;width: 60px;">
                <div class="profile-photo rounded-circle" [style.background-image]="'url(' + getProfilePhoto(teacherId) +')'">
                </div>
              </div>
              <div *ngIf="!getProfilePhoto(teacherId)" class="initials-circle-box">
                <span class="m-auto">{{ getInitials(teacherId) }}</span>
              </div>
            </div>
            <div class="col-12 col-sm-auto description">
              <p class="text-muted mb-0 f-12">Teacher:</p>
              <span>{{getTeacherName(teacherId)}}</span>
            </div>
            <div class="col-12 col-sm-auto ms-sm-auto">
              <p class="text-muted mb-0 f-12">Date:</p>
              <span>{{_slScheduleDate | date: 'dd MMMM yyyy'}}</span>
              <span class="text-primary"> | </span>
              <span>{{_slScheduleDate | date: 'HH:mm'}}</span>
            </div>

            <!-- any tutor is disabled until the correct implementation <a tabindex="" *ngIf="_slShowAll" class="list-group-item list-group-item-action no-teacher" [ngClass]="{'active': _slPrefferedTeacher == null}" (click)="_slPrefferedTeacher = null">
                Book any tutor
            </a>-->
          </div>
        </div>
      </div>
      <div class="modal-footer border-0">
      <!-- disabled when tutor not selected - remove after fix the issue -->
      <button type="submit" [attr.disabled]="_slPrefferedTeacher ? null : ''"  class="btn btn-primary rounded-pill px-3 me-2" i18n="Lesson schedule modal, submit button@@student-calendar.schedule-modal.reserve-btn">Reserve</button>
      </div>
    </form>
  </div>
</app-modal>
<app-week-calendar
  [loading]="loading"
  [events]="events"
  [_introductionState]="_introductionState"
  [studentId]="_studentId"
  (dateUpdate)="focusDate = $event"
  (timeSelect)="onCalendarClick($event)">
</app-week-calendar>
