import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class WindowsManagerService {
  private openedWindows: { [windowId: string]: Window} = {};

  public openWindow(id: string, url: string, target?: string, features?: string) {
    const openedWindow = window.open(url ,target, features);
    this.openedWindows[id] = openedWindow;
  }

  public closeWindow(id: string) {
    const openedWindow = this.openedWindows[id];
    if (openedWindow) {
      openedWindow.close();
      delete this.openedWindows[id];
    } 
  }
}
