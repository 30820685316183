import { Component, OnInit, Input } from '@angular/core';
import { StudentRestService } from 'src/app/services/rest/student-rest.service';
import { Pageable, Page, ComponentEvent, StateAwareComponent } from 'src/app/model/rest/base';
import { LessonBundeFilter, ApiLessonBundle } from 'src/app/model/rest/rest-model';
import { ManagerRestService } from 'src/app/services/rest/manager-rest.service';
import { Observable } from 'rxjs';

export class StudentPurchaseHistoryComponentEvent extends ComponentEvent {
}

@Component({
  selector: 'app-student-purchase-history',
  templateUrl: './student-purchase-history.component.html',
  styleUrls: ['./student-purchase-history.component.css']
})
export class StudentPurchaseHistoryComponent extends StateAwareComponent<StudentPurchaseHistoryComponentEvent> implements OnInit {
  _studentId: number;
  private pageable: Pageable;
  private filter: LessonBundeFilter;
  results: ApiLessonBundle[];
  lastPage: Page<ApiLessonBundle>;
  hasNext = false;
  _schoolId: number;

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
  }

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
  }

  constructor(
    private studentRest: StudentRestService,
    private managerRest: ManagerRestService
  ) {
    super();
   }


  initDataLoading(): any {
    if (this._studentId == null) { return; }
    this.clear();
    this.pageable = Pageable.of(0, 10 , ['lb.date,desc']);
    this.filter = new LessonBundeFilter(null, null);
    this.loadThePage();
  }

  loadThePage(): any {
    let dataLoadingObservable: Observable<Page<ApiLessonBundle>>;
    if (this._schoolId) {
      dataLoadingObservable = this.managerRest.findStudentLessonBundles(this._schoolId, this._studentId, this.filter, this.pageable);
    } else {
      dataLoadingObservable = this.studentRest.findStudentLessonBundles(this._studentId, this.filter, this.pageable);
    }

    dataLoadingObservable.subscribe( page => this.handleResultPage(page));
  }

  handleResultPage(page: Page<ApiLessonBundle>): void {
    if (this.lastPage == null && page != null) {
      this.stateEvent.emit(ComponentEvent.initialized());
      this.eventLoaded()
    }
    this.lastPage = page;
    this.results = this.results.concat(page.content);
    this.hasNext = !page.last;
  }

  next() {
    if (!this.hasNext || !this.pageable || !this.filter) { return; }
    this.pageable = this.pageable.next();
    this.loadThePage();
  }

  clear(): any {
    this.pageable = null;
    this.filter = null;
    this.results = [];
    this.hasNext = false;
    this.lastPage = null;
  }

  ngOnInit() {
    this.initDataLoading();
  }

}
