import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  StudentCommingLessonComponentEvent,
  CommingLessonDemoMode } from 'src/app/components/student/student-comming-lesson/student-comming-lesson.component';
import { ComponentEvent } from 'src/app/model/rest/base';
import { LangProductMapper } from 'src/app/utils/lang-mappers';
import { StudentContextService } from 'src/app/services/ctx/student-context.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiLearningUnitTeacher, ApiLessonInstance, ApiPersonalProfile } from 'src/app/model/rest/rest-model';

declare var $: any;

enum TutorialSteps {
  ProvaBooked, ClassroomReady, ClassroomEnter
}

@Component({
  selector: 'app-prova-booked-tutorial',
  templateUrl: './prova-booked-tutorial.component.html',
  styleUrls: ['./prova-booked-tutorial.component.css']
})
export class ProvaBookedTutorialComponent implements OnInit, OnDestroy, AfterViewInit {

  studentId: String;
  hideCommingLesson = false;
  langSubscription: Subscription;
  lang: string;
  langName: string;
  tutorialStep: TutorialSteps = TutorialSteps.ProvaBooked;
  lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  highlightTimeout: any;
  back: string;
  private highlightClass = 'on';
  private highlightAttr = 'data-tutorial-highlight';
  private highlightMarkClass = 'tutorial-highlight-mark';

  constructor(
    activatedRoute: ActivatedRoute,
    private studentContext: StudentContextService,
    private router: Router
  ) {
    activatedRoute.parent.parent.params.subscribe( params => {
      if (params['studentId']) {
        this.studentId = params['studentId'];
      }
    });
    activatedRoute.queryParams.subscribe( params => {
      if (params['back']) {
        this.back = params['back'];
      }
    });
  }

  getDemoMode(): CommingLessonDemoMode {
    if (this.tutorialStep === TutorialSteps.ClassroomReady) {
      return CommingLessonDemoMode.VideoRoomEnterButton;
    } else {
      return CommingLessonDemoMode.NoEnterButton;
    }
  }

  isShowBrowserWindow() {
    return this.tutorialStep === TutorialSteps.ClassroomEnter;
  }

  isShowCommingLesson(): boolean {
    return ([TutorialSteps.ProvaBooked, TutorialSteps.ClassroomReady] as Array<TutorialSteps>).indexOf(this.tutorialStep) >= 0;
  }

  getStepsNumber(): number {
    return Math.round( Object.keys(TutorialSteps).length / 2);
  }

  getStepNumber(): number {
    return this.tutorialStep + 1;
  }

  findLangName() {
    if (!this.lang ) { return; }
    this.langName = this.mapToName(this.lang);
  }

  mapToName(code: string): string {
    return LangProductMapper.mapLangCodeToLangName(code);
  }

  onNext() {
    if (this.tutorialStep === this.getStepsNumber() - 1) {
      this.onClose();
      return;
    }
    this.tutorialStep++;
    this.reloadHighlight();
  }

  onPrev() {
    if (this.tutorialStep === 0) {
      return;
    }
    this.tutorialStep--;
    this.reloadHighlight();
  }

  onClose() {
    if (this.back) {
      this.router.navigateByUrl(this.back);
      this.stopHighlights();
      return;
    }
    this.router.navigate(['student', this.studentId, 'lessons', this.lesson.id, 'confirmation']);
    this.stopHighlights();
  }

  onCommingLessonEvent(event: StudentCommingLessonComponentEvent) {
    this.hideCommingLesson = event.eventType === ComponentEvent.ET_LOADING
    || (event.eventType === ComponentEvent.ET_TOTAL_ELEMENTS && event.totalElements === 0);

    if (event.eventType === ComponentEvent.ET_LOADED) {
      this.lesson = event.data as ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
    }
  }

  ngOnInit() {
    this.langSubscription = this.studentContext.langCodeSubject
    .pipe( tap( lang => this.lang = lang))
    .subscribe( lang => {
      this.findLangName();
    });
  }

  ngAfterViewInit(): void {
    this.reloadHighlight();
  }

  reloadHighlight() {
    this.stopHighlights();
    this.nextTimeout(null, 1000);
  }

  stopHighlights() {
    this.clearHighlightTimeout();
    this.clearHighlights();
  }

  clearHighlights() {
    $('.' + this.highlightMarkClass).removeClass([this.highlightClass, this.highlightMarkClass]);
  }

  nextTimeout(currentHighlightElement: any, time: number) {
    this.clearHighlights();

    if (currentHighlightElement ) {
      this.highlightElement(currentHighlightElement);
    }

    this.highlightTimeout = setTimeout(() => {
      const highlightElements = $('[' + this.highlightAttr + ']');
      const nextHighlightElementIndex = (!currentHighlightElement) ?
      0 : (highlightElements.index(currentHighlightElement) + 1) % highlightElements.length;
      const nextHighlightElement = highlightElements[nextHighlightElementIndex];
      this.nextTimeout(nextHighlightElement, 4000);
    }, time);
  }
  highlightElement(currentHighlightElement: any) {
    $(currentHighlightElement).addClass([this.highlightClass, this.highlightMarkClass]);
    const highlightQuery = $(currentHighlightElement).attr(this.highlightAttr);
    $(highlightQuery).addClass([this.highlightClass, this.highlightMarkClass]);
  }


  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
    this.stopHighlights();
  }

  clearHighlightTimeout() {
    if (!this.highlightTimeout) {
      return;
    }
    clearTimeout(this.highlightTimeout);
    this.highlightTimeout = null;
  }

}
