<form *ngIf="formBack" #f="ngForm" (ngSubmit)="f.form.valid && onSave()">
  <div class="row">
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select class="form-select"
          id="language"
          #language="ngModel"
          [(ngModel)]="formBack.language"
          name="language"
          [attr.disabled]="editable ? null : ''"
          required
          [ngClass]="{ 'is-invalid': f.submitted && language.invalid, 'select-not-empty' : formBack.language }"
          >
            <option *ngFor="let lang of languages"
              [value]="lang.code">
              {{lang.name}}
            </option>
        </select>
        <label for="language" i18n="@@student-technical-profile.form.lang.label">Language</label>
        <div *ngIf="f.submitted && language.invalid" class="invalid-feedback">
          <div *ngIf="language.errors.required" i18n="@@common.field-required">Field is required</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input type="text" class="form-control" id="skype"
            placeholder="Skype"
            #skype="ngModel"
            [(ngModel)]="formBack.skype" name="skype"
            [ngClass]="{ 'is-invalid': f.submitted && skype.invalid }"
            [attr.disabled]="editable ? null : ''"
            required>
        <i class="fab fa-skype text-primary skype-icon"></i>
        <label i18n="@@student-technical-profile.form.skype.label" for="skype">Skype</label>
        <div *ngIf="f.submitted && skype.invalid" >
            <span *ngIf="skype.errors.required" class="d-block invalid-feedback f-12" i18n="@@common.field-required">Field is required</span>
        </div>
      </div>
    </div>
  </div>
  <nav>
    <ul class="nav nav-tabs" id="nav-tab" role="tablist">
      <li class="nav-item">
        <a *ngIf="showLang('en')" class="nav-link active" id="nav-english-tab"
          data-bs-toggle="tab" href="#nav-english" role="tab"
          aria-controls="nav-english" aria-selected="true"
        >
          English
        </a>
      </li>
      <li class="nav-item">
        <a *ngIf="showLang('sp')" class="nav-link" id="nav-spanish-tab"
          data-bs-toggle="tab" href="#nav-spanish" role="tab"
          aria-controls="nav-spanish" aria-selected="false"
        >
          Spanish
        </a>
      </li>
      <li class="nav-item">
        <a *ngIf="showLang('en.ch')" class="nav-link" id="nav-kids-tab"
          data-bs-toggle="tab" href="#nav-kids" role="tab"
          aria-controls="nav-kids" aria-selected="false"
        >
          Kids
        </a>
      </li>
      <li class="nav-item">
        <a *ngIf="showLang('en.bs')" class="nav-link" id="nav-business-tab"
          data-bs-toggle="tab" href="#nav-business" role="tab"
          aria-controls="nav-business" aria-selected="false"
        >
          Business
        </a>
      </li>
    </ul>
  </nav>
  <div class="tab-content p-2 pt-4" id="nav-tabContent">
    <div *ngIf="showLang('en')" class="tab-pane fade show active" id="nav-english" role="tabpanel" aria-labelledby="nav-english-tab">
      <a *ngIf="!formBack.productCompetences['en'] && editable" href="javascript:void(0);" (click)="activateLang('en')">English course isn't active. Click here to activate.</a>
      <p *ngIf="!formBack.productCompetences['en'] && !editable">
        English course isn't active. To activate the course click on form edit first
      </p>
      <div *ngIf="formBack.productCompetences['en']">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4 mb-4">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                name="enAccent"
                id="enAccent"
                [(ngModel)]="formBack.productCompetences['en'].accent"
                [attr.disabled]="editable ? null : ''"
                [ngClass]="{ 'is-invalid': f.submitted && enAccent.invalid }"
                #enAccent="ngModel"
                required
                >
              <label for="enAccent">Accent</label>
              <div *ngIf="f.submitted && enAccent.invalid" class="invalid-feedback">
                <div *ngIf="enAccent.errors.required">Accent is required</div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 mb-4">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                name="enVideo"
                id="enVideo"
                [(ngModel)]="formBack.productCompetences['en'].videoUrl"
                [attr.disabled]="editable ? null : ''"
                [ngClass]="{ 'is-invalid': f.submitted && enVideo.invalid }"
                #enVideo="ngModel"
              >
              <label for="enVideo">Video URL</label>
              <div *ngIf="f.submitted && enVideo.invalid" class="invalid-feedback">
                <div *ngIf="enVideo.errors.required">Video is required</div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="form-floating">
              <textarea
                type="text"
                class="form-control textarea-height"
                id="enDescription"
                placeholder="description"
                #enDescription="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && enDescription.invalid}"
                [(ngModel)]="formBack.productCompetences['en'].description"
                name="enDescription"
                [attr.disabled]="editable ? null : ''"
                required
              >
              </textarea>
              <label for="enDescription">Description</label>
              <div *ngIf="f.submitted && enDescription.invalid" class="invalid-feedback">
                <div *ngIf="enDescription.errors.required">Description is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
              <strong>Comptetences</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="en_prova"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en'].competences['PROVA']"
              >
              <label class="form-check-label">
                Prova
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="en_test"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en'].competences['TEST']"
              >
              <label class="form-check-label" >
                Test
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="en_a"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en'].competences['A']"
              >
              <label class="form-check-label">
                A ( Stage 1 )
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="en_b"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en'].competences['B']"
              >
              <label class="form-check-label" >
                B ( Stage 2 - 10 )
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="en_c"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en'].competences['C']"
              >
              <label class="form-check-label" >
                C ( Stage 11 - 12 )
              </label>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="showLang('sp')" class="tab-pane fade" id="nav-spanish" role="tabpanel" aria-labelledby="nav-spanish-tab">
      <a *ngIf="!formBack.productCompetences['sp'] && editable" href="javascript:void(0);" (click)="activateLang('sp')">Spanish course isn't active. Click here to activate.</a>
      <p *ngIf="!formBack.productCompetences['sp'] && !editable">
          Spanish course isn't active. To activate the course click on form edit first
        </p>
      <div *ngIf="formBack.productCompetences['sp']">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4 mb-4">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                name="spAccent"
                id="spAccent"
                [(ngModel)]="formBack.productCompetences['sp'].accent"
                [attr.disabled]="editable ? null : ''"
                [ngClass]="{ 'is-invalid': f.submitted && spAccent.invalid }"
                #spAccent="ngModel"
                required
              >
              <label for="spAccent">Accent</label>
              <div *ngIf="f.submitted && spAccent.invalid" class="invalid-feedback">
                <div *ngIf="spAccent.errors.required">Accent is required</div>
              </div>
              </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 mb-4">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                name="spVideo"
                id="spVideo"
                [(ngModel)]="formBack.productCompetences['sp'].videoUrl"
                [attr.disabled]="editable ? null : ''"
                [ngClass]="{ 'is-invalid': f.submitted && spVideo.invalid }"
                #spVideo="ngModel"
              >
              <label for="spVideo">Video URL</label>
              <div *ngIf="f.submitted && spVideo.invalid" class="invalid-feedback">
                <div *ngIf="spVideo.errors.required">Video is required</div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="form-floating">
              <textarea
                type="text"
                class="form-control textarea-height"
                id="spDescription"
                name="spDescription"
                placeholder="description"
                [(ngModel)]="formBack.productCompetences['sp'].description"
                [attr.disabled]="editable ? null : ''"
                [ngClass]="{ 'is-invalid': f.submitted && spDescription.invalid }"
                #spDescription="ngModel"
                required
              ></textarea>
              <label for="spDescription">Description</label>
              <div *ngIf="f.submitted && spDescription.invalid" class="invalid-feedback">
                <div *ngIf="spDescription.errors.required">Description is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
              <strong>Comptetences</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="sp_prova"
              [(ngModel)]="formBack.productCompetences['sp'].competences['PROVA']"
              [attr.disabled]="compEditable ? null : ''"
              >
              <label class="form-check-label">
                Prova
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="sp_test"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['sp'].competences['TEST']"
              >
              <label class="form-check-label" >
                Test
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="sp_a"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['sp'].competences['A']"
              >
              <label class="form-check-label" >
                A ( Stage 1 )
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="sp_b"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['sp'].competences['B']"
              >
              <label class="form-check-label" >
                B ( Stage 2 - 6 )
              </label>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="showLang('en.ch')" class="tab-pane fade" id="nav-kids" role="tabpanel" aria-labelledby="nav-kids-tab">
      <a *ngIf="!formBack.productCompetences['en.ch'] && editable" href="javascript:void(0);" (click)="activateLang('en.ch')">English Children course isn't active. Click here to activate.</a>
      <p *ngIf="!formBack.productCompetences['en.ch'] && !editable">
          English Children course isn't active. To activate the course click on form edit first
        </p>
      <div *ngIf="formBack.productCompetences['en.ch']">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4 mb-4">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                name="enChAccent"
                id="enChAccent"
                [(ngModel)]="formBack.productCompetences['en.ch'].accent"
                [attr.disabled]="editable ? null : ''"
                [ngClass]="{ 'is-invalid': f.submitted && enChAccent.invalid }"
                #enChAccent="ngModel"
                required
              >
              <label for="enChAccent">Accent</label>
              <div *ngIf="f.submitted && enChAccent.invalid" class="invalid-feedback">
                <div *ngIf="enChAccent.errors.required">Accent is required</div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 mb-4">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                name="enChVideo"
                id="enChVideo"
                [(ngModel)]="formBack.productCompetences['en.ch'].videoUrl"
                [attr.disabled]="editable ? null : ''"
                [ngClass]="{ 'is-invalid': f.submitted && enChVideo.invalid }"
                #enChVideo="ngModel"
              >
              <label for="enChVideo">Video URL</label>
              <div *ngIf="f.submitted && enChVideo.invalid" class="invalid-feedback">
                <div *ngIf="enChVideo.errors.required">Video is required</div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="form-floating">
              <textarea
                type="text"
                class="form-control textarea-height"
                id="enChDescription"
                name="enChDescription"
                placeholder="description"
                [(ngModel)]="formBack.productCompetences['en.ch'].description"
                [attr.disabled]="editable ? null : ''"
                [ngClass]="{ 'is-invalid': f.submitted && enChDescription.invalid }"
                #enChDescription="ngModel"
                required
                ></textarea>
              <label for="enChDescription">Description</label>
              <div *ngIf="f.submitted && enChDescription.invalid" class="invalid-feedback">
                <div *ngIf="enChDescription.errors.required">Description is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
              <strong>Comptetences</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="enCh_prova"
              [(ngModel)]="formBack.productCompetences['en.ch'].competences['PROVA']"
              [attr.disabled]="compEditable ? null : ''"
              >
              <label class="form-check-label">
                Prova
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="enCh_test"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en.ch'].competences['TEST']"
              >
              <label class="form-check-label" >
                Test
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="enCh_a"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en.ch'].competences['A']"
              >
              <label class="form-check-label" >
                A ( Stage 1 )
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="enCh_b"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en.ch'].competences['B']"
              >
              <label class="form-check-label" >
                B ( Stage 2 - 6 )
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showLang('en.bs')" class="tab-pane fade" id="nav-business" role="tabpanel" aria-labelledby="nav-business-tab">
      <a *ngIf="!formBack.productCompetences['en.bs'] && editable" href="javascript:void(0);" (click)="activateLang('en.bs')">English Business course isn't active. Click here to activate.</a>
      <p *ngIf="!formBack.productCompetences['en.bs'] && !editable">
          English Business course isn't active. To activate the course click on form edit first
        </p>
      <div *ngIf="formBack.productCompetences['en.bs']">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4 mb-4">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                name="enBsAccent"
                id="enBsAccent"
                [(ngModel)]="formBack.productCompetences['en.bs'].accent"
                [attr.disabled]="editable ? null : ''"
                [ngClass]="{ 'is-invalid': f.submitted && enBsAccent.invalid }"
                #enBsAccent="ngModel"
                required
              >
              <label for="enBsAccent">Accent</label>
              <div *ngIf="f.submitted && enBsAccent.invalid" class="invalid-feedback">
                <div *ngIf="enBsAccent.errors.required">Accent is required</div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 mb-4">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                name="enBsVideo"
                id="enBsVideo"
                [(ngModel)]="formBack.productCompetences['en.bs'].videoUrl"
                [attr.disabled]="editable ? null : ''"
                [ngClass]="{ 'is-invalid': f.submitted && enBsVideo.invalid }"
                #enBsVideo="ngModel"
              >
              <label for="enBsVideo">Video URL</label>
              <div *ngIf="f.submitted && enBsVideo.invalid" class="invalid-feedback">
                <div *ngIf="enBsVideo.errors.required">Video is required</div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="form-floating">
              <textarea
                type="text"
                class="form-control textarea-height"
                id="enBsDescription"
                name="enBsDescription"
                placeholder="description"
                [(ngModel)]="formBack.productCompetences['en.bs'].description"
                class="form-control"
                [attr.disabled]="editable ? null : ''"
                [ngClass]="{ 'is-invalid': f.submitted && enBsDescription.invalid }"
                #enBsDescription="ngModel"
                required
              ></textarea>
              <label for="enBsDescription">Description</label>
              <div *ngIf="f.submitted && enBsDescription.invalid" class="invalid-feedback">
                <div *ngIf="enBsDescription.errors.required">Description is required</div>
              </div>
            </div>
          </div>
        <div class="row mb-2">
          <div class="col-12">
              <strong>Comptetences</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="enBs_prova"
              [(ngModel)]="formBack.productCompetences['en.bs'].competences['PROVA']"
              [attr.disabled]="compEditable ? null : ''"
              >
              <label class="form-check-label">
                Prova
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="enBs_test"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en.bs'].competences['TEST']"
              >
              <label class="form-check-label" >
                Test
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input"
              type="checkbox"
              name="enBs_a"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en.bs'].competences['A']"
              >
              <label class="form-check-label" >
                A ( Stage 1 )
              </label>
            </div>
          </div>

        </div>
      </div>
      </div>
    </div>
  </div>
  <div class="mt-2" *ngIf="editable && !saving">
      <button  class="btn btn-primary float-end">Save</button>
  </div>
</form>
