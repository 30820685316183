import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiLearningUnitStudent, ApiLearningUnitTeacher, ApiTeacherProfile } from 'src/app/model/rest/rest-model';

@Component({
  selector: 'app-manager-dashboard',
  templateUrl: './manager-dashboard.component.html',
  styleUrls: ['./manager-dashboard.component.css']
})
export class ManagerDashboardComponent implements OnInit {
  schoolId: string;

  constructor(private activeRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.activeRoute.parent.paramMap.subscribe( params => this.schoolId = params.get('schoolId'));
  }

  itemClicked(item: any) {
    if (item instanceof ApiLearningUnitStudent) {
      this.router.navigate(['school', this.schoolId, 'student', item.id, 'lessons']);
      return;
    } else if (item instanceof ApiTeacherProfile) {
      this.router.navigate(['school', this.schoolId, 'teacher', item.teacher.id, 'lessons']);
    }
  }

}
