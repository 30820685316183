import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { filter, tap, switchMap } from 'rxjs/operators';
import { Cart, Currencies } from 'src/app/model/rest/products';
import { ApiPerson } from 'src/app/model/rest/rest-model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { StudentShoppingCartService } from 'src/app/services/student-shopping-cart.service';
import { ApiPersonalProfile } from 'src/app/model/rest/rest-model';

@Component({
  selector: 'app-shopping-cart-header',
  templateUrl: './shopping-cart-header.component.html',
  styleUrls: ['./shopping-cart-header.component.css']
})
export class ShoppingCartHeaderComponent implements OnInit, OnDestroy {

  _studentId: number;
  currencies = Currencies.values;
  _currency: string;
  currencySubject = new BehaviorSubject<string>(Currencies.values[0]);
  cart: Cart
  private orderCompleteSubscription: Subscription;
  private self: ApiPerson<ApiPersonalProfile>;
  private currencyChangeSubscription: Subscription;


  constructor(
    private studentChache: StudentCacheProxyService,
    private analytics: AnalyticsService,
    private shoppingCart: StudentShoppingCartService
    ) {
  }

  @Output()
  currencyChange = new EventEmitter();

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    // this.listenForCurrencyUpdate();
  }

  get studentId() {
    return this._studentId;
  }

  set currency(currency: string) {
    if (this.currencies.indexOf(currency) >= 0) {
      this._currency = currency;
    } else {
      this._currency = this.currencies[0];
    }
    this.shoppingCart.setCurrency(this._currency);
    this.currencyChange.emit(this._currency);
    this.currencySubject.next(this._currency);
  }

  getCurrencyIcon(cur: string) {
    if(cur === 'eur')
      return '€'
    else if(cur === 'pln')
      return 'zł'
    else if(cur === 'usd')
      return '$'
    else if(cur === 'gbp')
      return '£'
    else if(cur === 'jpy')
      return '¥'
    else
      return null
  }

  listenForCurrencyUpdate(): any {
    if (this._studentId == null) {return; }
    if (this.currencyChangeSubscription) {return; }
    this.currencyChangeSubscription =
    combineLatest(
      this.studentChache.loadSelfPerson(this._studentId)
        .pipe(
          filter( p => p!=null ),
          tap<ApiPerson<ApiPersonalProfile>>( p => this.self = p)
        ),
      this.currencySubject.pipe(filter( c => c != null))
    ).subscribe(
      // ([mePerson, currency]) => this.fillTheCart(mePerson, currency)
    );
  }

  // fillTheCart(mePerson: ApiPerson<ApiPersonalProfile>, currency: string): void {
  //   if (!mePerson) { return; }
  //   const name = PersonNameExtractor.getPersonName(mePerson);
  //   const callanId = mePerson.accountId;
  //   const email = mePerson.emailAddress;

  //   this.shoppingCart.setCurrency(currency)
  // }

  ngOnDestroy(): void {
    // this.orderCompleteSubscription.unsubscribe();
  }

  get currency() {
    return this._currency;
  }

  // handleOrderComplete(data: any) {
  //   console.log(data);
  //   const transactionId: string = data.token;
  //   const currency: string = data.currency;
  //   const total: number = data.total;
  //   const totalRounded = Math.round(total);
  //   this.currencySubject.pipe(take(1)).subscribe( lastCurrency => this.fillTheCart(this.self, lastCurrency));

  //   this.analytics.event(
  //     Event.ACT_CONVERSION,
  //     new Event(undefined, undefined, totalRounded, currency, transactionId), Group.ADS, environment.adsTransactionLabel);

  // }

  ngOnInit() {
    // this.orderCompleteSubscription = this.shoppingCart.orderComplete.subscribe(data => this.handleOrderComplete(data));
    this.shoppingCart.getCurrency().pipe(
      tap(curr =>
        this.currency = curr
      ),
      switchMap(_ => this.shoppingCart.getCart())
    ).subscribe(cart =>
      this.cart = cart
    )
  }

  getItems() {
    return this.cart.items.length
  }

  getTotal() {
    return `${this.cart.total} ${this.shoppingCart.getCurrencySign()}`
  }
}
