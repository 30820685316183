  <div *ngIf="isReady()">
    <div  class="mt-2" *ngFor="let section of selectedChapterSections()">
        <p class="lead">
            <span *ngIf="section.type == 'QA'">Question & Answer</span>
            <span *ngIf="section.type == 'DICT'">Dictation</span>
            <span *ngIf="section.type == 'LANG'">Extra Practice</span>
        </p>
        <p>
            <span class="text-muted" *ngIf="section.type == 'QA'">Listen to questions, say your answers, record and compare with native speakers.</span>
            <span class="text-muted" *ngIf="section.type == 'DICT'">Listen and write. See your mistakes and correct them.</span>
            <span class="text-muted" *ngIf="section.type == 'LANG'">Practise the grammar and vocabulary you’ve learned with these quick and fun exercises.</span>
        </p>
        <div class="my-2">

            <app-exercise-box
                *ngFor="let exercise of getSectionExercises(section)"
                [availability]="getExerciseAvailability(section, exercise)"
                [exercise]="exercise"
                (exerciseClick)="onExerciseSelected(section, exercise)" ></app-exercise-box>
        </div>
    </div>
</div>
