<div class="form-check">
  <input class="form-check-input" 
    [attr.disabled]="gdprEnabled() ? null : ''"  
    type="checkbox" [(ngModel)]="gdpr" 
    (change)="gdprChange($event)" id="defaultCheck1"
  >
  <label class="form-check-label" for="defaultCheck1"> 
    I accept the <a target="_blank" href="https://callanonline.com/en/termsandconditions">Terms and Conditions</a>
     and <a target="_blank" href="https://callanonline.com/en/gdpr-tutor">Privacy Policy</a> of Callan Online.
  </label>
</div>