import { Injectable } from '@angular/core';
import {TeacherBookRestService} from "./teacher-book-rest.service";
import {
  EventReference,
  ProductAvailabilityDetails,
  ProductAvailabilityRequest,
  ScheduleReference,
  SimpleProductAvailability,
  SimpleScheduleEvents
} from "../../model/rest/booking-rest-v2";
import {Observable} from "rxjs";
import {TeacherBookRestOldService} from "./teacher-book-rest-old.service";
import {
  ApiStudentSchedule,
  ApiTeacherWorktime,
  ApiWorktimeDefinition,
  RecurringType
} from "../../model/rest/booking-rest.model";
import {map} from "rxjs/operators";
import {ApiCompetence, ApiCourseProduct} from "../../model/rest/rest-model";

@Injectable({
  providedIn: 'root'
})
export class TeacherBookRestBridgeService implements TeacherBookRestService {

  constructor(private restOld: TeacherBookRestOldService) { }

  createAvailability(teacherId: number, request: ProductAvailabilityRequest): Observable<ScheduleReference> {
    let definition = this.productAvailabilityRequestToApiWorktimeDefinition(request)
    return this.restOld.createWorktime(teacherId, definition).pipe(
      map(res => {
        let scheduleRef = new ScheduleReference()
        scheduleRef.id = res.id
        return scheduleRef
      })
    )
  }

  deleteAvailability(teacherId: number, availabilityId: number, eventId: number, applyRecurring: boolean): Observable<void> {
    return this.restOld.deleteWorktime(teacherId, availabilityId, applyRecurring)
  }

  listAvailabilities(teacherId: number, focusDate: Date): Observable<SimpleProductAvailability[]> {
    return this.restOld.listWorktimeWeekSchedules(teacherId, focusDate).pipe(
      map(res => res.map(wt => this.apiTeacherWorktimeToSimpleProductAvailability(wt)))
    )
  }

  listLessons(teacherId: number, focusDate: Date): Observable<SimpleScheduleEvents[]> {
    return this.restOld.listWeekSchedules(teacherId, focusDate).pipe(
      map(res => res.map(wt => this.apiStudentScheduleSimpleScheduleEvents(wt)))
    )
  }

  productAvailabilityRequestToApiWorktimeDefinition(src: ProductAvailabilityRequest) {
    let baseWorktime = new ApiTeacherWorktime();
    baseWorktime.competences = src.availabilityDetails.competences
    baseWorktime.duration = src.scheduleDetails.duration;
    baseWorktime.product = new ApiCourseProduct();
    baseWorktime.product.code = src.availabilityDetails.product.code;
    baseWorktime.minimumOvertake = src.availabilityDetails.overtake;
    baseWorktime.startDate = src.scheduleDetails.time.starting

    let target = new ApiWorktimeDefinition()
    target.recurring = this.recurringToRecurringType(src.scheduleDetails.recurring)
    target.recurringFinish = src.scheduleDetails.ending
    target.baseWorktime = baseWorktime
    target.timezoneOffset = src.scheduleDetails.time.starting.getTimezoneOffset();
    target.weekDays = this.getWeekDays(src.scheduleDetails.recurring)
    return target
  }

  recurringToRecurringType(r: string) {
    switch (true) {
      case r == null:
        return RecurringType.Single
      case r == "w:1":
        return RecurringType.Weekly
      case r == "m:1":
        return RecurringType.Monthly
      case r?.startsWith("w:1"):
        return RecurringType.Daily
      default:
        return null
    }
  }

  getWeekDays(r: string) {
    if(!r?.startsWith("w:1")) return []
    console.log(r)
    return r.replace("w:1", "")
      .split(':')
      .filter(d => d != "")
      .map(d => ((+d - 2 + 7) % 7).toString())
  }

  apiTeacherWorktimeToSimpleProductAvailability(src: ApiTeacherWorktime) {
    let details = new ProductAvailabilityDetails()
    details.product = src.product
    details.competences = src.competences
    details.overtake = src.minimumOvertake

    let event = new EventReference()
    event.eventDate = src.startDate
    event.duration = src.duration

    let target = new SimpleProductAvailability()
    target.schedule = new ScheduleReference()
    target.schedule.id = src.id
    target.details = details
    target.events = new Array<EventReference>()
    target.events.push(event)
    return target
  }

  apiStudentScheduleSimpleScheduleEvents(src: ApiStudentSchedule) {
    let event = new EventReference()
    event.eventDate = src.startTime
    event.duration = src.duration

    let details = new ProductAvailabilityDetails()
    details.product = src.course.product
    details.competences = new Array<ApiCompetence>(src.competence)

    let target = new SimpleScheduleEvents()
    target.schedule = new ScheduleReference()
    target.schedule.id = src.lesson.id
    target.events = new Array<EventReference>()
    target.events.push(event)
    return target
  }
}
