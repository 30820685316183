import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TeacherBookRestService} from 'src/app/services/rest/teacher-book-rest.service';
import {combineLatest, forkJoin} from 'rxjs';
import {RecurringType, RecurringTypeUtils} from 'src/app/model/rest/booking-rest.model';
import {CalendarEntry, ClickEvent} from '../../calendar/week-calendar/week-calendar.component';
import {map, tap} from 'rxjs/operators';
import {ModalComponent} from '../../modal/modal.component';
import {TeacherRestService} from 'src/app/services/rest/teacher-rest.service';
import {
  ApiCourseProduct,
  ApiLearningUnitStudent,
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiPerson,
  ApiPersonalProfile,
  ApiTeacherProfile
} from 'src/app/model/rest/rest-model';
import {LangProductMapper} from 'src/app/utils/lang-mappers';
import {Dates} from 'src/app/utils/calendar-utils';
import {
  LocalDateTime,
  ProductAvailabilityDetails,
  ProductAvailabilityRequest,
  ScheduleDefinition,
  SimpleProductAvailability,
  SimpleScheduleEvents
} from "../../../model/rest/booking-rest-v2";

@Component({
  selector: 'app-teacher-main-calendar',
  templateUrl: './teacher-main-calendar.component.html',
  styleUrls: ['./teacher-main-calendar.component.css']
})
export class TeacherMainCalendarComponent implements OnInit {

  @ViewChild('createWorktimeModal', {static: true}) createWorktimeModal: ModalComponent;
  @ViewChild('deleteWorktimeModal', {static: true}) deleteWorktimeModal: ModalComponent;
  _teacherId: number;
  _focusDate: Date;
  calendarEntries: CalendarEntry[] = [];
  profile: ApiTeacherProfile;
  availableProducts: string[];
  recurringUtils = RecurringTypeUtils;
  recurringTypes = RecurringType;
  minRecurring = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);

  // new worktime dialog fields
  newWorktimeDate: Date;
  newWorktimeFinishTime: string;
  newWorktimeProduct: string;
  newWorktimeStartTime: string;
  newWorktimeDays: {[ dayName: string]: boolean};
  studentById: {[ studentId: number]: ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>} = {};
  newWorktimeOvertake = 0;

  loading = true;

  allProductsCode = '__all__';

  newWorktimeRecurring = RecurringType.Single;
  newRecurringFinishDate: Date;

  // delete worktime dialog
  availabilityToDelete: SimpleProductAvailability;
  deleteRecurring = false;
  private eventIdToDelete: number;
  eventToDelete: CalendarEntry;


  constructor(private teacherRest: TeacherRestService) { }

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.loadWeekEvents();
    this.loadTeacherCompetences();
  }

  loadTeacherCompetences(): any {
    if (!this._teacherId) { return; }

    this.teacherRest.getProfile(this._teacherId)
    .pipe( tap( profile => this.profile = profile))
    .pipe( tap( profile => this.availableProducts = profile.competences.map( pCompetence => pCompetence.product.code )))
    .subscribe();
  }

  setTimeWithStr(date: Date, time: string) {
    const timeSplit = time.split(':');
    const hour = Number(timeSplit[0]);
    const minutes = Number(timeSplit[1]);

    const res = new Date(date);
    res.setHours(hour);
    res.setMinutes(minutes);

    return res;
  }

  saveWorktimeForProduct( targetProduct: string) {

    const startDate = this.setTimeWithStr(this.newWorktimeDate, this.newWorktimeStartTime);
    let finishDate = this.setTimeWithStr(this.newWorktimeDate, this.newWorktimeFinishTime);

    // if the hour is less than starting time move date one day forward
    if (finishDate.getTime() < startDate.getTime()) {
      finishDate = this.setTimeWithStr(new Date(this.newWorktimeDate.getTime() + 1000 * 60 * 60 * 24), this.newWorktimeFinishTime);
    }

    const duration = finishDate.getTime() - startDate.getTime();

    let request = new ProductAvailabilityRequest()
    request.availabilityDetails = new ProductAvailabilityDetails()
    request.availabilityDetails.product = new ApiCourseProduct()
    request.availabilityDetails.product.code = targetProduct
    request.availabilityDetails.competences = this.profile.competences.find( pc => pc.product.code === targetProduct).competences;
    request.availabilityDetails.overtake = this.newWorktimeOvertake
    request.scheduleDetails = new ScheduleDefinition()
    request.scheduleDetails.recurring = this.createRecurringString()
    request.scheduleDetails.ending = this.newRecurringFinishDate
    request.scheduleDetails.duration = duration
    request.scheduleDetails.time = new LocalDateTime()
    request.scheduleDetails.time.starting = startDate
    request.scheduleDetails.time.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return this.teacherRest.createAvailability(this._teacherId, request);
  }

  createRecurringString() {
    let weekDays = []
    for (const dayName in this.newWorktimeDays) {
      if (this.newWorktimeDays.hasOwnProperty(dayName)) {
        const isActive = this.newWorktimeDays[dayName];
        if (!isActive) { continue; }
        weekDays.push(dayName);
      }
    }

    return RecurringTypeUtils.getRecurring(this.newWorktimeRecurring, weekDays)
  }

  saveWorktime() {
    this.loading = true;
    const productsArray = this.newWorktimeProduct === this.allProductsCode ? this.availableProducts : [this.newWorktimeProduct];

    forkJoin(...productsArray.map( pCode => this.saveWorktimeForProduct(pCode)))
    .subscribe(() => this.loadWeekEvents(), e => this.loading = false);

    this.createWorktimeModal.hide();
  }

  getProductName(code: string): string {
    return LangProductMapper.mapLangCodeToLangName(code);
  }

  set focusDate(date: Date) {
    this._focusDate = date;
    this.loadWeekEvents();
  }

  calendarClicked(event: ClickEvent) {
    if (!event.entry) {
      // empty space was clicked
      this.openWorktimeDialog(event.clickDate);
    } else if (event.entry.relatedObject instanceof SimpleProductAvailability) {
      this.availabilityToDelete = event.entry.relatedObject;
      this.eventToDelete = event.entry
      this.eventIdToDelete = event.entry.eventId
      this.deleteRecurring = false;
      this.deleteWorktimeModal.show();
    }
  }

  openWorktimeDialog(clickDate: Date): any {
    if (clickDate.getTime() < new Date().getTime()) { return; }
    this.newWorktimeDate = Dates.roundDateIntoMiddleOfClick(clickDate);
    this.newWorktimeStartTime = this.getTimeStr(this.newWorktimeDate);

    const finishingDate = new Date( this.newWorktimeDate.getTime() + 1000 * 60 * 60 * 8);
    this.newWorktimeFinishTime = this.getTimeStr(finishingDate);
    this.newWorktimeDays = {};
    RecurringTypeUtils.days.forEach( day => this.newWorktimeDays[day] = true );
    this.createWorktimeModal.show();

  }
  getTimeStr(date: Date): string {
    return date.getHours() + ':' + date.getMinutes().toString().padStart(2, '0');
  }

  deleteWorktime() {
    this.loading = true;
    this.teacherRest.deleteAvailability(this._teacherId, this.availabilityToDelete.schedule.id, this.eventIdToDelete, this.deleteRecurring)
    .subscribe(() => {
      this.availabilityToDelete = null;
      this.loadWeekEvents();
    }, e => this.loading = false);
    this.deleteWorktimeModal.hide();
  }

  loadWeekEvents() {
    if (!this._teacherId || !this._focusDate) { return; }
    this.loading = true;
    combineLatest(
      this.teacherRest.listAvailabilities(this._teacherId, this._focusDate)
      .pipe(
        map( worktimesArray =>
          this.mapSimpleProductAvailabilityToCalendarEntry(worktimesArray)
        )
      ),
      this.teacherRest.listLessons(this._teacherId, this._focusDate)
      .pipe(
        map( schedulesArray =>
          this.mapSimpleScheduleEventsToCalendarEntry(schedulesArray)
        ),
        tap( schedulesArray => this.loadStudents(schedulesArray))
      )
    )
    .pipe(
      map(([worktimeEntries , scheduleEntries]) => worktimeEntries.concat(scheduleEntries)),
      tap(calendarEntries => this.calendarEntries = calendarEntries)
      )
    .subscribe( () => this.loading = false, () => this.loading = false);
  }

  loadStudents(schedulesArray: CalendarEntry[]): void {
    const studentIds = schedulesArray
      .map( schedule => (schedule.relatedObject as ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>))
      .map( lesson => lesson.students )
      .filter( studentsArray => studentsArray && studentsArray.length > 0 )
      .reduce( (src: Array<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>, arg: Array<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>) => src.concat(arg), [])
      .map( student => student.id );

    const studentsToDownolad = Array.from(new Set(studentIds))
      .filter( studentId => !this.studentById[studentId]);

    this.teacherRest.queryForStudentsById(this._teacherId, studentsToDownolad).pipe(
      tap( studentsPage => studentsPage.content.forEach( student => this.studentById[student.id] = student))
    ).subscribe();
  }

  getAvailabilityName(av: SimpleProductAvailability) {
    return `${av.details.product.code}[${av.details.competences.map(c => c.code).join(", ")}]`
  }

  mapSimpleProductAvailabilityToCalendarEntry(schedules: SimpleProductAvailability[]): CalendarEntry[] {
    let entries = new Array<CalendarEntry>()
    schedules.forEach(schedule => schedule.events.forEach(ev => {
      const dateTo = new Date();
      dateTo.setTime(ev.eventDate.getTime() + ev.duration);
      let entry = new CalendarEntry(
        schedule.schedule.id,
        ev.eventId,
        ev.eventDate,
        dateTo,
        () => this.getAvailabilityName(schedule),
        null,
        schedule,
        'worktime'
      )
      if(schedules.length > 1)
        entry = entry.narrowIntersected()

      entries.push(entry);
    }))
     return entries
  }
  mapSimpleScheduleEventsToCalendarEntry(schedules: SimpleScheduleEvents[]): CalendarEntry[] {
    let entries = new Array<CalendarEntry>()
    schedules.forEach(schedule => schedule.events.forEach(ev => {
      const dateTo = new Date();
      dateTo.setTime(ev.eventDate.getTime() + ev.duration);
      let entry = new CalendarEntry(
        schedule.schedule.id,
        ev.eventId,
        ev.eventDate,
        dateTo,
        () => schedule.schedule.name,
        null,
        schedule,
        'schedule'
      )
      entries.push(entry);
    }))
     return entries
  }
  ngOnInit() {
  }

}
