import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyObserver } from 'src/app/utils/property-observer';
import {
  LessonDetailsEvent,
  LessonType
} from 'src/app/components/student/student-lessons/student-lessons.component';
import { StudentProfileData, StudentContextService } from 'src/app/services/ctx/student-context.service';
import { ComponentEvent, Languages } from 'src/app/model/rest/base';
import { StudentCommingLessonComponentEvent } from 'src/app/components/student/student-comming-lesson/student-comming-lesson.component';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { LangProductMapper } from 'src/app/utils/lang-mappers';
import { ApiPersonalProfile, ApiPersonTechnicalProfile, IntroductionState } from 'src/app/model/rest/rest-model';
import { filter, switchMap, finalize } from 'rxjs/operators';
import { StaticPagesUrlProviderService } from 'src/app/services/shared/url-provider.service';
import { AppEventsService } from 'src/app/services/ctx/app-events.service';
import { AnalyticsService, Event } from 'src/app/services/analytics.service';
import { LocaleManagerService } from 'src/app/services/locale-manager.service';
import { StudentStartersOfferComponentEvent } from 'src/app/components/student/student-starters-offer/student-starters-offer.component';
import { StudentDashboardService } from 'src/app/services/student-dashboard.service';
import { AuthImplService } from 'src/app/services/col2/auth.service';
import { environment } from 'src/environments/environment';

class LanguageIntroductionDialogData {
  languageCode: string;
  languages = Languages.values;
}
class CourseIntroductionDialogData {
  productCode: string;
  productMapper = LangProductMapper;
}

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.css'],
})
/*
Main student page content
*/
export class StudentDashboardComponent implements OnInit, OnDestroy {

  private static modalIntroductionStates = [ IntroductionState.Language, IntroductionState.Course, IntroductionState.PersonalProfile ];
  private static dimmedIntroductionStates = [ IntroductionState.FreeCredit, IntroductionState.ReserveProva, IntroductionState.FutureLesson, IntroductionState.Cspa, IntroductionState. Welcome ];


  @ViewChild('initialModal', {static: true})
  initialModal: ModalComponent;

  @ViewChild('inviteModal', {static: true})
  inviteModal: ModalComponent;

  langCode: string;
  profileData: StudentProfileData;
  langCodeSubscription: Subscription;
  lessonTypes = LessonType;
  studentId: number;
  showCspa = false;
  progressLoading = true;

  id = 0;
  creditsLoading = true;
  cspaLoading = true;
  futureLoading = true;
  pastLoading = true;
  hideCommingLesson = true;
  teachersUrl: string;
  private chosenSection: string

  readonly IntroductionState = IntroductionState

  // introduction fields
  _introductionState = IntroductionState.Language;
  _langDialog = new LanguageIntroductionDialogData();
  _courseDialog = new CourseIntroductionDialogData();
  _profileDialog = new ApiPersonalProfile();
  _showTutorialDim = false;
  technicalProfile: any;
  teacherUrlSubscription: Subscription;
  technicalProfileUpdateSubscription: Subscription;
  hideStarterOffers = true;

  constructor(private studentContextService: StudentContextService,
    private studentCache: StudentCacheProxyService,
    private route: ActivatedRoute,
    private router: Router,
    teachersUrlService: StaticPagesUrlProviderService,
    appEvents: AppEventsService,
    private analytics: AnalyticsService,
    private localeService: LocaleManagerService,
    public dashboardService: StudentDashboardService,
    public authImplService: AuthImplService) {
      this.teacherUrlSubscription = teachersUrlService.teachersBaseSubject.pipe(filter( url => url != null)).subscribe(
        url => this.teachersUrl = url.urlBase + '?productCode=' + url.productCode
      );
      this.technicalProfileUpdateSubscription = appEvents.technicalProfileUpdated.subscribe(
        newProfile => this.resolveIntroductionState(newProfile)
      );
  }

  ngOnDestroy(): void {
    this.langCodeSubscription = PropertyObserver.unsuscribe(this.langCodeSubscription);
    this.teacherUrlSubscription.unsubscribe();
    this.technicalProfileUpdateSubscription.unsubscribe();
  }

  onStarterOfferEvent(event: StudentStartersOfferComponentEvent) {
    this.hideStarterOffers =
      (event.eventType === ComponentEvent.ET_LOADING
        || (event.eventType === ComponentEvent.ET_TOTAL_ELEMENTS && event.totalElements === 0));
  }

  onCommingLessonEvent(event: StudentCommingLessonComponentEvent) {
    this.hideCommingLesson = event.eventType === ComponentEvent.ET_LOADING
    || (event.eventType === ComponentEvent.ET_TOTAL_ELEMENTS && event.totalElements === 0);
  }

  ngOnInit() {
    this.langCodeSubscription = this.studentContextService
      .langCodeSubject
      .subscribe(lang => {
        this.langCode = lang;
      });
    this.route.parent.paramMap.subscribe( params => {
      this.studentId = +params.get('studentId');
      if (this.studentId ) {
        this.loadTechnicalProfile();
      }
    });
    this.dashboardService.getChosenSection().subscribe(
      section => {
        this.chosenSection = section
      }
    )
    let browserLang = window.navigator.language;
    const minusPos = browserLang.indexOf('-');
    if (minusPos > 0) {
      browserLang = browserLang.slice(0, minusPos);
    }
    if (Languages.values.map( l => l.code).indexOf(browserLang) >= 0) {
      this._langDialog.languageCode = browserLang;
    } else {
      this._langDialog.languageCode = Languages.English.code;
    }
  }

  ngAfterViewChecked() {
    if(this.chosenSection === 'top') {
      window.scrollTo(0, 0)
      this.chosenSection = ''
    }
    else if(this.chosenSection && this.chosenSection !== '') {
      document.getElementById(this.chosenSection).scrollIntoView(
        { behavior: 'smooth'}
      )
      this.chosenSection = ''
    }
  }

  loadTechnicalProfile(): any {
    this.studentCache.loadStudentTechnicalProfile(Number(this.studentId))
    .subscribe(
      studentProfile => this.resolveIntroductionState(studentProfile)
    );
  }

  resolveIntroductionState(studentProfile: ApiPersonTechnicalProfile): void {
    this._introductionState = studentProfile && studentProfile.introductionState ? studentProfile.introductionState : IntroductionState.Language;
    this.solvePersonDetailsIfPersonalForm(studentProfile);
    if (StudentDashboardComponent.modalIntroductionStates.indexOf(this._introductionState) >= 0) {
      this.initialModal.show(false);
    }
    if (StudentDashboardComponent.dimmedIntroductionStates.indexOf(this._introductionState) >= 0) {
      this._showTutorialDim = true;
    } else {
      this._showTutorialDim = false;
    }
    if(this._introductionState === IntroductionState.Finished){
      this.inviteModal.show(true)
    }
  }
  solvePersonDetailsIfPersonalForm(studentProfile: ApiPersonTechnicalProfile): any {
    if (studentProfile && studentProfile.introductionState === IntroductionState.PersonalProfile) {
      this.studentCache.loadSelfPerson(Number(this.studentId)).subscribe( selfPerson => {
        this._profileDialog.name = selfPerson.name;
        this._profileDialog.surname = selfPerson.surname;
        this._profileDialog.email = selfPerson.emailAddress;
      });
    }
  }

  backIntroduction() {
    if (this._introductionState === IntroductionState.PersonalProfile) {
      this._introductionState = IntroductionState.Course;
    } else if (this._introductionState === IntroductionState.Course) {
      this._introductionState = IntroductionState.Language;
    }
  }

  public saveLanguage() {
    const profile = new ApiPersonTechnicalProfile();
    profile.introductionState = IntroductionState.Course;
    profile.lang = this._langDialog.languageCode;
    this.analytics.event(Event.ACT_IND_SAVE_LANG, new Event(Event.CAT_IND, this._langDialog.languageCode));
    this.analytics.event(
      Event.ACT_IND_SAVE_LANG, new Event(Event.catInductionWithLang(this.localeService.localeId), this._langDialog.languageCode));
    this.studentCache.saveStudentTechnicalProfile(Number(this.studentId), profile).subscribe();
  }

  public updateCourse() {
    this.studentCache.addStudentProductContext(Number(this.studentId), this._courseDialog.productCode).pipe(
      switchMap( () => this.studentCache.updateIntroductionState(Number(this.studentId), IntroductionState.PersonalProfile))
    ).subscribe(
      () => {
        this.analytics.event(Event.ACT_IND_CHOOSE_PROD, new Event(Event.CAT_IND, this._courseDialog.productCode));
        this.analytics.event(
          Event.ACT_IND_CHOOSE_PROD, new Event(Event.catInductionWithLang(this.localeService.localeId), this._courseDialog.productCode));
        this.studentContextService.switchLang(this._courseDialog.productCode);
      }
    );
  }

  public saveProfileForm() {
    this.studentCache.savePersonalProfile(Number(this.studentId), this._profileDialog).pipe(
      switchMap( () => this.studentCache.updateIntroductionState(Number(this.studentId), IntroductionState.FreeCredit)),
      finalize(() => this.router.navigate([`student/${this.studentId}/lessons/reserve`]))
    ).subscribe(
      () => {
        this.analytics.event(Event.ACT_IND_SAVE_PROFILE, new Event(Event.CAT_IND));

        this.analytics.event(Event.ACT_IND_SAVE_PROFILE, new Event(Event.catInductionWithLang(this.localeService.localeId)));
      }
    );

    this.initialModal.hide();
  }

  public getFreeCredit() {
    this.studentCache.chargeFreeCredit(Number(this.studentId), this.langCode).pipe(
      switchMap( () => this.studentCache.updateIntroductionState(Number(this.studentId), IntroductionState.ReserveProva))
    ).subscribe(
      () => {
        this.analytics.event(Event.ACT_IND_GET_CREDIT, new Event(Event.CAT_IND, this.langCode));

        this.analytics.event(Event.ACT_IND_GET_CREDIT, new Event(Event.catInductionWithLang(this.localeService.localeId), this.langCode));
      }
    );
  }

  public reserveProva() {
    this._introductionState = IntroductionState.FutureLesson;
  }

  public showCspaTutorial() {
    this.dashboardService.setChosenSection('exercisePopOver')
    this.studentCache.updateIntroductionState(Number(this.studentId), IntroductionState.Cspa).subscribe(
      () => {
        this.analytics.event(Event.ACT_IND_CSPA, new Event(Event.CAT_IND));
        this.analytics.event(Event.ACT_IND_CSPA, new Event(Event.catInductionWithLang(this.localeService.localeId)));
      }
    );
  }

  public finishTutorial() {
    this.studentCache.updateIntroductionState(Number(this.studentId), IntroductionState.Finished).subscribe(
      () => {
        this.analytics.event(Event.ACT_IND_FINISH, new Event(Event.CAT_IND));
        this.analytics.event(Event.ACT_IND_FINISH, new Event(Event.catInductionWithLang(this.localeService.localeId)));
      }
    );
  }

  public openLessonDetails(event: LessonDetailsEvent) {
    this.router.navigate(['student', this.studentId, 'lessons', event.lesson.id ]);
  }

  public reserveProvaClicked() {
    this.studentCache.updateIntroductionState(Number(this.studentId), IntroductionState.ReserveSkipped).subscribe();
  }

  public isShowReserveProva() {
    return (this._introductionState === IntroductionState.ReserveSkipped || this._introductionState === IntroductionState.ReserveProva);
  }

  getCspaSet() {
    return LangProductMapper.mapLangToNewCspa(this.langCode);
  }

  getStep() {
    switch (this._introductionState) {
      case IntroductionState.Language:
        return 1
      case IntroductionState.Course:
        return 2
      case IntroductionState.PersonalProfile:
        return 3
      default:
        return 1
    }
  }

  isModalIntroduction() {
    return StudentDashboardComponent.modalIntroductionStates.includes(this._introductionState)
  }

  goToNewCallan(){
    this.studentCache.updateIntroductionState(Number(this.studentId), IntroductionState.InvitedToColNew).subscribe();
    this.authImplService.getAccessCode()
    .subscribe(res => {
      const redirectUrl = encodeURIComponent(btoa("student/dashboard"))
      window.location.href = `${environment.areaServerBase}/oauth?code=${res}&state=${redirectUrl}`
    })
  }

  dissmissInvitationModal(){
    this.studentCache.updateIntroductionState(Number(this.studentId), IntroductionState.InvitedToColNew).subscribe();
    this.inviteModal.hide()
  }
  
}
