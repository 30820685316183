import { Injectable } from '@angular/core';
import {ExerciseSet, ApiExercisesStructure, Exercise,
                    ApiPersonScores,
                  ApiExerciseSession} from '../../model/rest/cspa-rest-model';
import { Observable} from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CspaRestService {
  private apiEndpoint = environment.casaRoot + '/api/cspa';
  constructor( private http: HttpClient ) { }

  public getExerciseSets(): Observable<ExerciseSet[]> {
    return this.http.get<ExerciseSet[]>(this.apiEndpoint + '/structure/self/availableExerciseSets');
  }

  public getExerciseStructure(lang: string): Observable<ApiExercisesStructure> {
    return this.http.get<ApiExercisesStructure>(
      this.apiEndpoint + '/structure',
      {params: {
        type: lang
      }});
  }

  public getPersonScores(): Observable<ApiPersonScores> {
    return this.http.get<ApiPersonScores>( this.apiEndpoint + '/structure/self/scoring' );
  }

  public createExerciseSession( exercise: Exercise): Observable<ApiExerciseSession> {
    return this.http.post<ApiExerciseSession>(
      this.apiEndpoint + '/session/create/' + exercise.id,
      {}
    );
  }

}
