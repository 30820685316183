import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudentShoppingCartService } from 'src/app/services/student-shopping-cart.service';

@Component({
  selector: 'app-course-products-page',
  templateUrl: './course-products-page.component.html',
  styleUrls: ['./course-products-page.component.css']
})
export class CourseProductsPageComponent implements OnInit {

  productCode: string;
  currency: string;
  studentId: number;

  constructor(private activatedRoute: ActivatedRoute,
    private shoppingCart: StudentShoppingCartService
    ) {
    activatedRoute.parent.paramMap.subscribe( params => this.studentId = Number(params.get('studentId')));
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe( params => this.productCode = params.get('productCode'));
    this.shoppingCart.getCurrency().subscribe(curr =>
      this.currency = curr
    )
  }

}
