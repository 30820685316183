<div class="row justify-content-center justify-content-md-start">
  <ng-template [ngLoading]="!teachersDescriptions" type="spinner">
  <div class="col-10 col-md-6 col-lg-5 col-xl-4 col-xxl-3 mb-3 mb-md-4 mx-auto position-relative"
    *ngFor="let teacherDescription of teachersDescriptions">
    <a routerLink="/student/{{_studentId}}/teachers/{{teacherDescription.teacherProfile.teacher.id}}">
        <div class="teacher-profile-photo" [style.background-image]="'url(' + getImage(teacherDescription) +')'" ></div>
      <!-- <p>
        {{getDescription(teacherDescription)}}
      </p> -->
    </a>
    <h3 class="teacher-name px-3">
      {{getName(teacherDescription)}}
    </h3>
  </div>
  </ng-template>
</div>
