import { Component, OnInit, OnDestroy } from '@angular/core';
import { LessonType, TeacherLessonsComponentEvent } from 'src/app/components/teacher/teacher-lessons/teacher-lessons.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TeacherContextService } from 'src/app/services/ctx/teacher-context.service';
import { ApiTeacherProfile, ApiLessonInstance } from 'src/app/model/rest/rest-model';
import { ComponentEvent } from 'src/app/model/rest/base';
import { AppEventsService } from 'src/app/services/ctx/app-events.service';
import { Subscription } from 'rxjs';
import {LessonDetailsEvent} from "../../../components/student/student-lessons/student-lessons.component";

@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.css']
})
/*
Main teacher page content
*/
export class TeacherDashboardComponent implements OnInit, OnDestroy {

  lessonTypes = LessonType;
  teacherId: string;
  teacherProfileLoaded: boolean;
  teacherProfile: ApiTeacherProfile;
  futureLessonsLoading = true;
  pastLessonsLoading = true;
  incompleteLessonsLoading = true;
  missingTeacherProfile = false;
  teacherProfileSubscription: Subscription;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private teacherContext: TeacherContextService,
    private appEvents: AppEventsService) { }

  ngOnInit() {
    this.route.parent.paramMap.subscribe(
      params => this.teacherId = params.get('teacherId')
      );
    this.teacherContext.getTeacherProfile()
    .subscribe( profile => {
      this.teacherProfileLoaded = true;
      this.teacherProfile = profile;
      if (!profile) {
        this.missingTeacherProfile = true;
      }
    });
    this.teacherProfileSubscription = this.appEvents.teacherProfileUpdated.subscribe( profile => {
      this.teacherProfileLoaded = true;
      this.teacherProfile = profile;
      if (!profile) {
        this.missingTeacherProfile = true;
      }

    });

  }

  onFutureLessonsEvent(event: TeacherLessonsComponentEvent) {
    this.futureLessonsLoading = event.eventType === ComponentEvent.ET_LOADING;
  }

  onPastLessonsEvent(event: TeacherLessonsComponentEvent) {
    this.pastLessonsLoading = event.eventType === ComponentEvent.ET_LOADING;
  }

  onIncompleteLessonsEvent(event: TeacherLessonsComponentEvent) {
    this.incompleteLessonsLoading = event.eventType === ComponentEvent.ET_LOADING;
  }

  ngOnDestroy(): void {
   if (this.teacherProfileSubscription) {
     this.teacherProfileSubscription.unsubscribe();
     this.teacherProfileSubscription = null;
   }
  }

  openLessonDetails(event: LessonDetailsEvent) {
    this.router.navigate(['teacher', this.teacherId , 'lessons', event.lesson.id, 'students', event.lesson.students[0].id, 'details']);
  }


}
