import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthServiceProvider} from "../../services/col2/auth.service";

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.css']
})
export class OrderSuccessComponent implements OnInit {

  constructor(private authService: AuthServiceProvider,
    private router: Router) { }

  ngOnInit(): void {}

  public logout() {
    this.authService.get().subscribe(api => api.logout());
  }

  routeToUserPage() {
    this.authService.get().subscribe(api => {
      if (api.isTokenValid()) {
        if (api.getStudentId()) {
          this.router.navigate( ['/student', api.getStudentId(), 'dashboard'] );
        } else if (api.getTeacherId()) {
          this.router.navigate( ['/teacher', api.getTeacherId(), 'dashboard']);
        } else if (api.getSchoolId()) {
          this.router.navigate( ['/school', api.getSchoolId(), 'dashboard']);
        }
        else {
          this.authService.get().subscribe(api => api.logout());
        }
      }
    })
  }
}
