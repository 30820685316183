<div class="row justify-content-between" *ngIf="hasPagination()">
        <div class="col-6 col-lg-2 order-0">
                <button class="btn btn-outline-secondary btn-circle lh-1"
                        [class]="!pageableComponent.hasPrev()? 'pe-none text-secondary opacity-75' : 'text-primary'"
                        (click)="pageableComponent.prevPage()">
                        <i class="fas fa-arrow-left"></i>
                </button>
        </div>
        <div class="col-12 col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0 pages d-flex justify-content-center px-4">
                <button type="button" 
                        class="btn me-1"
                        (click)="pageableComponent.firstPage()"
                        [class]="pageableComponent.getPageNumber() === 1? 'btn-primary pe-none' : 'btn-outline-primary'">
                        1
                </button>
                <span class="text-muted mx-1"
                        *ngIf="pageableComponent.getPageNumber() > 3">
                        ...
                </span>
                <button type="button" 
                        *ngFor="let page of getMiddlePages()"
                        class="btn mx-1"
                        (click)="pageableComponent.nthPage(page-1)"
                        [class]="pageableComponent.getPageNumber() === page? 'btn-primary pe-none' : 'btn-outline-primary'">
                        {{ page }}
                </button>
                <span class="text-muted mx-1"
                        *ngIf="pageableComponent.getPageNumber() < pageableComponent.getPagesNumber() - 2">
                        ...
                </span>
                <button type="button" 
                        class="btn ms-1"
                        (click)="pageableComponent.lastPage()"
                        [class]="pageableComponent.getPageNumber() === pageableComponent.getPagesNumber()? 'btn-primary pe-none' : 'btn-outline-primary'">
                        {{pageableComponent.getPagesNumber()}}
                </button>
        </div>
        <div class="col-6 col-lg-2 order-1 order-lg-2">
                <button class="btn btn-outline-secondary btn-circle float-end lh-1"
                        [class]="!pageableComponent.hasNext()? 'pe-none text-secondary opacity-75' : 'text-primary'"
                        (click)="pageableComponent.nextPage()">
                        <i class="fas fa-arrow-right"></i>
                </button>
        </div>
</div>
