<!-- <div class="lesson-status-legent-wrapper">
    <div class="lesson-status-legend" >
        <span class="badge due" i18n="start in less than 12 hours label in agenda@@student-lessons.lesson-status.comming">Start &lt; 12h</span>
        <span class="badge in-progress" i18n="lesson in progress label in agenda@@student-lessons.lesson-status.in-progress">In progress</span>
        <span class="badge complete" i18n="complete lesson label in agenda@@student-lessons.lesson-status.complete">Complete</span>
        <span class="badge student-invalidate" i18n="cancelled lesson label in agenda@@student-lessons.lesson-status.cancelled">Cancelled</span>
    </div>
</div> -->
<ng-template [ngLoading]="!loaded" type="spinner">
    <p *ngIf="!hasResults()"
        i18n="please wait while loading@@common.please-wait"
        class="text-muted text-center my-3">
    Please wait...
    </p>
    <p *ngIf="hasResults() && getTotalResults()==0"
        i18n="didn't find any result@@common.no-results"
        class="text-muted text-center my-3">
    No results found.
    </p>
    <ul *ngIf="hasResults()" class="list-unstyled lessons-list">
        <li class="row mb-2 px-2 py-3" *ngFor="let lesson of getLessonsToDisplay()">
            <div class="col">
                <div class="row">
                    <div class="col-12 col-lg-3 mb-2 mb-lg-0 d-flex">
                        <div  class="rounded-circle img-thumbnail" style="width: 4rem; height: fit-content;">
                            <div class="profile-photo rounded-circle" [style.background-image]="'url(' + getLessonTeacherPhotoUrl(lesson) +')'">
                            </div>
                        </div>
                        <span *ngIf="lesson.lessonStatus" class="badge d-lg-none my-auto ms-auto" [ngClass]='lessonBadgeClass(lesson)' i18n="@@common.badge.select">{getLessonStatus(lesson), select, Reserved {Reserved} Complete {Complete} Start &lt; 12h {Start &lt; 12h} In progress {In progress} Cancelled {Cancelled}}</span>
                    </div>
                    <div class="col-12 col-lg-4 mb-2 mb-lg-0">
                        <p class="text-muted f-14 mb-0">Teacher:</p>
                        <h6 class="mt-0">{{getLessonTeacher(lesson)}}</h6>
                    </div>
                    <div class="col-12 col-lg-5 mb-2 mb-lg-0">
                        <p class="text-muted f-14 mb-0">Date:</p>
                        <p >{{getLessonDate(lesson) | date: 'EEEE, dd MMM yyyy'}} {{getLessonDate(lesson) | date: 'HH:mm'}}</p>
                    </div>
                </div>
            </div>
            <div class="col-4 col-lg-2 d-none d-lg-flex">
                <span *ngIf="lesson.lessonStatus" class="badge m-auto" [ngClass]='lessonBadgeClass(lesson)' i18n="@@common.badge.select">{getLessonStatus(lesson), select, Reserved {Reserved} Complete {Complete} Start &lt; 12h {Start &lt; 12h} In progress {In progress} Cancelled {Cancelled}}</span>
            </div>
            <div class="col-12 col-lg-3 mb-1 d-flex flex-column">
                    <button (click)="openDetails(lesson, $event)" *ngIf="mayBeViewed(lesson)" class="btn btn-outline-secondary text-white rounded-pill d-block w-100 my-auto"
                    i18n="@@student-lessons.view-lesson.btn"
                    >View details</button>
                    <button *ngIf="mayBeCancelled(lesson)" (click)="showLessonCancelation(lesson)" class="btn btn-outline-danger rounded-pill d-block w-100 mt-2"
                    i18n="@@student-lessons.cancel-lesson.btn"
                    >Cancel</button>
            </div>
        </li>
    </ul>

    <div *ngIf="mayExtend() || mayCollapse()" class="justify-content-center d-flex">
    <a *ngIf="mayCollapse()" (click)="changePageSize(-5)" tabindex="" class="me-2"><i class="fa fa-angle-double-up justify-content-center"></i></a>
    <a *ngIf="mayExtend()" (click)="changePageSize(5)" tabindex=""><i class="fa fa-angle-double-down justify-content-center"></i></a>
    </div>
</ng-template>
<app-modal #cancelLessonModal>
    <div class="modal-content" *ngIf="_clLessonToCancel">
        <div class="modal-header">
            <h5 class="modal-title" i18n="@@student-lessons.cancel-dialog.title">Cancel the Lesson</h5>
            <button type="button" class="btn-close" (click)="cancelLessonModal.hide()"  aria-label="Close"></button>
        </div>
        <form #f="ngForm" (ngSubmit)="f.valid && cancelLesson()">
            <div class="modal-body">
                <p i18n="cancellation body with info about time and tutor@@student-lessons.cancel-dialog.body">You are going to cancel the lesson planned on {{getLessonPlannedDate(_clLessonToCancel) | date: 'EEEE, dd MMM yyyy HH:mm'}} with {{getLessonTeacher(_clLessonToCancel)}}</p>
                <div *ngIf="_clLessonToCancel.lessonStatus == 'Due'" class="alert alert-danger"
                i18n="Warning about consequence because lesson is starting soon and it shouldn't be cancelled@@student-lessons.cancel-dialog.close-time-warn">
                    The lesson was confirmed therefore it can be only invalidated. Are you sure ?
                </div>
                <div class="form-group">
                    <label i18n="@@student-lessons.cancel-dialog.form.cancel-reason.label">Cancellation Reason</label>
                    <textarea
                    #reason="ngModel"
                    name="reason"
                    class="form-control"
                    required
                    [(ngModel)]="_clReason"
                    [ngClass]="{ 'is-invalid': f.submitted && reason.invalid }"
                    ></textarea>
                    <div *ngIf="f.submitted && reason.invalid" class="invalid-feedback">
                        <div *ngIf="reason.errors.required" i18n="@@student-lessons.cancel-dialog.form.cancel-reason.required">Cancellation reason is required</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-danger" i18n="cancellation confirmation button - the only one@@student-lessons.cancel-dialog.cancel.btn">Cancel The Lesson</button>
            </div>
        </form>
    </div>
</app-modal>
