import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { StudentRestService } from 'src/app/services/rest/student-rest.service';
import { ApiPersonalProfile, Gender, ApiFile, ApiCountry } from 'src/app/model/rest/rest-model';
import { switchMap, flatMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { StateAwareComponent, ComponentEvent } from 'src/app/model/rest/base';
import { environment } from 'src/environments/environment';
import { FilesRestService } from 'src/app/services/rest/files-rest.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ProfilePhotoUrlExtractor } from 'src/app/utils/profile-photo-url-extractor';
import { AppEventsService } from 'src/app/services/ctx/app-events.service';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { PublicRestService } from 'src/app/services/rest/public-rest.service';
import { Utils } from 'src/app/utils/utils';
import { PublicRestProxyService } from 'src/app/services/public-rest-proxy.service';
import { LocaleManagerService } from 'src/app/services/locale-manager.service';
import { ManagerRestService } from 'src/app/services/rest/manager-rest.service';


export class StudentPersonalProfileFormComponentEvent extends ComponentEvent {
  static ET_FORM_EDITABLE = 'underEdit';
  static ET_FORM_READ_ONLY = 'readOnly';

  static fromEditableFlag(editable: boolean) {
    const res = new StudentPersonalProfileFormComponentEvent();
    if (editable) {
      res.eventType = this.ET_FORM_EDITABLE;
    } else {
      res.eventType = this.ET_FORM_READ_ONLY;
    }
    return res;
  }
}

@Component({
  selector: 'app-student-personal-profile-form',
  templateUrl: './student-personal-profile-form.component.html',
  styleUrls: ['./student-personal-profile-form.component.css']
})
export class StudentPersonalProfileFormComponent extends StateAwareComponent<StudentPersonalProfileFormComponentEvent> implements OnInit {

  _studentId: number;
  genders = [ 'Male', 'Female', 'Other' ];
  profile: ApiPersonalProfile;
  _editable = true;
  uploadProgress: number = null;
  fileUrlBase = environment.fileEndpoint + '/img/';

  @ViewChild('fileInput', {static: false}) fileInput;
  countries: ApiCountry[];
  _schoolId: number;
  _componentRole: string;

  constructor(
    private fileRest: FilesRestService,
    private studentProxy: StudentCacheProxyService,
    private mangerRest: ManagerRestService,
    publicRest: PublicRestProxyService,
    localeService: LocaleManagerService) {
    super();
    publicRest.loadCountries(localeService.localeId).subscribe(countries => this.processCountries(countries));
  }

  processCountries(countries: ApiCountry[]): void {
    this.countries = countries.concat().sort((l, r) => l.name.localeCompare(r.name));
  }


  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    this.loadProfile();
  }

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.loadProfile();
  }

  @Input()
  set componentRole(role: string) {
    this._componentRole = role;
    this.loadProfile();
  }


  @Input()
  set editable(editable: boolean) {
    this._editable = editable;
    this.stateEvent.emit(StudentPersonalProfileFormComponentEvent.fromEditableFlag(this._editable));
  }

  public openFileChoose() {
    if (!this._editable) {return; }
    this.fileInput.nativeElement.click();
  }

  get editable() {
    return this._editable;
  }

  public getProfilePhoto(profile: ApiPersonalProfile) {
    if(profile.profilePhoto)
      return 'url(' + environment.fileEndpoint + '/img/'
          + profile.profilePhoto.id
          + '?fileAccessToken='
          + profile.profilePhoto.accessToken 
          + ')';
    return 'transparent'
  }

  public onProfileImageUpload(files: File[]) {
    this.uploadProgress = 0;
    this.fileRest.saveFile(files[0]).subscribe( event => {
      if (event.type === HttpEventType.UploadProgress ) {
        this.uploadProgress = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        const file = event.body as ApiFile;
        this.profile.profilePhoto = file;
        this.uploadProgress = null;
      }
    }, () => this.uploadProgress = null);
  }

  saveProfile() {
    if (this._componentRole === 'student') {
      return this.studentProxy.savePersonalProfile(this._studentId, this.profile);
    }
    if (this._componentRole === 'manager') {
      return this.mangerRest.saveStudentPersonalProfile(this._schoolId, this._studentId, this.profile);
    }
  }

  public onSave() {
    this.editable = false;
    this.saveProfile().pipe(
      map( p => Utils.jsonClone(p))
    )
    .subscribe(profileAfterUpdate => {
      this.profile = profileAfterUpdate;
    });
  }

  loadPerson() {
    if (this._componentRole === 'student') {
      return this.studentProxy.loadSelfPerson(this._studentId);
    } else if (this._componentRole === 'manager') {
      return this.mangerRest.getStudentPerson(this._schoolId, this._studentId);
    }
  }

  loadProfile(): any {
    if (!this._componentRole) {
      return;
    }
    if (this._componentRole === 'manager' && (!this._schoolId || !this._studentId)) {return; }
    if (this._componentRole === 'student' && (!this._studentId)) {return; }
    if (this._studentId == null) {
      return;
    }
    this.loadPerson().pipe(
      tap( selfPerson => {
        if ( !selfPerson.personalProfile ) {
          selfPerson.personalProfile = new ApiPersonalProfile();
          selfPerson.personalProfile.name = selfPerson.name;
          selfPerson.personalProfile.surname = selfPerson.surname;
          selfPerson.personalProfile.email = selfPerson.emailAddress;
        }
      }),
      tap (selfPerson => selfPerson.personalProfile.email = selfPerson.emailAddress ),
      map (selfPerson => selfPerson.personalProfile),
      map (profile => Utils.jsonClone(profile)),
      tap( profile => this.profile = profile )
    ).subscribe( () => this.stateEvent.emit(ComponentEvent.initialized()));
  }

  ngOnInit() {
    this.editable = false;
  }

}
