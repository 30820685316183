<section class="mb-5">
    <div class="container px-md-5">
        <div class="course-be">
            <div class="course">
                <h4 class="mb-0" i18n="Business courses page title@@businesscourses_page_title">Business English</h4>
            </div>
            <!-- <div class="card-body">
            <h5 class="text-primary" i18n="@@businesscourses.invest-career">Invest in your career</h5>
            <p i18n="english business courses page intro line 2|quick description of the progress@@english-business.intro.line2">Want to get ahead at work or improve your organisation’s English capability? Achieve it with our intensive programme of premium, one-to-one online Business English lessons with experienced professional native-speaking tutors.</p>
            </div> -->
        </div>
    </div>
</section>

<section class="mb-5">
    <div class="container">
        <nav>
            <div class="nav nav-tabs justify-content-center text-uppercase" id="nav-tab" role="tablist">
            <button class="nav-link text-uppercase px-2 px-md-4 active" id="nav-upfront-tab" data-bs-toggle="tab" 
                data-bs-target="#nav-upfront" type="button" role="tab"
                aria-controls="nav-upfront" aria-selected="true"
                i18n="upfront plans@@plans.upfront.label"
                >PAY UPFRONT</button>
            <button class="nav-link text-uppercase px-2 px-md-4" id="nav-instalment-tab" data-bs-toggle="tab" 
                data-bs-target="#nav-instalment" type="button" role="tab"
                aria-controls="nav-instalment" aria-selected="false"
                i18n="instalments plans@@plans.instalments.label"
                >PAY IN INSTALMENTS</button>
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">

            <div class="tab-pane fade show active" id="nav-upfront" role="tabpanel" aria-labelledby="nav-upfront-tab">
            <div class="row text-center">
                <div class="col-12 col-md-10 col-lg-8 col-xl-7 mx-auto">
                    <p class="text-muted f-14 my-4" i18n="upfront upsell@@english-business.upfront.upsell">Buy the whole course and save £250. You have 24 weeks to complete the course.</p>
                </div>
            </div>
            <div class="row justify-content-center g-3">
                <div class="col-10 col-sm-8 col-md-5 col-lg-4 col-xl-3 mb-3 h-100" *ngFor="let product of upfront">
                <app-product-box [product]="product" desc=""></app-product-box>
                </div>
            </div>
            </div>

            <div class="tab-pane fade" id="nav-instalment" role="tabpanel" aria-labelledby="nav-instalment-tab">
            <div class="row text-center">
                <div class="col-12 col-md-10 col-lg-8 col-xl-7 mx-auto">
                    <p class="text-muted f-14 my-4" i18n="english business course instalments intro|short introduction of the instalment plan@@english-business.instalments.intro">Payment divided into 3 parts. Buy each instalment when you are ready. Every time you buy an instalment, you get 1/3 of the course: 24 lesson credits and 8 weeks' time to use them.</p>
                </div>
            </div>
            <div class="row justify-content-center g-3">
                <div class="col-10 col-sm-8 col-md-5 col-lg-4 col-xl-3 mb-3 h-100" *ngFor="let product of instalment">
                <app-product-box [product]="product" desc=""></app-product-box>
                </div>
            </div>
            </div>
        </div>
    </div>
</section>

<section class="mb-5">
    <div class="container px-md-5">
        <div class="card">
            <div class="card-header">
                <h4 class="mb-0" i18n="@@english-business.useful-content.title">Useful content</h4>
            </div>
            <div class="card-body">
                <p class="mb-5" i18n="@@english-business.useful-content.body">Here are our tutors explaining some of the words and expressions that you will learn in your Business English course: </p>
                <div class="row">
                    <div class="col-10 col-sm-9 col-md-6 col-xl-4 mx-auto mb-4">
                        <div class="video-container mb-2">
                            <iframe src="https://www.youtube.com/embed/ajWzt6qOBjU" frameborder="0" width="560" height="315" allowfullscreen=""></iframe>
                        </div>
                        <p class="text-primary f-12 mb-0" i18n="@@english-business.video.lesson1.title">Lesson 1</p>
                        <p class="fs-5 mb-0" i18n="@@english-business.video.lesson1.subtitle">CEO</p>
                    </div>
                    <div class="col-10 col-sm-9 col-md-6 col-xl-4 mx-auto mb-4">
                        <div class="video-container mb-2">
                            <iframe src="https://www.youtube.com/embed/2WGTSiSNg74" frameborder="0" width="560" height="315" allowfullscreen=""></iframe>
                        </div>
                        <p class="text-primary f-12 mb-0" i18n="@@english-business.video.lesson4.title">Lesson 4</p>
                        <p class="fs-5 mb-0" i18n="@@english-business.video.lesson4.subtitle">Put somebody through to</p>
                    </div>
                    <div class="col-10 col-sm-9 col-md-6 col-xl-4 mx-auto mb-4">
                        <div class="video-container mb-2">
                            <iframe src="https://www.youtube.com/embed/mJlCw3am6qY" frameborder="0" width="560" height="315" allowfullscreen=""></iframe>
                        </div>
                        <p class="text-primary f-12 mb-0" i18n="@@english-business.video.lesson6.title">Lesson 6</p>
                        <p class="fs-5 mb-0" i18n="@@english-business.video.lesson6.subtitle">Lay somebody off</p>
                    </div>
                    <div class="col-10 col-sm-9 col-md-6 col-xl-4 mx-auto mb-4">
                        <div class="video-container mb-2">
                            <iframe src="https://www.youtube.com/embed/D3_06N463N4" frameborder="0" width="560" height="315" allowfullscreen=""></iframe>
                        </div>
                        <p class="text-primary f-12 mb-0" i18n="@@english-business.video.lesson8.title">Lesson 8</p>
                        <p class="fs-5 mb-0" i18n="@@english-business.video.lesson8.subtitle">FYI</p>
                    </div>
                    <div class="col-10 col-sm-9 col-md-6 col-xl-4 mx-auto mb-4">
                        <div class="video-container mb-2">
                            <iframe src="https://www.youtube.com/embed/R0b4L0lUrUk" frameborder="0" width="560" height="315" allowfullscreen=""></iframe>
                        </div>
                        <p class="text-primary f-12 mb-0" i18n="@@english-business.video.lesson12.title">Lesson 12</p>
                        <p class="fs-5 mb-0" i18n="@@english-business.video.lesson12.subtitle">Give somebody a heads-up</p>
                    </div>
                    <div class="col-10 col-sm-9 col-md-6 col-xl-4 mx-auto mb-4">
                        <div class="video-container mb-2">
                            <iframe src="https://www.youtube.com/embed/UH9ohPW3pPw" frameborder="0" width="560" height="315" target="_blank" allowfullscreen=""></iframe>
                        </div>
                        <p class="text-primary f-12 mb-0" i18n="@@english-business.video.lesson17.title">Lesson 17</p>
                        <p class="fs-5 mb-0" i18n="@@english-business.video.lesson17.subtitle">Flag up</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pb-5">
    <div class="container px-md-5">
        <div class="row text-center justify-content-center">
            <div class="col-12 col-md-6 col-xl-4">
                <p class="fw-bold" i18n="@@english-business.flexible-schedule.title">Flexible schedule</p>
                <p class="text-muted" i18n="@@english-business.flexible-schedule.desc">The business course includes 72 lessons. You should complete it within 24 weeks. You reserve each lesson individually, choosing the date, time and tutor. You should aim to have 5 lessons each week.</p>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
                <p class="fw-bold" i18n="@@english-business.professional-tutors.title">Professional tutors</p>
                <p class="text-muted" i18n="@@english-business.professional-tutors.body">Our business English courses are delivered by some of the best and most experienced Callan Method teachers. They will ensure that you progress consistently and realise your full potential. You can choose one tutor for the whole of your course, or change tutors to become familiar with different accents.</p>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
                <p class="fw-bold" i18n="@@english-business.entry-requirements.title"> Entry requirements</p>
                <p class="text-muted" i18n="@@english-business.entry-requirements.body">To take this course, you must be at least 20 years old and be at Stage 8 or higher.  Before you start, you will need a level test.</p>
            </div>
        </div>
    </div>
</section>    