import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { LangProductMapper } from 'src/app/utils/lang-mappers';
import { Dates } from 'src/app/utils/calendar-utils';
import { ApiLessonType, ApiLessonFlag, ApiLessonStatus } from 'src/app/model/rest/rest-model';

export class ReportGenericFilterData {
  dateFrom: Date;
  dateTo: Date;
  productCode: string;
  lessonType: string[];
  lessonFlag: string[];
  lessonStatus: string[];
}

@Component({
  selector: 'app-reports-filter',
  templateUrl: './reports-filter.component.html',
  styleUrls: ['./reports-filter.component.css']
})
export class ReportsFilterComponent implements OnInit {
  dateFrom: string;
  dateTo: string;
  productCode: string;
  langCodes = LangProductMapper.activeLangCodes;
  preselectedPeriod: number;
  flagTypes = ApiLessonFlag.flagsTyppes;
  lessonType: string[];
  lessonFlag: string[];
  lessonStatus: string[];
  constructor() { }

  @Input()
  withLessonType = false;
  @Input()
  withLessonStatus = false;
  @Input()
  withLessonFlag = false;

  @Output()
  generateReport = new EventEmitter<{dateFrom: Date, dateTo: Date, productCode: string}>();

  getLangName(langCode: string) {
    return LangProductMapper.mapLangCodeToLangName(langCode);
  }

  preselectedChange() {
    this.preselectedPeriod = Number(this.preselectedPeriod);
    this.dateFrom = Dates.simpleDateFormat(Dates.monthStartDate(this.preselectedPeriod));
    this.dateTo = Dates.simpleDateFormat(new Date());
    if (this.preselectedPeriod !== 0) {
      this.dateTo = Dates.simpleDateFormat(Dates.monthFinishDate(this.preselectedPeriod));
    }
  }

  public generate() {
    const dateFrom = this.dateFrom ? Dates.simpleDateTimeParse(this.dateFrom) : null;
    const dateTo = this.dateTo ? Dates.simpleDateTimeParse(this.dateTo) : null;
    const event: ReportGenericFilterData = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      productCode: this.productCode,
      lessonType: this.lessonType,
      lessonFlag: this.lessonFlag,
      lessonStatus: this.lessonStatus};
    this.generateReport.emit(event);
  }

  public predefProva() {
    this.lessonFlag = null;
    this.lessonType = ['Prova'];
    this.lessonStatus = ['Booked', 'Due', 'InProgress', 'Complete', 'InvalidatedByTeacher', 'InvalidatedByStudent'];
  }
  public predefProvaNoShow() {
    this.lessonFlag = ['StudentNotShow'];
    this.lessonType = ['Prova'];
    this.lessonStatus = ['Complete'];
  }
  public predefNoShow() {
    this.lessonFlag = null;
    this.lessonType = ['Standard', 'Revision', 'QuickStageRevision', 'Introduction', 'Prova', 'Exam'];
    this.lessonFlag = ['StudentNotShow'];
    this.lessonStatus = ['Complete'];
  }
  ngOnInit() {
  }

}
