<div class="card mb-4 mb-md-5" *ngIf="lessonDetails">
  <div class="card-header">
    <h6 class="mb-0">
      {{getLessonDate(lessonDetails) | date: 'EEEE, dd MMM yyyy'}} at {{getLessonDate(lessonDetails) | date: 'HH:mm'}}
      <strong *ngIf="getLessonStartedDate(lessonDetails)"><br>(started at: {{getLessonStartedDate(lessonDetails) | date: "HH:mm"}})</strong>
      <br>
      <small class="text-muted">{{lessonDetails.course.name}} • {{lessonDetails.lessonType}} • {{lessonDetails.lessonStatus}}</small>
      <span *ngIf="getLessonProductVersion()" class="ms-1" [ngClass]="{'badge bg-danger': isLessonInOldVersion()}">
        {{getLessonProductVersion()}}
      </span>
    </h6>
  </div>
  <div class="card-body">
    <div class="row gy-3">
      <div class="col-12 col-md-5 col-lg-4 order-md-1">
        <div class="row">
          <div class="col-auto">
            <div  class="rounded-circle img-thumbnail" style="margin: auto;width: 64px;">
              <div class="profile-photo rounded-circle" [style.background-image]="'url(' + getStudentPhoto(lessonDetails) +')'">
              </div>
            </div>
          </div>
          <div class="col">
            <p class="mb-0">
              <span>{{getStudentName(lessonDetails)}} </span><span *ngIf="getProductVersion()" class="ms-1" [ngClass]="{'badge bg-danger': isRedLabel()}">
        ({{getProductVersion()}})
      </span>
            </p>
            <p class="mb-0">
              <small class="text-muted">
              <span *ngIf="studentTechnicalProfile && studentTechnicalProfile.skype">Skype: {{studentTechnicalProfile.skype}}<br/></span>
              <span *ngIf="getStudentNationality()">Nationality: {{getStudentNationality()}}<br></span>
              <span *ngIf="getStudentCountry()">Country: {{getStudentCountry()}}<br></span>
              <span *ngIf="getStudentGender()">Gender: {{getStudentGender()}}</span>
              </small>
            </p>
          </div>


        </div>
      </div>
      <div class="col-12 col-md">

        <!--Lesson type:<strong>{{lessonDetails.lessonType}}</strong>, status: <strong>{{lessonDetails.lessonStatus}}</strong>-->
        <div *ngIf="currentProgress">
          <p>Recommended lesson plan:</p>
          <dl class="row">
            <dd class="col-sm-4 col-lg-3">Revision:</dd>
            <dt class="col-sm-8 col-lg-9">{{getRevisionPage()}}</dt>
            <dd *ngIf="currentProgress.reading" class="col-sm-4 col-lg-3">Reading:</dd>
            <dt *ngIf="currentProgress.reading" class="col-sm-8 col-lg-9"> {{getRecomendedReading()}}</dt>
            <dd class="col-sm-4 col-lg-3">NW:</dd>
            <dd class="col-sm-8 col-lg-9"><strong>{{currentProgress.nwp}}</strong>, after HW: <strong>{{currentProgress.hw}}</strong></dd>

          </dl>
        </div>
      </div>
    </div>

    <div *ngIf="currentProgress && roomUrl && isRunning">
      <a class="btn btn-primary me-2" [href]="roomUrl" target="_blank" *ngIf="!isSkype">Enter Room</a>
      <button class="btn btn-secondary me-2" *ngIf="isSkype" (click)="reserveVideoClassroom()">Switch to Video</button>
      <button class="btn btn-secondary me-2" [attr.disabled]="allowNotification ? null :''" (click)="sendStudentStartNotification()">Send Reminder</button>
    </div>
    <div *ngIf="needConfirmation || readyToStart || isComming">
        <button class="btn btn-primary me-2" (click)="confirm()" *ngIf="needConfirmation" [disabled]="!f.valid">Confirm</button>
        <button class="btn btn-primary me-2" *ngIf="(isComming || readyToStart) && !lessonDetails.roomUrl && !needConfirmation && mayBeStartedTime" (click)="reserveVideoClassroom()">Start Video</button>
        <a class="btn btn-success me-2" *ngIf="(isRunning || readyToStart) && roomUrl && !isSkype" [href]="roomUrl" (click)="enterTheRoom()" tabindex="" target="_blank"> Enter Room</a>
        <button class="btn btn-success me-2" (click)="startLesson()" *ngIf="readyToStart && roomUrl && isSkype" [disabled]="!f.valid">Start</button>
        <button class="btn btn-danger me-2" *ngIf="cancelable" (click)="showCancelLesson()">Cancel</button>
        <button class="btn btn-danger me-2" *ngIf="isComming && !cancelable" (click)="showCancelLesson()">Cancel</button>
        <button class="btn btn-secondary me-2" [attr.disabled]="allowNotification ? null :''" (click)="sendStudentStartNotification()">Send Reminder</button>
    </div>
  </div>
</div>

<div class="card lesson-form mb-4 mb-md-5" *ngIf="lessonDetails && (isComplete || isRunning || shouldBeStarted)">
  <div class="card-header">
      <span>Lesson commited progress</span>
  </div>
  <div class="card-body">

    <form #f="ngForm" (ngSubmit)="f.form.valid && commitLesson()">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <input
              class="form-control input-arrows-none"
              type="number"
              name="comStart"
              id="comStart"
              placeholder="comStart"
              [(ngModel)]="lessonDetails.progressCommited.start"
              #comStart="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && comStart.invalid }"
              [attr.disabled]="canUpdateProgress ? null : ''"
              required
            >
            <label class="required">Start page</label>
            <div *ngIf="f.submitted && comStart.invalid" class="invalid-feedback">
              <div *ngIf="comStart.errors.required">Field is required</div>
              <div *ngIf="comStart.errors.pattern">Only numbers are allowed</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <input
              class="form-control input-arrows-none"
              type="number"
              id="comNwp"
              name="comNwp"
              placeholder="comNwp"
              #comNwp="ngModel"
              [(ngModel)]="lessonDetails.progressCommited.nwp"
              [attr.disabled]="canUpdateProgress ? null : ''"
              [ngClass]="{ 'is-invalid': f.submitted && comNwp.invalid }"
              required
            >
            <label for="comNwp" class="required">New work page</label>
            <div *ngIf="f.submitted && comNwp.invalid" class="invalid-feedback">
              <div *ngIf="comNwp.errors.required">Field is required</div>
              <div *ngIf="comNwp.errors.pattern">Only numbers are allowed</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <input
              class="form-control input-arrows-none"
              type="number"
              id="comReading"
              name="comReading"
              placeholder="comReading"
              #comReading="ngModel"
              [(ngModel)]="lessonDetails.progressCommited.reading"
              [attr.disabled]="canUpdateProgress ? null : ''"
              [ngClass]="{ 'is-invalid': f.submitted && comReading.invalid }"
            >
            <label for="comReading">Reading</label>
            <div *ngIf="f.submitted && comReading.invalid" class="invalid-feedback">
              <div *ngIf="comReading.errors.required">Field is required</div>
              <div *ngIf="comReading.errors.pattern">Only numbers are allowed</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              id="comHw"
              name="comHw"
              placeholder="comHw"
              [(ngModel)]="lessonDetails.progressCommited.hw"
              #comHw="ngModel"
              [attr.disabled]="canUpdateProgress ? null : ''"
              [ngClass]="{ 'is-invalid': f.submitted && comHw.invalid }"
              required
            >
            <label for="comHw" class="required">Last headword</label>
            <div *ngIf="f.submitted && comHw.invalid" class="invalid-feedback">
              <div *ngIf="comHw.errors.required">Field is required</div>
            </div>
          </div>
        </div>
      </div>
      <div >

        <div class="row">
          <div class="col-12 col-md-6 mb-4">
            <div class="form-floating">
              <textarea
                id="comment"
                name="comment"
                placeholder="comment"
                class="form-control textarea-height"
                [(ngModel)]="lessonDetails.message.lessonComment"
                [attr.disabled]="canUpdateProgress ? null : ''"
                #comment="ngModel"
              ></textarea>
              <label for="comment">Lesson comment</label>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-4">
            <div class="form-floating">
              <textarea
                class="form-control textarea-height"
                name="studentMessage"
                id="studentMessage"
                placeholder="studentMessage"
                [(ngModel)]="lessonDetails.studentMessage"
                [attr.disabled]="canUpdateProgress ? null : ''"
                #studentMessage="ngModel"
              ></textarea>
              <label for="studentMessage">Message for the student</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <select
              class="form-select"
              id="lessonType"
              name="lessonType"
              [(ngModel)]="newLessonType"
              [attr.disabled]="canUpdateProgress ? null : ''"
              [ngClass]="{'select-not-empty' : newLessonType}"
              required
              >
              <option value="Standard">Standard</option>
              <option value="Revision">Revision</option>
              <option value="QuickStageRevision">Quick Stage Revision</option>
              <option value="Introduction">Introduction</option>
              <option value="Exam">Exam</option>
              <option value="Prova">Prova</option>
            </select>
          <label for="lessonType" class="required">Current Lesson Type</label>
          </div>
        </div>
        <div *ngIf="studentContext" class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <select
              class="form-select"
              id="commingLessonType"
              name="commingLessonType"
              [(ngModel)]="studentContext.nextLessonType"
              [attr.disabled]="canUpdateProgress ? null : ''"
              [ngClass]="{'select-not-empty' : studentContext.nextLessonType}"
              required
            >
              <option value="Standard">Standard</option>
              <option value="Revision">Revision</option>
              <option value="QuickStageRevision">Quick Stage Revision</option>
              <option value="Introduction">Introduction</option>
              <option value="Exam">Exam</option>
              <option value="Prova">Prova</option>
            </select>
            <label for="commingLessonType" class="required">Coming Lesson Type</label>
          </div>
        </div>
        <div *ngIf="studentContext" class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <select
              class="form-select"
              id="studentsStage"
              name="studentsStage"
              [(ngModel)]="studentContext.currentCourse"
              [attr.disabled]="canUpdateProgress ? null : ''"
              [ngClass]="{'select-not-empty' : studentContext.currentCourse }"
              required
            >
            <option *ngFor="let course of productCourses" [ngValue]="course">{{course.name}}</option>
            </select>
            <label for="studentsStage" class="required">Student's Stage</label>
          </div>
        </div>
      </div>
      <div *ngIf="lessonDetails && lessonDetails.flags && lessonDetails.flags.length > 0">
        <ul>
          <li *ngFor="let flag of lessonDetails.flags">
              {{fGetFlagDescription(flag.flagType)}}
            <span *ngIf="flag.description"><br>
              with description: {{flag.description}}
            </span>
          </li>
        </ul>
      </div>
      <div class="row" *ngIf="canUpdateProgress">
        <div class="col-12">
          <button *ngIf="!isComplete" type="submit" class="btn btn-primary me-2 mb-2">Save progress and Finish The Lesson</button>
          <button *ngIf="isComplete && f.touched" class="btn btn-primary me-2 mb-2" [disabled]="!f.valid" (click)="commitLesson()">Update progress</button>
          <button [attr.disabled]="isComplete ? null : ''" type="button" class="btn btn-secondary me-2  mb-2" (click)="showFlagsModal()">Attach the Flag</button>
          <button type="button" class="btn btn-danger me-2 mb-2" *ngIf="cancelable" (click)="showCancelLesson()">Cancel</button>
          <button type="button" class="btn btn-danger me-2 mb-2" *ngIf="isComming && !cancelable" (click)="showCancelLesson()">Dismiss</button>
          <button type="button" class="btn btn-danger me-2 mb-2" *ngIf="haveGift()" (click)="giveGift()">{{getGiftButtonDescription()}}</button>
          <button type="button" class="btn btn-danger me-2 mb-2" (click)="studentNoShow()" *ngIf="(shouldBeStarted && isComming) || isRunning">Student no show</button>
          <button type="button" class="btn btn-success mb-2" (click)="migrateToNewEnglishConfirmationModal.show()" *ngIf="isOldEnglishVersion()">Switch edition</button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="card mb-4 mb-md-5">
  <div class="card-header">
      <span>Lesson history</span>
  </div>
  <div class="card-body">

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>Date</th>
            <th>Start</th>
            <th>Nwp</th>
            <th>Read</th>
            <th>Headword</th>
            <th>Tutor</th>
            <th>Comment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lesson of history">
            <td >{{lesson.lessonType}}</td>
            <td>{{getHistoryDate(lesson) | date: 'dd/MM/yy HH:mm'}}</td>
            <td>{{lesson.progressCommited?.start? lesson.progressCommited?.start : '-' }}</td>
            <td>{{lesson.progressCommited?.nwp? lesson.progressCommited?.nwp : '-' }}</td>
            <td>{{lesson.progressCommited?.reading? lesson.progressCommited?.reading : '-' }}</td>
            <td>{{lesson.progressCommited?.hw? lesson.progressCommited?.hw : '-' }}</td>
            <td>{{getTeacherName(lesson)}}</td>
            <td [ngClass]="{'text-center' : !(lesson.message.lessonComment ||lesson.message.privateLessonComment)}">
                <span *ngIf="!(lesson.message.lessonComment ||lesson.message.privateLessonComment)">-</span>
                <span class="text-break" *ngIf="lesson.message.lessonComment">lesson comment: {{lesson.message.lessonComment}}<br></span>
                <span class="text-break" *ngIf="lesson.message.privateLessonComment">lesson private comment: {{lesson.message.privateLessonComment}}<br></span>
            </td>
            <td class="text-end">
              <div *ngIf="lesson.flags.length > 0" class="lesson-table-tooltip">
                <button class="btn btn-flag px-0 border-0"
                        type="button">
                  <i  class="fas fa-flag"></i>
                </button>
                <div class="lesson-table-tooltip-content p-2 rounded shadow">
                  <ul class="list-unstyled mb-0">
                    <li *ngFor="let flag of lesson.flags">
                        <strong>{{fGetFlagDescription(flag.flagType)}}</strong><span *ngIf="flag.description">:<br>
                        with description: {{flag.description}}
                        </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="lesson.studentMessage" class="lesson-table-tooltip">
                <button class="btn btn-comment px-0 border-0"
                        type="button">
                  <i class="fas fa-comments" ></i>
                </button>
                <div class="lesson-table-tooltip-content p-2 rounded shadow">
                    <span>message to the student: {{lesson.studentMessage}}</span>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="hasHistoryNext" (click)="historyNext()">
            <td colspan="9" class="text-center"><a tabindex=""><i class="fa fa-angle-double-down justify-content-center"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-modal #flagModal >
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" >Post the flag</h5>
      <button type="button" class="btn-close" (click)="flagModal.hide()"  aria-label="Close"></button>
    </div>
    <form #f="ngForm" (ngSubmit)="postFlag()">
      <div class="modal-body" *ngIf="_fFlags">
        <p>Choose the flag type:</p>
        <div class="input-group" *ngFor="let flagType of _fAllFlags">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              name="{{flagType}}"
              [(ngModel)]="_fFlags[flagType]"
              [disabled]="fHasFlag(flagType)"
              >
            <label class="form-check-label" >
              {{fGetFlagDescription(flagType)}}
            </label>
          </div>
        </div>
        <div class="row" *ngIf="fAskForComment()">
          <div class="col-12 col-md-6">
            <div class="form-floating">
              <textarea
                #description="ngModel"
                name="description"
                id="description"
                class="form-control textarea-height"
                required
                [(ngModel)]="_fDescription"
                [ngClass]="{ 'is-invalid': f.submitted && description.invalid }"
              ></textarea>
              <label for="description">Flag description</label>
              <div *ngIf="f.submitted && description.invalid" class="invalid-feedback">
                  <div *ngIf="description.errors.required">Flag description</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-danger">Save flag</button>
      </div>
  </form>
  </div>
</app-modal>
<app-modal #cancelLessonModal>
  <div class="modal-content" *ngIf="lessonDetails">
      <div class="modal-header">
          <h5 class="modal-title" >Cancel the Lesson</h5>
          <button type="button" class="btn-close" (click)="cancelLessonModal.hide()"  aria-label="Close"></button>
      </div>
      <form #f="ngForm" (ngSubmit)="f.valid && cancelLesson()">
          <div class="modal-body">
              <p>You are going to cancel this lesson.</p>
              <div *ngIf="lessonDetails.lessonStatus == 'Due' || shouldBeStarted" class="alert alert-danger">
                  The lesson was confirmed or is after planned start time therefore it can be only invalidated. Are you sure?
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-floating">
                    <textarea
                      #reason="ngModel"
                      name="reason"
                      id="reason"
                      placeholder="reason"
                      class="form-control textarea-height"
                      required
                      [(ngModel)]="_clReason"
                      [ngClass]="{ 'is-invalid': f.submitted && reason.invalid }"
                    ></textarea>
                    <label for="reason">Cancelation Reason</label>
                    <div *ngIf="f.submitted && reason.invalid" class="invalid-feedback">
                        <div *ngIf="reason.errors.required">Cancelation reason is required</div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="submit" class="btn btn-danger">Cancel The Lesson</button>
          </div>
      </form>
  </div>
</app-modal>
<app-modal #notificationConfirmationModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" >Notification confirmation</h5>
      <button type="button" class="btn-close" (click)="notificationConfirmationModal.hide()"  aria-label="Close"></button>
    </div>
    <div class="modal-body">
      Notification was sent.
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="notificationConfirmationModal.hide()">Ok</button>
    </div>
  </div>
</app-modal>
<app-modal #migrateToNewEnglishConfirmationModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Confirm switch</h5>
      <button type="button" class="btn-close" (click)="migrateToNewEnglishConfirmationModal.hide()" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      Are you sure you want to switch this Student to 3rd Edition?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="migrateToNewEnglishConfirmationModal.hide()">Cancel</button>
      <button type="submit" class="btn btn-primary" [disabled]="isMigratingInProgress" (click)="migrateToNewEnglish()">
        Confirm
        <div *ngIf="isMigratingInProgress" class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
  </div>
</app-modal>


