import { Component, OnInit } from '@angular/core';
import { LessonType } from 'src/app/components/teacher/teacher-lessons/teacher-lessons.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiLessonInstance } from 'src/app/model/rest/rest-model';
import {LessonDetailsEvent} from "../../../components/student/student-lessons/student-lessons.component";

@Component({
  selector: 'app-manager-teacher-lessons-page',
  templateUrl: './manager-teacher-lessons-page.component.html',
  styleUrls: ['./manager-teacher-lessons-page.component.css']
})
export class ManagerTeacherLessonsPageComponent implements OnInit {

  schoolId: string;
  teacherId: string;
  lessonTypes = LessonType;

  constructor(activatedRoute: ActivatedRoute,
    private router: Router) {
    activatedRoute.parent.paramMap.subscribe( params => {
      this.schoolId = params.get('schoolId');
      this.teacherId = params.get('teacherId');
    });
  }

  openLessonDetails(event: LessonDetailsEvent) {
    if (event.event.altKey || event.event.shiftKey) {
      const urlTree = this.router.createUrlTree(['school', this.schoolId, 'teacher', this.teacherId, 'lessons', event.lesson.id]);
      const url = this.router.serializeUrl(urlTree)
      const handle = window.open(url, '_blank');
      handle.blur()
      window.focus()
      return;
    }
    this.router.navigate(['school', this.schoolId, 'teacher', this.teacherId, 'lessons', event.lesson.id]);
  }

  ngOnInit() {
  }

}
