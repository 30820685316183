import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd, UrlSegment } from '@angular/router';
import { ManagerRestService } from 'src/app/services/rest/manager-rest.service';
import { tap } from 'rxjs/operators';
import { ApiLearningUnitStudent, ApiPerson, ApiPersonalProfile } from 'src/app/model/rest/rest-model';
import { PersonNameExtractor } from 'src/app/utils/profile-photo-url-extractor';
import { ManagerStudentCreditsPageComponent
} from 'src/app/pages/manager/manager-student-credits-page/manager-student-credits-page.component';
import { ManagerStudentLessonsPageComponent
} from 'src/app/pages/manager/manager-student-lessons-page/manager-student-lessons-page.component';

@Component({
  selector: 'app-manager-student-details-layout',
  templateUrl: './manager-student-details-layout.component.html',
  styleUrls: ['./manager-student-details-layout.component.css']
})
export class ManagerStudentDetailsLayoutComponent implements OnInit {
  _schoolId: number;
  _studentId: number;
  student: ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>;
  currentFragment: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private managerRest: ManagerRestService) { }

  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.loadStudent();
  }

  set studentId(studentId: number) {
    this._studentId = studentId;
    this.loadStudent();
  }

  getPersonName(person: ApiPerson<ApiPersonalProfile>) {
    return PersonNameExtractor.getPersonName(person);
  }

  loadStudent() {
    if (!this._schoolId || !this._studentId) {
      return;
    }

    this.managerRest.getStudent(this._schoolId, this._studentId).pipe(
      tap( student => this.student = student)
    ).subscribe();

  }

  ngOnInit() {
    this.determineActiveButton(this.activeRoute.firstChild.snapshot.url);
    this.activeRoute.parent.paramMap.subscribe( params => this.schoolId = Number(params.get('schoolId')));
    this.activeRoute.paramMap.subscribe( params => this.studentId = Number(params.get('studentId')));
    this.router.events.subscribe( e => this.handleRouterEvent(e));
  }

  handleRouterEvent(e: any): void {
    if (!(e instanceof ActivationEnd)) {
      return;
    }
    this.determineActiveButton(e.snapshot.url);
  }

  determineActiveButton(urls: UrlSegment[]): void {
    if (urls.length > 0) {
      const lastPath = urls[urls.length - 1].path;
      if ( lastPath === 'credits') {
        this.currentFragment = 'credits';
      } else if ( lastPath === 'lessons') {
        this.currentFragment = 'lessons';
      } else if ( lastPath === 'profile') {
        this.currentFragment = 'profile';
      }
    }
  }

}
