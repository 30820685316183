import {Component, OnInit, Input, OnDestroy, ViewChild} from '@angular/core';
import { StudentRestService } from 'src/app/services/rest/student-rest.service';
import { tap, map } from 'rxjs/operators';
import {ApiLessonInstance, ApiPerson, ApiLessonStatus, ApiTeacherProfile, ApiLearningUnitTeacher, ApiPersonalProfile, ApiPersonalProfileBaseWithPhoto} from 'src/app/model/rest/rest-model';
import { PersonNameExtractor, ProfilePhotoUrlExtractor } from 'src/app/utils/profile-photo-url-extractor';
import { interval, Observable, Subscription, merge, zip } from 'rxjs';
import { ComponentEvent } from 'src/app/model/rest/base';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Dates, TimeUnits } from 'src/app/utils/calendar-utils';
import { environment } from 'src/environments/environment';
import { AnalyticsService, Event } from 'src/app/services/analytics.service';
import { Router } from '@angular/router';
import {ModalComponent} from "../../modal/modal.component";

export class StudentCommingLessonComponentEvent extends ComponentEvent {}

export enum CommingLessonDemoMode {
  NoEnterButton,
  SkypeEnterButton,
  VideoRoomEnterButton
}


@Component({
  selector: 'app-student-comming-lesson',
  templateUrl: './student-comming-lesson.component.html',
  styleUrls: ['./student-comming-lesson.component.css']
})
export class StudentCommingLessonComponent
  implements OnInit, OnDestroy {


  _studentId: number;
  _commingLessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  timePhrase: string;
  i = 0;
  isSkype: boolean;
  startedAt: Date = null;
  roomUrl: SafeResourceUrl;
  plannedAt: Date;
  isComming: boolean;
  isStarted: boolean;
  shouldStart: boolean;
  mayStart: boolean;
  isAfterPlannedStartDate: boolean;
  reloadLessonIntervalSubscription: Subscription;
  timeSubscription: Subscription;
  staticPagesRoot = environment.staticPagesBase;

  @Input()
  demoMode: CommingLessonDemoMode = null;

  @ViewChild("classroomFallback", {static: true})
  classroomFallbackModal: ModalComponent
  private teacherSkype: string;

  constructor(
    private studentRest: StudentRestService,
    private _santizer: DomSanitizer,
    private analytics: AnalyticsService,
    private router: Router
  ) {}

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
  }

  @Input()
  set commingLessonDetails(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    this._commingLessonDetails = lesson
    this.prepareDetails();
  }
  get commingLessonDetails(): ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher> {
    return this._commingLessonDetails
  }

  mayEnterClassroom(): boolean {
    if (this.demoMode === CommingLessonDemoMode.NoEnterButton) {
      return false;
    }
    if (this.demoMode === CommingLessonDemoMode.SkypeEnterButton ||
      this.demoMode === CommingLessonDemoMode.VideoRoomEnterButton) {
        return true;
      }
    return this.roomUrl && (this.mayStart || this.shouldStart || this.isStarted);
  }
  getPersonName(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
    return PersonNameExtractor.getPersonName(person);
  }

  getCourseName(): string {
    return this.commingLessonDetails.course.name;
  }

  hasCommingDetails(): boolean {
    if (this.commingLessonDetails) {
      return true;
    } else {
      return false;
    }
  }

  getTeacherName(): string {
    return this.getPersonName(this.commingLessonDetails.teacher.person);
  }

  getPlannedStartDate(): Date {
    return this.commingLessonDetails.lessonMetric.plannedStartDate;
  }

  isLessonGoingToStartRegulary(): boolean {
    if (this.demoMode === CommingLessonDemoMode.NoEnterButton) {
      return true;
    }
    if (this.demoMode != null) {
      return false;
    }
    return this.isComming && !this.shouldStart && !this.isAfterPlannedStartDate && !(this.roomUrl && this.mayStart);
  }

  isTeacherMissingToStartLesson(): boolean {
    if (this.demoMode != null) {
      return false;
    }
    return this.isAfterPlannedStartDate && !this.roomUrl;
  }

  isLessonReadyToStart(): boolean {
    if (this.demoMode === CommingLessonDemoMode.NoEnterButton) {
      return false;
    }
    if (this.demoMode != null) {
      return true;
    }
    return this.roomUrl && ( (this.isComming && this.mayStart) || this.shouldStart || this.isStarted);
  }

  mayEnterRoomBySkype(): boolean {
    if (this.demoMode === CommingLessonDemoMode.SkypeEnterButton) {
      return true;
    }
    if (this.demoMode != null) {
      return false;
    }
    return this.mayEnterClassroom() && this.isSkype;
  }

  mayEnterVideoRoom(): boolean {
    if (this.demoMode === CommingLessonDemoMode.VideoRoomEnterButton) {
      return true;
    }
    if (this.demoMode != null) {
      return false;
    }
    return this.mayEnterClassroom() && !this.isSkype;
  }

  getPersonPhoto(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(person);
  }

  getRoomUrl(): SafeResourceUrl {
    if (this.demoMode != null) {
      return this._santizer.bypassSecurityTrustResourceUrl('javascript:void(0)');
    }
    return this.roomUrl;
  }

  moveToInstuctions() {
    if (this.demoMode != null) {
      return;
    }
    this.router.navigate(['student', this._studentId, 'tutorials', 'prova-booked'],
    {queryParams : { back : '/'}});
  }

  getTarget(): string {
    if (this.demoMode != null) {
      return '';
    }
    return '_blank';
  }

  handleEnterRoomEvent(url: SafeResourceUrl) {
    if (this.demoMode) {
      return;
    }
    if (this.isSkype) {
      this.analytics.event(Event.ACT_LS_ENTER_ROOM, new Event(Event.CAT_LESSON, 'skype'));
    } else {
      this.analytics.event(Event.ACT_LS_ENTER_ROOM, new Event(Event.CAT_LESSON, 'video-room'));
    }
  }

  getLessonSkypeAddress(): string {
    if (!this.isSkype || !this.commingLessonDetails || !this.commingLessonDetails.roomUrl) {
      return 'missing address';
    }
    if (this.commingLessonDetails.roomUrl.startsWith('skype:')) {
      return this.commingLessonDetails.roomUrl.substring('skype:'.length);
    }
  }

  prepareDetails(): any {
    this.startedAt = null;
    this.plannedAt = null;
    this.isComming = false;
    this.isStarted = false;
    this.shouldStart = false;
    this.mayStart = false;
    this.prepareRoomInfo();
    if (!this.commingLessonDetails) {return; }
    if (this.commingLessonDetails.lessonMetric.started) {
      this.startedAt = new Date(this.commingLessonDetails.lessonMetric.started);
    }
    const lessonStatus = ApiLessonStatus[this.commingLessonDetails.lessonStatus];
    this.plannedAt = new Date(this.commingLessonDetails.lessonMetric.plannedStartDate);
    this.isComming = lessonStatus === ApiLessonStatus.Booked || lessonStatus === ApiLessonStatus.Due;
    this.isStarted = lessonStatus === ApiLessonStatus.InProgress;
    this.shouldStart = this.isComming && Dates.diff(this.plannedAt, new Date()) > TimeUnits.Minues(3).toMilis();
    this.isAfterPlannedStartDate = this.isComming && Dates.diff(this.plannedAt, new Date()) > 0;
    this.mayStart = this.isComming && Dates.diff(new Date(), this.plannedAt) < TimeUnits.Minues(5).toMilis();
  }

  prepareRoomInfo(): any {
    this.roomUrl = null;
    this.isSkype = false;
    if (!this.commingLessonDetails || !this.commingLessonDetails.roomUrl) {return; }
    if (this.commingLessonDetails.roomUrl.startsWith('skype:')) {
      this.isSkype = true;
      const url = this.commingLessonDetails.roomUrl + '?call';
      this.roomUrl = this._santizer.bypassSecurityTrustResourceUrl(url);
    } else if (this.commingLessonDetails.roomUrl.startsWith('video_room_url:')) {
      const url = this.commingLessonDetails.roomUrl.substring('video_room_url:'.length);
      this.roomUrl = this._santizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  ngOnInit() {
    this.timeSubscription = interval(1000).pipe(
      tap( () => this.prepareDetails()),
      map(() => this.prepareTimePhrase())
    ).subscribe( phrase => this.timePhrase = phrase);


  }

  ngOnDestroy(): void {
    this.timeSubscription.unsubscribe();
  }

  prepareTimePhrase(): string {
    if (!this.commingLessonDetails) {return ''; }
    const timeReference = this.isStarted ? this.startedAt : this.plannedAt;
    return this.toTimeStr(Dates.diffAbs(timeReference, new Date()));
  }

  toTimeStr(time: number): string {
    return Dates.toTimeStr(time);
  }


  getTeacherSkype() {
    return this.teacherSkype;
  }

  getTeacherSkypeUrl() {
    if (!this.teacherSkype) return null;
    return this._santizer.bypassSecurityTrustResourceUrl(`skype:${this.teacherSkype}`);
  }

  openSkypeHelp() {
    if (this.demoMode != null) return;
    this.loadTeacherSkype().subscribe();
    this.classroomFallbackModal.show(true);
  }

  private loadTeacherSkype(): Observable<ApiTeacherProfile> {
    return this.studentRest.listTeachers(this._studentId, [this.commingLessonDetails.teacher.id])
      .pipe(
        map( teachers => teachers && teachers.length > 0 ? teachers[0] : null),
        tap( teacher => teacher ? this.teacherSkype = teacher.skype : this.teacherSkype = null)
      )
  }

  isProva() {
    return this.commingLessonDetails && this.commingLessonDetails.lessonType === 'Prova';
  }

  getCourseLangName() {
    return this.commingLessonDetails && (
      (this.commingLessonDetails.course.code.startsWith("en.") && "English")
      || (this.commingLessonDetails.course.code.startsWith("sp.") && "Spanish")
      || (this.commingLessonDetails.course.code.startsWith("en.bs.") && "Business English")
      || (this.commingLessonDetails.course.code.startsWith("en.ch.") && "English For Children")
    )
    || ""
  }
}
