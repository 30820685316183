<table class="table">
  <thead>
    <tr>
      <th>Teacher</th>
      <th>Complete</th>
      <th>student no show</th>
      <th>invalidated by student</th>
      <th>invalidated by teacher</th>
      <th>canceled</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let entry of reportResult">
      <td>{{getTeacherName(entry.teacher.teacher.person)}}</td>
      <td>{{entry.completed}}</td>
      <td>{{entry.studentNoShow}}</td>
      <td>{{entry.invalidatedByStudent}}</td>
      <td>{{entry.invalidatedByTeacher}}</td>
      <td>{{entry.cancelled}}</td>
    </tr>
  </tbody>
</table>