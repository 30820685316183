import { Component, OnInit, Input } from '@angular/core';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { tap, map } from 'rxjs/operators';
import { ApiTeacherProfile, ApiTeacherProductCompetences } from 'src/app/model/rest/rest-model';
import { ProfilePhotoUrlExtractor, PersonNameExtractor } from 'src/app/utils/profile-photo-url-extractor';

class TeacherDescription {
  constructor( public teacherProfile: ApiTeacherProfile, public competence: ApiTeacherProductCompetences) {}
}

@Component({
  selector: 'app-student-teachers-grid',
  templateUrl: './student-teachers-grid.component.html',
  styleUrls: ['./student-teachers-grid.component.css']
})
export class StudentTeachersGridComponent implements OnInit {

  _langCode: string;
  _studentId: number;
  teachersDescriptions: TeacherDescription[];
  constructor(private studentCache: StudentCacheProxyService) { }

  @Input()
  set langCode(langCode: string) {
    this._langCode = langCode;
    this.loadTeachers();
  }

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    this.loadTeachers();
  }

  ngOnInit() {
  }

  getImage(descr: TeacherDescription) {
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(
      (descr && descr.teacherProfile && descr.teacherProfile.teacher) ? descr.teacherProfile.teacher.person : null);
  }

  getName(descr: TeacherDescription) {
    return PersonNameExtractor.getPersonName(
      (descr && descr.teacherProfile && descr.teacherProfile.teacher) ? descr.teacherProfile.teacher.person : null
     );
  }

  getDescription(descr: TeacherDescription) {
    const res = descr.competence.productCompetenceDescription;
    if (!res ) { return ''; }
    if (res.length > 200) { return res.slice(0, 200) + '...'; }
    return res;
  }

  loadTeachers(): any {
    if (!this._studentId || !this._langCode) {
      return;
    }

    this.studentCache.findAllTeachers(this._studentId).pipe(
      map( teachers =>
        teachers
        .filter( teacherProfile => teacherProfile.competences.some( c => c.product.code === this._langCode ))
        .map( teacherProfile =>
          new TeacherDescription(teacherProfile, teacherProfile.competences.find( c => c.product.code === this._langCode)))
      ),
      tap( teacherDescriptions => this.teachersDescriptions = teacherDescriptions)
    ).subscribe();

  }


}
