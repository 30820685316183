import { Component, OnInit, Input, LOCALE_ID, Inject, Directive } from '@angular/core';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { ApiProduct, ProductsUtils } from 'src/app/model/rest/products';
import { StudentStartersOfferComponentEvent } from 'src/app/components/student/student-starters-offer/student-starters-offer.component';
import { ComponentEvent } from 'src/app/model/rest/base';
import { StudentShoppingCartService } from 'src/app/services/student-shopping-cart.service';
declare var $: any;

@Directive()
export abstract class CoreProductsBase implements OnInit {
  lights: ApiProduct[];
  std: ApiProduct[];
  intensive: ApiProduct[];
  pag: ApiProduct[];
  _currency: string;
  books: ApiProduct[];

  @Input()
  productCode: string;
  @Input()
  studentId: string;

  hideStarterOffers = true;

  constructor(
    protected studentCache: StudentCacheProxyService, 
    public locale: string,
    private shoppingCart: StudentShoppingCartService
    ) {
  }

  @Input()
  set currency(currency: string) {
    this._currency = currency;
    this.refreshProducts();
  }

  onStarterOfferEvent(event: StudentStartersOfferComponentEvent) {
    this.hideStarterOffers =
      (event.eventType === ComponentEvent.ET_LOADING
        || (event.eventType === ComponentEvent.ET_TOTAL_ELEMENTS && event.totalElements === 0));
  }

  refreshProducts(): any {
    if (!this._currency) {return; }
    if (!this.locale) {
      this.locale = 'en';
    }
    this.locale = this.locale.toUpperCase();
    this.studentCache.listProducts(this.locale, this._currency).subscribe(products => this.filterProducts(products));
  }

  ngOnInit() {
  }

  filterProducts(products: ApiProduct[]): void {
    const englishProducts = ProductsUtils.filterByProductLabel(products, this.getProductsLabel());
    this.lights = ProductsUtils.orderByOrderLabel(ProductsUtils.filterByVariantLabel(englishProducts, this.getLightCategoryLabel()));
    this.std = ProductsUtils.orderByOrderLabel(ProductsUtils.filterByVariantLabel(englishProducts, this.getStdCategoryLabel()));
    this.intensive = ProductsUtils.orderByOrderLabel(ProductsUtils.filterByVariantLabel(englishProducts, this.getIntensiveCategoryLabel()));
    this.pag = ProductsUtils.orderByOrderLabel(ProductsUtils.filterByVariantLabel(englishProducts, this.getPaygCategoryLabel()));

    this.books = ProductsUtils.orderByOrderLabel(ProductsUtils.filterByProductLabel(products, this.getEbookCategoryLabel()));

  }
  abstract getEbookCategoryLabel(): string ;
  abstract getPaygCategoryLabel(): string ;
  abstract getIntensiveCategoryLabel(): string ;
  abstract getStdCategoryLabel(): string ;
  abstract getLightCategoryLabel(): string ;
  abstract getProductsLabel(): string;

  getImage(product: ApiProduct) {
    const imageLabel = product.productLabels.find( l => l.startsWith('image:'));
    if (!imageLabel) {return ''; }
    return imageLabel.substr('image:'.length);
  }
  
  addItem(product: ApiProduct) {
    this.shoppingCart.addItem(product)
  }
}

@Component({
  selector: 'app-english-core-products',
  templateUrl: './english-core-products.component.html',
  styleUrls: ['./english-core-products.component.css']
})
export class EnglishCoreProductsComponent extends CoreProductsBase {
  constructor(
    studentCache: StudentCacheProxyService, 
    @Inject(LOCALE_ID) locale: string, 
    shoppingCart: StudentShoppingCartService
  ) {
    super(studentCache, locale, shoppingCart);
  }

  getEbookCategoryLabel(): string {
    return 'category:ebook.en';
  }
  getPaygCategoryLabel(): string {
    return 'category:lessons.en.pag';
  }
  getIntensiveCategoryLabel(): string {
    return 'category:lessons.en.int';
  }
  getStdCategoryLabel(): string {
    return 'category:lessons.en.std';
  }
  getLightCategoryLabel(): string {
  return 'category:lessons.en.light';
  }
  getProductsLabel(): string {
    return ProductsUtils.EN_PRODUCT_LABEL;
  }
}
