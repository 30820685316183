<app-modal #createWorktimeModal>
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >Define Work Time at {{newWorktimeDate | date: 'EEE, dd MMM yyyy'}}</h5>
          <button type="button" class="btn-close" (click)="createWorktimeModal.hide()"  aria-label="Close"></button>
        </div>
        <form #g="ngForm" (ngSubmit)="g.form.valid && saveWorktime()">
        <div class="modal-body">
          <p>Please fill the availability form
          </p>
          <div class="form-row">
            <div class="form-group col">
              <label>Starting at</label>
              <input
                type="text"
                name="worktimeStartTime"
                class="form-control"
                [(ngModel)]="newWorktimeStartTime"
                placeholder="hh:mm"
                pattern="^((\d)|([0,1]\d)|(2[0-3])):[0,3][0]$"
                #startTime="ngModel"
                [ngClass]="{ 'is-invalid': g.submitted && startTime.invalid }"
                required="required">
                <small  class="form-text text-muted">Minimum time resolution is 30 minutes</small>
                <div *ngIf=" startTime.invalid" class="invalid-feedback">
                    <div *ngIf="startTime.errors.required">Start time is required</div>
                    <div *ngIf="startTime.errors.pattern">Write correct value i.e. 2, 2:00 or 2:30. Shorter
                      periods than 30 minutes are not available
                    </div>
                </div>
            </div>

            <div class="form-group col">
              <label>Ending at</label>
              <input
              name="worktimeFinish"
              type="text"
              class="form-control"
              [(ngModel)]="newWorktimeFinishTime"
              placeholder="hh:mm"
              pattern="^((\d)|([0,1]\d)|(2[0-3])):[0,3][0]$"
              #worktimeFinish="ngModel"
              [ngClass]="{ 'is-invalid': g.submitted && worktimeFinish.invalid }"
              required="required">
              <small  class="form-text text-muted">Time shortest period is 30 minutes</small>
              <div *ngIf=" worktimeFinish.invalid" class="invalid-feedback">
                  <div *ngIf="worktimeFinish.errors.required">Finish time is required</div>
                  <div *ngIf="worktimeFinish.errors.pattern">Write correct value i.e. 2, 2:00 or 2:30. Shorter
                    periods than 30 minutes are not available
                  </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Minimum booking time</label>
            <select
              required
              class="form-control"
              [(ngModel)]="newWorktimeOvertake"
              name="worktimeOvertake"
              #worktimeOvertake="ngModel"
              [ngClass]="{ 'is-invalid': g.submitted && worktimeOvertake.invalid }"
            >
              <option value="0">Always</option>
              <option value="1800000">0.5 Hour</option>
              <option value="3600000">1 hour</option>
              <option value="5400000">1.5 hour</option>
              <option value="7200000">2 hours</option>
              <option value="10800000">3 hours</option>
              <option value="14400000">4 hours</option>
              <option value="18000000">5 hours</option>
              <option value="21600000">6 hours</option>
              <option value="25200000">7 hours</option>
              <option value="28800000">8 hours</option>
              <option value="57600000">16 hours</option>
              <option value="86400000">24 hours</option>
            </select>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <label>Choose the course</label>
              <select
              required
              class="form-control"
              [(ngModel)]="newWorktimeProduct"
              name="newWorktimeProduct"
              [ngClass]="{ 'is-invalid': g.submitted && product.invalid }"
              #product="ngModel">
              <option [value]="allProductsCode">All</option>
                <option *ngFor="let prod of availableProducts"  [value]="prod">{{getProductName(prod)}}</option>
              </select>
              <div *ngIf="g.submitted && product.invalid" class="invalid-feedback">
                  <div *ngIf="product.errors.required">Select the product</div>
              </div>
            </div>
            <div class="form-group col">
              <label>Recurring Type</label>
              <select
              required
              class="form-control"
              name="recurring"
              [(ngModel)]="newWorktimeRecurring"
              [ngClass]="{ 'is-invalid': g.submitted && recurring.invalid }"
              #recurring="ngModel">
                <option *ngFor="let rec of recurringUtils.values" [value]="rec">{{recurringUtils.getName(rec)}}</option>
              </select>
              <div *ngIf="g.submitted && recurring.invalid" class="invalid-feedback">
                  <div *ngIf="recurring.errors.required">Select the recurring type</div>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="newWorktimeRecurring != recurringTypes.Single">
            <label>Recurring finish date</label>
            <input
            type="date"
            required
            name="recurringFinish"
            class="form-control"
            [(ngModel)]="newRecurringFinishDate"
            #recurringFinish="ngModel"
            appAfterDate="{{minRecurring}}"
            [ngClass]="{ 'is-invalid': g.submitted && recurringFinish.invalid }">
            <div *ngIf="g.submitted && recurringFinish.invalid" class="invalid-feedback">
                <div *ngIf="recurringFinish.errors.required">Choose recurring finish date</div>
                <div *ngIf="!recurringFinish.errors.required && recurringFinish.errors.invalidDate">Set valid date, at least after tomorow</div>
            </div>
          </div>
          <div *ngIf="newWorktimeDays && newWorktimeRecurring == recurringTypes.Daily">
              <div class="form-check form-check-inline">
                <input class="form-check-input" name="weekdays" type="checkbox" value="Monday" [(ngModel)]="newWorktimeDays['Monday']">
                <label class="form-check-label" >Monday</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" name="weekdays" type="checkbox" value="Tuesday"  [(ngModel)]="newWorktimeDays['Tuesday']">
                <label class="form-check-label" >Tuesday</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" name="weekdays" type="checkbox" value="Wednesday" [(ngModel)]="newWorktimeDays['Wednesday']" >
                <label class="form-check-label" >Wednesday</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" name="weekdays" type="checkbox" value="Thursday" [(ngModel)]="newWorktimeDays['Thursday']" >
                <label class="form-check-label" >Thursday</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" name="weekdays" type="checkbox" value="Friday" [(ngModel)]="newWorktimeDays['Friday']" >
                <label class="form-check-label" >Friday</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" name="weekdays" type="checkbox" value="Saturday" [(ngModel)]="newWorktimeDays['Saturday']" >
                <label class="form-check-label" >Saturday</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" name="weekdays" type="checkbox" value="Sunday" [(ngModel)]="newWorktimeDays['Sunday']" >
                <label class="form-check-label" >Sunday</label>
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="createWorktimeModal.hide()" >Close</button>
          <button type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
      </div>

</app-modal>
<app-modal #deleteWorktimeModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" >Deleting work time</h5>
      <button type="button" class="btn-close" (click)="deleteWorktimeModal.hide()"  aria-label="Close"></button>
    </div>
    <form #wdf="ngForm" (ngSubmit)="deleteWorktime()">
      <div class="modal-body">
        <p>Are you sure you want to delete work time starting starting at {{eventToDelete?.dateFrom | date: 'EEE, dd MMM yyyy HH:mm'}}?</p>
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              name="deleteRecurring"
              [(ngModel)]="deleteRecurring"
              >
            <label class="form-check-label" >
              Delete all recurring periods after selected.
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="deleteWorktimeModal.hide()" >Close</button>
        <button type="submit" class="btn btn-danger">Delete</button>
      </div>
  </form>
  </div>
</app-modal>

<app-week-calendar [loading]="loading" (dateUpdate)="focusDate = $event" [events]="calendarEntries" (timeSelect)="calendarClicked($event)"></app-week-calendar>
